<template>
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 13.25C9.95175 13.25 12.75 10.4517 12.75 7C12.75 3.54822 9.95175 0.75 6.5 0.75C3.04822 0.75 0.25 3.54822 0.25 7C0.25 10.4517 3.04822 13.25 6.5 13.25Z"
      fill="#18AC81"
    />
    <path
      d="M5.61195 9.2375C5.48695 9.2375 5.3682 9.1875 5.2807 9.1L3.51191 7.33125C3.33066 7.15 3.33066 6.85 3.51191 6.66875C3.69316 6.4875 3.99316 6.4875 4.17441 6.66875L5.61195 8.10625L8.82445 4.89375C9.0057 4.7125 9.3057 4.7125 9.48695 4.89375C9.6682 5.075 9.6682 5.375 9.48695 5.55625L5.9432 9.1C5.8557 9.1875 5.73695 9.2375 5.61195 9.2375Z"
      fill="white"
    />
  </svg>

</template>

<script>
export default {

}
</script>

<style scoped>
svg {
  margin-right: 9px;
}
</style>
