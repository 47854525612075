<template>
  <div>
    <h4 class="modal-title">
      {{ properties.title }}
    </h4>
    <h6 class="modal-subtitle">
      {{ properties.text }}
    </h6>
    <div class="d-flex justify-content-between mt-5">
      <b-button
        variant="outline-success pr-4 pl-4 mr-5"
        @click="hideModal('DeleteItem')"
      >
        Cancelar
      </b-button>
      <b-button
        class="btn-grad pr-4 pl-4 m-0"
        @click="deleteItem()"
      >
        Continuar
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  BButton,
} from 'bootstrap-vue'
import { mapGetters, mapMutations } from 'vuex'
import { fetchTableAfterDelete } from '@core/mixins/ui/forms'
import axios from '@/libs/axios'

export default {
  components: {
    BButton,
  },
  mixins: [fetchTableAfterDelete],
  props: {
    properties: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({ getItensToDelete: 'app/getItensDelete' }),
  },
  methods: {
    ...mapMutations({ setItensToDelete: 'app/SET_ITENS_DELETE' }),
    deleteItem() {
      const source = { idList: this.getItensToDelete.itens.map(arr => arr.id) }
      axios.delete(this.getItensToDelete.api, { data: source }).then(() => {
        this.toast({
          title: 'Excluído',
          text: 'Cadastro excluído com sucesso',
          variant: 'success',
        })
        this.hideModal('DeleteItem')
        this.fetchTable()
        this.setItensToDelete({ itens: [], api: '', fetchTable: '' })
      })
    },
  },
}
</script>
