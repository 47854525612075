<template>
  <div class="content-with-sidebar-layout">
    <div>
      <b-container
        class="cws-container"
        fluid
      >
        <div>
          <HeaderApp />
        </div>
        <div>
          <navigation-bar />
        </div>
        <page-title class="pt-3 fadeIn" />
        <router-view class="fadeIn" />
      </b-container>
    </div>
    <DeleteItem />
  </div>
</template>

<script>
import { BContainer } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import HeaderApp from '../header/Hearder.vue'
import NavigationBar from '../navigation/NavigationBar.vue'
import PageTitle from './PageTitle.vue'
import DeleteItem from '@/components/delete-item/DeleteItem.vue'

export default {
  components: {
    BContainer,

    HeaderApp,
    NavigationBar,
    PageTitle,
    DeleteItem,
  },
  computed: {
    ...mapGetters({ sidebarCollapsed: 'app/getSidebarCollapsed', getWindowSize: 'app/getWindowSize' }),
  },
}
</script>

<style lang="scss">

</style>
