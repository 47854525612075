import api from '@/libs/axios'

export default {
  getLicenseTypes: () => api.get('/system/license-type/general'),
  getLicenseTypeById: id => api.get(`/system/license-type/general/${id}`),
  editLicenseType: form => api.patch(`/system/license-type/general/${form.id}`, form),
  createLicenseType: form => api.post('/system/license-type/general', form),
  deleteLicenseTypes: array => api.delete('/system/license-type/general', array),

  getDocumentsCondintioning: () => api.get('/system/license-type/documents-condintioning'),
  getDocumentsCondintioningById: id => api.get(`/system/license-type/documents-condintioning/${id}`),
  getDocumentsCondintioningByLicenseTypeId: id => api.get(`/system/license-type/documents-condintioning/license-type/${id}`),
  editDocumentsCondintioning: form => api.patch(`/system/license-type/documents-condintioning/${form.id}`, form),
  createDocumentsCondintioning: form => api.post('/system/license-type/documents-condintioning', form),
  deleteDocumentsCondintionings: array => api.delete('/system/license-type/documents-condintioning', array),
}
