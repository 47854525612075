import api from '@/libs/axios'

export default {
  createTicket: newTicket => api.post('/tickets', newTicket),
  getTickets: () => api.get('/tickets'),
  getTicketMessages: protocol => api.get(`/tickets/messages/list/${protocol}`),
  setNewMessage: message => api.post('/tickets/messages', message),
  getUserTickets: () => api.get('/tickets/my-tickets'),
  editTicket: form => api.patch(`/tickets/${form.protocol}`, form),
  getTicketByid: id => api.get(`/tickets/find/${id}`),
  getTicketDashboardMaster: () => api.get('/tickets/dashboard'),
}
