export default [
  {
    path: '/empresa',
    name: 'company',
    component: () => import(/* webpackChunkName: "company" */ '@/views/company/Company.vue'),
    meta: {
      title: 'Empresa',
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'company',
    },
  },
  {
    path: '/empresa/:id/detalhes',
    name: 'company-details',
    component: () => import(/* webpackChunkName: "company-details" */ '@/views/company/CompanyDetails.vue'),
    meta: {
      title: 'Empresa',
      subTitle: true,
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'company',
    },
  },
]
