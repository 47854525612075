export default [
  {
    path: '/importacao/empresa',
    name: 'import-company',
    component: () => import(/* webpackChunkName: "my-account" */ '@/views/file-import/FileImportCompany.vue'),
    meta: {
      title: 'Importação Empresa',
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'dashboard',
      canAccessUserPlanDisabled: false,
    },
  },
  {
    path: '/importacao/empreendimento',
    name: 'import-enterprise',
    component: () => import(/* webpackChunkName: "my-account" */ '@/views/file-import/FileImportEnterprise.vue'),
    meta: {
      title: 'Importação Empreendimento',
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'dashboard',
      canAccessUserPlanDisabled: false,
    },
  },
  {
    path: '/importacao/:id/residuos',
    name: 'import-waste',
    component: () => import(/* webpackChunkName: "my-account" */ '@/views/file-import/FileImportWaste.vue'),
    meta: {
      title: 'Importação de Operações em Resíduos',
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'dashboard',
      canAccessUserPlanDisabled: false,
    },
  },
  {
    path: '/importacao/orgaos-licenciadores',
    name: 'import-public-agencies',
    component: () => import(/* webpackChunkName: "my-account" */ '@/views/file-import/FileImportPublicAgencies.vue'),
    meta: {
      title: 'Importação Órgãos Licenciadores',
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'dashboard',
      canAccessUserPlanDisabled: false,
    },
  },
  {
    path: '/importacao/fornecedor',
    name: 'import-provider',
    component: () => import(/* webpackChunkName: "my-account" */ '@/views/file-import/FileImportProvider.vue'),
    meta: {
      title: 'Importação Fornecedor',
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'dashboard',
      canAccessUserPlanDisabled: false,
    },
  },
  {
    path: '/importacao/licenca-condicionantes',
    name: 'import-license-table',
    component: () => import(/* webpackChunkName: "my-account" */ '@/views/file-import/FileImportLicenseTable.vue'),
    meta: {
      title: 'Importação Licenca e Condicionantes',
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'dashboard',
      canAccessUserPlanDisabled: false,
    },
  },
  {
    path: '/importacao/:id/condicionantes',
    name: 'import-license-conditions',
    component: () => import(/* webpackChunkName: "my-account" */ '@/views/file-import/FileImportLicenseConditions.vue'),
    meta: {
      title: 'Importação Condicionantes',
      menu: true,
      authRequired: true,
      action: 'read',
      subTitle: true,
      resource: 'dashboard',
      canAccessUserPlanDisabled: false,
    },
  },
  {
    path: '/importacao/licenciamento',
    name: 'import-licensing-table',
    component: () => import(/* webpackChunkName: "my-account" */ '@/views/file-import/FileImportLicensingTable.vue'),
    meta: {
      title: 'Importação Licenciamento',
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'dashboard',
      canAccessUserPlanDisabled: false,
    },
  },
  {
    path: '/importacao/:id/documentos',
    name: 'import-licensing-documents',
    component: () => import(/* webpackChunkName: "my-account" */ '@/views/file-import/FileImportLicensingDocuments.vue'),
    meta: {
      title: 'Importação Documentos e Atividades',
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'dashboard',
      canAccessUserPlanDisabled: false,
    },
  },
  {
    path: '/importacao/tipos-licenca',
    name: 'import-license-type',
    component: () => import(/* webpackChunkName: "my-account" */ '@/views/file-import/FileImportLicenseType.vue'),
    meta: {
      title: 'Importação Tipos de Licença',
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'dashboard',
      canAccessUserPlanDisabled: false,
    },
  },
]
