export default [
  {
    path: '/auth',
    name: 'auth',
    component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Auth.vue'),
    meta: {
      action: 'read',
      resource: 'auth',
    },
  },
]
