<template>
  <div>
    <h2>Sessão desconectada</h2>
    <p>Sua conta foi desconectada de uma sessão em %cidade%, %estado% no dispositivo %modelo_do_dipositivo%.
      Se você não reconhece esse dispositivo, altere imediatamente sua senha.</p>
    <div class="d-flex justify-content-between">
      <BButton
        variant="outline-primary"
        class="exit"
        @click="hideModal('DisconnectedSession')"
      >
        Sair
      </BButton>
      <BButton
        variant="primary"
        class="password"
      >
        Alterar minha senha
      </BButton>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },

}
</script>

<style lang='scss' scoped>
h2 {
font-weight: 600;
font-size: 21px;
line-height: 25px;
text-align: center;
letter-spacing: 0.75px;
color: #33383C;
}
p {
font-size: 13px;
line-height: 16px!important;
text-align: center;
letter-spacing: 0.75px;
color: #7D8E9E;
margin-bottom: 34px;
}
.password {
background: linear-gradient(90deg, #076E5F 0%, #18AC81 100%);
border-radius: 5px;
width: 208px;
height: 45px
}
.exit {
  width: 95px;
}
</style>
