/* eslint-disable */
import api from '@/libs/axios'

export const checkUserPlanActive = () => {
  const userIdAccount = JSON.parse(localStorage.getItem('id_account_verdesys'))
  api.get(`/accounts/${userIdAccount}`).then(response => {
    return response.data.isActive
  })
}

export const checkUserAccessDate = () => {
  localStorage.setItem('can_access', JSON.stringify(true))

  const localUserInfos = JSON.parse(localStorage.getItem('user_infos'))
  const expireDateIugu = localUserInfos?.expires_at
  const dueDate = localUserInfos?.recent_invoices[0]?.due_date;
  //expirationDate mais 5 dias
 
  //acrescenta 5 dias na data de expiração
   const expirationDateDef = new Date(localUserInfos?.expires_at)
   expirationDateDef?.setDate(expirationDateDef?.getDate() + 5)
   //formata mostra apenas yyyy-mm-dd


   const expirationDate = expirationDateDef instanceof Date && !isNaN(expirationDateDef) ? expirationDateDef.toISOString().split('T')[0] : null;


  const invoiceStatus = localUserInfos?.recent_invoices[0]?.status
  const planIdentifier = localUserInfos?.plan_identifier
  const recentInvoices = localUserInfos?.recent_invoices
  const id = localStorage.getItem('id_account_verdesys')

  //procura dentro do array recent_invoices o algum invoice com status paid, se tiver, retorna false, se não, retorna true
  const firstAccess = recentInvoices?.find(invoice => invoice.status === 'paid') ? false : true
  
  

  if (planIdentifier === 'plano_1' || planIdentifier === 'plano_2' || planIdentifier === 'plano_3' && id !== '25' && id !== '16' ) {
  
    console.log('primeiro acesso', firstAccess)

    if (firstAccess === true) {
      if (dueDate === expireDateIugu  && invoiceStatus !== 'paid') {
        localStorage.setItem('can_access', JSON.stringify(false))
        console.log('não pode acessar')
      }
    }
  }

  console.log('DUE', dueDate, 'EXPIRE', expirationDate, 'STATUS INVOICE', invoiceStatus)
  const userCanAccess = JSON.parse(localStorage.getItem('can_access'))
  return userCanAccess
}

export default {
  checkUserPlanActive,
  checkUserAccessDate,
}
