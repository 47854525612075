export default [
  {
    path: '/ajuda',
    name: 'help',
    component: () => import(/* webpackChunkName: "my-account" */ '@/views/help/Help.vue'),
    meta: {
      title: 'Ajuda',
      subTitle: true,
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'dashboard',
      canAccessUserPlanDisabled: true,
    },
  },
]
