<template>
  <div>
    <h4 class="modal-title">
      Relação de {{ properties.module === 'conditioning' ? 'Condicionantes' : 'Documentos' }}
    </h4>
    <div class="j-c-center">
      <h6 class="modal-subtitle w-75">
        Preencha todos os campos para criar uma relação de {{ properties.module === 'conditioning' ? 'Condicionantes' : 'Documentos' }}.
      </h6>
    </div>
    <div class="mt-4">
      <b-form-group label="Ano de vigência">
        <b-input-group>
          <b-form-input
            id="ano-vigencia"
            v-model="item.validity_year"
            v-mask="'####'"
            :disabled="disbleInputs"
            placeholder="Ano de vigência"
            type="number"
          />
        </b-input-group>
      </b-form-group>

      <b-form-group label="Título">
        <b-input-group>
          <b-form-input
            id="titulo"
            v-model="item.title"
            :disabled="disbleInputs"
            placeholder="Título"
            type="text"
          />
        </b-input-group>
      </b-form-group>

      <b-form-group label="Descrição">
        <b-input-group>
          <b-form-textarea
            id="descricao"
            v-model="item.description"
            :disabled="disbleInputs"
            placeholder="Descrição"
            type="text"
            rows="3"
          />
        </b-input-group>
      </b-form-group>

      <b-form-group label="Observação">
        <b-input-group>
          <b-form-tags
            v-model="tags"
            :disabled="disbleInputs"
            input-id="tags-basic"
            placeholder="Observação"
            input-class="form-control"
            :remove-on-delete="true"
            add-button-text="Adicionar"
            add-button-variant="outline-primary"
            duplicate-tag-text="Tag duplicada"
          />
        </b-input-group>
      </b-form-group>
    </div>
    <div class="d-flex justify-content-around mt-5">
      <b-button
        block
        variant="outline-primary mr-3"
        @click="hideModal('DocumentsConditioningRelations')"
      >
        Cancelar
      </b-button>
      <BButton
        v-if="!disbleInputs"
        variant="primary"
        @click="saveChanges()"
      >
        Salvar
      </BButton>
      <BButton
        v-else
        variant="primary"
        @click="() => {
          properties.action = 'edit'
          disbleInputs = false
        }"
      >
        Editar
      </BButton>
    </div>
  </div>
</template>
<script>
import {
  BFormGroup, BInputGroup, BFormInput, BButton, BFormTextarea, BFormTags,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import licenseType from '@/services/licenseType'

export default {
  components: {
    BFormGroup, BInputGroup, BFormInput, BButton, BFormTextarea, BFormTags,
  },
  props: {
    properties: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      disbleInputs: false,
      item: {},
      tags: [],
      messageCreated: {
        title: 'Criado!',
        text: 'Cadastro feito com sucesso.',
        variant: 'success',
      },
      messageEdited: {
        title: 'Editado!',
        text: 'Cadastro editado com sucesso.',
        variant: 'success',
      },
    }
  },
  created() {
    if (this.properties.action === 'read' || this.properties.action === 'edit') {
      this.item = this.properties.item
      this.tags = this.properties.item.observation.filter(arr => arr.isActive).map(arr => arr.title)
    }
    if (this.properties.action === 'read') {
      this.disbleInputs = true
    }
  },
  methods: {
    ...mapActions({ fetchDocumentsCondintioningByLicenseTypeId: 'licenseType/fetchDocumentsCondintioningByLicenseTypeId' }),
    // TODO: fazer ajuste no metode de editar pois ele não exclui as observações
    saveChanges() {
      this.item.observation = this.tags.map(arr => ({ title: arr }))
      this.item.license_type = Number(this.$route.params.id)
      this.item[this.properties.module] = true
      if (this.properties.action === 'create') {
        this.createDocumentsCondintioning()
      } else if (this.properties.action === 'edit') {
        this.editDocumentsCondintioning()
      }
    },
    createDocumentsCondintioning() {
      licenseType.createDocumentsCondintioning(this.item).then(() => {
        this.toast(this.messageCreated)
        this.fetchDocumentsCondintioningByLicenseTypeId(Number(this.$route.params.id))
        this.hideModal('DocumentsConditioningRelations')
      })
    },
    editDocumentsCondintioning() {
      licenseType.editDocumentsCondintioning(this.item).then(() => {
        this.toast(this.messageCreated)
        this.fetchDocumentsCondintioningByLicenseTypeId(Number(this.$route.params.id))
        this.hideModal('DocumentsConditioningRelations')
      })
    },
  },
}
</script>
