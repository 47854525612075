/* eslint-disable no-param-reassign */
import users from '@/services/users'

export default {
  namespaced: true,
  state: {
    users: [],
    usersMaster: [],
  },
  getters: {
    getUsers: state => state.users,
    getUsersMaster: state => state.usersMaster,
    getUsersOptions: state => state.users.filter(arr => arr.isActive === true).map(arr => ({ text: arr.full_name || 'Nome não informado', value: arr.id })),
    getUsersMasterOptions: state => state.usersMaster.filter(arr => arr.isActive === true).map(arr => ({ text: arr.full_name || 'Nome não informado', value: arr.id })).sort((a, b) => a.text.localeCompare(b.text)),
  },
  mutations: {
    SET_USERS(state, payload) {
      state.users = payload
    },
    SET_USERS_MASTER(state, payload) {
      state.usersMaster = payload
    },
  },
  actions: {
    fetchUsers({ commit }) {
      users.getUsers().then(res => { commit('SET_USERS', res.data) })
    },
    fetchUsersMaster({ commit }) {
      users.getUsersMaster().then(res => { commit('SET_USERS_MASTER', res.data.filter(arr => arr.isActive)) })
    },
  },
}
