<template>
  <div>
    <h4 class="modal-title">
      Upload de arquivo
    </h4>
    <h6 class="modal-subtitle">
      Arquivos suportados pdf, xsls, docx, png e jpeg.
    </h6>
    <validation-observer ref="form">
      <div class="mt-4">
        <validation-provider
          v-slot="{ errors }"
          name="nome do arquivo"
          rules="required|max:50"
        >
          <b-form-group label="Nome do arquivo">
            <b-input-group>
              <b-form-input
                id="nome-arquivo"
                v-model="form.file_name"
                type="text"
                :disabled="disbleInputs"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="descrição do arquivo"
          rules="required|max:50"
        >
          <b-form-group label="Descrição do arquivo">
            <b-input-group>
              <b-form-input
                id="descricao-arquivo"
                v-model="form.file_description"
                type="text"
                :disabled="disbleInputs"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="arquivo"
          rules="required"
        >
          <b-form-group
            v-if="properties.action === 'create'"
            label="Anexar arquivo "
          >
            <b-form-file
              v-model="files"
              placeholder="Selecione um arquivo ou solte aqui..."
              drop-placeholder="Solte o arquivo aqui..."
              accept=".jpg, .png, .docx, .jpeg, .pdf, .xsls"
            />
            <small v-if="!files" class="text-danger">{{ errors[0] }}</small>
            <b-badge
              v-if="files"
              variant="primary"
              class="mt-3"
            >
              <span class=" text-wrap">
                {{ files ? files.name : null }}
              </span>
              <b-button
                variant=""
                class="bg-transparent border-0 size-10"
                @click="cleanFiles()"
              >
                <span>
                  x
                </span>
              </b-button>
            </b-badge>
          </b-form-group>
        </validation-provider>
      </div>
    </validation-observer>
    <div class="d-flex justify-content-around mt-5">
      <b-button
        block
        variant="outline-primary mr-3"
        @click="hideModal('UploadFile')"
      >
        Cancelar
      </b-button>
      <b-button
        block
        class="btn-grad m-0"
        @click="sendUpload()"
      >
        Continuar
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  BFormGroup, BInputGroup, BFormInput, BFormFile, BBadge, BButton,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import upload from '@/services/upload'

export default {
  components: {
    BFormGroup, BInputGroup, BFormInput, BFormFile, BBadge, BButton,
  },
  props: {
    properties: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      disbleInputs: false,
      files: null,
      form: {},
    }
  },

  created() {
    if (this.properties.action === 'read' || this.properties.action === 'edit') {
      this.form = this.properties.item
      this.files = this.properties.item.attachments
    }
    if (this.properties.action === 'read') { this.disbleInputs = true }
  },
  methods: {

    ...mapActions({ uploadFile: 'upload/uploadFile', fetchUploads: 'upload/fetchUploads' }),
    sendUpload() {
      this.$refs.form.validate().then(success => {
        if (success) {
          if (this.properties.action === 'create') {
            const formatData = new FormData()
            if (this.files) formatData.append('attachment_file', this.files)
            if (this.properties.relation) formatData.append(this.properties.relation, this.$route.params.id)
            formatData.append('file_name', this.form.file_name)
            formatData.append('file_description', this.form.file_description)

            if (this.properties.relations) {
              this.properties.relations.forEach(element => {
                formatData.append(element.name, Number(element.id))
              })
            }

            const formUpload = formatData
            upload.createUploadFile(formUpload).then(() => {
              this.fetchUploads()
              this.toast({
                title: 'Criado!',
                text: 'Upload criado com sucesso',
                variant: 'success',
              })
              this.hideModal('UploadFile')
            }).catch(e => {
              console.warn(e.response)
              this.toast({
                title: 'Erro!',
                text: 'Verifique o arquivo e os dados informados e tente novamente',
                variant: 'erro',
              })
            })
          } else if (this.properties.action === 'edit') {
            upload.editUpload(this.form, this.properties.item.id).then(() => {
              this.fetchUploads()
              this.toast({
                title: 'Atualizado!',
                text: 'Upload atualizado com sucesso',
                variant: 'success',
              })
              this.hideModal('UploadFile')
            }).catch(e => {
              console.warn(e.response)
              this.toast({
                title: 'Erro!',
                text: 'Verifique o arquivo e os dados informados e tente novamente',
                variant: 'erro',
              })
            })
          }
        }
      })
    },
    cleanFiles() {
      this.files = null
    },
  },
}
</script>
<style lang="scss" scoped>
.size-10 {
  padding: 0;
  margin: 0;
  width: 8px !important;
  height: 19px !important;
}
.badge-upload {
  max-width: 70%;
}
</style>
