<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 5 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="2.5"
      cy="3"
      r="2.5"
      fill="#A0AEB9"
    />
    <circle
      cx="2.5"
      cy="11"
      r="2.5"
      fill="#A0AEB9"
    />
    <circle
      cx="2.5"
      cy="19"
      r="2.5"
      fill="#A0AEB9"
    />
  </svg>

</template>
<script>
export default {
  props: {
    width: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
}
</script>
