<template>
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10"
      cy="17"
      r="2"
      fill="#A0AEB9"
    />
    <circle
      cx="17"
      cy="17"
      r="2"
      fill="#A0AEB9"
    />
    <circle
      cx="24"
      cy="17"
      r="2"
      fill="#A0AEB9"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
}
</script>
