import tickets from '@/services/tickets'

export default {
  namespaced: true,
  state: {
    tickets: [],
    userTickets: [],
  },
  getters: {
    getTickets: state => state.tickets.filter(arr => arr.isActive),
    getUserTickets: state => state.userTickets.filter(arr => arr.isActive),
  },
  mutations: {
    SET_TICKETS(state, payload) {
      state.tickets = payload
    },
    SET_USER_TICKETS(state, payload) {
      state.userTickets = payload
    },
  },
  actions: {
    fetchTickets({ commit }) {
      tickets.getTickets().then(res => { commit('SET_TICKETS', res.data) })
    },
    fetchUserTickets({ commit }) {
      tickets.getUserTickets().then(res => { commit('SET_USER_TICKETS', res.data) })
    },
  },
}
