<template>
  <div>
    <h4 class="modal-title">
      Feedbacks
    </h4>
    <h6 class="modal-subtitle">
      Envie suas susgestões e reclamações relacionadas
      a essa página da VerdeSys.
    </h6>

    <div class="emoji-box">
      <div
        class="emoji"
        :class="selectedEmotion === 'emojiHeart' ? 'emoji-selected' : '' "
        @click="selectEmotion('emojiHeart')"
      >
        <VSIcons
          height="32"
          width="32"
          name="EmojiHeart"
        />
      </div>
      <div
        class="emoji"
        :class="selectedEmotion === 'emojiHappy' ? 'emoji-selected' : '' "
        @click="selectEmotion('emojiHappy')"
      >
        <VSIcons
          height="32"
          width="32"
          name="EmojiHappy"
        />
      </div>
      <div
        class="emoji"
        :class="selectedEmotion === 'emojiSad' ? 'emoji-selected' : '' "
        @click="selectEmotion('emojiSad')"
      >
        <VSIcons
          height="32"
          width="32"
          name="EmojiSad"
        />
      </div>
    </div>
    <div class="mt-3">
      <b-form-group label="Assunto">
        <b-input-group>
          <b-form-input
            id="titulo"
            v-model="subject"
            type="text"
          />
        </b-input-group>
      </b-form-group>
      <b-form-group label="Descreva o que está acontecendo">
        <b-input-group>
          <b-form-textarea
            id="descricao"
            v-model="description"
            rows="10"
            min-rows="10"
            type="text"
          />
        </b-input-group>
      </b-form-group>
      <!-- <b-form-group label="Anexar arquivo">
        <b-input-group>
          <b-form-file
            id="file"
            v-model="anexos"
            multiple
            placeholder="Selecione um arquivo ou solte aqui..."
            drop-placeholder="Solte o arquivo aqui..."
            @input="fileFormat"
          />
        </b-input-group>
      </b-form-group> -->
    </div>
    <!-- <div class="feedbacks-anexos">
      <b-img
        v-for="img in anexos"
        :key="img.$path"
        height="70"
        width="70"
        class="feedback-anexo"
        :src="img.name"
      />
    </div> -->
    <div class="d-flex justify-content-between mt-2">
      <b-button
        variant="outline-success pr-4 pl-4 mr-5"
        @click="hideModal('Feedback')"
      >
        Cancelar
      </b-button>
      <b-button
        class="btn-grad pr-4 pl-4 m-0"
        @click="sendFeedback()"
      >
        Continuar
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup, BInputGroup, BFormInput, BButton, BFormTextarea,
} from 'bootstrap-vue'
import VSIcons from '@/assets/icons/VSIcons.vue'
import feedback from '@/services/feedback'

export default {
  components: {
    VSIcons,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BButton,
  },
  data() {
    return {
      description: '',
      subject: '',
      selectedEmotion: 'emojiHeart',
      anexos: null,
      data: null,
    }
  },

  methods: {
    selectEmotion(emotion) {
      this.selectedEmotion = emotion
    },
    sendFeedback() {
      const newFeedback = {
        reaction: this.selectedEmotion,
        subject: this.subject,
        description: this.description,
        images: [1, 2],
      }

      if (this.description.length <= 0 || this.subject.length <= 0 || this.selectedEmotion.length <= 0) {
        this.toast({
          title: 'Campos vazios',
          text: 'Por favor, preencha todos os campos antes de enviar.',
          variant: 'warning',
        })
      } else {
        feedback.createFeedback(newFeedback).then(() => {
          this.toast({
            title: 'Enviado com sucesso',
            text: 'Seu feedback foi enviado com sucesso!',
            variant: 'success',
          })
          // this.$router.go('')
        })
      }
    },
    fileFormat() {
    },
  },
}
</script>

<style lang="scss" scoped>
.emoji-box {
  display: flex !important;
  justify-content: space-evenly;
  margin-top: 1rem;
  align-items: center;
}
.feedbacks-anexos {
  display: flex;
  max-height: 70px;
  overflow-y: hidden;
  padding: 0px 0px 10px;
}
.feedback-anexo {
  border-radius: 13px;
  border: 3px solid rgba(160, 174, 185, 0.21);
  margin-right: 6px;
  &:hover {
    border-color: rgba(27, 31, 34, 0.21);
  }
}
.emoji {
  cursor: pointer;
  transition: .5s ease;
  border-radius: 30px;
  border: 10px solid rgba(255, 255, 255, 0.34);
  &-selected {
    border-color:rgba(160, 174, 185, 0.34);
  }
}
</style>
