import supplier from '@/services/supplier'

export default {
  namespaced: true,
  state: {
    providerData: null,
    providers: [],
    providersOptions: [{ text: 'Nenhuma das opções', value: null }],
  },
  getters: {
    getProviderData: state => state.providerData,
    getProviders: state => state.providers,
    getProviderOptions: state => state.providersOptions,
  },
  mutations: {
    SET_PROVIDER_DATA(state, payload) {
      state.providerData = payload
    },
    SET_PROVIDERS(state, payload) {
      state.providers = payload
    },
  },
  actions: {
    fetchProviders({ state, commit }) {
      return new Promise(resolve => {
        supplier.getSuppliers().then(res => {
          commit('SET_PROVIDERS', res.data.filter(arr => arr.isActive))
          state.providersOptions = [{ text: 'Nenhuma das opções', value: null }]
          state.providersOptions.push(...state.providers.map(arr => ({
            text: arr.fantasy_name || arr.full_name, value: arr.id, cnpjCpf: arr.isLegalPerson ? arr.cnpj : arr.cpf, isLegalPerson: arr.isLegalPerson,
          })).sort((a, b) => a.text.localeCompare(b.text)))
          resolve(res)
        })
      })
    },
    fetchProviderById({ commit }, id) {
      return new Promise(resolve => {
        supplier.getSupplierById(id).then(res => {
          commit('SET_PROVIDER_DATA', res.data)
          resolve(res)
        })
      })
    },
  },
}
