<template>
  <div
    class="avatar-user "
  >
    <b-avatar
      :size="size"
      variant="primary"
      :src="src"
    />
    <div
      v-if="upload"
      class="avatar-user-edit"
      @click="openModal()"
    >
      <div
        class="avatar-user-edit-bg"
      >
        <VSIcons name="EditImageAvatar" />
      </div>
    </div>
    <VSModal
      name="AddNewAvatarUser"
      :width="400"
    />
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
import VSIcons from '@/assets/icons/VSIcons.vue'
import VSModal from '@/components/modals/VSModal.vue'

export default {
  components: {
    BAvatar,
    VSIcons,
    VSModal,
  },
  props: {
    size: {
      type: String,
      default: '45px',
    },
    upload: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: '',
    },
  },
  methods: {
    openModal() {
      this.showModal('AddNewAvatarUser')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
