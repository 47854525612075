import licensing from '@/services/licensing'

export default {
  namespaced: true,
  state: {
    licensingData: null,
    licensing: [],
    licensingDocuments: [],
    licensingDashboardPerYear: [],
    licensingDashboardPerMonth: [],
    licensingModeView: '',
    apiLicensingIncall: false,
    isDocsAndActTabActive: false,
  },
  getters: {
    getLicensingData: state => state.licensingData,
    getLicensing: state => state.licensing,
    getLicensingDocuments: state => state.licensingDocuments,
    getLicensingDashboardPerYear: state => state.licensingDashboardPerYear.map(arr => {
      const obj = {
        jan: '0', fev: '0', mar: '0', abr: '0', mai: '0', jun: '0', jul: '0', ago: '0', set: '0', out: '0', nov: '0', dez: '0',
      }
      if (arr['01']) {
        obj.jan = arr['01']
      }
      if (arr['02']) {
        obj.fev = arr['02']
      }
      if (arr['03']) {
        obj.mar = arr['03']
      }
      if (arr['04']) {
        obj.abr = arr['04']
      }
      if (arr['05']) {
        obj.mai = arr['05']
      }
      if (arr['06']) {
        obj.jun = arr['06']
      }
      if (arr['07']) {
        obj.jul = arr['07']
      }
      if (arr['08']) {
        obj.ago = arr['08']
      }
      if (arr['09']) {
        obj.set = arr['09']
      }
      if (arr['10']) {
        obj.out = arr['10']
      }
      if (arr['11']) {
        obj.nov = arr['11']
      }
      if (arr['12']) {
        obj.dez = arr['12']
      }

      return obj
    }),
    getLicensingDashboardPerMonth: state => state.licensingDashboardPerMonth,
    getLicensingModeView: state => state.licensingModeView,
    getLicensingApiInCall: state => state.apiLicensingIncall,
    getIsDocsAndActTabActive: state => state.isDocsAndActTabActive,
  },
  mutations: {
    SET_LICENSING_DATA(state, payload) {
      state.licensingData = payload
    },
    SET_LICENSING(state, payload) {
      state.licensing = payload
    },
    SET_LICENSING_DOCUMENTS(state, payload) {
      state.licensingDocuments = payload
    },
    SET_LICENSING_DASHBOARD_PER_YEAR(state, payload) {
      state.licensingDashboardPerYear = payload
    },
    SET_LICENSING_DASHBOARD_PER_MONTH(state, payload) {
      state.licensingDashboardPerMonth = payload
    },
    SET_LICENSING_MODE_VIEW(state, payload) {
      state.licensingModeView = payload
    },
    SET_API_LICENSING_IN_CALL(state, payload) {
      state.apiLicensingIncall = payload
    },
    SET_IS_DOCS_AND_ACT_TAB_ACTIVE(state, payload) {
      state.isDocsAndActTabActive = payload
    },
  },
  actions: {
    fetchLicensing({ commit, state }) {
      licensing.getLicensing().then(res => {
        commit('SET_LICENSING', res.data.filter(arr => arr.isActive))
        state.apiLicensingIncall = false
      })
    },
    fetchLicensingById({ commit }, id) {
      licensing.getLicensingById(id).then(res => commit('SET_LICENSING_DATA', res.data))
    },
    fetchLicensingDocuments({ commit }, id) {
      licensing.getLicensingDocuments().then(res => commit('SET_LICENSING_DOCUMENTS', res.data.filter(arr => arr.isActive && Number(id) === arr.licensing_id)))
    },
    fetchLicensingDashboardPerYear({ commit }, year) {
      licensing.getLicensingDashboardPerYear(year).then(res => commit('SET_LICENSING_DASHBOARD_PER_YEAR', res.data))
    },
    fetchLicensingDashboardPerMonth({ commit }, obj) {
      licensing.getLicensingDashboardPerMonth(obj).then(res => commit('SET_LICENSING_DASHBOARD_PER_MONTH', res.data))
    },
    fetchAndActivateDocsAndActTab({ commit }, status) {
      commit('SET_IS_DOCS_AND_ACT_TAB_ACTIVE', status)
    },
  },
}
