<template>
  <div class="wrapper pl-4">
    <div class="d-flex">
      <img
        :src="require(`@/assets/images/${userImage}`)"
        class="user-img"
      >
      <div class="d-flex flex-column justify-content-center">
        <span class="username mb-1"> {{ username }} </span>
        <span class="date">{{ date }}</span>
      </div>
    </div>
    <p class="message">
      {{ message }}
    </p>
    <hr class="my-4">
  </div>
</template>

<script>
export default {
  props: {
    userImage: {
      type: String,
      required: true,
    },
    username: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang='scss' scoped>
.username {
font-weight: 600;
font-size: 15px;
line-height: 100%;
color: #33383C;
opacity: 0.87;
}
.date {
font-size: 13px;
line-height: 100%;
color: #7D8E9E;
}
.user-img {
  margin-right: 9px;
}
p {
font-size: 15px;
line-height: 22px;
color: #7D8E9E;
margin-top: 13px;
overflow-wrap: break-word;
}

</style>
