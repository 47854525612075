<template>
  <div class="">
    <div class="notifications">
      <div class="white-filter" />
      <h2>Notificações</h2>
      <p>Todas as notificações recebidas.</p>
      <div
        v-if="notifications.length > 0"
        class="pr-2"
      >
        <NotificationItem
          v-for="notification in notifications"
          :key="notification.id"
          :title="notification.subject"
          :text="notification.message"
          :day="notification.sendDate"
          :author="notification.sender"
          :read="notification.status !== 'Read'"
        />
      </div>
      <div
        v-else
        class="d-flex justify-content-center"
      >
        <span
          class="text-center font-15 font-weight-600"
        >
          Nenhuma notificação recebida.
        </span>

      </div>

    </div>
    <BButton
      variant="outline-primary"
      class="d-block mx-auto mt-5 w-50"
      @click="hideModal('Notifications')"
    >
      Fechar
    </BButton>
  </div>

</template>

<script>
import { BButton } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import NotificationItem from './notification-item/NotificationItem.vue'

export default {
  components: {
    NotificationItem,
    BButton,
  },
  computed: { ...mapGetters({ notifications: 'announcements/getMyAnnouncements' }) },
}
</script>

<style lang='scss' scoped>
.notifications {
   max-height: 500px;
   overflow-y: scroll;
   overflow-x: hidden;
   position: relative;
}
h2 {
font-weight: 600;
font-size: 21px;
line-height: 25px;
text-align: center;
letter-spacing: 0.75px;
color: #33383C;
text-align: center;
margin-top: -70px;
}
p {
font-weight: 500;
font-size: 13px;
line-height: 16px;
text-align: center;
letter-spacing: 0.75px;
color: #7D8E9E;
}
.white-filter {
   display: block;
   width: 100%;
   height: 90px;
   background: rgba(255, 255, 255, .4);
   position: sticky;
   top: 80%;
   left: 0;
   z-index: 10;
}
</style>
