<template>
  <div class="">
    <h2>Qual será o método de cadastro?</h2>
    <div class="d-flex justify-content-between mt-4">
      <b-row>
        <b-col md="6">
          <BButton
            variant="outline-primary"
            class="button"
            @click="createNewRegister()"
          >
            Novo Cadastro
          </BButton>
        </b-col>
        <b-col md="6">
          <BButton
            variant="outline-primary"
            class="button"
            @click="createNewRegisterFromImport()"
          >
            Importação
          </BButton>
        </b-col>
      </b-row>
    </div>
    <div class="d-flex justify-content-center mt-4">
      <b-row>
        <b-col
          md="12"
        >
          <BButton
            variant="danger"
            class="button"
            @click="hideModal('Import')"
          >
            Cancelar
          </BButton>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BButton, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
  },
  data() {
    return {}
  },
  props: {
    properties: Object 
  },
  computed: { },
  created() {
  },
  methods: {
    openModal() {
      this.hideModal('Import')
      this.showModal('AddNewDocumentsActivies')
    },
    createNewRegister() {
      console.log(this.$route)
      switch (this.$route.name) {
        case 'licensing': {
          this.$router.push('licenciamento/adicionar/detalhes')
          break
        }
        case 'licensing-details': {
          this.openModal();
          break
        }
        case 'license': {
          this.$router.push('licenca/adicionar/detalhes')
          break
        }
        case 'license-details': {
          this.openModal();
          break
        }
        case 'company': {
          this.$router.push('empresa/adicionar/detalhes')
          break
        }
        case 'enterprise': {
          this.$router.push('empreendimento/adicionar/detalhes')
          break
        }
        case 'license-type': {
          this.$router.push('tipos-licenca/adicionar/detalhes')
          break
        }
        case 'public-agencies': {
          this.$router.push('orgaos-publicos/adicionar/detalhes')
          break
        }
        case 'provider': {
          this.$router.push('fornecedor/adicionar/detalhes')
          break
        }
        default:

          break
      }
    },
    createNewRegisterFromImport() {
      console.log('testeIf1', this.$route.params.id)
      switch (this.$route.name) {
        case 'licensing': {
          this.$router.push('/importacao/licenciamento')
          break
        }
        case 'licensing-details': {
          this.$router.push(`/importacao/${this.$route.params.id}/documentos`)
          break
        }
        case 'license': {
          this.$router.push('/importacao/licenca-condicionantes')
          break
        }
        case 'license-details': {
          this.$router.push(`/importacao/${this.$route.params.id}/condicionantes`)
          break
        }
        case 'company': {
          this.$router.push('/importacao/empresa')
          break
        }
        case 'enterprise': {
          this.$router.push('/importacao/empreendimento')
          break
        }
        case 'license-type': {
          this.$router.push('/importacao/tipos-licenca')
          break
        }
        case 'public-agencies': {
          this.$router.push('/importacao/orgaos-licenciadores')
          break
        }
        case 'provider': {
          this.$router.push('/importacao/fornecedor')
          break
        }
        default:

          break
      }
    },
  },
  mounted() {
  },
}
</script>

<style lang='scss' scoped>
.modal-content {
  box-shadow: none !important;
}

h2 {
  font-size: 21px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.75px;
  color: #33383C;
}

p {
  font-weight: 500;
  color: #7D8E9E;
  font-size: 13px;
  line-height: 100%;
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.75px;
}

button {
  width: 190px;
}

.signatureValid {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 100%;
  border: 2px solid #E0E0E0;
  border-radius: 4px;
  background: #FFFFFF;
}

#vencimento {
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  letter-spacing: 0.75px;
  color: #7D8E9E;
  margin-bottom: 0!important;
}

</style>
