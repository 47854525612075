<template>
  <div>
    <h2 v-if="properties.action === 'create'">
      Adicionar responsáveis
    </h2>
    <h2 v-if="properties.action === 'read'">
      Detalhes responsáveis
    </h2>
    <h2 v-if="properties.action === 'edit'">
      Editar responsáveis
    </h2>
    <validation-observer ref="form">
      <b-row>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="nome"
            rules="required|max:100"
          >
            <b-form-group label="Nome">
              <b-input-group>
                <b-form-input
                  id="nome"
                  v-model="item.name"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="cpf"
            rules="required|cpf"
          >
            <b-form-group label="CPF">
              <b-input-group>
                <b-form-input
                  id="cpf"
                  v-model="item.cpf"
                  v-mask="'###.###.###-##'"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="telefone"
            rules="phone"
          >
            <b-form-group label="Telefone">
              <b-input-group>
                <b-form-input
                  id="telefone"
                  v-model="item.telephone"
                  v-mask="maskTelephone(item.telephone)"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="e-mail"
            rules="email"
          >
            <b-form-group label="E-mail">
              <b-input-group>
                <b-form-input
                  id="email"
                  v-model="item.email"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="profissão"
            rules="max:70"
          >
            <b-form-group label="Profissão">
              <b-input-group>
                <b-form-input
                  id="profissao"
                  v-model="item.occupation"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="reg. profissional"
            rules="max:100"
          >
            <b-form-group label="Reg. Profissional">
              <b-input-group>
                <b-form-input
                  id="regProfissional"
                  v-model="item.professional_registration"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="tipo"
            rules="required"
          >
            <b-form-group label="Tipo">
              <b-input-group>
                <b-form-select
                  id="tipo"
                  v-model="item.type"
                  :options="typeOptions"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <div v-if="properties.action === 'create'">
        <b-row>
          <b-col>
            <validation-provider
              v-slot="{ errors }"
              name="nome arquivo"
              rules="max:100"
            >
              <b-form-group label="Nome do Arquivo">
                <b-input-group>
                  <b-form-input
                    id="nomeArquivo"
                    v-model="item.file_name"
                    type="text"
                    :disabled="disbleInputs"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="8">
            <b-form-group label="Descrição do Arquivo">
              <b-input-group>
                <b-form-input
                  id="descricaoArquivo"
                  v-model="item.file_description"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Anexar arquivo "
            >
              <b-form-file
                v-model="files"
                placeholder="Selecione um arquivo ou solte aqui..."
                drop-placeholder="Solte o arquivo aqui..."
                accept=".jpg, .png, .docx, .jpeg, .pdf, .xsls"
              />
              <b-badge
                v-if="files"
                variant="primary"
                class="mt-3"
              >
                {{ files ? files.name : null }}
                <b-button
                  variant=""
                  class="bg-transparent border-0 size-10"
                  @click="cleanFiles()"
                >
                  <span>
                    x
                  </span>
                </b-button>
              </b-badge>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </validation-observer>
    <div class="d-flex justify-content-between">
      <BButton
        variant="outline-primary"
        @click="hideModal('AddResponsables')"
      >
        Cancelar
      </BButton>
      <BButton
        variant="primary"
        @click="saveChanges()"
      >
        Continuar
      </BButton>
    </div>
  </div>

</template>

<script>
import {
  BFormInput, BInputGroup, BFormGroup, BFormSelect, BFormFile, BBadge, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import { maskPhone } from '@core/mixins/ui/forms'
import accountable from '@/services/accountable'
import upload from '@/services/upload'

export default {
  components: {
    BFormInput, BInputGroup, BFormGroup, BFormSelect, BFormFile, BBadge, BButton, BRow, BCol,
  },
  mixins: [
    maskPhone,
  ],
  props: {
    properties: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      disbleInputs: false,
      files: null,
      item: {},
      typeOptions: [{ text: 'Nenuma das opções', value: null }, 'Técnico', 'Legal', 'Contato', 'Procurador'],
    }
  },
  created() {
    if (this.properties.action === 'read' || this.properties.action === 'edit') {
      this.item = this.properties.item
      if (this.item.file_name) this.item.file_name = this.properties.item.attachment[0].file_name
      if (this.item.file_description) this.item.file_description = this.properties.item.attachment[0].file_name
    }
    if (this.properties.action === 'read') { this.disbleInputs = true }
  },
  methods: {
    ...mapActions({
      fetchAccountable: 'accountable/fetchAccountable',
      fetchUploads: 'upload/fetchUploads',
    }),
    convertFileData(item, files) {
      return new Promise(resolve => {
        const formatData = new FormData()
        formatData.append('name', JSON.stringify(item.name))
        formatData.append('cpf', JSON.stringify(item.cpf))
        formatData.append('attachment_file', files)
        resolve(formatData)
      })
    },
    saveChanges() {
      this.$refs.form.validate().then(success => {
        if (success) {
          if (this.properties.module) {
            this.item.licensingCondition_id = Number(this.$route.params.id)
          } else {
            this.item.licensing_id = Number(this.$route.params.id)
          }
          if (this.properties.action === 'create') {
            accountable.createAccountable(this.item).then(res => {
              if (this.files) {
                this.createUpload(res.data.id)
              }
              this.createdMessage()
              this.fetchAccountable()
            })
          } else if (this.properties.action === 'edit') {
            accountable.editAccountable(this.item).then(() => {
              this.updateMessage()
              this.fetchAccountable()
            })
          }
          this.hideModal('AddResponsables')
          this.fetchAccountable()
        }
      })
    },
    createUpload(id) {
      const formatData = new FormData()
      formatData.append('attachment_file', this.files)
      formatData.append('file_name', this.item.file_name)
      formatData.append('file_description', this.item.file_description)
      formatData.append('accountable_id', id)
      if (this.properties.module) {
        formatData.append('licensingCondition_id', this.$route.params.id)
      } else formatData.append('licensing_id', this.$route.params.id)

      const formUpload = formatData
      upload.createUploadFile(formUpload).then(() => {
        this.fetchUploads()
      })
    },
    createdMessage() {
      this.toast({
        title: 'Criado !',
        text: 'Cadastro realizado com sucesso.',
        variant: 'success',
      })
    },
    updateMessage() {
      this.toast({
        title: 'Editado !',
        text: 'Cadastro atualizado com sucesso.',
        variant: 'success',
      })
    },
  },
}
</script>

<style lang='scss' scoped>
h2 {
font-weight: 600;
font-size: 21px;
line-height: 25px;
text-align: center;
letter-spacing: 0.75px;
color: #33383C;

}

button {
width: 190px;
height: 45px;
}
.size-10 {
  padding: 0;
  margin: 0;
  width: 8px !important;
  height: 19px !important;
}
</style>
