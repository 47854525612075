import announcements from '@/services/announcements'

export default {
  namespaced: true,
  state: {
    announcements: [],
    announcementsData: null,
    myAnnouncements: [],
  },
  getters: {
    getAnnouncements: state => state.announcements,
    getAnnouncementsData: state => state.announcementsData,
    getMyAnnouncements: state => state.myAnnouncements,
  },
  mutations: {
    SET_ANNOUNCEMENTS_DATA(state, payload) {
      state.announcementsData = payload
    },
    SET_ANNOUNCEMENTS(state, payload) {
      state.announcements = payload
    },
    SET_MY_ANNOUNCEMENTS(state, payload) {
      state.myAnnouncements = payload
    },
  },
  actions: {
    fetchAnnouncements({ commit }, id) {
      announcements.getAnnouncements(id).then(res => commit('SET_ANNOUNCEMENTS', res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))))
    },
    fetchMyAnnouncements({ commit }) {
      return new Promise(resolve => {
        announcements.getMyAnnouncements().then(res => {
          const notificationsSorted = res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          commit('SET_MY_ANNOUNCEMENTS', notificationsSorted)
          return resolve(notificationsSorted)
        })
      })
    },
  },
}
