<template>
  <div>
    <h2> Desconectar de todas as sessões </h2>
    <p>Por motivos de segurança iremos apagar todas as sessões anteriores, também será necessário criar uma nova senha de acesso.</p>
    <BButton
      variant="primary"
      class="d-block mx-auto"
    >
      Continuar
    </BButton>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
}
</script>

<style lang='scss' scoped>
h2 {
font-weight: 600;
font-size: 21px;
line-height: 25px;
letter-spacing: 0.75px;
color: #33383C;
}
p {
font-weight: 500;
font-size: 13px;
line-height: 16px;
letter-spacing: 0.75px;
color: #7D8E9E;
text-align: center;
}
button {
  margin-top: 34px;
}
</style>
