import waste from '@/services/waste'

export default {
  namespaced: true,
  state: {
    wasteData: null,
    waste: [],
    wasteDashboardPerMonth: [],
    wasteInventory: [],
    wasteOperations: [],
    wasteOptions: [{ text: 'Nenhuma das opções', value: null }],
    classificationsOptions: [
      { text: 'Nenuma das opções', value: null },
      'Classe I',
      'Classe IIA',
      'Classe IIB',
      'Classe A – RCC',
      'Classe B – RCC',
      'Classe C – RCC',
      'Classe D – RCC',
    ],
    physicalStateOptions: [{
      text: 'Nenuma das opções',
      value: null,
    }, 'Sólido', 'Líquido', 'Semissólido', 'Gasoso'],
    unitOptions: [{
      text: 'Nenuma das opções',
      value: null,
    }, 'Metro cúbico', 'Litro', 'Quilograma', 'Tonelada', 'Unidade'],
    technologyOptions: [
      { text: 'Nenuma das opções', value: null },
      'Aterro',
      'Auto Clave',
      'Blendagem para Coprocessamento',
      'Compostagem',
      'Coprocessamento',
      'Descontaminação de Lâmpadas',
      'Estação de Transferência de RSU',
      'Gaseificação',
      'Incineração',
      'Microondas',
      'Reciclagem',
      'Recuperação Energética',
      'Rerrefino',
      'Tratamento de Efluentes',
      'Tratamento Térmico',
      'Uso Agríc',
      'Outros',
    ],
    conditionningOptions: [
      { text: 'Granel', value: 'Granel' },
      { text: 'Tambor', value: 'Tambor' },
      { text: 'Caçamba Fechada', value: 'Caçamba Fechada' },
      { text: 'Fardo', value: 'Fardo' },
      { text: 'Palete', value: 'Palete' },
      { text: 'Sacos Plásticos', value: 'Sacos Plásticos' },
      { text: 'Big Bags', value: 'Big Bags' },
      { text: 'Caçamba Aberta', value: 'Caçamba Aberta' },
      { text: 'Caixa', value: 'Caixa' },
      { text: 'Caixa de Papelão', value: 'Caixa de Papelão' },
      { text: 'Outros', value: 'Outros' },
    ],
    wasteInventoryOptions: [{ text: 'Nenhuma das opções', value: null }],
    isOperationsTabActive: false,
    wasteCode: [
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 101,
        descricao2: 'Resíduos da Mineração',
        codResiduo: '10101',
        descricao3: 'Resíduos da Extração de Minérios Metálicos',
      },
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 101,
        descricao2: 'Resíduos da Mineração',
        codResiduo: '10102',
        descricao3: 'Resíduos da Extração de Minérios Não Metálicos',
      },
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 103,
        descricao2: 'Resíduos de transformação física e química de minérios metálicos',
        codResiduo: '010304(*)',
        descricao3: 'Rejeitados geradores de ácidos, resultantes da transformação de sulfuretos',
      },
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 103,
        descricao2: 'Resíduos de transformação física e química de minérios metálicos',
        codResiduo: '010305(*)',
        descricao3: 'Outros rejeitados contendo substancias perigosas',
      },
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 103,
        descricao2: 'Resíduos de transformação física e química de minérios metálicos',
        codResiduo: '10306',
        descricao3: 'Rejeitados não abrangidos em 01 03 04 (*) e 01 03 05 (*)',
      },
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 103,
        descricao2: 'Resíduos de transformação física e química de minérios metálicos',
        codResiduo: '010307(*)',
        descricao3: 'Outros resíduos contendo substancias perigosas, resultantes da transformação física e química de minérios metálicos',
      },
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 103,
        descricao2: 'Resíduos de transformação física e química de minérios metálicos',
        codResiduo: '10308',
        descricao3: 'Poeiras e pós não abrangidos em 01 03 07 (*)',
      },
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 103,
        descricao2: 'Resíduos de transformação física e química de minérios metálicos',
        codResiduo: '10309',
        descricao3: 'Lamas vermelhas da produção de alumina não abrangidas em 01 03 07',
      },
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 103,
        descricao2: 'Resíduos de transformação física e química de minérios metálicos',
        codResiduo: '10399',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 104,
        descricao2: 'Resíduos da transformação física e química de minérios não metálicos',
        codResiduo: '010407(*)',
        descricao3: 'Resíduos contendo substancia perigosas, resultantes da transformação física e química de minérios não metálicos',
      },
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 104,
        descricao2: 'Resíduos da transformação física e química de minérios não metálicos',
        codResiduo: '10408',
        descricao3: 'Cascalhos e fragmentos de rocha não abrangidos em 01 04 07 (*)',
      },
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 104,
        descricao2: 'Resíduos da transformação física e química de minérios não metálicos',
        codResiduo: '10409',
        descricao3: 'Areias e Argilas',
      },
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 104,
        descricao2: 'Resíduos da transformação física e química de minérios não metálicos',
        codResiduo: '10410',
        descricao3: 'Poeiras e pós não abrangidos em 01 04 07 (*)',
      },
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 104,
        descricao2: 'Resíduos da transformação física e química de minérios não metálicos',
        codResiduo: '10412',
        descricao3: 'Rejeitados e outros resíduos, resultantes da lavagem de minérios, não abrangidos em 01 04 07 (*)',
      },
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 104,
        descricao2: 'Resíduos da transformação física e química de minérios não metálicos',
        codResiduo: '10413',
        descricao3: 'Resíduos de corte e serragem de pedra não abrangidos em 01 04 07 (*)',
      },
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 104,
        descricao2: 'Resíduos da transformação física e química de minérios não metálicos',
        codResiduo: '10499',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 105,
        descricao2: 'Lodos e outros resíduos de perfuração',
        codResiduo: '10504',
        descricao3: 'Lodos e outros resíduos de perfuração contendo água doce',
      },
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 105,
        descricao2: 'Lodos e outros resíduos de perfuração',
        codResiduo: '010505(*)',
        descricao3: 'Lodos e outros resíduos de perfuração contendo hidrocarbonetos',
      },
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 105,
        descricao2: 'Lodos e outros resíduos de perfuração',
        codResiduo: '010506(*)',
        descricao3: 'Lodos e outros resíduos de perfuração contendo substancias perigosas',
      },
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 105,
        descricao2: 'Lodos e outros resíduos de perfuração',
        codResiduo: '10507',
        descricao3: 'Lodos e outros resíduos de perfuração contendo sais de bário não abrangidos em 01 05 05 (*) e 01 05 06 (*)',
      },
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 105,
        descricao2: 'Lodos e outros resíduos de perfuração',
        codResiduo: '10508',
        descricao3: 'Lodos e outros resíduos de perfuração contendo cloretos não abrangidos em 01 05 05 (*) e 01 05 06 (* )',
      },
      {
        capitulo: 1,
        descricao: 'Resíduos da Prospecção e Exploração de Minas e Pedreiras, bem como de tratamentos físicos e químicos das matérias extraídas',
        subcapitulo: 105,
        descricao2: 'Lodos e outros resíduos de perfuração',
        codResiduo: '10599',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 201,
        descricao2: 'Resíduos da agricultura, horticultura, aquicultura, silvicultura caça e pesca',
        codResiduo: '20101',
        descricao3: 'Lodos provenientes da lavagem e limpeza',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 201,
        descricao2: 'Resíduos da agricultura, horticultura, aquicultura, silvicultura caça e pesca',
        codResiduo: '20102',
        descricao3: 'Resíduos de tecidos animais',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 201,
        descricao2: 'Resíduos da agricultura, horticultura, aquicultura, silvicultura caça e pesca',
        codResiduo: '20103',
        descricao3: 'Resíduos de tecidos vegetais',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 201,
        descricao2: 'Resíduos da agricultura, horticultura, aquicultura, silvicultura caça e pesca',
        codResiduo: '20104',
        descricao3: 'Resíduos de plásticos (excluindo embalagens )',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 201,
        descricao2: 'Resíduos da agricultura, horticultura, aquicultura, silvicultura caça e pesca',
        codResiduo: '20106',
        descricao3: 'Fezes, urina e estrume de animais (incluindo palha suja), efluentes recolhidos separadamente e tratados noutro local',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 201,
        descricao2: 'Resíduos da agricultura, horticultura, aquicultura, silvicultura caça e pesca',
        codResiduo: '20107',
        descricao3: 'Resíduos Silvícolas',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 201,
        descricao2: 'Resíduos da agricultura, horticultura, aquicultura, silvicultura caça e pesca',
        codResiduo: '020108(*)',
        descricao3: 'Resíduos agrotóxicos e afins (agroquímicos) contendo substancias perigosas',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 201,
        descricao2: 'Resíduos da agricultura, horticultura, aquicultura, silvicultura caça e pesca',
        codResiduo: '20109',
        descricao3: 'Resíduos agrotóxicos e afins (agroquímicos) não abrangidos em 02 01 08 (* )',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 201,
        descricao2: 'Resíduos da agricultura, horticultura, aquicultura, silvicultura caça e pesca',
        codResiduo: '20110',
        descricao3: 'Resíduos metálicos, como por exemplo, estruturas metálicas, sucatas metálicas, varas e cabos utilizados em campo',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 201,
        descricao2: 'Resíduos da agricultura, horticultura, aquicultura, silvicultura caça e pesca',
        codResiduo: '20199',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 202,
        descricao2: 'Resíduos de preparação e processamento de carne, peixe e outros produtos alimentares de origem animal',
        codResiduo: '20201',
        descricao3: 'Lodos provenientes da lavagem e limpeza',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 202,
        descricao2: 'Resíduos de preparação e processamento de carne, peixe e outros produtos alimentares de origem animal',
        codResiduo: '20202',
        descricao3: 'Resíduos de tecidos animais e orgânico de processo (sebo, soro, ossos, sangue, etc. )',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 202,
        descricao2: 'Resíduos de preparação e processamento de carne, peixe e outros produtos alimentares de origem animal',
        codResiduo: '20203',
        descricao3: 'Materiais impróprios para consumo ou processamento',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 202,
        descricao2: 'Resíduos de preparação e processamento de carne, peixe e outros produtos alimentares de origem animal',
        codResiduo: '20204',
        descricao3: 'Lodos do Tratamento local de efluentes',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 202,
        descricao2: 'Resíduos de preparação e processamento de carne, peixe e outros produtos alimentares de origem animal',
        codResiduo: '20299',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 203,
        descricao2: 'Resíduos da preparação e processamento de frutos, legumes, cereais, óleos alimentares, cacau, café, chá e tabaco resíduos da produção de conservas resíduos de levedura e extrato de levedura e fermentação de melaço',
        codResiduo: '20301',
        descricao3: 'Lodos de lavagem, limpeza, descasque, centrifugação e separação',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 203,
        descricao2: 'Resíduos da preparação e processamento de frutos, legumes, cereais, óleos alimentares, cacau, café, chá e tabaco resíduos da produção de conservas resíduos de levedura e extrato de levedura e fermentação de melaço',
        codResiduo: '20302',
        descricao3: 'Resíduos de agentes conservantes',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 203,
        descricao2: 'Resíduos da preparação e processamento de frutos, legumes, cereais, óleos alimentares, cacau, café, chá e tabaco resíduos da produção de conservas resíduos de levedura e extrato de levedura e fermentação de melaço',
        codResiduo: '20303',
        descricao3: 'Resíduos da extração por solventes',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 203,
        descricao2: 'Resíduos da preparação e processamento de frutos, legumes, cereais, óleos alimentares, cacau, café, chá e tabaco resíduos da produção de conservas resíduos de levedura e extrato de levedura e fermentação de melaço',
        codResiduo: '20304',
        descricao3: 'Materiais impróprios para consumo ou processamento',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 203,
        descricao2: 'Resíduos da preparação e processamento de frutos, legumes, cereais, óleos alimentares, cacau, café, chá e tabaco resíduos da produção de conservas resíduos de levedura e extrato de levedura e fermentação de melaço',
        codResiduo: '20305',
        descricao3: 'Lodos do Tratamento local de efluentes',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 203,
        descricao2: 'Resíduos da preparação e processamento de frutos, legumes, cereais, óleos alimentares, cacau, café, chá e tabaco resíduos da produção de conservas resíduos de levedura e extrato de levedura e fermentação de melaço',
        codResiduo: '20399',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 204,
        descricao2: 'Resíduos do Processamento do Açúcar',
        codResiduo: '20403',
        descricao3: 'Lodos do Tratamento local de efluentes',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 204,
        descricao2: 'Resíduos do Processamento do Açúcar',
        codResiduo: '20404',
        descricao3: 'Vinhaça',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 204,
        descricao2: 'Resíduos do Processamento do Açúcar',
        codResiduo: '20405',
        descricao3: 'Bagaço de Cana de Açúcar',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 204,
        descricao2: 'Resíduos do Processamento do Açúcar',
        codResiduo: '20499',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 205,
        descricao2: 'Resíduos da Industria de laticínios',
        codResiduo: '20501',
        descricao3: 'Materiais impróprios para consumo ou processamento',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 205,
        descricao2: 'Resíduos da Industria de laticínios',
        codResiduo: '20502',
        descricao3: 'Lodos do Tratamento local de efluentes',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 205,
        descricao2: 'Resíduos da Industria de laticínios',
        codResiduo: '20599',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 206,
        descricao2: 'Resíduos da indústria de panificação e confeitaria',
        codResiduo: '20601',
        descricao3: 'Materiais impróprios para consumo ou processamento',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 206,
        descricao2: 'Resíduos da indústria de panificação e confeitaria',
        codResiduo: '20602',
        descricao3: 'Resíduos de agentes conservantes',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 206,
        descricao2: 'Resíduos da indústria de panificação e confeitaria',
        codResiduo: '20603',
        descricao3: 'Lodos do Tratamento local de efluentes',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 206,
        descricao2: 'Resíduos da indústria de panificação e confeitaria',
        codResiduo: '20699',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 207,
        descricao2: 'Resíduos da produção de bebidas alcoólicas e não alcoólicas (excluindo café, chá e cacau)',
        codResiduo: '20701',
        descricao3: 'Resíduos de lavagem, limpeza e redução mecânica das matérias primas',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 207,
        descricao2: 'Resíduos da produção de bebidas alcoólicas e não alcoólicas (excluindo café, chá e cacau)',
        codResiduo: '20702',
        descricao3: 'Resíduos da destilação de álcool',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 207,
        descricao2: 'Resíduos da produção de bebidas alcoólicas e não alcoólicas (excluindo café, chá e cacau)',
        codResiduo: '20703',
        descricao3: 'Resíduos de tratamentos químicos',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 207,
        descricao2: 'Resíduos da produção de bebidas alcoólicas e não alcoólicas (excluindo café, chá e cacau)',
        codResiduo: '20704',
        descricao3: 'Materiais impróprios para consumo ou processamento',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 207,
        descricao2: 'Resíduos da produção de bebidas alcoólicas e não alcoólicas (excluindo café, chá e cacau)',
        codResiduo: '20705',
        descricao3: 'Lodos do Tratamento local de efluentes',
      },
      {
        capitulo: 2,
        descricao: 'Resíduos de agricultura, horticultura, aquicultura, silvicultura, caça e pesca e da preparação e processamento de produtos alimentares',
        subcapitulo: 207,
        descricao2: 'Resíduos da produção de bebidas alcoólicas e não alcoólicas (excluindo café, chá e cacau)',
        codResiduo: '20799',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 301,
        descricao2: 'Resíduos do processamento de madeira e fabricação de painéis e mobiliário',
        codResiduo: '30101',
        descricao3: 'Resíduos do descasque da madeira',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 301,
        descricao2: 'Resíduos do processamento de madeira e fabricação de painéis e mobiliário',
        codResiduo: '030104(*)',
        descricao3: 'Serragem, aparas, fitas de aplainamento, madeira, aglomerados e folheados, contendo substancias perigosas',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 301,
        descricao2: 'Resíduos do processamento de madeira e fabricação de painéis e mobiliário',
        codResiduo: '30105',
        descricao3: 'Serragem, aparas, fitas de aplainamento, madeira, aglomerados e folheados não abrangidos em 03 01 04 (* )',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 301,
        descricao2: 'Resíduos do processamento de madeira e fabricação de painéis e mobiliário',
        codResiduo: '30199',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 302,
        descricao2: 'Resíduos da preservação da madeira',
        codResiduo: '030201(*)',
        descricao3: 'Produtos orgânicos não halogenados de preservação da madeira',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 302,
        descricao2: 'Resíduos da preservação da madeira',
        codResiduo: '030202(*)',
        descricao3: 'Agentes organoclorados de preservação da madeira',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 302,
        descricao2: 'Resíduos da preservação da madeira',
        codResiduo: '030203(*)',
        descricao3: 'Agentes organometálicos de preservação da madeira',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 302,
        descricao2: 'Resíduos da preservação da madeira',
        codResiduo: '030204(*)',
        descricao3: 'Agentes inorgânicos de preservação da madeira',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 302,
        descricao2: 'Resíduos da preservação da madeira',
        codResiduo: '030205(*)',
        descricao3: 'Outros agentes de preservação da madeira contendo substancias perigosas',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 302,
        descricao2: 'Resíduos da preservação da madeira',
        codResiduo: '030206(*)',
        descricao3: 'Efluentes líquidos e resíduos originados no processo de preservação da madeira, provenientes de plantas que utilizam formulações que contem creosoto, com exceção dos efluentes líquidos dos processos de preservação da madeira que usam creosoto e ou pentaclorofenol',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 302,
        descricao2: 'Resíduos da preservação da madeira',
        codResiduo: '030207(*)',
        descricao3: 'Efluentes líquidos e resíduos originados no processo de preservação da madeira, provenientes de plantas que utilizam ou tenham utilizado formulações clorofenólicas, com exceção dos efluentes líquidos dos processos de preservação da madeira que usam creosoto e ou pentaclorofenol',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 302,
        descricao2: 'Resíduos da preservação da madeira',
        codResiduo: '030208(*)',
        descricao3: 'Efluentes líquidos e resíduos originados no processo de preservação da madeira, provenientes de plantas que utilizam conservantes inorgânicos contendo arsênio ou cromo, com exceção dos efluentes líquidos dos processos de preservação de madeira que usam creosoto e ou pentaclorofenol',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 302,
        descricao2: 'Resíduos da preservação da madeira',
        codResiduo: '30299',
        descricao3: 'Agentes de preservação da madeira não anteriormente especificados',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 303,
        descricao2: 'Resíduos da Produção e da transformação de papel e celulose',
        codResiduo: '30301',
        descricao3: 'Resíduos do descasque da madeira e resíduos de madeira',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 303,
        descricao2: 'Resíduos da Produção e da transformação de papel e celulose',
        codResiduo: '30302',
        descricao3: 'Lodos de lixívia verde (provenientes da valorização da lixívia de cozimento ou licor negro )',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 303,
        descricao2: 'Resíduos da Produção e da transformação de papel e celulose',
        codResiduo: '30305',
        descricao3: 'Lodos de branqueamento, provenientes da reciclagem de papel',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 303,
        descricao2: 'Resíduos da Produção e da transformação de papel e celulose',
        codResiduo: '30307',
        descricao3: 'Rejeitos mecanicamente separados da fabricação de pasta a partir de papel e papelão usado',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 303,
        descricao2: 'Resíduos da Produção e da transformação de papel e celulose',
        codResiduo: '30308',
        descricao3: 'Resíduos de triagem de papel e papelão destinado a reciclagem',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 303,
        descricao2: 'Resíduos da Produção e da transformação de papel e celulose',
        codResiduo: '30309',
        descricao3: 'Resíduos de lodo de cal',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 303,
        descricao2: 'Resíduos da Produção e da transformação de papel e celulose',
        codResiduo: '30310',
        descricao3: 'Rejeitos de fibras e lodos de fibras, fillers, e revestimentos, provenientes da separação mecânica',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 303,
        descricao2: 'Resíduos da Produção e da transformação de papel e celulose',
        codResiduo: '30311',
        descricao3: 'Lodos do tratamento local de efluentes não abrangidos em 03 03 10',
      },
      {
        capitulo: 3,
        descricao: 'Resíduos do processamento de madeira e da fabricação de painéis, mobiliário, papel e celulose',
        subcapitulo: 303,
        descricao2: 'Resíduos da Produção e da transformação de papel e celulose',
        codResiduo: '30399',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 401,
        descricao2: 'Resíduos das industrias do couro e produtos de couro',
        codResiduo: '40101',
        descricao3: 'Resíduos das operações de descarna e divisão de tripa.',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 401,
        descricao2: 'Resíduos das industrias do couro e produtos de couro',
        codResiduo: '40102',
        descricao3: 'Resíduos da operação de calagem',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 401,
        descricao2: 'Resíduos das industrias do couro e produtos de couro',
        codResiduo: '040103(*)',
        descricao3: 'Resíduos de desengorduramento contendo solventes sem fase aquosa',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 401,
        descricao2: 'Resíduos das industrias do couro e produtos de couro',
        codResiduo: '40104',
        descricao3: 'Licores de curtimento contendo cromo',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 401,
        descricao2: 'Resíduos das industrias do couro e produtos de couro',
        codResiduo: '40105',
        descricao3: 'Licores de curtimento sem cromo',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 401,
        descricao2: 'Resíduos das industrias do couro e produtos de couro',
        codResiduo: '40106',
        descricao3: 'Lodos, em especial do tratamento de efluentes, contendo cromo',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 401,
        descricao2: 'Resíduos das industrias do couro e produtos de couro',
        codResiduo: '40107',
        descricao3: 'Lodos, em especial do tratamento de efluentes, sem cromo',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 401,
        descricao2: 'Resíduos das industrias do couro e produtos de couro',
        codResiduo: '40108',
        descricao3: 'Aparas, serragem e pós de couro provenientes de couros curtidos ao cromo',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 401,
        descricao2: 'Resíduos das industrias do couro e produtos de couro',
        codResiduo: '40109',
        descricao3: 'Resíduos da confecção e acabamentos',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 401,
        descricao2: 'Resíduos das industrias do couro e produtos de couro',
        codResiduo: '40110',
        descricao3: 'Lodo do caleiro',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 401,
        descricao2: 'Resíduos das industrias do couro e produtos de couro',
        codResiduo: '040111(*)',
        descricao3: 'Lodos provenientes do tratamento de efluentes líquidos originados no processo de curtimento de couros ao cromo',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 401,
        descricao2: 'Resíduos das industrias do couro e produtos de couro',
        codResiduo: '40199',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 402,
        descricao2: 'Resíduos da indústria têxtil',
        codResiduo: '40209',
        descricao3: 'Resíduos de materiais têxteis (têxteis impregnados, elastômeros, plastômeros )',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 402,
        descricao2: 'Resíduos da indústria têxtil',
        codResiduo: '40210',
        descricao3: 'Matéria orgânica de produtos naturais (por exemplo gordura, cera )',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 402,
        descricao2: 'Resíduos da indústria têxtil',
        codResiduo: '040214(*)',
        descricao3: 'Resíduos dos acabamentos, contendo solventes orgânicos ou contaminados',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 402,
        descricao2: 'Resíduos da indústria têxtil',
        codResiduo: '40215',
        descricao3: 'Resíduos dos acabamentos não abrangidos em 04 02 14 (* )',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 402,
        descricao2: 'Resíduos da indústria têxtil',
        codResiduo: '040216(*)',
        descricao3: 'Corantes e pigmentos contendo substancias perigosas',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 402,
        descricao2: 'Resíduos da indústria têxtil',
        codResiduo: '40217',
        descricao3: 'Corantes e pigmentos não abrangidos em 04 02 16 (* )',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 402,
        descricao2: 'Resíduos da indústria têxtil',
        codResiduo: '040219(*)',
        descricao3: 'Lodos do tratamento local de efluentes contendo substancias perigosas',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 402,
        descricao2: 'Resíduos da indústria têxtil',
        codResiduo: '40220',
        descricao3: 'Lodos do tratamento local de efluente não abrangidos em 04 02 19 (* )',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 402,
        descricao2: 'Resíduos da indústria têxtil',
        codResiduo: '40221',
        descricao3: 'Resíduos de fibras têxteis não processadas',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 402,
        descricao2: 'Resíduos da indústria têxtil',
        codResiduo: '40222',
        descricao3: 'Resíduos de fibras têxteis processadas',
      },
      {
        capitulo: 4,
        descricao: 'Resíduos da indústria do couro e produtos de couro e da indústria têxtil',
        subcapitulo: 402,
        descricao2: 'Resíduos da indústria têxtil',
        codResiduo: '40299',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 501,
        descricao2: 'Resíduos da refinação de petróleo',
        codResiduo: '050102(*)',
        descricao3: 'Lodos de dessalinização',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 501,
        descricao2: 'Resíduos da refinação de petróleo',
        codResiduo: '050103(*)',
        descricao3: 'Resíduos provenientes de fundos de tanques empregados na indústria de refino de petróleo, inclusive do tanque de armazenamento de óleo crú',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 501,
        descricao2: 'Resíduos da refinação de petróleo',
        codResiduo: '050104(*)',
        descricao3: 'Lodos alquílicos ácidos',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 501,
        descricao2: 'Resíduos da refinação de petróleo',
        codResiduo: '050105(*)',
        descricao3: 'Derrames de hidrocarbonetos',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 501,
        descricao2: 'Resíduos da refinação de petróleo',
        codResiduo: '050106(*)',
        descricao3: 'Lodos contendo hidrocarbonetos provenientes de operações de manutenção das instalações ou equipamentos, inclusive lodos provenientes de separadores e da limpeza dos tubos dos trocadores de calor',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 501,
        descricao2: 'Resíduos da refinação de petróleo',
        codResiduo: '050107(*)',
        descricao3: 'Alcatrões ácidos',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 501,
        descricao2: 'Resíduos da refinação de petróleo',
        codResiduo: '050108(*)',
        descricao3: 'Outros alcatrões',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 501,
        descricao2: 'Resíduos da refinação de petróleo',
        codResiduo: '050109(*)',
        descricao3: 'Lodos do tratamento local de efluentes contendo substancias perigosas',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 501,
        descricao2: 'Resíduos da refinação de petróleo',
        codResiduo: '50110',
        descricao3: 'Lodos do tratamento local de efluentes não abrangidos em 05 01 09 (* )',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 501,
        descricao2: 'Resíduos da refinação de petróleo',
        codResiduo: '050111(*)',
        descricao3: 'Resíduos de limpeza de combustíveis com bases',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 501,
        descricao2: 'Resíduos da refinação de petróleo',
        codResiduo: '050112(*)',
        descricao3: 'Hidrocarbonetos contendo ácidos',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 501,
        descricao2: 'Resíduos da refinação de petróleo',
        codResiduo: '50113',
        descricao3: 'Lodos de tratamento de água para abastecimento de caldeiras',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 501,
        descricao2: 'Resíduos da refinação de petróleo',
        codResiduo: '50114',
        descricao3: 'Resíduos de colunas de arrefecimento',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 501,
        descricao2: 'Resíduos da refinação de petróleo',
        codResiduo: '050115(*)',
        descricao3: 'Argilas de filtração usadas',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 501,
        descricao2: 'Resíduos da refinação de petróleo',
        codResiduo: '50116',
        descricao3: 'Resíduos contendo enxofre de dessulfurização de petróleo',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 501,
        descricao2: 'Resíduos da refinação de petróleo',
        codResiduo: '50117',
        descricao3: 'Betumes',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 501,
        descricao2: 'Resíduos da refinação de petróleo',
        codResiduo: '050118(*)',
        descricao3: 'Sólidos provenientes da emulsão residual oleosa, inclusive o sobrenadante proveniente de separadores tipo DAF (Dissolved Air Flotation )',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 501,
        descricao2: 'Resíduos da refinação de petróleo',
        codResiduo: '50199',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 506,
        descricao2: 'Resíduos do tratamento pirolítico do carvão',
        codResiduo: '050601(*)',
        descricao3: 'Alcatrões ácidos',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 506,
        descricao2: 'Resíduos do tratamento pirolítico do carvão',
        codResiduo: '050603(*)',
        descricao3: 'Outros alcatrões',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 506,
        descricao2: 'Resíduos do tratamento pirolítico do carvão',
        codResiduo: '50604',
        descricao3: 'Resíduos de colunas de arrefecimento',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 506,
        descricao2: 'Resíduos do tratamento pirolítico do carvão',
        codResiduo: '050605(*)',
        descricao3: 'Resíduos provenientes dos tanques e lagoas de produção do coque, incluindo resíduos da coqueificação do carvão',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 506,
        descricao2: 'Resíduos do tratamento pirolítico do carvão',
        codResiduo: '050606(*)',
        descricao3: 'Resíduos provenientes da recuperação e destilação de subprodutos do coque produzidos a partir do carvão',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 506,
        descricao2: 'Resíduos do tratamento pirolítico do carvão',
        codResiduo: '050607(*)',
        descricao3: 'Resíduos provenientes dos sistemas de tratamento de gases dos processos de coqueificação da carvão e da obtenção de subprodutos de coque produzidos a partir do carvão',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 506,
        descricao2: 'Resíduos do tratamento pirolítico do carvão',
        codResiduo: '050608(*)',
        descricao3: 'Lodo calcário da destilação da amônia proveniente das operações de coqueificação',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 506,
        descricao2: 'Resíduos do tratamento pirolítico do carvão',
        codResiduo: '50699',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 507,
        descricao2: 'Resíduos de purificação e transporte de gás natural',
        codResiduo: '050701(*)',
        descricao3: 'Resíduos contendo mercúrio',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 507,
        descricao2: 'Resíduos de purificação e transporte de gás natural',
        codResiduo: '50702',
        descricao3: 'Resíduos contendo enxofre de dessulfurização de petróleo',
      },
      {
        capitulo: 5,
        descricao: 'Resíduos da refinação de petróleo, da purificação de gás natural e do tratamento pirolítico do carvão',
        subcapitulo: 507,
        descricao2: 'Resíduos de purificação e transporte de gás natural',
        codResiduo: '50799',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 601,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de ácidos',
        codResiduo: '060101(*)',
        descricao3: 'Ácido sulfúrico e ácido sulfuroso',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 601,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de ácidos',
        codResiduo: '060102(*)',
        descricao3: 'Acido clorídrico',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 601,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de ácidos',
        codResiduo: '060103(*)',
        descricao3: 'Acido fluorídrico',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 601,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de ácidos',
        codResiduo: '060104(*)',
        descricao3: 'Acido fosfórico e acido fosforoso',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 601,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de ácidos',
        codResiduo: '060105(*)',
        descricao3: 'Acido nítrico e acido nitroso',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 601,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de ácidos',
        codResiduo: '060106(*)',
        descricao3: 'Outros ácidos',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 601,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de ácidos',
        codResiduo: '60199',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 602,
        descricao2: 'Resíduos de fabricação, formulação, distribuição e utilização de bases',
        codResiduo: '060201(*)',
        descricao3: 'Hidróxido de cálcio',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 602,
        descricao2: 'Resíduos de fabricação, formulação, distribuição e utilização de bases',
        codResiduo: '060203(*)',
        descricao3: 'Hidróxido de amônio',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 602,
        descricao2: 'Resíduos de fabricação, formulação, distribuição e utilização de bases',
        codResiduo: '060204(*)',
        descricao3: 'Hidróxidos de sódio e de potássio',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 602,
        descricao2: 'Resíduos de fabricação, formulação, distribuição e utilização de bases',
        codResiduo: '060205(*)',
        descricao3: 'Outras bases',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 602,
        descricao2: 'Resíduos de fabricação, formulação, distribuição e utilização de bases',
        codResiduo: '60299',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 603,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de sais e suas soluções e de óxidos metálicos',
        codResiduo: '060311(*)',
        descricao3: 'Sais no estado sólido e em soluções contendo cianetos',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 603,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de sais e suas soluções e de óxidos metálicos',
        codResiduo: '060313(*)',
        descricao3: 'Sais no estado sólido e em soluções contendo metais pesados',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 603,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de sais e suas soluções e de óxidos metálicos',
        codResiduo: '060314(*)',
        descricao3: 'Sais no estado sólido e não abrangidos em 06 03 11 (*) e 03 03 13 (* )',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 603,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de sais e suas soluções e de óxidos metálicos',
        codResiduo: '060315(*)',
        descricao3: 'Óxidos metálicos contendo metais pesados',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 603,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de sais e suas soluções e de óxidos metálicos',
        codResiduo: '60316',
        descricao3: 'Óxidos metálicos não abrangidos em 06 03 15 (* )',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 603,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de sais e suas soluções e de óxidos metálicos',
        codResiduo: '60399',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 604,
        descricao2: 'Resíduos contendo metais não abrangidos em 06 03',
        codResiduo: '060403(*)',
        descricao3: 'Resíduos contendo arsênio',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 604,
        descricao2: 'Resíduos contendo metais não abrangidos em 06 03',
        codResiduo: '060404(*)',
        descricao3: 'Resíduos contendo mercúrio',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 604,
        descricao2: 'Resíduos contendo metais não abrangidos em 06 03',
        codResiduo: '060405(*)',
        descricao3: 'Resíduos contendo outros metais pesados',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 604,
        descricao2: 'Resíduos contendo metais não abrangidos em 06 03',
        codResiduo: '60499',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 605,
        descricao2: 'Lodos de tratamento local de efluentes',
        codResiduo: '060502(*)',
        descricao3: 'Lodos de tratamento local de efluentes contendo substancias perigosas',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 605,
        descricao2: 'Lodos de tratamento local de efluentes',
        codResiduo: '60503',
        descricao3: 'Lodos de tratamento local de efluentes não abrangidos em 06 05 02 (* )',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 606,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos e processos químicos do enxofre e de processos de dessulfuração',
        codResiduo: '060602(*)',
        descricao3: 'Resíduos contendo sulfuretos perigosos',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 606,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos e processos químicos do enxofre e de processos de dessulfuração',
        codResiduo: '60603',
        descricao3: 'Resíduos contendo sulfuretos não abrangidos em 06 06 02 (* )',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 606,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos e processos químicos do enxofre e de processos de dessulfuração',
        codResiduo: '60699',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 607,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de halogênios e processos químico dos halogênios',
        codResiduo: '060701(*)',
        descricao3: 'Resíduos de eletrolise contendo amianto',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 607,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de halogênios e processos químico dos halogênios',
        codResiduo: '060702(*)',
        descricao3: 'Resíduos de carvão ativado utilizado na produção de cloro',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 607,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de halogênios e processos químico dos halogênios',
        codResiduo: '060703(*)',
        descricao3: 'Lodos de sulfato de bário contendo mercúrio',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 607,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de halogênios e processos químico dos halogênios',
        codResiduo: '060704(*)',
        descricao3: 'Soluções e ácidos, por exemplo, ácido de contato',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 607,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de halogênios e processos químico dos halogênios',
        codResiduo: '060705(*)',
        descricao3: 'Lodos de purificação de salmoura e lodos provenientes do tratamento de efluentes líquidos originados no processo de produção de cloro em células de mercúrio',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 607,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de halogênios e processos químico dos halogênios',
        codResiduo: '60799',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 609,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos e processos químicos do fósforo',
        codResiduo: '60902',
        descricao3: 'Escórias com fósforo',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 609,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos e processos químicos do fósforo',
        codResiduo: '060903(*)',
        descricao3: 'Resíduos cálcicos de reação contendo ou contaminados com substancias perigosas',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 609,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos e processos químicos do fósforo',
        codResiduo: '60904',
        descricao3: 'Resíduos cálcicos de reação não abrangidos em 06 09 03 (* )',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 609,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos e processos químicos do fósforo',
        codResiduo: '60999',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 610,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos e processos químicos do azoto e da fabricação de fertilizantes',
        codResiduo: '061002(*)',
        descricao3: 'Resíduos contendo substancias perigosas',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 610,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos e processos químicos do azoto e da fabricação de fertilizantes',
        codResiduo: '61099',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 611,
        descricao2: 'Resíduos da fabricação de pigmentos inorgânicos e opacificantes',
        codResiduo: '61101',
        descricao3: 'Resíduos cálcicos de reação da produção de dióxido de titânio',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 611,
        descricao2: 'Resíduos da fabricação de pigmentos inorgânicos e opacificantes',
        codResiduo: '061102(*)',
        descricao3: 'Lodos provenientes do tratamento de efluentes líquidos originados no processo de produção do pigmento branco de dióxido de titânio, por meio do método de cloretos, a partir de minérios que contenham cromo',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 611,
        descricao2: 'Resíduos da fabricação de pigmentos inorgânicos e opacificantes',
        codResiduo: '061103(*)',
        descricao3: 'Resíduos da fabricação e de locais de armazenamento de cloreto férrico a partir de ácidos formados durante a produção do dióxido de titânio utilizando o processo de ilmenitecloreto',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 611,
        descricao2: 'Resíduos da fabricação de pigmentos inorgânicos e opacificantes',
        codResiduo: '061104(*)',
        descricao3: 'Lodo de tratamento de efluentes líquidos originados na produção dos seguintes pigmentos: laranja e amarelo de cromo, laranja de molibdato, amarelo de zinco, verde de cromo, verde de óxido de cromo,(anidro e hidratado) e azul de ferro',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 611,
        descricao2: 'Resíduos da fabricação de pigmentos inorgânicos e opacificantes',
        codResiduo: '61199',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 613,
        descricao2: 'Resíduos de processos químicos inorgânicos não anteriormente especificados',
        codResiduo: '061301(*)',
        descricao3: 'Produtos inorgânicos de proteção das plantas, agentes de preservação da madeira e outros biocidas',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 613,
        descricao2: 'Resíduos de processos químicos inorgânicos não anteriormente especificados',
        codResiduo: '061302(*)',
        descricao3: 'Carvão ativado usado - exceto 06 07 02 (* )',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 613,
        descricao2: 'Resíduos de processos químicos inorgânicos não anteriormente especificados',
        codResiduo: '61303',
        descricao3: 'Negro de fumo',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 613,
        descricao2: 'Resíduos de processos químicos inorgânicos não anteriormente especificados',
        codResiduo: '061304(*)',
        descricao3: 'Resíduos de processamento do amianto, incluindo pós e fibras',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 613,
        descricao2: 'Resíduos de processos químicos inorgânicos não anteriormente especificados',
        codResiduo: '061305(*)',
        descricao3: 'Fuligem',
      },
      {
        capitulo: 6,
        descricao: 'Resíduos de processos químicos inorgânicos',
        subcapitulo: 613,
        descricao2: 'Resíduos de processos químicos inorgânicos não anteriormente especificados',
        codResiduo: '61399',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 701,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de halogênios e processos químico orgânicos de base',
        codResiduo: '070101(*)',
        descricao3: 'Líquidos de lavagem e efluentes orgânicos aquosos',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 701,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de halogênios e processos químico orgânicos de base',
        codResiduo: '070103(*)',
        descricao3: 'Solventes, líquidos de lavagem e efluentes orgânicos halogenados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 701,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de halogênios e processos químico orgânicos de base',
        codResiduo: '070104(*)',
        descricao3: 'Outros solventes, líquidos de lavagem e efluentes orgânicos',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 701,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de halogênios e processos químico orgânicos de base',
        codResiduo: '070107(*)',
        descricao3: 'Resíduos de destilação e resíduos de reação halogenados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 701,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de halogênios e processos químico orgânicos de base',
        codResiduo: '070108(*)',
        descricao3: 'Outros resíduos de destilação e resíduos de reação',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 701,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de halogênios e processos químico orgânicos de base',
        codResiduo: '070109(*)',
        descricao3: 'Absorventes usados e tortas de filtro halogenados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 701,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de halogênios e processos químico orgânicos de base',
        codResiduo: '070110(*)',
        descricao3: 'Outros absorventes usados e tortas de filtro',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 701,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de halogênios e processos químico orgânicos de base',
        codResiduo: '070111(*)',
        descricao3: 'Lodos de tratamento de efluentes contendo substancias perigosas',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 701,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de halogênios e processos químico orgânicos de base',
        codResiduo: '70112',
        descricao3: 'Lodos do tratamento local de efluentes não abrangidas em 07 01 11 (* )',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 701,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de halogênios e processos químico orgânicos de base',
        codResiduo: '70199',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 702,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de plásticos, borracha e fibras sintéticas',
        codResiduo: '070201(*)',
        descricao3: 'Líquidos de lavagem e efluentes orgânicos aquosos',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 702,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de plásticos, borracha e fibras sintéticas',
        codResiduo: '070203(*)',
        descricao3: 'Solventes, líquidos de lavagem e efluentes orgânicos halogenados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 702,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de plásticos, borracha e fibras sintéticas',
        codResiduo: '070204(*)',
        descricao3: 'Outros solventes, líquidos de lavagem e efluentes orgânicos',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 702,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de plásticos, borracha e fibras sintéticas',
        codResiduo: '070207(*)',
        descricao3: 'Resíduos de destilação e resíduos de reação halogenados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 702,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de plásticos, borracha e fibras sintéticas',
        codResiduo: '070208(*)',
        descricao3: 'Outros resíduos de destilação e resíduos de reação',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 702,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de plásticos, borracha e fibras sintéticas',
        codResiduo: '070209(*)',
        descricao3: 'Absorventes usados e tortas de filtro halogenados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 702,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de plásticos, borracha e fibras sintéticas',
        codResiduo: '070210(*)',
        descricao3: 'Outros absorventes usados e tortas de filtro',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 702,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de plásticos, borracha e fibras sintéticas',
        codResiduo: '070211(*)',
        descricao3: 'Lodos de tratamento de efluentes contendo substancias perigosas',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 702,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de plásticos, borracha e fibras sintéticas',
        codResiduo: '70212',
        descricao3: 'Lodos do tratamento local de efluentes não abrangidas em 07 02 11 (* )',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 702,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de plásticos, borracha e fibras sintéticas',
        codResiduo: '70213',
        descricao3: 'Resíduos e refugos de plásticos',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 702,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de plásticos, borracha e fibras sintéticas',
        codResiduo: '070214(*)',
        descricao3: 'Resíduos de aditivos contendo substâncias perigosas',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 702,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de plásticos, borracha e fibras sintéticas',
        codResiduo: '70215',
        descricao3: 'Resíduos de aditivos não abrangidos em 07 02 14 (* )',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 702,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de plásticos, borracha e fibras sintéticas',
        codResiduo: '070216(*)',
        descricao3: 'Resíduos contendo silicones perigosos',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 702,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de plásticos, borracha e fibras sintéticas',
        codResiduo: '70217',
        descricao3: 'Resíduos contendo silicones que não os mencionados na rubrica 07 02 16 (* )',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 702,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de plásticos, borracha e fibras sintéticas',
        codResiduo: '70299',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 703,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de corantes e pigmentos orgânicos',
        codResiduo: '070301(*)',
        descricao3: 'Líquidos de lavagem e efluentes orgânicos aquosos',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 703,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de corantes e pigmentos orgânicos',
        codResiduo: '070303(*)',
        descricao3: 'Solventes, líquidos de lavagem e efluentes orgânicos halogenados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 703,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de corantes e pigmentos orgânicos',
        codResiduo: '070304(*)',
        descricao3: 'Outros solventes, líquidos de lavagem e efluentes orgânicos',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 703,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de corantes e pigmentos orgânicos',
        codResiduo: '070307(*)',
        descricao3: 'Resíduos de destilação e resíduos de reação halogenados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 703,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de corantes e pigmentos orgânicos',
        codResiduo: '070308(*)',
        descricao3: 'Outros resíduos de destilação e resíduos de reação',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 703,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de corantes e pigmentos orgânicos',
        codResiduo: '070309(*)',
        descricao3: 'Absorventes usados e tortas de filtro halogenados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 703,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de corantes e pigmentos orgânicos',
        codResiduo: '070310(*)',
        descricao3: 'Outros absorventes usados e tortas de filtro',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 703,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de corantes e pigmentos orgânicos',
        codResiduo: '070311(*)',
        descricao3: 'Lodos de tratamento de efluentes contendo substancias perigosas',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 703,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de corantes e pigmentos orgânicos',
        codResiduo: '70312',
        descricao3: 'Lodos do tratamento local de efluentes não abrangidas em 07 02 11 (* )',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 703,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de corantes e pigmentos orgânicos',
        codResiduo: '70399',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 704,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos orgânicos de proteção das plantas (exceto 02 01 08 e 02 01 09), agente de preservação da madeira (exceto 03 02) e outros biocidas',
        codResiduo: '070401(*)',
        descricao3: 'Líquidos de lavagem e efluentes orgânicos aquosos',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 704,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos orgânicos de proteção das plantas (exceto 02 01 08 e 02 01 09), agente de preservação da madeira (exceto 03 02) e outros biocidas',
        codResiduo: '070403(*)',
        descricao3: 'Solventes, líquidos de lavagem e efluentes orgânicos halogenados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 704,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos orgânicos de proteção das plantas (exceto 02 01 08 e 02 01 09), agente de preservação da madeira (exceto 03 02) e outros biocidas',
        codResiduo: '070404(*)',
        descricao3: 'Outros solventes, líquidos de lavagem e efluentes orgânicos',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 704,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos orgânicos de proteção das plantas (exceto 02 01 08 e 02 01 09), agente de preservação da madeira (exceto 03 02) e outros biocidas',
        codResiduo: '070407(*)',
        descricao3: 'Resíduos de destilação e resíduos de reação halogenados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 704,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos orgânicos de proteção das plantas (exceto 02 01 08 e 02 01 09), agente de preservação da madeira (exceto 03 02) e outros biocidas',
        codResiduo: '070408(*)',
        descricao3: 'Outros resíduos de destilação e resíduos de reação',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 704,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos orgânicos de proteção das plantas (exceto 02 01 08 e 02 01 09), agente de preservação da madeira (exceto 03 02) e outros biocidas',
        codResiduo: '070409(*)',
        descricao3: 'Absorventes usados e tortas de filtro halogenados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 704,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos orgânicos de proteção das plantas (exceto 02 01 08 e 02 01 09), agente de preservação da madeira (exceto 03 02) e outros biocidas',
        codResiduo: '070410(*)',
        descricao3: 'Outros absorventes usados e tortas de filtro',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 704,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos orgânicos de proteção das plantas (exceto 02 01 08 e 02 01 09), agente de preservação da madeira (exceto 03 02) e outros biocidas',
        codResiduo: '070411(*)',
        descricao3: 'Lodos de tratamento de efluentes contendo substancias perigosas',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 704,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos orgânicos de proteção das plantas (exceto 02 01 08 e 02 01 09), agente de preservação da madeira (exceto 03 02) e outros biocidas',
        codResiduo: '70412',
        descricao3: 'Lodos do tratamento local de efluentes não abrangidas em 07 04 11 (* )',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 704,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos orgânicos de proteção das plantas (exceto 02 01 08 e 02 01 09), agente de preservação da madeira (exceto 03 02) e outros biocidas',
        codResiduo: '070413(*)',
        descricao3: 'Resíduos contendo substancias perigosas',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 704,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos orgânicos de proteção das plantas (exceto 02 01 08 e 02 01 09), agente de preservação da madeira (exceto 03 02) e outros biocidas',
        codResiduo: '70499',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 705,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos farmacêuticos',
        codResiduo: '070501(*)',
        descricao3: 'Líquidos de lavagem e efluentes orgânicos aquosos',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 705,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos farmacêuticos',
        codResiduo: '070503(*)',
        descricao3: 'Solventes, líquidos de lavagem e efluentes orgânicos halogenados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 705,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos farmacêuticos',
        codResiduo: '070504(*)',
        descricao3: 'Outros solventes, líquidos de lavagem e efluentes orgânicos',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 705,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos farmacêuticos',
        codResiduo: '070505(*)',
        descricao3: 'Lodos provenientes do tratamento de efluentes líquidos originados no processo de produção de compostos arsênicas ou organoarseniacais',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 705,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos farmacêuticos',
        codResiduo: '070506(*)',
        descricao3: 'Resíduos de fundo de destilação originados na etapa de destilação de compostos anilínicos empregados na produção de compostos arseniacais ou compostos organoarseniacais',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 705,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos farmacêuticos',
        codResiduo: '070507(*)',
        descricao3: 'Resíduos de destilação e resíduos de reação halogenados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 705,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos farmacêuticos',
        codResiduo: '070508(*)',
        descricao3: 'Outros resíduos de destilação e resíduos de reação',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 705,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos farmacêuticos',
        codResiduo: '070509(*)',
        descricao3: 'Carvão ativo usado proveniente da etapa de descoloração da produção de compostos arseniacais ou organoarseniacais',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 705,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos farmacêuticos',
        codResiduo: '070510(*)',
        descricao3: 'Absorventes usados e tortas de filtro halogenados e não halogenados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 705,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos farmacêuticos',
        codResiduo: '070511(*)',
        descricao3: 'Lodos de tratamento de efluentes contendo substancias perigosas',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 705,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos farmacêuticos',
        codResiduo: '70512',
        descricao3: 'Lodos do tratamento local de efluentes não abrangidas em 07 05 11 (* )',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 705,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos farmacêuticos',
        codResiduo: '070513(*)',
        descricao3: 'Resíduos contendo substancias perigosas',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 705,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos farmacêuticos',
        codResiduo: '70514',
        descricao3: 'Resíduos sólidos não abrangidos em 07 05 13 (* )',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 705,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de produtos farmacêuticos',
        codResiduo: '70599',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 706,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de gorduras, sabões, detergentes, desinfetantes e cosméticos',
        codResiduo: '070601(*)',
        descricao3: 'Líquidos de lavagem e efluentes orgânicos aquosos',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 706,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de gorduras, sabões, detergentes, desinfetantes e cosméticos',
        codResiduo: '070603(*)',
        descricao3: 'Solventes, líquidos de lavagem e efluentes orgânicos halogenados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 706,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de gorduras, sabões, detergentes, desinfetantes e cosméticos',
        codResiduo: '070604(*)',
        descricao3: 'Outros solventes, líquidos de lavagem e efluentes orgânicos',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 706,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de gorduras, sabões, detergentes, desinfetantes e cosméticos',
        codResiduo: '070607(*)',
        descricao3: 'Resíduos de destilação e resíduos de reação halogenados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 706,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de gorduras, sabões, detergentes, desinfetantes e cosméticos',
        codResiduo: '070608(*)',
        descricao3: 'Outros resíduos de destilação e resíduos de reação',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 706,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de gorduras, sabões, detergentes, desinfetantes e cosméticos',
        codResiduo: '070609(*)',
        descricao3: 'Absorventes usados e tortas de filtro halogenados e halogenados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 706,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de gorduras, sabões, detergentes, desinfetantes e cosméticos',
        codResiduo: '070610(*)',
        descricao3: 'Outros absorventes usados e tortas de filtro',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 706,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de gorduras, sabões, detergentes, desinfetantes e cosméticos',
        codResiduo: '070611(*)',
        descricao3: 'Lodos de tratamento de efluentes contendo substancias perigosas',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 706,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de gorduras, sabões, detergentes, desinfetantes e cosméticos',
        codResiduo: '70612',
        descricao3: 'Lodos do tratamento local de efluentes não abrangidas em 07 06 11 (* )',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 706,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de gorduras, sabões, detergentes, desinfetantes e cosméticos',
        codResiduo: '70699',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 707,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização da química fina e de produtos químicos não anteriormente especificados',
        codResiduo: '070701(*)',
        descricao3: 'Líquidos de lavagem e efluentes orgânicos aquosos',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 707,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização da química fina e de produtos químicos não anteriormente especificados',
        codResiduo: '070703(*)',
        descricao3: 'Solventes, líquidos de lavagem e efluentes orgânicos halogenados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 707,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização da química fina e de produtos químicos não anteriormente especificados',
        codResiduo: '070704(*)',
        descricao3: 'Outros solventes, líquidos de lavagem e efluentes orgânicos',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 707,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização da química fina e de produtos químicos não anteriormente especificados',
        codResiduo: '070707(*)',
        descricao3: 'Resíduos de destilação e resíduos de reação halogenados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 707,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização da química fina e de produtos químicos não anteriormente especificados',
        codResiduo: '070708(*)',
        descricao3: 'Outros resíduos de destilação e resíduos de reação',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 707,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização da química fina e de produtos químicos não anteriormente especificados',
        codResiduo: '070709(*)',
        descricao3: 'Absorventes usados e tortas de filtro halogenados e halogenados',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 707,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização da química fina e de produtos químicos não anteriormente especificados',
        codResiduo: '070710(*)',
        descricao3: 'Outros absorventes usados e tortas de filtro',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 707,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização da química fina e de produtos químicos não anteriormente especificados',
        codResiduo: '070711(*)',
        descricao3: 'Lodos de tratamento de efluentes contendo substancias perigosas',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 707,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização da química fina e de produtos químicos não anteriormente especificados',
        codResiduo: '70712',
        descricao3: 'Lodos do tratamento local de efluentes não abrangidas em 07 07 11 (* )',
      },
      {
        capitulo: 7,
        descricao: 'Resíduos de processos químicos orgânicos',
        subcapitulo: 707,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização da química fina e de produtos químicos não anteriormente especificados',
        codResiduo: '70799',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 801,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização e remoção de tintas e vernizes',
        codResiduo: '080111(*)',
        descricao3: 'Resíduos de tintas e vernizes contendo solventes orgânicos ou outras substancias perigosas',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 801,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização e remoção de tintas e vernizes',
        codResiduo: '80112',
        descricao3: 'Resíduos de tintas e vernizes não abrangidos em 08 01 11 (* )',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 801,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização e remoção de tintas e vernizes',
        codResiduo: '080113(*)',
        descricao3: 'Lodos de tintas e vernizes contendo solventes orgânicos ou outras substancias perigosas',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 801,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização e remoção de tintas e vernizes',
        codResiduo: '80114',
        descricao3: 'Lodos de tintas e vernizes não abrangias em 08 01 13 (* )',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 801,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização e remoção de tintas e vernizes',
        codResiduo: '080115(*)',
        descricao3: 'Lodos aquosas contendo tintas e vernizes orgânicos ou outras substancias perigosas',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 801,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização e remoção de tintas e vernizes',
        codResiduo: '80116',
        descricao3: 'Lodos aquosas contendo tintas e vernizes não abrangidas em 08 01 15 (* )',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 801,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização e remoção de tintas e vernizes',
        codResiduo: '080117(*)',
        descricao3: 'Resíduos da remoção de tintas e vernizes contendo solventes orgânicos ou outras substancias perigosas',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 801,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização e remoção de tintas e vernizes',
        codResiduo: '80118',
        descricao3: 'Resíduos da remoção de tintas e vernizes não abrangidos em 08 01 17 (* )',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 801,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização e remoção de tintas e vernizes',
        codResiduo: '080119(*)',
        descricao3: 'Suspensões aquosas contendo tintas ou vernizes com solventes orgânicos ou outras substancias perigosas',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 801,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização e remoção de tintas e vernizes',
        codResiduo: '80120',
        descricao3: 'Suspensões aquosas contendo tintas e vernizes não abrangidas em 08 01 19 (* )',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 801,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização e remoção de tintas e vernizes',
        codResiduo: '080121(*)',
        descricao3: 'Resíduos de produtos de remoção de tintas e vernizes',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 801,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização e remoção de tintas e vernizes',
        codResiduo: '080122(*)',
        descricao3: 'Lodos ou poeiras provenientes do sistema de controle de emissão de gases empregado na produção de tintas',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 801,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização e remoção de tintas e vernizes',
        codResiduo: '80199',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 802,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de outros revestimentos (incluindo materiais cerâmicos)',
        codResiduo: '80201',
        descricao3: 'Resíduos de revestimentos na forma pulvurulenta',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 802,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de outros revestimentos (incluindo materiais cerâmicos)',
        codResiduo: '80202',
        descricao3: 'Lodos aquosas contendo materiais cerâmicos',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 802,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de outros revestimentos (incluindo materiais cerâmicos)',
        codResiduo: '80203',
        descricao3: 'Suspensões aquosas contendo materiais cerâmicos',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 802,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de outros revestimentos (incluindo materiais cerâmicos)',
        codResiduo: '080204(*)',
        descricao3: 'Resíduos de revestimentos contendo amianto',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 802,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de outros revestimentos (incluindo materiais cerâmicos)',
        codResiduo: '80299',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 803,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de tintas de impressão',
        codResiduo: '80307',
        descricao3: 'Lodos aquosas contendo tintas de impressão',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 803,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de tintas de impressão',
        codResiduo: '80308',
        descricao3: 'Resíduos líquidos aquosos contendo tintas de impressão',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 803,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de tintas de impressão',
        codResiduo: '080312(*)',
        descricao3: 'Resíduos de tintas de impressão contendo substancias perigosas',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 803,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de tintas de impressão',
        codResiduo: '80313',
        descricao3: 'Resíduos de tintas não abrangidos em 08 03 12 (* )',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 803,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de tintas de impressão',
        codResiduo: '080314(*)',
        descricao3: 'Lodos de tintas contendo substancias perigosas',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 803,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de tintas de impressão',
        codResiduo: '80315',
        descricao3: 'Lodos de tintas de impressão não abrangidas em 08 03 14 (* )',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 803,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de tintas de impressão',
        codResiduo: '80316',
        descricao3: 'Resíduos de solução de água régia',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 803,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de tintas de impressão',
        codResiduo: '080317(*)',
        descricao3: 'Resíduos de toner de impressão contendo substancias perigosas',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 803,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de tintas de impressão',
        codResiduo: '80318',
        descricao3: 'Resíduos de toner de impressão não abrangidos em 08 03 17 (* )',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 803,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de tintas de impressão',
        codResiduo: '080319(*)',
        descricao3: 'Óleos de dispersão',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 803,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de tintas de impressão',
        codResiduo: '80399',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 804,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de colas e vedantes (incluindo produtos impermeabilizantes)',
        codResiduo: '080409(*)',
        descricao3: 'Resíduos de colas ou vedantes contendo solventes orgânicos ou outras substancias perigosas',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 804,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de colas e vedantes (incluindo produtos impermeabilizantes)',
        codResiduo: '80410',
        descricao3: 'Resíduos de colas ou vedantes não abrangidos em 08 04 09 (* )',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 804,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de colas e vedantes (incluindo produtos impermeabilizantes)',
        codResiduo: '080411(*)',
        descricao3: 'Lodos de colas ou vedantes contendo solventes orgânicos ou outras substancias perigosas',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 804,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de colas e vedantes (incluindo produtos impermeabilizantes)',
        codResiduo: '80412',
        descricao3: 'Lodos de colas ou vedantes não abrangidas em 08 04 11 (* )',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 804,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de colas e vedantes (incluindo produtos impermeabilizantes)',
        codResiduo: '080413(*)',
        descricao3: 'Lodos aquosos contendo colas ou vedantes com solventes orgânicos ou outras substancias perigosas',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 804,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de colas e vedantes (incluindo produtos impermeabilizantes)',
        codResiduo: '80414',
        descricao3: 'Lodos aquosos contendo colas ou vedantes não abrangidos em 08 04 13 (* )',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 804,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de colas e vedantes (incluindo produtos impermeabilizantes)',
        codResiduo: '080415(*)',
        descricao3: 'Resíduos líquidos aquosos contendo colas ou vedantes com solventes orgânicos ou substancias perigosas',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 804,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de colas e vedantes (incluindo produtos impermeabilizantes)',
        codResiduo: '80416',
        descricao3: 'Resíduos líquidos aquosos contendo colas ou vedantes não abrangidos em 08 04 15 (* )',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 804,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de colas e vedantes (incluindo produtos impermeabilizantes)',
        codResiduo: '080417(*)',
        descricao3: 'Óleo de resina',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 804,
        descricao2: 'Resíduos da fabricação, formulação, distribuição e utilização de colas e vedantes (incluindo produtos impermeabilizantes)',
        codResiduo: '80499',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 8,
        descricao: 'Resíduos de fabricação, formulação, distribuição e utilização de revestimentos (tintas, vernizes e esmaltes vítreos) colas, vedantes e tintas de impressão',
        subcapitulo: 805,
        descricao2: 'Outros resíduos não anteriormente especificados em 08',
        codResiduo: '080501(*)',
        descricao3: 'resíduos de isocianatos',
      },
      {
        capitulo: 9,
        descricao: 'Resíduos da indústria fotográfica',
        subcapitulo: 901,
        descricao2: 'Resíduos da indústria fotográfica',
        codResiduo: '090101(*)',
        descricao3: 'Banhos de revelação e ativação de base aquosa',
      },
      {
        capitulo: 9,
        descricao: 'Resíduos da indústria fotográfica',
        subcapitulo: 901,
        descricao2: 'Resíduos da indústria fotográfica',
        codResiduo: '090102(*)',
        descricao3: 'Banhos de revelação de chapas litográficas de impressão de base aquosa',
      },
      {
        capitulo: 9,
        descricao: 'Resíduos da indústria fotográfica',
        subcapitulo: 901,
        descricao2: 'Resíduos da indústria fotográfica',
        codResiduo: '090103(*)',
        descricao3: 'Banhos de revelação à base de solventes',
      },
      {
        capitulo: 9,
        descricao: 'Resíduos da indústria fotográfica',
        subcapitulo: 901,
        descricao2: 'Resíduos da indústria fotográfica',
        codResiduo: '090104(*)',
        descricao3: 'Banhos de fixação',
      },
      {
        capitulo: 9,
        descricao: 'Resíduos da indústria fotográfica',
        subcapitulo: 901,
        descricao2: 'Resíduos da indústria fotográfica',
        codResiduo: '090105(*)',
        descricao3: 'Banhos de branqueamento e de fixadores de branqueamento',
      },
      {
        capitulo: 9,
        descricao: 'Resíduos da indústria fotográfica',
        subcapitulo: 901,
        descricao2: 'Resíduos da indústria fotográfica',
        codResiduo: '090106(*)',
        descricao3: 'Resíduos contendo prata do tratamento local de resíduos fotográficos',
      },
      {
        capitulo: 9,
        descricao: 'Resíduos da indústria fotográfica',
        subcapitulo: 901,
        descricao2: 'Resíduos da indústria fotográfica',
        codResiduo: '90107',
        descricao3: 'Película e papel fotográfico com prata ou compostos de prata',
      },
      {
        capitulo: 9,
        descricao: 'Resíduos da indústria fotográfica',
        subcapitulo: 901,
        descricao2: 'Resíduos da indústria fotográfica',
        codResiduo: '90108',
        descricao3: 'Película e papel fotográfico sem prata ou compostos de prata',
      },
      {
        capitulo: 9,
        descricao: 'Resíduos da indústria fotográfica',
        subcapitulo: 901,
        descricao2: 'Resíduos da indústria fotográfica',
        codResiduo: '090113(*)',
        descricao3: 'Resíduos líquidos aquosos da recuperação local de prata não abrangidos em 09 01 06 (* )',
      },
      {
        capitulo: 9,
        descricao: 'Resíduos da indústria fotográfica',
        subcapitulo: 901,
        descricao2: 'Resíduos da indústria fotográfica',
        codResiduo: '90199',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100101',
        descricao3: 'Cinzas, escórias e poeiras de caldeiras (excluídas as poeiras de caldeiras abrangidas em 10 01 04 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100102',
        descricao3: 'Cinzas voláteis da combustão de carvão',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100103',
        descricao3: 'Cinzas voláteis da combustão de turfa ou madeira não tratada',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100104(*)',
        descricao3: 'Cinzas voláteis e poeiras de caldeiras da combustão de hidrocarbonetos',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100105',
        descricao3: 'Resíduos cálcicos de reação, na forma sólida, provenientes da dessulfuração de gases de combustão',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100107',
        descricao3: 'Resíduos cálcicos de reação, na forma de lodos, provenientes da dessulfuração de gases de combustão',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100109(*)',
        descricao3: 'Ácido sulfúrico e ácido sulfuroso',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100113(*)',
        descricao3: 'Cinzas voláteis da combustão de hidrocarbonetos emulsionados, utilizados como combustível',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100114(*)',
        descricao3: 'cinzas, escórias e poeiras de caldeiras de co-incineração contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100115',
        descricao3: 'Cinzas, escórias e poeiras de caldeiras de co-incineração não abrangidas em 10 01 14 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100116(*)',
        descricao3: 'Cinzas voláteis de co-incineração contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100117',
        descricao3: 'Cinzas voláteis de co-incineração não abrangidas em 10 01 16 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100118(*)',
        descricao3: 'Resíduos de lavagem de gases contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100119',
        descricao3: 'Resíduos de lavagem de gases não abrangidos em 10 01 05, 10 01 07 e 10 01 18 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100120(*)',
        descricao3: 'Lodos de tratamento local de efluentes contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100121',
        descricao3: 'Lodos de tratamento não abrangidos em 10 01 20 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100122(*)',
        descricao3: 'Lodos aquosos provenientes da limpeza de caldeiras contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100123',
        descricao3: 'Lodos aquosos provenientes da limpeza de caldeiras não abrangidos em 10 01 22 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100124',
        descricao3: 'Areias de leitos fluidizados',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100125',
        descricao3: 'Resíduos do armazenamento de combustíveis e da preparação de centrais elétricas a carvão',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100126',
        descricao3: 'Resíduos do tratamento de água de arrefecimento',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1001,
        descricao2: 'Resíduos de centrais elétricas e de outras instalações de combustão (exceto 19)',
        codResiduo: '100199',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1002,
        descricao2: 'Resíduos da indústria do ferro e do aço',
        codResiduo: '100201',
        descricao3: 'Escória e outros desperdícios da fabricação do ferro e do aço',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1002,
        descricao2: 'Resíduos da indústria do ferro e do aço',
        codResiduo: '100202',
        descricao3: 'Escórias de alto-fornos granulada (areia de escória) provenientes da fabricação de ferro e do aço',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1002,
        descricao2: 'Resíduos da indústria do ferro e do aço',
        codResiduo: '100203(*)',
        descricao3: 'Lodos ou poeiras provenientes do sistema de controle de emissão da gases na produção de aço primário em fornos elétricos',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1002,
        descricao2: 'Resíduos da indústria do ferro e do aço',
        codResiduo: '100207(*)',
        descricao3: 'Resíduos sólidos do tratamento de gases contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1002,
        descricao2: 'Resíduos da indústria do ferro e do aço',
        codResiduo: '100208',
        descricao3: 'Resíduos sólidos do tratamento de gases não abrangidos em 10 02 07 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1002,
        descricao2: 'Resíduos da indústria do ferro e do aço',
        codResiduo: '100210',
        descricao3: 'Escamas de laminagem',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1002,
        descricao2: 'Resíduos da indústria do ferro e do aço',
        codResiduo: '100211(*)',
        descricao3: 'Resíduos do tratamento de agua de arrefecimento contendo hidrocarbonetos',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1002,
        descricao2: 'Resíduos da indústria do ferro e do aço',
        codResiduo: '100212',
        descricao3: 'Resíduos do tratamento de agua de arrefecimento não abrangidos em 10 02 11 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1002,
        descricao2: 'Resíduos da indústria do ferro e do aço',
        codResiduo: '100213(*)',
        descricao3: 'Lodos e tortas de filtro de tratamento de gases contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1002,
        descricao2: 'Resíduos da indústria do ferro e do aço',
        codResiduo: '100214',
        descricao3: 'Lodos e tortas de filtro de tratamento de gases não abrangidos em 10 02 13 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1002,
        descricao2: 'Resíduos da indústria do ferro e do aço',
        codResiduo: '100215',
        descricao3: 'Outros lodos e tortas de filtro',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1002,
        descricao2: 'Resíduos da indústria do ferro e do aço',
        codResiduo: '100216(*)',
        descricao3: 'Poeiras provenientes do sistema de controle de emissão de gases empregado nos fornos Cubilot empregados na fundição de ferro',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1002,
        descricao2: 'Resíduos da indústria do ferro e do aço',
        codResiduo: '100299',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100302',
        descricao3: 'Resíduos de ânodos',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100303(*)',
        descricao3: 'Cátodos usados provenientes da redução de alumínio primário',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100304(*)',
        descricao3: 'Escórias da produção primária',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100305(*)',
        descricao3: 'Resíduos provenientes do desmonte das cubas de redução empregadas na produção de alumínio primário',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100306',
        descricao3: 'Resíduos de alumina',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100308(*)',
        descricao3: 'Escórias salinas da produção secundária',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100309(*)',
        descricao3: 'Impurezas negras da produção secundária',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100315(*)',
        descricao3: 'Escumas inflamáveis ou que, em contato com a água, libertam gases inflamáveis em quantidades perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100316',
        descricao3: 'Escumas não abrangidas em 10 03 15 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100317(*)',
        descricao3: 'Resíduos da fabricação de ânodos contendo alcatrão',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100318',
        descricao3: 'Resíduos da fabricação de ânodos contendo carbono, não abrangidos em 10 03 17 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100319(*)',
        descricao3: 'Poeiras de gases de combustão contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100320',
        descricao3: 'Poeiras de gases de combustão não abrangidos em 10 03 19 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100321(*)',
        descricao3: 'Outras partículas e poeiras (incluindo poeiras da trituração de escórias) contendo substâncias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100322',
        descricao3: 'Outras partículas e poeiras (incluindo poeiras da trituração de escórias) não abrangidas em 10 03 21 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100323(*)',
        descricao3: 'Resíduos sólidos do tratamento de gases contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100324',
        descricao3: 'Resíduos sólidos do tratamento de gases não abrangidos em 10 03 23 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100325(*)',
        descricao3: 'Lodos e tortas de filtro do tratamento de gases contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100326',
        descricao3: 'Lodos e tortas de filtro do tratamento de gases não abrangidos em 10 03 25 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100327(*)',
        descricao3: 'Resíduos do tratamento da água de arrefecimento contendo hidrocarbonetos',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100328',
        descricao3: 'Resíduos do tratamento da água de arrefecimento não abrangidos em 10 03 27 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100329(*)',
        descricao3: 'Resíduos do tratamento de escórias salinas e do tratamento das impurezas negras contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100330',
        descricao3: 'Resíduos do tratamento de escórias salinas e do tratamento das impurezas negras não abrangidos em 10 03 29 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1003,
        descricao2: 'Resíduos de pirometalurgia do alumínio',
        codResiduo: '100399',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1004,
        descricao2: 'Resíduos da pirometalurgia do chumbo',
        codResiduo: '100401(*)',
        descricao3: 'Escórias da produção primária e secundária',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1004,
        descricao2: 'Resíduos da pirometalurgia do chumbo',
        codResiduo: '100402(*)',
        descricao3: 'Impurezas e escumas da produção primária e secundária',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1004,
        descricao2: 'Resíduos da pirometalurgia do chumbo',
        codResiduo: '100403(*)',
        descricao3: 'Arseniato de cálcio',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1004,
        descricao2: 'Resíduos da pirometalurgia do chumbo',
        codResiduo: '100404(*)',
        descricao3: 'Lodos, lixivias ou poeiras provenientes de sistema de controle de emissão de gases empregado na produção primária e secundária do chumbo',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1004,
        descricao2: 'Resíduos da pirometalurgia do chumbo',
        codResiduo: '100405(*)',
        descricao3: 'Outras partículas e poeiras',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1004,
        descricao2: 'Resíduos da pirometalurgia do chumbo',
        codResiduo: '100406(*)',
        descricao3: 'Resíduos sólidos do tratamento de gases',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1004,
        descricao2: 'Resíduos da pirometalurgia do chumbo',
        codResiduo: '100407(*)',
        descricao3: 'Lodos e tortas de filtro do tratamento de gases',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1004,
        descricao2: 'Resíduos da pirometalurgia do chumbo',
        codResiduo: '100409(*)',
        descricao3: 'Resíduos do tratamento da água de arrefecimento contendo hidrocarbonetos',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1004,
        descricao2: 'Resíduos da pirometalurgia do chumbo',
        codResiduo: '100410',
        descricao3: 'Resíduos de tratamento de água de arrefecimento não abrangidos em 10 04 09 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1004,
        descricao2: 'Resíduos da pirometalurgia do chumbo',
        codResiduo: '100499',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1005,
        descricao2: 'Resíduos da pirometalurgia do zinco',
        codResiduo: '100501',
        descricao3: 'Escórias da produção primária e secundária',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1005,
        descricao2: 'Resíduos da pirometalurgia do zinco',
        codResiduo: '100502(*)',
        descricao3: 'Lodos calcários de anodos eletrolíticos originados na produção de zinco primário',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1005,
        descricao2: 'Resíduos da pirometalurgia do zinco',
        codResiduo: '100503(*)',
        descricao3: 'Poeiras de gases de combustão',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1005,
        descricao2: 'Resíduos da pirometalurgia do zinco',
        codResiduo: '100504',
        descricao3: 'Outras partículas e poeiras não perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1005,
        descricao2: 'Resíduos da pirometalurgia do zinco',
        codResiduo: '100505(*)',
        descricao3: 'Resíduos sólidos do tratamento de gases',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1005,
        descricao2: 'Resíduos da pirometalurgia do zinco',
        codResiduo: '100506(*)',
        descricao3: 'Lodos e tortas de filtro do tratamento de gases',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1005,
        descricao2: 'Resíduos da pirometalurgia do zinco',
        codResiduo: '100507(*)',
        descricao3: 'Resíduos provenientes da unidade cádmio (óxido de ferro) do processo de produção de zinco primário',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1005,
        descricao2: 'Resíduos da pirometalurgia do zinco',
        codResiduo: '100508(*)',
        descricao3: 'Resíduos do tratamento da água de arrefecimento contendo hidrocarbonetos',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1005,
        descricao2: 'Resíduos da pirometalurgia do zinco',
        codResiduo: '100509',
        descricao3: 'Resíduos do tratamento da água de arrefecimento não abrangidos em 10 05 08 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1005,
        descricao2: 'Resíduos da pirometalurgia do zinco',
        codResiduo: '100510(*)',
        descricao3: 'Impurezas e escumas inflamáveis ou que, em contato com a água, libertam gases inflamáveis em quantidades perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1005,
        descricao2: 'Resíduos da pirometalurgia do zinco',
        codResiduo: '100511',
        descricao3: 'Impurezas e escumas não abrangidas em 10 05 10 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1005,
        descricao2: 'Resíduos da pirometalurgia do zinco',
        codResiduo: '100599',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1006,
        descricao2: 'Resíduos da pirometalurgia do cobre',
        codResiduo: '100601',
        descricao3: 'Escórias da produção primária e secundária',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1006,
        descricao2: 'Resíduos da pirometalurgia do cobre',
        codResiduo: '100602',
        descricao3: 'Impurezas e escumas da produção primária e secundária',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1006,
        descricao2: 'Resíduos da pirometalurgia do cobre',
        codResiduo: '100603(*)',
        descricao3: 'Poeiras de gases de combustão',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1006,
        descricao2: 'Resíduos da pirometalurgia do cobre',
        codResiduo: '100604',
        descricao3: 'Outras partículas e poeiras não perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1006,
        descricao2: 'Resíduos da pirometalurgia do cobre',
        codResiduo: '100606(*)',
        descricao3: 'Resíduos sólidos do tratamento de gases',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1006,
        descricao2: 'Resíduos da pirometalurgia do cobre',
        codResiduo: '100607(*)',
        descricao3: 'Lodos e tortas de filtro do tratamento de gases',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1006,
        descricao2: 'Resíduos da pirometalurgia do cobre',
        codResiduo: '100609(*)',
        descricao3: 'Resíduos do tratamento da água de arrefecimento contendo hidrocarbonetos , incluindo lamas e lodos do adensamento da purga ácida do processo de produção de cobre primário',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1006,
        descricao2: 'Resíduos da pirometalurgia do cobre',
        codResiduo: '100610',
        descricao3: 'Resíduos do tratamento da água de arrefecimento não abrangidos em 10 06 09 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1006,
        descricao2: 'Resíduos da pirometalurgia do cobre',
        codResiduo: '100699',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1007,
        descricao2: 'Resíduos da pirometalurgia da prata, do ouro e da platina',
        codResiduo: '100701',
        descricao3: 'Escórias da produção primária e secundária',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1007,
        descricao2: 'Resíduos da pirometalurgia da prata, do ouro e da platina',
        codResiduo: '100702',
        descricao3: 'Impurezas e escumas da produção primária e secundária',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1007,
        descricao2: 'Resíduos da pirometalurgia da prata, do ouro e da platina',
        codResiduo: '100703',
        descricao3: 'Resíduos sólidos do tratamento de gases',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1007,
        descricao2: 'Resíduos da pirometalurgia da prata, do ouro e da platina',
        codResiduo: '100704',
        descricao3: 'Outras partículas e poeiras não perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1007,
        descricao2: 'Resíduos da pirometalurgia da prata, do ouro e da platina',
        codResiduo: '100705',
        descricao3: 'Lodos e tortas de filtro do tratamento de gases',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1007,
        descricao2: 'Resíduos da pirometalurgia da prata, do ouro e da platina',
        codResiduo: '100707(*)',
        descricao3: 'Resíduos do tratamento da água de arrefecimento contendo hidrocarbonetos',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1007,
        descricao2: 'Resíduos da pirometalurgia da prata, do ouro e da platina',
        codResiduo: '100708',
        descricao3: 'Resíduos do tratamento da água de arrefecimento não abrangidos em 10 07 07 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1007,
        descricao2: 'Resíduos da pirometalurgia da prata, do ouro e da platina',
        codResiduo: '100799',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1008,
        descricao2: 'Resíduos da pirometalurgia de outros metais não ferrosos',
        codResiduo: '100804',
        descricao3: 'Partículas e poeiras não perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1008,
        descricao2: 'Resíduos da pirometalurgia de outros metais não ferrosos',
        codResiduo: '100808(*)',
        descricao3: 'Escórias salinas da produção primária e secundária',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1008,
        descricao2: 'Resíduos da pirometalurgia de outros metais não ferrosos',
        codResiduo: '100809',
        descricao3: 'Outras escórias',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1008,
        descricao2: 'Resíduos da pirometalurgia de outros metais não ferrosos',
        codResiduo: '100810(*)',
        descricao3: 'Impurezas e escumas inflamáveis ou que, em contato com a água, libertam gases inflamáveis em quantidades perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1008,
        descricao2: 'Resíduos da pirometalurgia de outros metais não ferrosos',
        codResiduo: '100811',
        descricao3: 'Impurezas e escumas não abrangidas em 10 08 10 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1008,
        descricao2: 'Resíduos da pirometalurgia de outros metais não ferrosos',
        codResiduo: '100812(*)',
        descricao3: 'Resíduos da fabricação de anodos contendo alcatrão',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1008,
        descricao2: 'Resíduos da pirometalurgia de outros metais não ferrosos',
        codResiduo: '100813',
        descricao3: 'Resíduos da fabricação de anodos contendo carbono não abrangidos em 10 08 12 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1008,
        descricao2: 'Resíduos da pirometalurgia de outros metais não ferrosos',
        codResiduo: '100814',
        descricao3: 'Resíduos de anodos',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1008,
        descricao2: 'Resíduos da pirometalurgia de outros metais não ferrosos',
        codResiduo: '100815(*)',
        descricao3: 'Poeiras da gases de combustão contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1008,
        descricao2: 'Resíduos da pirometalurgia de outros metais não ferrosos',
        codResiduo: '100816',
        descricao3: 'Poeiras de gases não abrangidos em 10 08 15 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1008,
        descricao2: 'Resíduos da pirometalurgia de outros metais não ferrosos',
        codResiduo: '100817(*)',
        descricao3: 'Lodos e tortas de filtro do tratamento de gases de combustão contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1008,
        descricao2: 'Resíduos da pirometalurgia de outros metais não ferrosos',
        codResiduo: '100818',
        descricao3: 'Lodos e tortas de filtro do tratamento de gases de combustão não abrangidos em 10 08 17 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1008,
        descricao2: 'Resíduos da pirometalurgia de outros metais não ferrosos',
        codResiduo: '100819(*)',
        descricao3: 'Resíduos do tratamento da água de arrefecimento contendo hidrocarbonetos',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1008,
        descricao2: 'Resíduos da pirometalurgia de outros metais não ferrosos',
        codResiduo: '100820',
        descricao3: 'Resíduos do tratamento da água de arrefecimento não abrangidos em 10 08 19 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1008,
        descricao2: 'Resíduos da pirometalurgia de outros metais não ferrosos',
        codResiduo: '100899',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1009,
        descricao2: 'Resíduos da fundição de peças ferrosas',
        codResiduo: '100903',
        descricao3: 'Escórias do forno',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1009,
        descricao2: 'Resíduos da fundição de peças ferrosas',
        codResiduo: '100905(*)',
        descricao3: 'Moldes e modelos e moldes de fundição não vazados contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1009,
        descricao2: 'Resíduos da fundição de peças ferrosas',
        codResiduo: '100906',
        descricao3: 'Moldes e modelos e moldes de fundição não vazados não abrangidos em 10 09 05 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1009,
        descricao2: 'Resíduos da fundição de peças ferrosas',
        codResiduo: '100907(*)',
        descricao3: 'Moldes e modelos e moldes de fundição vazados contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1009,
        descricao2: 'Resíduos da fundição de peças ferrosas',
        codResiduo: '100908',
        descricao3: 'Moldes e modelos e moldes de fundição vazados não abrangidos em 10 09 07 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1009,
        descricao2: 'Resíduos da fundição de peças ferrosas',
        codResiduo: '100909(*)',
        descricao3: 'Poeiras de gases de combustão contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1009,
        descricao2: 'Resíduos da fundição de peças ferrosas',
        codResiduo: '100910',
        descricao3: 'Poeiras de gases de combustão não abrangidos em 10 09 09 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1009,
        descricao2: 'Resíduos da fundição de peças ferrosas',
        codResiduo: '100911(*)',
        descricao3: 'Outras partículas contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1009,
        descricao2: 'Resíduos da fundição de peças ferrosas',
        codResiduo: '100912',
        descricao3: 'Outras partículas não abrangidas em 10 09 11 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1009,
        descricao2: 'Resíduos da fundição de peças ferrosas',
        codResiduo: '100913(*)',
        descricao3: 'Resíduos de aglutinantes contendo substâncias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1009,
        descricao2: 'Resíduos da fundição de peças ferrosas',
        codResiduo: '100914',
        descricao3: 'Resíduos de aglutinantes não abrangidos em 10 09 13 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1009,
        descricao2: 'Resíduos da fundição de peças ferrosas',
        codResiduo: '100915(*)',
        descricao3: 'Resíduos de agentes indicadores de fendas e trincas contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1009,
        descricao2: 'Resíduos da fundição de peças ferrosas',
        codResiduo: '100916',
        descricao3: 'Resíduos de agentes indicadores de fendas e trincas não abrangidos em 10 09 15 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1009,
        descricao2: 'Resíduos da fundição de peças ferrosas',
        codResiduo: '100999',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1010,
        descricao2: 'Resíduos de Fundição de peças não ferrosas',
        codResiduo: '101003',
        descricao3: 'Escórias do forno',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1010,
        descricao2: 'Resíduos de Fundição de peças não ferrosas',
        codResiduo: '101005(*)',
        descricao3: 'Moldes e modelos e moldes de fundição não vazados contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1010,
        descricao2: 'Resíduos de Fundição de peças não ferrosas',
        codResiduo: '101006',
        descricao3: 'Moldes e modelos e moldes de fundição não vazados não abrangidos em 10 10 05 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1010,
        descricao2: 'Resíduos de Fundição de peças não ferrosas',
        codResiduo: '101007(*)',
        descricao3: 'Moldes e modelos e moldes de fundição vazados contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1010,
        descricao2: 'Resíduos de Fundição de peças não ferrosas',
        codResiduo: '101008',
        descricao3: 'Moldes e modelos e moldes de fundição vazados não abrangidos em 10 10 07 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1010,
        descricao2: 'Resíduos de Fundição de peças não ferrosas',
        codResiduo: '101009(*)',
        descricao3: 'Poeiras de gases de combustão contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1010,
        descricao2: 'Resíduos de Fundição de peças não ferrosas',
        codResiduo: '101010',
        descricao3: 'Poeiras de gases de combustão não abrangidas em 10 10 09 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1010,
        descricao2: 'Resíduos de Fundição de peças não ferrosas',
        codResiduo: '101011(*)',
        descricao3: 'Outras partículas contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1010,
        descricao2: 'Resíduos de Fundição de peças não ferrosas',
        codResiduo: '101012',
        descricao3: 'Outras partículas não abrangidas em 10 10 11 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1010,
        descricao2: 'Resíduos de Fundição de peças não ferrosas',
        codResiduo: '101013(*)',
        descricao3: 'Resíduos de aglutinantes contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1010,
        descricao2: 'Resíduos de Fundição de peças não ferrosas',
        codResiduo: '101014',
        descricao3: 'Resíduos de aglutinantes não abrangidos em 10 10 13 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1010,
        descricao2: 'Resíduos de Fundição de peças não ferrosas',
        codResiduo: '101015(*)',
        descricao3: 'Resíduos de agentes indicadores de fendas e trincas contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1010,
        descricao2: 'Resíduos de Fundição de peças não ferrosas',
        codResiduo: '101016',
        descricao3: 'Resíduos de agentes indicadores de fendas e trincas não abrangidos em 10 10 15 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1010,
        descricao2: 'Resíduos de Fundição de peças não ferrosas',
        codResiduo: '101099',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1011,
        descricao2: 'Resíduos da fabricação do vidro e de produtora de vidro',
        codResiduo: '101103',
        descricao3: 'Resíduos de materiais fibrosos à base de vidro',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1011,
        descricao2: 'Resíduos da fabricação do vidro e de produtora de vidro',
        codResiduo: '101105',
        descricao3: 'Partículas e poeiras',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1011,
        descricao2: 'Resíduos da fabricação do vidro e de produtora de vidro',
        codResiduo: '101109(*)',
        descricao3: 'Resíduos da preparação da mistura (antes do processo térmico) contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1011,
        descricao2: 'Resíduos da fabricação do vidro e de produtora de vidro',
        codResiduo: '101110',
        descricao3: 'Resíduos da preparação da mistura (antes do processo térmico) não abrangidas em 10 11 09 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1011,
        descricao2: 'Resíduos da fabricação do vidro e de produtora de vidro',
        codResiduo: '101111(*)',
        descricao3: 'Resíduos de vidro em pequenas partículas e em pó de vidro contendo metais pesados (por exemplo, tubos catódicos )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1011,
        descricao2: 'Resíduos da fabricação do vidro e de produtora de vidro',
        codResiduo: '101112',
        descricao3: 'Resíduos de vidros não abrangidos em 10 11 11 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1011,
        descricao2: 'Resíduos da fabricação do vidro e de produtora de vidro',
        codResiduo: '101113(*)',
        descricao3: 'Lodos de polimento e retificação de vidro contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1011,
        descricao2: 'Resíduos da fabricação do vidro e de produtora de vidro',
        codResiduo: '101114',
        descricao3: 'Lodos de polimento e retificação de vidro não abrangidos em 10 11 13 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1011,
        descricao2: 'Resíduos da fabricação do vidro e de produtora de vidro',
        codResiduo: '101115(*)',
        descricao3: 'Resíduos sólidos de tratamento de gases de combustão contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1011,
        descricao2: 'Resíduos da fabricação do vidro e de produtora de vidro',
        codResiduo: '101116',
        descricao3: 'Resíduos sólidos de tratamento de gases de combustão não abrangidos em 10 11 15 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1011,
        descricao2: 'Resíduos da fabricação do vidro e de produtora de vidro',
        codResiduo: '101117(*)',
        descricao3: 'Lodos e tortas de filtro do tratamento de gases de combustão contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1011,
        descricao2: 'Resíduos da fabricação do vidro e de produtora de vidro',
        codResiduo: '101118',
        descricao3: 'Lodos e tortas de filtro do tratamento de gases de combustão não abrangidas em 10 11 17 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1011,
        descricao2: 'Resíduos da fabricação do vidro e de produtora de vidro',
        codResiduo: '101119(*)',
        descricao3: 'Resíduos sólidos do tratamento local de efluentes contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1011,
        descricao2: 'Resíduos da fabricação do vidro e de produtora de vidro',
        codResiduo: '101120',
        descricao3: 'Resíduos sólidos do tratamento local de efluentes não abrangidas em 10 11 19 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1011,
        descricao2: 'Resíduos da fabricação do vidro e de produtora de vidro',
        codResiduo: '101199',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1012,
        descricao2: 'Resíduos da fabricação de peças cerâmicas , tijolos, ladrilhos, telhas e produtos de construção',
        codResiduo: '101201',
        descricao3: 'Resíduos da preparação da mistura (antes do tratamento térmico )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1012,
        descricao2: 'Resíduos da fabricação de peças cerâmicas , tijolos, ladrilhos, telhas e produtos de construção',
        codResiduo: '101203',
        descricao3: 'Partículas e poeiras',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1012,
        descricao2: 'Resíduos da fabricação de peças cerâmicas , tijolos, ladrilhos, telhas e produtos de construção',
        codResiduo: '101205',
        descricao3: 'Lodos e tortas de filtro do tratamento de gases',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1012,
        descricao2: 'Resíduos da fabricação de peças cerâmicas , tijolos, ladrilhos, telhas e produtos de construção',
        codResiduo: '101206',
        descricao3: 'Moldes fora de uso',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1012,
        descricao2: 'Resíduos da fabricação de peças cerâmicas , tijolos, ladrilhos, telhas e produtos de construção',
        codResiduo: '101208',
        descricao3: 'Resíduos de fabricação de peças cerâmicas, tijolos, ladrilhos, telhas e produtos de construção (após o processo térmico )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1012,
        descricao2: 'Resíduos da fabricação de peças cerâmicas , tijolos, ladrilhos, telhas e produtos de construção',
        codResiduo: '101209(*)',
        descricao3: 'Resíduos sólidos do tratamento de gases contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1012,
        descricao2: 'Resíduos da fabricação de peças cerâmicas , tijolos, ladrilhos, telhas e produtos de construção',
        codResiduo: '101210',
        descricao3: 'Resíduos sólidos do tratamento de gases não abrangidos em 10 12 09 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1012,
        descricao2: 'Resíduos da fabricação de peças cerâmicas , tijolos, ladrilhos, telhas e produtos de construção',
        codResiduo: '101211(*)',
        descricao3: 'Resíduos de vitrificação contendo metais pesados',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1012,
        descricao2: 'Resíduos da fabricação de peças cerâmicas , tijolos, ladrilhos, telhas e produtos de construção',
        codResiduo: '101212',
        descricao3: 'Resíduos de vitrificação não abrangidos em 10 12 11 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1012,
        descricao2: 'Resíduos da fabricação de peças cerâmicas , tijolos, ladrilhos, telhas e produtos de construção',
        codResiduo: '101213',
        descricao3: 'Lodos de tratamento local de efluentes',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1012,
        descricao2: 'Resíduos da fabricação de peças cerâmicas , tijolos, ladrilhos, telhas e produtos de construção',
        codResiduo: '101299',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1013,
        descricao2: 'Resíduos de fabricação de cimento, cal e gesso e de artigos e produtos fabricados a partir deles',
        codResiduo: '101301',
        descricao3: 'Resíduos de preparação da mistura antes do processo térmico',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1013,
        descricao2: 'Resíduos de fabricação de cimento, cal e gesso e de artigos e produtos fabricados a partir deles',
        codResiduo: '101304',
        descricao3: 'Resíduos da calcinação e hidratação da cal',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1013,
        descricao2: 'Resíduos de fabricação de cimento, cal e gesso e de artigos e produtos fabricados a partir deles',
        codResiduo: '101306',
        descricao3: 'Partículas e poeiras exceto 10 13 12 (*) e 10 13 13',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1013,
        descricao2: 'Resíduos de fabricação de cimento, cal e gesso e de artigos e produtos fabricados a partir deles',
        codResiduo: '101307',
        descricao3: 'Lodos e tortas de filtro do tratamento de gases',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1013,
        descricao2: 'Resíduos de fabricação de cimento, cal e gesso e de artigos e produtos fabricados a partir deles',
        codResiduo: '101309(*)',
        descricao3: 'Resíduos da fabricação de fibrocimento contendo amianto',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1013,
        descricao2: 'Resíduos de fabricação de cimento, cal e gesso e de artigos e produtos fabricados a partir deles',
        codResiduo: '101310',
        descricao3: 'Resíduos da fabricação de fibrocimento não abrangidos em 10 13 09 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1013,
        descricao2: 'Resíduos de fabricação de cimento, cal e gesso e de artigos e produtos fabricados a partir deles',
        codResiduo: '101311',
        descricao3: 'Resíduos de materiais compósitos à base de cimento não abrangidos em 10 13 09 (*) e 10 13 10',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1013,
        descricao2: 'Resíduos de fabricação de cimento, cal e gesso e de artigos e produtos fabricados a partir deles',
        codResiduo: '101312(*)',
        descricao3: 'Resíduos sólidos do tratamento de gases contendo substancias perigosas',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1013,
        descricao2: 'Resíduos de fabricação de cimento, cal e gesso e de artigos e produtos fabricados a partir deles',
        codResiduo: '101313',
        descricao3: 'Resíduos sólidos do tratamento de gases não abrangidos em 10 13 12 (* )',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1013,
        descricao2: 'Resíduos de fabricação de cimento, cal e gesso e de artigos e produtos fabricados a partir deles',
        codResiduo: '101314',
        descricao3: 'Resíduos de cimento e de lodos de cimento',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1013,
        descricao2: 'Resíduos de fabricação de cimento, cal e gesso e de artigos e produtos fabricados a partir deles',
        codResiduo: '101399',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 10,
        descricao: 'Resíduos de processos térmicos',
        subcapitulo: 1014,
        descricao2: 'Resíduos de crematórios',
        codResiduo: '101401(*)',
        descricao3: 'Resíduos de limpeza de gases contendo mercúrio',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1101,
        descricao2: 'Resíduos de tratamentos químicos de superfície e revestimentos de metais e outros materiais (por exemplo, galvanização, zincagem, decapagem, contrastação, fosfatação, desengorduramento alcalino, anodização)',
        codResiduo: '110104(*)',
        descricao3: 'Banho de decapagem exaurido proveniente das operações de acabamento do aço',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1101,
        descricao2: 'Resíduos de tratamentos químicos de superfície e revestimentos de metais e outros materiais (por exemplo, galvanização, zincagem, decapagem, contrastação, fosfatação, desengorduramento alcalino, anodização)',
        codResiduo: '110105(*)',
        descricao3: 'Ácidos de decapagem',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1101,
        descricao2: 'Resíduos de tratamentos químicos de superfície e revestimentos de metais e outros materiais (por exemplo, galvanização, zincagem, decapagem, contrastação, fosfatação, desengorduramento alcalino, anodização)',
        codResiduo: '110106(*)',
        descricao3: 'Ácidos não anteriormente especificados',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1101,
        descricao2: 'Resíduos de tratamentos químicos de superfície e revestimentos de metais e outros materiais (por exemplo, galvanização, zincagem, decapagem, contrastação, fosfatação, desengorduramento alcalino, anodização)',
        codResiduo: '110107(*)',
        descricao3: 'Bases de decapagem',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1101,
        descricao2: 'Resíduos de tratamentos químicos de superfície e revestimentos de metais e outros materiais (por exemplo, galvanização, zincagem, decapagem, contrastação, fosfatação, desengorduramento alcalino, anodização)',
        codResiduo: '110108(*)',
        descricao3: 'Lodos de fosfatização',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1101,
        descricao2: 'Resíduos de tratamentos químicos de superfície e revestimentos de metais e outros materiais (por exemplo, galvanização, zincagem, decapagem, contrastação, fosfatação, desengorduramento alcalino, anodização)',
        codResiduo: '110109(*)',
        descricao3: 'Lodos e tortas de filtro contendo substancias perigosas',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1101,
        descricao2: 'Resíduos de tratamentos químicos de superfície e revestimentos de metais e outros materiais (por exemplo, galvanização, zincagem, decapagem, contrastação, fosfatação, desengorduramento alcalino, anodização)',
        codResiduo: '110110',
        descricao3: 'Lodos e tortas de filtro não abrangidas em 11 01 09 (* )',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1101,
        descricao2: 'Resíduos de tratamentos químicos de superfície e revestimentos de metais e outros materiais (por exemplo, galvanização, zincagem, decapagem, contrastação, fosfatação, desengorduramento alcalino, anodização)',
        codResiduo: '110111(*)',
        descricao3: 'Soluções exauridas, lodos e líquidos de lavagem aquosos contendo cianeto e ou outras substancias perigosas',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1101,
        descricao2: 'Resíduos de tratamentos químicos de superfície e revestimentos de metais e outros materiais (por exemplo, galvanização, zincagem, decapagem, contrastação, fosfatação, desengorduramento alcalino, anodização)',
        codResiduo: '110112',
        descricao3: 'Líquidos de lavagem aquosos não abrangidos em 11 01 11 (* )',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1101,
        descricao2: 'Resíduos de tratamentos químicos de superfície e revestimentos de metais e outros materiais (por exemplo, galvanização, zincagem, decapagem, contrastação, fosfatação, desengorduramento alcalino, anodização)',
        codResiduo: '110113(*)',
        descricao3: 'Resíduos de desengorduramento contendo substancias perigosas',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1101,
        descricao2: 'Resíduos de tratamentos químicos de superfície e revestimentos de metais e outros materiais (por exemplo, galvanização, zincagem, decapagem, contrastação, fosfatação, desengorduramento alcalino, anodização)',
        codResiduo: '110114',
        descricao3: 'Resíduos de desengorduramento não abrangidas em 11 01 13 (* )',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1101,
        descricao2: 'Resíduos de tratamentos químicos de superfície e revestimentos de metais e outros materiais (por exemplo, galvanização, zincagem, decapagem, contrastação, fosfatação, desengorduramento alcalino, anodização)',
        codResiduo: '110115(*)',
        descricao3: 'Eluatos e lodos de sistema de membranas ou de permuta iônica contendo substancias perigosas',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1101,
        descricao2: 'Resíduos de tratamentos químicos de superfície e revestimentos de metais e outros materiais (por exemplo, galvanização, zincagem, decapagem, contrastação, fosfatação, desengorduramento alcalino, anodização)',
        codResiduo: '110116(*)',
        descricao3: 'Resinas de permuta iônica saturadas ou usadas',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1101,
        descricao2: 'Resíduos de tratamentos químicos de superfície e revestimentos de metais e outros materiais (por exemplo, galvanização, zincagem, decapagem, contrastação, fosfatação, desengorduramento alcalino, anodização)',
        codResiduo: '110198(*)',
        descricao3: 'Outros resíduos contendo substancias perigosas',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1101,
        descricao2: 'Resíduos de tratamentos químicos de superfície e revestimentos de metais e outros materiais (por exemplo, galvanização, zincagem, decapagem, contrastação, fosfatação, desengorduramento alcalino, anodização)',
        codResiduo: '110199',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1102,
        descricao2: 'Resíduos de processos hidrometalurgicos de metais não ferrosos',
        codResiduo: '110202(*)',
        descricao3: 'Lodos da hidrometalurgia do zinco',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1102,
        descricao2: 'Resíduos de processos hidrometalurgicos de metais não ferrosos',
        codResiduo: '110203',
        descricao3: 'Resíduos da produção de ânodos dos processos eletrolíticos aquosos',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1102,
        descricao2: 'Resíduos de processos hidrometalurgicos de metais não ferrosos',
        codResiduo: '110205(*)',
        descricao3: 'Resíduos de processos hidrometalurgicos do cobre contendo substancias perigosas',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1102,
        descricao2: 'Resíduos de processos hidrometalurgicos de metais não ferrosos',
        codResiduo: '110206',
        descricao3: 'Resíduos de processos hidrometalurgicos do cobre não abrangidos em 11 02 05 (* )',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1102,
        descricao2: 'Resíduos de processos hidrometalurgicos de metais não ferrosos',
        codResiduo: '110207(*)',
        descricao3: 'Outros resíduos contendo substancias perigosas',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1102,
        descricao2: 'Resíduos de processos hidrometalurgicos de metais não ferrosos',
        codResiduo: '110299',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1103,
        descricao2: 'Lodos e sólidos de processos de têmpera',
        codResiduo: '110301(*)',
        descricao3: 'Resíduos contendo cianetos',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1103,
        descricao2: 'Lodos e sólidos de processos de têmpera',
        codResiduo: '110302(*)',
        descricao3: 'Outros resíduos',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1103,
        descricao2: 'Lodos e sólidos de processos de têmpera',
        codResiduo: '110303(*)',
        descricao3: 'Lodos originados no tratamento de efluentes líquidos provenientes dos banhos de têmpera das operações de tratamento térmico de metais nos quais são utilizados cianetos',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1105,
        descricao2: 'Resíduos de processos de galvanização a quente',
        codResiduo: '110501',
        descricao3: 'Escórias e cinzas de zinco não perigosas',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1105,
        descricao2: 'Resíduos de processos de galvanização a quente',
        codResiduo: '110502(*)',
        descricao3: 'Cinzas de zinco contendo cádmio ou chumbo',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1105,
        descricao2: 'Resíduos de processos de galvanização a quente',
        codResiduo: '110503(*)',
        descricao3: 'Resíduos sólidos do tratamento de gases',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1105,
        descricao2: 'Resíduos de processos de galvanização a quente',
        codResiduo: '110504(*)',
        descricao3: 'Fluxantes usados',
      },
      {
        capitulo: 11,
        descricao: 'Resíduos de tratamentos químicos e revestimentos de metais e outros materiais, resíduos da hidrometalurgia de metais não ferrosos',
        subcapitulo: 1105,
        descricao2: 'Resíduos de processos de galvanização a quente',
        codResiduo: '110599',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1201,
        descricao2: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        codResiduo: '120101',
        descricao3: 'Aparas e limalhas de metais ferrosos',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1201,
        descricao2: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        codResiduo: '120102',
        descricao3: 'Poeiras e partículas de metais ferrosos',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1201,
        descricao2: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        codResiduo: '120103',
        descricao3: 'Aparas e limalhas de metais não ferrosos',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1201,
        descricao2: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        codResiduo: '120104',
        descricao3: 'Poeiras e partículas de metais não ferrosos',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1201,
        descricao2: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        codResiduo: '120105',
        descricao3: 'Aparas de matérias plásticas',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1201,
        descricao2: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        codResiduo: '120106(*)',
        descricao3: 'Óleos minerais de corte e usinagem com halogênios (exceto emulsões, misturas e soluções )',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1201,
        descricao2: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        codResiduo: '120107(*)',
        descricao3: 'Óleos minerais de corte e usinagem sem halogênios (exceto emulsões, misturas e soluções )',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1201,
        descricao2: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        codResiduo: '120108(*)',
        descricao3: 'Emulsões, misturas e soluções de corte e usinagem com halogênios',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1201,
        descricao2: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        codResiduo: '120109(*)',
        descricao3: 'Emulsões, misturas e soluções de corte e usinagem sem halogênios',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1201,
        descricao2: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        codResiduo: '120110(*)',
        descricao3: 'Óleos sintéticos de corte e usinagem',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1201,
        descricao2: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        codResiduo: '120112(*)',
        descricao3: 'Ceras e gorduras usadas',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1201,
        descricao2: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        codResiduo: '120113',
        descricao3: 'Resíduos de soldadura',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1201,
        descricao2: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        codResiduo: '120114(*)',
        descricao3: 'Lodos da usinagem contendo substancias perigosas',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1201,
        descricao2: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        codResiduo: '120115',
        descricao3: 'Lodos de usinagem não abrangidas em 10 01 14 (* )',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1201,
        descricao2: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        codResiduo: '120116(*)',
        descricao3: 'Resíduos de materiais de polimento contendo substancias perigosas',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1201,
        descricao2: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        codResiduo: '120117',
        descricao3: 'Resíduos de materiais de polimento não abrangidos em 12 01 16 (* )',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1201,
        descricao2: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        codResiduo: '120118(*)',
        descricao3: 'Lodos metálicos (lodos de retificação, superacabamento e lixamento) contendo óleo',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1201,
        descricao2: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        codResiduo: '120119(*)',
        descricao3: 'Óleos de usinagem facilmente biodegradáveis',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1201,
        descricao2: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        codResiduo: '120120(*)',
        descricao3: 'Mós e materiais de retificação usados contendo substâncias perigosas',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1201,
        descricao2: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        codResiduo: '120121',
        descricao3: 'Mós e materiais de retificação usados não abrangidos em 12 01 20 (* )',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1201,
        descricao2: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        codResiduo: '120199',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1203,
        descricao2: 'Resíduos de processo de desengorduramento à água e à vapor (exceto 11)',
        codResiduo: '120301(*)',
        descricao3: 'Líquidos de lavagem aquosos',
      },
      {
        capitulo: 12,
        descricao: 'Resíduos da moldagem e do tratamento físico e mecânico de superfície de metais e plástico',
        subcapitulo: 1203,
        descricao2: 'Resíduos de processo de desengorduramento à água e à vapor (exceto 11)',
        codResiduo: '120302(*)',
        descricao3: 'Resíduos de desengorduramento a vapor',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1301,
        descricao2: 'Óleos hidraúlicos usados',
        codResiduo: '130101(*)',
        descricao3: 'Óleos hidraúlicos contendo PCB (1 )',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1301,
        descricao2: 'Óleos hidraúlicos usados',
        codResiduo: '130104(*)',
        descricao3: 'Emulsões coloidais',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1301,
        descricao2: 'Óleos hidraúlicos usados',
        codResiduo: '130105(*)',
        descricao3: 'Emulsões não coloidais',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1301,
        descricao2: 'Óleos hidraúlicos usados',
        codResiduo: '130109(*)',
        descricao3: 'Óleos hidráulicos minerais clorados',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1301,
        descricao2: 'Óleos hidraúlicos usados',
        codResiduo: '130110(*)',
        descricao3: 'Óleos hidráulicos minerais não clorados',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1301,
        descricao2: 'Óleos hidraúlicos usados',
        codResiduo: '130111(*)',
        descricao3: 'Óleos hidráulicos sintéticos',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1301,
        descricao2: 'Óleos hidraúlicos usados',
        codResiduo: '130112(*)',
        descricao3: 'Óleos hidráulicos facilmente biodegradáveis',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1301,
        descricao2: 'Óleos hidraúlicos usados',
        codResiduo: '130113(*)',
        descricao3: 'Outros óleos hidráulicos',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1302,
        descricao2: 'Óleos de motores, transmissões e lubrificação usados ou contaminados',
        codResiduo: '130201(*)',
        descricao3: 'Óleos de motores, transmissões e lubrificação usados ou contaminados',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1302,
        descricao2: 'Óleos de motores, transmissões e lubrificação usados ou contaminados',
        codResiduo: '130299(*)',
        descricao3: 'Outros óleos de motores, transmissão e lubrificação',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1303,
        descricao2: 'Óleos isolantes, de refrigeração e de transmissão de calor usados',
        codResiduo: '130301(*)',
        descricao3: 'Óleos de isolamento térmico, de refrigeração e de transmissão de calor usados, fluidos dielétricos e resíduos contaminados com bifenilas policloradas (PCB )',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1303,
        descricao2: 'Óleos isolantes, de refrigeração e de transmissão de calor usados',
        codResiduo: '130306(*)',
        descricao3: 'Óleos minerais isolantes , de refrigeração e de transmissão de calor clorados, não abrangidos em 13 03 01 (* )',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1303,
        descricao2: 'Óleos isolantes, de refrigeração e de transmissão de calor usados',
        codResiduo: '130307(*)',
        descricao3: 'Óleos minerais isolantes , de refrigeração e de transmissão de calor não clorados',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1303,
        descricao2: 'Óleos isolantes, de refrigeração e de transmissão de calor usados',
        codResiduo: '130308(*)',
        descricao3: 'Óleos sintéticos isolantes, de refrigeração e de transmissão de calor',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1303,
        descricao2: 'Óleos isolantes, de refrigeração e de transmissão de calor usados',
        codResiduo: '130309(*)',
        descricao3: 'Óleos facilmente biodegradáveis isolantes, de refrigeração e de transmissão de calor',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1303,
        descricao2: 'Óleos isolantes, de refrigeração e de transmissão de calor usados',
        codResiduo: '130310(*)',
        descricao3: 'Outros óleos isolantes, de refrigeração e de transmissão de calor',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1304,
        descricao2: 'Óleos bunker usados em navios',
        codResiduo: '130401(*)',
        descricao3: 'Óleos bunker de navios de navegação interior',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1304,
        descricao2: 'Óleos bunker usados em navios',
        codResiduo: '130402(*)',
        descricao3: 'Óleos bunker provenientes das canalizações dos cais',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1304,
        descricao2: 'Óleos bunker usados em navios',
        codResiduo: '130403(*)',
        descricao3: 'Óleos bunker de outros tipos de navios',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1305,
        descricao2: 'Conteúdo de separadores óleo água',
        codResiduo: '130501(*)',
        descricao3: 'Resíduos sólidos provenientes de desarenadores e de separadores óleo água',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1305,
        descricao2: 'Conteúdo de separadores óleo água',
        codResiduo: '130502(*)',
        descricao3: 'Lodo proveniente dos separadores óleo água',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1305,
        descricao2: 'Conteúdo de separadores óleo água',
        codResiduo: '130503(*)',
        descricao3: 'Lodo proveniente do interceptor',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1305,
        descricao2: 'Conteúdo de separadores óleo água',
        codResiduo: '130506(*)',
        descricao3: 'Óleos provenientes dos separadores óleo água',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1305,
        descricao2: 'Conteúdo de separadores óleo água',
        codResiduo: '130507(*)',
        descricao3: 'Água com óleo proveniente dos separadores óleo água',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1305,
        descricao2: 'Conteúdo de separadores óleo água',
        codResiduo: '130508(*)',
        descricao3: 'Misturas de resíduos provenientes de desarenadores e de separadores',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1307,
        descricao2: 'Resíduos de combustíveis líquidos',
        codResiduo: '130701(*)',
        descricao3: 'Fuelóleo e óleo diesel',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1307,
        descricao2: 'Resíduos de combustíveis líquidos',
        codResiduo: '130702(*)',
        descricao3: 'Gasolina',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1307,
        descricao2: 'Resíduos de combustíveis líquidos',
        codResiduo: '130703(*)',
        descricao3: 'Outros combustíveis (incluindo misturas )',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1308,
        descricao2: 'Outros óleos usados não anteriormente especificados',
        codResiduo: '130801(*)',
        descricao3: 'Lodos ou emulsões de dessalinização',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1308,
        descricao2: 'Outros óleos usados não anteriormente especificados',
        codResiduo: '130802(*)',
        descricao3: 'Outras emulsões e misturas',
      },
      {
        capitulo: 13,
        descricao: 'Óleos usados e resíduos de combustíveis líquidos (exceto óleos alimentares e capitulos 05, 12 e 19)',
        subcapitulo: 1308,
        descricao2: 'Outros óleos usados não anteriormente especificados',
        codResiduo: '130899(*)',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 14,
        descricao: 'Resíduos de solventes, fluídos de refrigeração e gases propulsores orgânicos (exceto 07e 08)',
        subcapitulo: 1406,
        descricao2: 'Resíduos de solventes, fluídos de refrigeração e gases propulsores de espumas aerosóis orgânicos',
        codResiduo: '140601(*)',
        descricao3: 'Clorofluorcarbonetos (CFC), HCFC, HFC',
      },
      {
        capitulo: 14,
        descricao: 'Resíduos de solventes, fluídos de refrigeração e gases propulsores orgânicos (exceto 07e 08)',
        subcapitulo: 1406,
        descricao2: 'Resíduos de solventes, fluídos de refrigeração e gases propulsores de espumas aerosóis orgânicos',
        codResiduo: '140602(*)',
        descricao3: 'Outros solventes e misturas de solventes halogenados',
      },
      {
        capitulo: 14,
        descricao: 'Resíduos de solventes, fluídos de refrigeração e gases propulsores orgânicos (exceto 07e 08)',
        subcapitulo: 1406,
        descricao2: 'Resíduos de solventes, fluídos de refrigeração e gases propulsores de espumas aerosóis orgânicos',
        codResiduo: '140603(*)',
        descricao3: 'Outros solventes e misturas de solventes',
      },
      {
        capitulo: 14,
        descricao: 'Resíduos de solventes, fluídos de refrigeração e gases propulsores orgânicos (exceto 07e 08)',
        subcapitulo: 1406,
        descricao2: 'Resíduos de solventes, fluídos de refrigeração e gases propulsores de espumas aerosóis orgânicos',
        codResiduo: '140604(*)',
        descricao3: 'Lodos ou resíduos sólidos contendo solventes halogenados',
      },
      {
        capitulo: 14,
        descricao: 'Resíduos de solventes, fluídos de refrigeração e gases propulsores orgânicos (exceto 07e 08)',
        subcapitulo: 1406,
        descricao2: 'Resíduos de solventes, fluídos de refrigeração e gases propulsores de espumas aerosóis orgânicos',
        codResiduo: '140605(*)',
        descricao3: 'Lodos ou resíduos sólidos contendo outros solventes',
      },
      {
        capitulo: 15,
        descricao: 'Resíduos de embalagens absorventes, panos de limpeza, materiais filtrantes e vestuário de proteção não anteriormente especificados',
        subcapitulo: 1501,
        descricao2: 'Embalagens (incluindo resíduos urbanos e equiparados de embalagens, recolhidos separadamente) (2)',
        codResiduo: '150101',
        descricao3: 'Embalagens de papel e cartão',
      },
      {
        capitulo: 15,
        descricao: 'Resíduos de embalagens absorventes, panos de limpeza, materiais filtrantes e vestuário de proteção não anteriormente especificados',
        subcapitulo: 1501,
        descricao2: 'Embalagens (incluindo resíduos urbanos e equiparados de embalagens, recolhidos separadamente) (2)',
        codResiduo: '150102',
        descricao3: 'Embalagens de plástico',
      },
      {
        capitulo: 15,
        descricao: 'Resíduos de embalagens absorventes, panos de limpeza, materiais filtrantes e vestuário de proteção não anteriormente especificados',
        subcapitulo: 1501,
        descricao2: 'Embalagens (incluindo resíduos urbanos e equiparados de embalagens, recolhidos separadamente) (2)',
        codResiduo: '150103',
        descricao3: 'Embalagens de madeira',
      },
      {
        capitulo: 15,
        descricao: 'Resíduos de embalagens absorventes, panos de limpeza, materiais filtrantes e vestuário de proteção não anteriormente especificados',
        subcapitulo: 1501,
        descricao2: 'Embalagens (incluindo resíduos urbanos e equiparados de embalagens, recolhidos separadamente) (2)',
        codResiduo: '150104',
        descricao3: 'Embalagens de metal',
      },
      {
        capitulo: 15,
        descricao: 'Resíduos de embalagens absorventes, panos de limpeza, materiais filtrantes e vestuário de proteção não anteriormente especificados',
        subcapitulo: 1501,
        descricao2: 'Embalagens (incluindo resíduos urbanos e equiparados de embalagens, recolhidos separadamente) (2)',
        codResiduo: '150105',
        descricao3: 'Embalagens longa-vida',
      },
      {
        capitulo: 15,
        descricao: 'Resíduos de embalagens absorventes, panos de limpeza, materiais filtrantes e vestuário de proteção não anteriormente especificados',
        subcapitulo: 1501,
        descricao2: 'Embalagens (incluindo resíduos urbanos e equiparados de embalagens, recolhidos separadamente) (2)',
        codResiduo: '150106',
        descricao3: 'Mistura de embalagens',
      },
      {
        capitulo: 15,
        descricao: 'Resíduos de embalagens absorventes, panos de limpeza, materiais filtrantes e vestuário de proteção não anteriormente especificados',
        subcapitulo: 1501,
        descricao2: 'Embalagens (incluindo resíduos urbanos e equiparados de embalagens, recolhidos separadamente) (2)',
        codResiduo: '150107',
        descricao3: 'Embalagens de vidro',
      },
      {
        capitulo: 15,
        descricao: 'Resíduos de embalagens absorventes, panos de limpeza, materiais filtrantes e vestuário de proteção não anteriormente especificados',
        subcapitulo: 1501,
        descricao2: 'Embalagens (incluindo resíduos urbanos e equiparados de embalagens, recolhidos separadamente) (2)',
        codResiduo: '150109',
        descricao3: 'Embalagens têxteis',
      },
      {
        capitulo: 15,
        descricao: 'Resíduos de embalagens absorventes, panos de limpeza, materiais filtrantes e vestuário de proteção não anteriormente especificados',
        subcapitulo: 1501,
        descricao2: 'Embalagens (incluindo resíduos urbanos e equiparados de embalagens, recolhidos separadamente) (2)',
        codResiduo: '150110(*)',
        descricao3: 'Embalagens de qualquer um dos tipos acima descritos contendo ou contaminadas por resíduos de substâncias perigosas',
      },
      {
        capitulo: 15,
        descricao: 'Resíduos de embalagens absorventes, panos de limpeza, materiais filtrantes e vestuário de proteção não anteriormente especificados',
        subcapitulo: 1501,
        descricao2: 'Embalagens (incluindo resíduos urbanos e equiparados de embalagens, recolhidos separadamente) (2)',
        codResiduo: '150111(*)',
        descricao3: 'Embalagens de metal, incluindo recipientes vazios sob pressão, com uma matriz porosa sólida perigosa (por exemplo amianto )',
      },
      {
        capitulo: 15,
        descricao: 'Resíduos de embalagens absorventes, panos de limpeza, materiais filtrantes e vestuário de proteção não anteriormente especificados',
        subcapitulo: 1502,
        descricao2: 'Absorventes, materiais filtrantes, panos de limpeza e vestuário de proteção',
        codResiduo: '150202(*)',
        descricao3: 'Absorventes, materiais filtrantes (incluindo filtros de óleo não anteriormente especificados), panos de limpeza e vestuário de proteção, contaminados por substâncias perigosas',
      },
      {
        capitulo: 15,
        descricao: 'Resíduos de embalagens absorventes, panos de limpeza, materiais filtrantes e vestuário de proteção não anteriormente especificados',
        subcapitulo: 1502,
        descricao2: 'Absorventes, materiais filtrantes, panos de limpeza e vestuário de proteção',
        codResiduo: '150203',
        descricao3: 'Absorventes, materiais filtrantes, panos de limpeza e vestuário de proteção não abrangidos em 15 02 02 (* )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160103(*)',
        descricao3: 'Veículos em fim de vida',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160104',
        descricao3: 'Veículos em fim de vida esvaziados de líquidos e outros componentes perigosos',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160106(*)',
        descricao3: 'Resíduo proveniente da trituração de veículos em fim de vida (Ash Schredder Residue )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160107(*)',
        descricao3: 'Filtros de óleo automotivos',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160108(*)',
        descricao3: 'Componentes e peças contendo mercúrio',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160109(*)',
        descricao3: 'Componentes e peças contendo PCB',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160110(*)',
        descricao3: 'Componentes explosivos, por exemplo, almofadas de ar (air bags )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160111(*)',
        descricao3: 'Pastilhas de freio contendo amianto',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160112',
        descricao3: 'Pastilhas de freio não abrangidas em 16 01 11 (* )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160113(*)',
        descricao3: 'Fluidos de freio',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160114(*)',
        descricao3: 'Fluidos anticongelantes contendo substancias perigosas',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160115',
        descricao3: 'Fluídos anticongelantes não abrangidos em 16 01 14 (* )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160116',
        descricao3: 'Recipientes para gás liquefeito sob pressão',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160117',
        descricao3: 'Sucatas metálicas ferrosas',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160118',
        descricao3: 'Sucatas metálicas não ferrosas',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160119',
        descricao3: 'Plástico',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160120',
        descricao3: 'Vidro',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160121(*)',
        descricao3: 'Componentes perigosos não abrangentes em 16 01 07 (*) a 16 01 11 (*), 16 01 13 (*) e 16 01 14 (* )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160122',
        descricao3: 'Componentes não anteriormente especificados',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160123',
        descricao3: 'Pneus inservíveis usados aeronáuticos',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160124',
        descricao3: 'Pneus inservíveis usados de automóveis',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160125',
        descricao3: 'Pneus inservíveis usados de bicicletas',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160126',
        descricao3: 'Pneus inservíveis usados de caminhões e ônibus',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160127',
        descricao3: 'Pneus inservíveis usados de motocicletas',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160128',
        descricao3: 'Pneus inservíveis usados de tratores',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160129',
        descricao3: 'Pneus inservíveis usados outras aplicações',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1601,
        descricao2: 'Veículos em fim de vida de diferentes meios de transporte (incluindo máquinas todo terreno) e resíduos de desmantelamento desmanche de veículos em fim de vida e da manutenção de veículos (exceto 13, 14, 16 06 e 16 08)',
        codResiduo: '160199',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1602,
        descricao2: 'Resíduos de equipamento elétrico e eletrônico',
        codResiduo: '160209(*)',
        descricao3: 'Transformadores, capacitores e demais equipamentos elétricos contendo PCB',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1602,
        descricao2: 'Resíduos de equipamento elétrico e eletrônico',
        codResiduo: '160210(*)',
        descricao3: 'Equipamento fora de uso contendo ou contaminado por PCB não abrangido em 16 02 09 (* )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1602,
        descricao2: 'Resíduos de equipamento elétrico e eletrônico',
        codResiduo: '160211(*)',
        descricao3: 'Equipamento fora de uso contendo clorofluorcarbonetos, HCFC, HFC',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1602,
        descricao2: 'Resíduos de equipamento elétrico e eletrônico',
        codResiduo: '160212(*)',
        descricao3: 'Equipamento fora de uso contendo amianto livre',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1602,
        descricao2: 'Resíduos de equipamento elétrico e eletrônico',
        codResiduo: '160213(*)',
        descricao3: 'Equipamento fora de uso contendo componentes perigosos não abrangidos em 16 02 09 (*) a 16 02 12 (* )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1602,
        descricao2: 'Resíduos de equipamento elétrico e eletrônico',
        codResiduo: '160214',
        descricao3: 'Equipamento fora de uso não abrangidos em 16 02 09 (*) a 16 02 13 (* )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1602,
        descricao2: 'Resíduos de equipamento elétrico e eletrônico',
        codResiduo: '160215(*)',
        descricao3: 'Componentes perigosos retirados de equipamento fora de uso',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1602,
        descricao2: 'Resíduos de equipamento elétrico e eletrônico',
        codResiduo: '160216',
        descricao3: 'Componentes retirados de equipamento fora de uso não abrangidos em 13 02 15 (* )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1603,
        descricao2: 'Produtos fora de especificação e produtos vencidos ou não utilizados',
        codResiduo: '160303(*)',
        descricao3: 'Resíduos inorgânicos contendo substâncias perigosas',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1603,
        descricao2: 'Produtos fora de especificação e produtos vencidos ou não utilizados',
        codResiduo: '160304',
        descricao3: 'Resíduos inorgânicos não abrangidos em 16 03 03 (* )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1603,
        descricao2: 'Produtos fora de especificação e produtos vencidos ou não utilizados',
        codResiduo: '160305(*)',
        descricao3: 'Resíduos orgânicos contendo substâncias perigosas',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1603,
        descricao2: 'Produtos fora de especificação e produtos vencidos ou não utilizados',
        codResiduo: '160306',
        descricao3: 'Resíduos orgânicos não abrangidos em 16 03 05 (* )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1604,
        descricao2: 'Resíduos de explosivos',
        codResiduo: '160401(*)',
        descricao3: 'Resíduos de munições',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1604,
        descricao2: 'Resíduos de explosivos',
        codResiduo: '160402(*)',
        descricao3: 'Resíduos de fogo de artifício',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1604,
        descricao2: 'Resíduos de explosivos',
        codResiduo: '160403(*)',
        descricao3: 'Lodos provenientes do tratamento de efluentes líquidos originados no processo e produção de explosivos',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1604,
        descricao2: 'Resíduos de explosivos',
        codResiduo: '160405(*)',
        descricao3: 'Carvão usado proveniente do tratamento de efluentes líquidos que contenham explosivos',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1604,
        descricao2: 'Resíduos de explosivos',
        codResiduo: '160406(*)',
        descricao3: 'Água rosa vermelha proveniente das operações de TNT',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1604,
        descricao2: 'Resíduos de explosivos',
        codResiduo: '160499(*)',
        descricao3: 'Outros resíduos de explosivos',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1605,
        descricao2: 'Gases em recipientes sob pressão e produtos químicos fora de uso',
        codResiduo: '160504(*)',
        descricao3: 'Gases em recipientes sob pressão (incluindo freons e halons) contendo substâncias perigosas',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1605,
        descricao2: 'Gases em recipientes sob pressão e produtos químicos fora de uso',
        codResiduo: '160505',
        descricao3: 'Gases em recipientes sob pressão não abrangidos em 13 05 04 (* )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1605,
        descricao2: 'Gases em recipientes sob pressão e produtos químicos fora de uso',
        codResiduo: '160506(*)',
        descricao3: 'Produtos químicos de laboratório contendo ou compostos por substâncias perigosas, incluindo misturas de produtos químicos de laboratório',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1605,
        descricao2: 'Gases em recipientes sob pressão e produtos químicos fora de uso',
        codResiduo: '160507(*)',
        descricao3: 'Produtos químicos inorgânicos de laboratório contendo ou compostos por substancias perigosas',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1605,
        descricao2: 'Gases em recipientes sob pressão e produtos químicos fora de uso',
        codResiduo: '160508(*)',
        descricao3: 'Produtos químicos orgânicos fora de uso contendo ou compostos por substâncias perigosas',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1605,
        descricao2: 'Gases em recipientes sob pressão e produtos químicos fora de uso',
        codResiduo: '160509',
        descricao3: 'Produtos químicos fora de uso não abrangidos em 16 05 06 (*), 16 05 07 (*) ou 16 05 08 (* )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1606,
        descricao2: 'Pilhas, baterias e acumuladores elétricos',
        codResiduo: '160601(*)',
        descricao3: 'Bateria e acumuladores elétricos à base de chumbo e seus resíduos, incluindo os plásticos provenientes da carcaça externa da bateria',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1606,
        descricao2: 'Pilhas, baterias e acumuladores elétricos',
        codResiduo: '160602(*)',
        descricao3: 'Bateria e acumuladores elétricos de niquel cádmio e seus resíduos',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1606,
        descricao2: 'Pilhas, baterias e acumuladores elétricos',
        codResiduo: '160603(*)',
        descricao3: 'Pilhas contendo mercúrio',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1606,
        descricao2: 'Pilhas, baterias e acumuladores elétricos',
        codResiduo: '160604',
        descricao3: 'Pilhas alcalinas - exceto 16 06 13 (* )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1606,
        descricao2: 'Pilhas, baterias e acumuladores elétricos',
        codResiduo: '160605',
        descricao3: 'Outras pilhas, baterias e acumuladores',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1606,
        descricao2: 'Pilhas, baterias e acumuladores elétricos',
        codResiduo: '160606(*)',
        descricao3: 'Eletrólitos de pilhas e acumuladores recolhidos separadamente',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1607,
        descricao2: 'Resíduos de limpeza de tanques de transporte, de depósitos, de depósitos de armazenagem e de barris (exceto 5 e 13)',
        codResiduo: '160708(*)',
        descricao3: 'Resíduos contendo hidrocarbonetos',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1607,
        descricao2: 'Resíduos de limpeza de tanques de transporte, de depósitos, de depósitos de armazenagem e de barris (exceto 5 e 13)',
        codResiduo: '160709(*)',
        descricao3: 'Resíduos contendo outras substâncias perigosas',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1607,
        descricao2: 'Resíduos de limpeza de tanques de transporte, de depósitos, de depósitos de armazenagem e de barris (exceto 5 e 13)',
        codResiduo: '160799',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1608,
        descricao2: 'Catalisadores usados',
        codResiduo: '160801',
        descricao3: 'Catalisadores usados contendo ouro, prata, rênio, ródio, paládio, irídio ou platina, exceto 16 08 07 (* )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1608,
        descricao2: 'Catalisadores usados',
        codResiduo: '160802(*)',
        descricao3: 'Catalisadores usados contendo metais de transição (4) ou compostos de metais de transição perigosos',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1608,
        descricao2: 'Catalisadores usados',
        codResiduo: '160803',
        descricao3: 'Catalisadores usados contendo metais de transição ou compostos de metais não especificados de outra forma',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1608,
        descricao2: 'Catalisadores usados',
        codResiduo: '160804',
        descricao3: 'Catalisadores usados de cracking catalítico em leito fluidizado, exceto 16 08 99 (* )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1608,
        descricao2: 'Catalisadores usados',
        codResiduo: '160805(*)',
        descricao3: 'Catalizadores usados contendo ácido fosfórico',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1608,
        descricao2: 'Catalisadores usados',
        codResiduo: '160806(*)',
        descricao3: 'Líquidos usados utilizados como catalisadores',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1608,
        descricao2: 'Catalisadores usados',
        codResiduo: '160807(*)',
        descricao3: 'Catalisadores usados provenientes do reator de hidrocloração utilizado na produção de 1,1,1-tricloroetano',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1608,
        descricao2: 'Catalisadores usados',
        codResiduo: '160808(*)',
        descricao3: 'Catalizador gasto proveniente do hidrotratamento das operações de refino de petróleo, incluindo leitos usados para dessulfurizar as alimentações para outros reatores catalíticos (este código não inclui o meio de suporte inerte )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1608,
        descricao2: 'Catalisadores usados',
        codResiduo: '160899(*)',
        descricao3: 'Outros catalizadores usados contaminados com substâncias perigosas',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1609,
        descricao2: 'Substâncias oxidantes',
        codResiduo: '160901(*)',
        descricao3: 'Permanganatos, por exemplo, permanganato de potássio',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1609,
        descricao2: 'Substâncias oxidantes',
        codResiduo: '160902(*)',
        descricao3: 'Cromatos, por exemplo, cromato de potássio, dicromato de potássio ou de sódio',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1609,
        descricao2: 'Substâncias oxidantes',
        codResiduo: '160903(*)',
        descricao3: 'Peróxidos , por exemplo, água oxigenada',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1609,
        descricao2: 'Substâncias oxidantes',
        codResiduo: '160904(*)',
        descricao3: 'Substâncias oxidantes não anteriormente especificados',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1610,
        descricao2: 'Resíduos líquidos aquosos destinados a serem tratados noutro local',
        codResiduo: '161001(*)',
        descricao3: 'Resíduos líquidos aquosos contendo substâncias perigosas',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1610,
        descricao2: 'Resíduos líquidos aquosos destinados a serem tratados noutro local',
        codResiduo: '161002',
        descricao3: 'Resíduos líquidos aquosos não abrangidos em 16 10 01 (* )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1610,
        descricao2: 'Resíduos líquidos aquosos destinados a serem tratados noutro local',
        codResiduo: '161003(*)',
        descricao3: 'Concentrados aquosos contendo substâncias perigosas',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1610,
        descricao2: 'Resíduos líquidos aquosos destinados a serem tratados noutro local',
        codResiduo: '161004',
        descricao3: 'Concentrados aquosos não abrangidos em 16 10 03 (* )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1611,
        descricao2: 'Resíduos de revestimentos de fornos e refratários',
        codResiduo: '161101(*)',
        descricao3: 'Revestimentos de fornos e refratários à base de carbono provenientes de processos metalúrgicos contendo substâncias perigosas',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1611,
        descricao2: 'Resíduos de revestimentos de fornos e refratários',
        codResiduo: '161102',
        descricao3: 'Revestimentos de fornos e refratários à base de carbono não abrangidos em 16 11 01 (* )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1611,
        descricao2: 'Resíduos de revestimentos de fornos e refratários',
        codResiduo: '161103(*)',
        descricao3: 'Outros revestimentos de fornos e refratários provenientes de processos metalúrgicos contendo substâncias perigosas',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1611,
        descricao2: 'Resíduos de revestimentos de fornos e refratários',
        codResiduo: '161104',
        descricao3: 'Outros revestimentos de fornos e refratários não abrangidos em 16 11 03 (* )',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1611,
        descricao2: 'Resíduos de revestimentos de fornos e refratários',
        codResiduo: '161105(*)',
        descricao3: 'Revestimentos de fornos e refratários provenientes de processos não metalúrgicos contendo substâncias perigosas',
      },
      {
        capitulo: 16,
        descricao: 'Resíduos não especificados em outros capitulos desta lista',
        subcapitulo: 1611,
        descricao2: 'Resíduos de revestimentos de fornos e refratários',
        codResiduo: '161106',
        descricao3: 'Revestimentos de fornos e refratários provenientes de processos não metalúrgicos não abrangidos em 16 11 05 (* )',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1701,
        descricao2: 'Cimento, tijolos, ladrilhos, telhas e materiais cerâmicos',
        codResiduo: '170101',
        descricao3: 'Resíduos de cimento',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1701,
        descricao2: 'Cimento, tijolos, ladrilhos, telhas e materiais cerâmicos',
        codResiduo: '170102',
        descricao3: 'Tijolos',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1701,
        descricao2: 'Cimento, tijolos, ladrilhos, telhas e materiais cerâmicos',
        codResiduo: '170103',
        descricao3: 'Ladrilhos, telhas e materiais cerâmicos',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1701,
        descricao2: 'Cimento, tijolos, ladrilhos, telhas e materiais cerâmicos',
        codResiduo: '170106(*)',
        descricao3: 'Misturas ou frações separadas de cimento, tijolos, ladrilhos, telhas e materiais cerâmicos contendo substancias perigosas',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1701,
        descricao2: 'Cimento, tijolos, ladrilhos, telhas e materiais cerâmicos',
        codResiduo: '170107',
        descricao3: 'Misturas de cimento, tijolos, ladrilhos, telhas e materiais cerâmicos não abrangidos em 17 01 06 (* )',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1702,
        descricao2: 'Madeiras, vidro e plástico',
        codResiduo: '170201',
        descricao3: 'Madeira',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1702,
        descricao2: 'Madeiras, vidro e plástico',
        codResiduo: '170202',
        descricao3: 'Vidro',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1702,
        descricao2: 'Madeiras, vidro e plástico',
        codResiduo: '170203',
        descricao3: 'Plástico',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1702,
        descricao2: 'Madeiras, vidro e plástico',
        codResiduo: '170204(*)',
        descricao3: 'Vidro, plástico e madeira, misturados ou não, contendo ou contaminados com substâncias perigosas',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1703,
        descricao2: 'Misturas betuminosas, asfalto e produtos de alcatrão',
        codResiduo: '170301(*)',
        descricao3: 'Misturas betuminosas contendo alcatrão',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1703,
        descricao2: 'Misturas betuminosas, asfalto e produtos de alcatrão',
        codResiduo: '170302',
        descricao3: 'Misturas betuminosas não abrangidas em 17 03 01 (* )',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1703,
        descricao2: 'Misturas betuminosas, asfalto e produtos de alcatrão',
        codResiduo: '170303(*)',
        descricao3: 'Asfalto e produtos de alcatrão',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1704,
        descricao2: 'Sucatas metálicas (incluindo ligas)',
        codResiduo: '170401',
        descricao3: 'Cobre, bronze e latão',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1704,
        descricao2: 'Sucatas metálicas (incluindo ligas)',
        codResiduo: '170402',
        descricao3: 'Alumínio',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1704,
        descricao2: 'Sucatas metálicas (incluindo ligas)',
        codResiduo: '170403',
        descricao3: 'Chumbo',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1704,
        descricao2: 'Sucatas metálicas (incluindo ligas)',
        codResiduo: '170404',
        descricao3: 'Zinco',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1704,
        descricao2: 'Sucatas metálicas (incluindo ligas)',
        codResiduo: '170405',
        descricao3: 'Ferro e aço',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1704,
        descricao2: 'Sucatas metálicas (incluindo ligas)',
        codResiduo: '170406',
        descricao3: 'Estanho',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1704,
        descricao2: 'Sucatas metálicas (incluindo ligas)',
        codResiduo: '170407',
        descricao3: 'Mistura de sucatas',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1704,
        descricao2: 'Sucatas metálicas (incluindo ligas)',
        codResiduo: '170409(*)',
        descricao3: 'Resíduos metálicos contaminados com substâncias perigosas',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1704,
        descricao2: 'Sucatas metálicas (incluindo ligas)',
        codResiduo: '170410(*)',
        descricao3: 'Cabos contendo hidrocarbonetos, alcatrão ou outras substâncias perigosas',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1704,
        descricao2: 'Sucatas metálicas (incluindo ligas)',
        codResiduo: '170411',
        descricao3: 'Cabos não abrangidos em 17 04 10 (* )',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1704,
        descricao2: 'Sucatas metálicas (incluindo ligas)',
        codResiduo: '170412',
        descricao3: 'Magnésio',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1704,
        descricao2: 'Sucatas metálicas (incluindo ligas)',
        codResiduo: '170413',
        descricao3: 'Níquel',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1705,
        descricao2: 'Solos (incluindo solos escavados de locais contaminados), rochas e lodos de dragagem',
        codResiduo: '170502(*)',
        descricao3: 'Solos e rochas contendo contaminados com bifenilas policloradas (PCB )',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1705,
        descricao2: 'Solos (incluindo solos escavados de locais contaminados), rochas e lodos de dragagem',
        codResiduo: '170503(*)',
        descricao3: 'Solos e rochas contendo outras substâncias perigosas',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1705,
        descricao2: 'Solos (incluindo solos escavados de locais contaminados), rochas e lodos de dragagem',
        codResiduo: '170504',
        descricao3: 'Solos e rochas não abrangidos em 17 05 03 (* )',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1705,
        descricao2: 'Solos (incluindo solos escavados de locais contaminados), rochas e lodos de dragagem',
        codResiduo: '170505(*)',
        descricao3: 'Lodos de dragagem contendo substâncias perigosas',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1705,
        descricao2: 'Solos (incluindo solos escavados de locais contaminados), rochas e lodos de dragagem',
        codResiduo: '170506',
        descricao3: 'Lodos de dragagem não abrangidos em 17 05 05 (* )',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1705,
        descricao2: 'Solos (incluindo solos escavados de locais contaminados), rochas e lodos de dragagem',
        codResiduo: '170507(*)',
        descricao3: 'Britas de linhas ferroviárias contendo substâncias perigosas',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1705,
        descricao2: 'Solos (incluindo solos escavados de locais contaminados), rochas e lodos de dragagem',
        codResiduo: '170508',
        descricao3: 'Britas de linhas de ferroviárias não abrangidas em 17 05 07 (* )',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1705,
        descricao2: 'Solos (incluindo solos escavados de locais contaminados), rochas e lodos de dragagem',
        codResiduo: '170509(*)',
        descricao3: 'Resíduos resultantes da incineração ou tratamento de solos contaminados por substâncias orgânicas perigosas',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1706,
        descricao2: 'Materiais de isolamento e materiais de construção contendo amianto',
        codResiduo: '170601(*)',
        descricao3: 'Materiais de isolamento contendo amianto',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1706,
        descricao2: 'Materiais de isolamento e materiais de construção contendo amianto',
        codResiduo: '170603(*)',
        descricao3: 'Outros materiais de isolamento contendo ou constituídos por substâncias perigosas',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1706,
        descricao2: 'Materiais de isolamento e materiais de construção contendo amianto',
        codResiduo: '170604',
        descricao3: 'Materiais de isolamento não abrangidos em 17 06 01 (*) e 17 06 03 (* )',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1706,
        descricao2: 'Materiais de isolamento e materiais de construção contendo amianto',
        codResiduo: '170605(*)',
        descricao3: 'Materiais de construção contendo amianto (por exemplo, telhas, tubos, etc. )',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1708,
        descricao2: 'Materiais de construção à base de gesso',
        codResiduo: '170801(*)',
        descricao3: 'Materiais de construção à base de gesso contaminados com substâncias perigosas',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1708,
        descricao2: 'Materiais de construção à base de gesso',
        codResiduo: '170802',
        descricao3: 'Materiais de construção à base de gesso não abrangidos em 17 08 01 (* )',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1709,
        descricao2: 'Outros resíduos de construção e demolição',
        codResiduo: '170901(*)',
        descricao3: 'Resíduos de construção e demolição contendo mercúrio',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1709,
        descricao2: 'Outros resíduos de construção e demolição',
        codResiduo: '170902(*)',
        descricao3: 'Resíduos de construção e demolição contendo PCB (por exemplo vedantes com PCB, revestimentos de piso à base de resinas com PCB, condensadores de uso doméstico com PCB )',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1709,
        descricao2: 'Outros resíduos de construção e demolição',
        codResiduo: '170903(*)',
        descricao3: 'Outros resíduos de construção e demolição (incluindo misturas de resíduos) contendo substâncias perigosas',
      },
      {
        capitulo: 17,
        descricao: 'Resíduos de construção e demolição (incluindo solos escavados de locais contaminados)',
        subcapitulo: 1709,
        descricao2: 'Outros resíduos de construção e demolição',
        codResiduo: '170904',
        descricao3: 'Mistura de resíduos de construção e demolição não abrangidos em 17 09 01 (*), 17 09 02 (*) e 17 09 03 (* )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1801,
        descricao2: 'Resíduos com a possível presença de agentes biológicos que, por suas características de maior virulência ou concentração, podem apresentar risco de infecção',
        codResiduo: '180101(*)',
        descricao3: 'Culturas e estoques de micro-organismos; resíduos de fabricação de produtos biológicos, exceto os hemoderivados; descarte de vacinas de micro-organismos vivos ou atenuados; meios de cultura e instrumentos utilizados para transferência, inoculação ou mistura de culturas; resíduos de laboratório de manipulação genética (A1 conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1801,
        descricao2: 'Resíduos com a possível presença de agentes biológicos que, por suas características de maior virulência ou concentração, podem apresentar risco de infecção',
        codResiduo: '180102(*)',
        descricao3: 'Resíduos resultantes da atenção da saúde de indivíduos ou animais, com suspeita ou certeza de contaminação biológica por agentes com elevado risco individual e elevado risco para a comunidade, micro-organismos com relevância epidemiológica e risco de disseminação ou causador de doença emergente que se torne epidemiologicamente importante ou cujo mecanismo de transmissão seja desconhecido (A1 conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1801,
        descricao2: 'Resíduos com a possível presença de agentes biológicos que, por suas características de maior virulência ou concentração, podem apresentar risco de infecção',
        codResiduo: '180103(*)',
        descricao3: 'Bolsa transfusionais contendo sangue ou hemocomponentes rejeitadas por contaminação ou por má conservação ou com prazo de validade vencido, e aquelas oriundas de coleta incompleta (A1 conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1801,
        descricao2: 'Resíduos com a possível presença de agentes biológicos que, por suas características de maior virulência ou concentração, podem apresentar risco de infecção',
        codResiduo: '180104(*)',
        descricao3: 'Sobras de amostras de laboratório contendo sangue ou líquidos corpóreos, recipientes e materiais resultantes do processo de assistência à saúde, contendo sangue ou líquidos corpóreos na forma livre (A1 conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1801,
        descricao2: 'Resíduos com a possível presença de agentes biológicos que, por suas características de maior virulência ou concentração, podem apresentar risco de infecção',
        codResiduo: '180105(*)',
        descricao3: 'Carcaças, peças anatômicas, vísceras e outros resíduos provenientes de animais submetidos a processos de experimentação com inoculação de micro-organismos, bem como suas forrações, e os cadáveres de animais suspeitos de serem portadores de micro-organismos de relevância epidemiológica e com risco de disseminação, que foram submetidos ou não a estudo anatomopatológico ou confirmação diagnóstica (A2 conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1801,
        descricao2: 'Resíduos com a possível presença de agentes biológicos que, por suas características de maior virulência ou concentração, podem apresentar risco de infecção',
        codResiduo: '180106(*)',
        descricao3: 'Peças anatômicas (membros) do ser humano; produto de fecundação sem sinais vitais, com peso menor que 500 gramas ou estatura menor que 25 cm ou idade gestacional menor que 20 semanas , que não tenham valor científico ou legal e não tenha havido requisição pelo paciente ou familiares (A3 conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1801,
        descricao2: 'Resíduos com a possível presença de agentes biológicos que, por suas características de maior virulência ou concentração, podem apresentar risco de infecção',
        codResiduo: '180107(*)',
        descricao3: 'Kits de linhas arteriais, endovenosas e dialisadores, quando descartados (A4 conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1801,
        descricao2: 'Resíduos com a possível presença de agentes biológicos que, por suas características de maior virulência ou concentração, podem apresentar risco de infecção',
        codResiduo: '180108(*)',
        descricao3: 'Filtros de ar e gases de área contaminada; membrana filtrante de equipamento médico hospitalar e de pesquisa, entre outros similares (A4 conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1801,
        descricao2: 'Resíduos com a possível presença de agentes biológicos que, por suas características de maior virulência ou concentração, podem apresentar risco de infecção',
        codResiduo: '180109(*)',
        descricao3: 'Sobras de amostras de laboratório e seus recipientes contendo fezes, urina e secreções, provenientes de pacientes que não contenham e nem sejam suspeitos de conter agentes com elevado risco individual e elevado risco à comunidade, e nem apresentem relevância epidemiológica e risco de disseminação, ou micro-organismo causador de doença emergente que se torne epidemiologicamente importante ou cujo mecanismo de transmissão seja desconhecido ou com suspeita de contaminação com príons (A4 conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1801,
        descricao2: 'Resíduos com a possível presença de agentes biológicos que, por suas características de maior virulência ou concentração, podem apresentar risco de infecção',
        codResiduo: '180110(*)',
        descricao3: 'Resíduos de tecido adiposo proveniente de lipoaspiração, lipoescultura ou outro procedimento de cirurgia plástica que gere este tipo de resíduo (A4 conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1801,
        descricao2: 'Resíduos com a possível presença de agentes biológicos que, por suas características de maior virulência ou concentração, podem apresentar risco de infecção',
        codResiduo: '180111(*)',
        descricao3: 'Recipientes e materiais resultantes do processo de assistência à saúde, que não contenha sangue ou líquidos corpóreos na forma livre (A4 conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1801,
        descricao2: 'Resíduos com a possível presença de agentes biológicos que, por suas características de maior virulência ou concentração, podem apresentar risco de infecção',
        codResiduo: '180112(*)',
        descricao3: 'Peças anatômicas (órgãos e tecidos) e outros resíduos provenientes de procedimentos cirúrgicos ou de estudo anátomo-patológicos ou de confirmação diagnóstica (A4 conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1801,
        descricao2: 'Resíduos com a possível presença de agentes biológicos que, por suas características de maior virulência ou concentração, podem apresentar risco de infecção',
        codResiduo: '180113(*)',
        descricao3: 'Carcaças, peças anatômicas, vísceras e outros resíduos provenientes de animais não submetidos a processos de experimentação com inoculação de micro-organismos, bem como suas forrações (A4 conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1801,
        descricao2: 'Resíduos com a possível presença de agentes biológicos que, por suas características de maior virulência ou concentração, podem apresentar risco de infecção',
        codResiduo: '180114(*)',
        descricao3: 'Bolsa transfusionais vazias ou com volume residual pós-transfusão (A4 conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1801,
        descricao2: 'Resíduos com a possível presença de agentes biológicos que, por suas características de maior virulência ou concentração, podem apresentar risco de infecção',
        codResiduo: '180115(*)',
        descricao3: 'Órgãos, tecidos, fluidos orgânicos, materiais perfurocortantes ou escarificantes e demais materiais resultantes da atenção à saúde de indivíduos ou animais, com suspeita ou certeza de contaminação com príons (A5 conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1802,
        descricao2: 'Resíduos contendo substâncias químicas que podem apresentar riscos à saúde pública ou ao meio ambiente, dependendo de suas características de inflamabilidade, corrosividade, reatividade e toxicidade',
        codResiduo: '180201(*)',
        descricao3: 'Produtos hormonais e produtos antimicrobianos; citostáticos; antineoplásicos - imunossupressores; digitálicos; imunomoduladores; anti-retrovirais, quando descartados por serviço de saúde, farmácias, drogarias e distribuidores de medicamentos ou apreendidos e os resíduos e insumos farmacêuticos dos medicamentos sujeitos a controle especial (B conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1802,
        descricao2: 'Resíduos contendo substâncias químicas que podem apresentar riscos à saúde pública ou ao meio ambiente, dependendo de suas características de inflamabilidade, corrosividade, reatividade e toxicidade',
        codResiduo: '180202(*)',
        descricao3: 'Resíduos de saneantes, desinfetantes, desinfestantes; resíduos contendo metais pesados; reagentes para laboratório, inclusive os recipientes contaminados por estes (B conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1802,
        descricao2: 'Resíduos contendo substâncias químicas que podem apresentar riscos à saúde pública ou ao meio ambiente, dependendo de suas características de inflamabilidade, corrosividade, reatividade e toxicidade',
        codResiduo: '180203(*)',
        descricao3: 'Efluentes de processadores de imagem (reveladores e fixadores) (B conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1802,
        descricao2: 'Resíduos contendo substâncias químicas que podem apresentar riscos à saúde pública ou ao meio ambiente, dependendo de suas características de inflamabilidade, corrosividade, reatividade e toxicidade',
        codResiduo: '180204(*)',
        descricao3: 'Efluentes dos equipamentos automatizados utilizados em análises clínicas (B conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1802,
        descricao2: 'Resíduos contendo substâncias químicas que podem apresentar riscos à saúde pública ou ao meio ambiente, dependendo de suas características de inflamabilidade, corrosividade, reatividade e toxicidade',
        codResiduo: '180205(*)',
        descricao3: 'Outros produtos considerados perigosos (B conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1803,
        descricao2: 'Materiais resultantes de atividades humanas que contenham radionuclídeos',
        codResiduo: '180301(*)',
        descricao3: 'Materiais resultantes de laboratórios de pesquisa e ensino na área de saúde, laboratórios de análises clínicas e serviços de medicina nuclear e radioterapia que contenha radionuclídeos em quantidade superior aos limites de eliminação - CNEN-6.05 (C conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1804,
        descricao2: 'Materiais perfurocortantes ou escarificantes',
        codResiduo: '180401(*)',
        descricao3: 'Materiais perfurocortantes ou escarificantes, tais como: lâminas de barbear, agulhas, escalpes, ampolas de vidro, brocas, limas endodônticas, pontas diamantadas, lâminas de bisturi, lancetas, tubos capilares; micropipetas; lâminas e lamínulas; espátulas; e todos os utensílios de vidro quebrados no laboratório (pipetas, tubos de coleta sanguínea e placas de Petri) e outras similares (E conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1901,
        descricao2: 'Resíduos da incineração ou pirólise de resíduos',
        codResiduo: '190102',
        descricao3: 'Materiais ferrosos removidos das cinzas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1901,
        descricao2: 'Resíduos da incineração ou pirólise de resíduos',
        codResiduo: '190105(*)',
        descricao3: 'Tortas de filtro provenientes do tratamento de gases',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1901,
        descricao2: 'Resíduos da incineração ou pirólise de resíduos',
        codResiduo: '190106(*)',
        descricao3: 'Resíduos líquidos aquosos provenientes do tratamento de gases e outros resíduos líquidos aquosos',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1901,
        descricao2: 'Resíduos da incineração ou pirólise de resíduos',
        codResiduo: '190107(*)',
        descricao3: 'Resíduos sólidos provenientes do tratamento de gases',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1901,
        descricao2: 'Resíduos da incineração ou pirólise de resíduos',
        codResiduo: '190110(*)',
        descricao3: 'Carvão ativado usado proveniente do tratamento de gases de combustão',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1901,
        descricao2: 'Resíduos da incineração ou pirólise de resíduos',
        codResiduo: '190111(*)',
        descricao3: 'Cinzas e escórias contendo substâncias perigosas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1901,
        descricao2: 'Resíduos da incineração ou pirólise de resíduos',
        codResiduo: '190112',
        descricao3: 'Cinzas e escórias não abrangidas em 19 01 11 (* )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1901,
        descricao2: 'Resíduos da incineração ou pirólise de resíduos',
        codResiduo: '190113(*)',
        descricao3: 'Cinzas voláteis contendo substâncias perigosas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1901,
        descricao2: 'Resíduos da incineração ou pirólise de resíduos',
        codResiduo: '190114',
        descricao3: 'Cinzas voláteis não abrangidas em 19 01 13 (* )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1901,
        descricao2: 'Resíduos da incineração ou pirólise de resíduos',
        codResiduo: '190115(*)',
        descricao3: 'Cinzas de caldeiras contendo substâncias perigosas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1901,
        descricao2: 'Resíduos da incineração ou pirólise de resíduos',
        codResiduo: '190116',
        descricao3: 'Cinzas de caldeiras não abrangidas em 19 01 15 (* )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1901,
        descricao2: 'Resíduos da incineração ou pirólise de resíduos',
        codResiduo: '190117(*)',
        descricao3: 'Resíduos de pirólise contendo substâncias perigosas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1901,
        descricao2: 'Resíduos da incineração ou pirólise de resíduos',
        codResiduo: '190118',
        descricao3: 'Resíduos de pirólise não abrangidos em 19 01 17 (* )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1901,
        descricao2: 'Resíduos da incineração ou pirólise de resíduos',
        codResiduo: '190119',
        descricao3: 'Areias de leitos fluidizados',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1901,
        descricao2: 'Resíduos da incineração ou pirólise de resíduos',
        codResiduo: '190199',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1902,
        descricao2: 'Resíduos de tratamentos fisico-químicos de resíduos (por exemplo, descromagem, descianetização, neutralização)',
        codResiduo: '190203',
        descricao3: 'Misturas de resíduos contendo apenas resíduos não perigosos',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1902,
        descricao2: 'Resíduos de tratamentos fisico-químicos de resíduos (por exemplo, descromagem, descianetização, neutralização)',
        codResiduo: '190204(*)',
        descricao3: 'Misturas de resíduos contendo, pelo menos, um resíduos perigoso',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1902,
        descricao2: 'Resíduos de tratamentos fisico-químicos de resíduos (por exemplo, descromagem, descianetização, neutralização)',
        codResiduo: '190205(*)',
        descricao3: 'Lodos de tratamento físico-químico contendo substâncias perigosas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1902,
        descricao2: 'Resíduos de tratamentos fisico-químicos de resíduos (por exemplo, descromagem, descianetização, neutralização)',
        codResiduo: '190206',
        descricao3: 'Lodos de tratamento físico-químico não abrangidos em 19 02 05 (* )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1902,
        descricao2: 'Resíduos de tratamentos fisico-químicos de resíduos (por exemplo, descromagem, descianetização, neutralização)',
        codResiduo: '190207(*)',
        descricao3: 'Óleos e concentrados da separação',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1902,
        descricao2: 'Resíduos de tratamentos fisico-químicos de resíduos (por exemplo, descromagem, descianetização, neutralização)',
        codResiduo: '190208(*)',
        descricao3: 'Resíduos combustíveis líquidos contendo substâncias perigosas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1902,
        descricao2: 'Resíduos de tratamentos fisico-químicos de resíduos (por exemplo, descromagem, descianetização, neutralização)',
        codResiduo: '190209(*)',
        descricao3: 'Resíduos combustíveis sólidos contendo substâncias perigosas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1902,
        descricao2: 'Resíduos de tratamentos fisico-químicos de resíduos (por exemplo, descromagem, descianetização, neutralização)',
        codResiduo: '190210',
        descricao3: 'Resíduos combustíveis não abrangidos em 19 02 08 (*) e 19 02 09 (* )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1902,
        descricao2: 'Resíduos de tratamentos fisico-químicos de resíduos (por exemplo, descromagem, descianetização, neutralização)',
        codResiduo: '190211(*)',
        descricao3: 'Outros resíduos contendo substancias perigosas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1902,
        descricao2: 'Resíduos de tratamentos fisico-químicos de resíduos (por exemplo, descromagem, descianetização, neutralização)',
        codResiduo: '190299',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1903,
        descricao2: 'Resíduos solidificantes estabilizados',
        codResiduo: '190304(*)',
        descricao3: 'Resíduos assinalados como perigosos, parcialmente estabilizados',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1903,
        descricao2: 'Resíduos solidificantes estabilizados',
        codResiduo: '190305',
        descricao3: 'Resíduos estabilizados não abrangidos em 19 03 04 (* )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1903,
        descricao2: 'Resíduos solidificantes estabilizados',
        codResiduo: '190306(*)',
        descricao3: 'Resíduos assinalados como perigosos, solidificados',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1903,
        descricao2: 'Resíduos solidificantes estabilizados',
        codResiduo: '190307',
        descricao3: 'Resíduos solidificados não abrangidos em 19 03 06 (* )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1904,
        descricao2: 'Resíduos vitrificados e resíduos da vitrificação',
        codResiduo: '190401',
        descricao3: 'Resíduos vitrificados',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1904,
        descricao2: 'Resíduos vitrificados e resíduos da vitrificação',
        codResiduo: '190402(*)',
        descricao3: 'Cinzas voláteis e outros resíduos do tratamento de gases de combustão',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1904,
        descricao2: 'Resíduos vitrificados e resíduos da vitrificação',
        codResiduo: '190403(*)',
        descricao3: 'Fase sólida não vitrificada',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1904,
        descricao2: 'Resíduos vitrificados e resíduos da vitrificação',
        codResiduo: '190404',
        descricao3: 'Resíduos líquidos aquosos da tempera de resíduos vitrificados',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1905,
        descricao2: 'Resíduos do tratamento aeróbico de resíduos sólidos',
        codResiduo: '190501',
        descricao3: 'Fração não compostada de resíduos urbanos e equiparados',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1905,
        descricao2: 'Resíduos do tratamento aeróbico de resíduos sólidos',
        codResiduo: '190502',
        descricao3: 'Fração não compostada de resíduos animais e vegetais',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1905,
        descricao2: 'Resíduos do tratamento aeróbico de resíduos sólidos',
        codResiduo: '190503',
        descricao3: 'Composto fora de especificação',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1905,
        descricao2: 'Resíduos do tratamento aeróbico de resíduos sólidos',
        codResiduo: '190599',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1906,
        descricao2: 'Resíduos do tratamento anaeróbico de resíduos',
        codResiduo: '190603',
        descricao3: 'Lodo do tratamento anaeróbico de resíduos urbanos e equiparados',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1906,
        descricao2: 'Resíduos do tratamento anaeróbico de resíduos',
        codResiduo: '190604',
        descricao3: 'Lamas e lodos de digestores de tratamento anaeróbico de resíduos urbanos e equiparados',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1906,
        descricao2: 'Resíduos do tratamento anaeróbico de resíduos',
        codResiduo: '190605',
        descricao3: 'Lodo de tratamento anaeróbico de resíduos animais e vegetais',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1906,
        descricao2: 'Resíduos do tratamento anaeróbico de resíduos',
        codResiduo: '190606',
        descricao3: 'Lama e lodos de digestores de tratamento anaeróbico de resíduos animais e vegetais',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1906,
        descricao2: 'Resíduos do tratamento anaeróbico de resíduos',
        codResiduo: '190699',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1907,
        descricao2: 'Lixiviados de aterros',
        codResiduo: '190702(*)',
        descricao3: 'Lixiviados ou líquidos percolados de aterros contendo substancias perigosas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1907,
        descricao2: 'Lixiviados de aterros',
        codResiduo: '190703',
        descricao3: 'Lixiviados ou líquidos percolados de aterros não abrangidos em 19 07 02 (* )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1908,
        descricao2: 'Resíduos de estações de tratamento de efluentes (ETE) não anteriormente especificados',
        codResiduo: '190801',
        descricao3: 'Resíduos retirados da fase de gradeamento',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1908,
        descricao2: 'Resíduos de estações de tratamento de efluentes (ETE) não anteriormente especificados',
        codResiduo: '190802',
        descricao3: 'Resíduos de desarenamento',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1908,
        descricao2: 'Resíduos de estações de tratamento de efluentes (ETE) não anteriormente especificados',
        codResiduo: '190805',
        descricao3: 'Lodos de tratamento de efluentes urbanos',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1908,
        descricao2: 'Resíduos de estações de tratamento de efluentes (ETE) não anteriormente especificados',
        codResiduo: '190806(*)',
        descricao3: 'Resinas de troca iônica, saturadas ou usadas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1908,
        descricao2: 'Resíduos de estações de tratamento de efluentes (ETE) não anteriormente especificados',
        codResiduo: '190807(*)',
        descricao3: 'Soluções e lodos da regeneração de colunas de permuta iônica',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1908,
        descricao2: 'Resíduos de estações de tratamento de efluentes (ETE) não anteriormente especificados',
        codResiduo: '190808(*)',
        descricao3: 'Resíduos de sistema de membranas contendo metais pesados',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1908,
        descricao2: 'Resíduos de estações de tratamento de efluentes (ETE) não anteriormente especificados',
        codResiduo: '190809',
        descricao3: 'Misturas de gorduras e óleos, da separação água óleo, contendo apenas óleos e gorduras alimentares',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1908,
        descricao2: 'Resíduos de estações de tratamento de efluentes (ETE) não anteriormente especificados',
        codResiduo: '190810(*)',
        descricao3: 'Misturas de gorduras e óleos, da separação água óleo, não abrangidas em 19 08 09',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1908,
        descricao2: 'Resíduos de estações de tratamento de efluentes (ETE) não anteriormente especificados',
        codResiduo: '190811(*)',
        descricao3: 'Lodos de tratamento biológico de efluentes industriais contendo substancias perigosas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1908,
        descricao2: 'Resíduos de estações de tratamento de efluentes (ETE) não anteriormente especificados',
        codResiduo: '190812',
        descricao3: 'Lodos de tratamento biológico de efluentes industriais não abrangidos em 19 08 11 (* )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1908,
        descricao2: 'Resíduos de estações de tratamento de efluentes (ETE) não anteriormente especificados',
        codResiduo: '190813(*)',
        descricao3: 'Lodos de outros tratamentos de efluentes industriais contendo substancias perigosas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1908,
        descricao2: 'Resíduos de estações de tratamento de efluentes (ETE) não anteriormente especificados',
        codResiduo: '190814',
        descricao3: 'Lodos de outros tratamentos de efluentes industriais não abrangidos em 19 08 13 (* )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1908,
        descricao2: 'Resíduos de estações de tratamento de efluentes (ETE) não anteriormente especificados',
        codResiduo: '190899',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1909,
        descricao2: 'Resíduos de estações de tratamento de água (ETA) para consumo humano ou de água de uso industrial',
        codResiduo: '190901',
        descricao3: 'Resíduos retirados da fase de gradeamento',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1909,
        descricao2: 'Resíduos de estações de tratamento de água (ETA) para consumo humano ou de água de uso industrial',
        codResiduo: '190902',
        descricao3: 'Lodos de clarificação da água',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1909,
        descricao2: 'Resíduos de estações de tratamento de água (ETA) para consumo humano ou de água de uso industrial',
        codResiduo: '190903',
        descricao3: 'Lodos de descarbonatação',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1909,
        descricao2: 'Resíduos de estações de tratamento de água (ETA) para consumo humano ou de água de uso industrial',
        codResiduo: '190904',
        descricao3: 'Carvão ativado usado',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1909,
        descricao2: 'Resíduos de estações de tratamento de água (ETA) para consumo humano ou de água de uso industrial',
        codResiduo: '190905',
        descricao3: 'Resinas de troca iônica, saturadas ou usadas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1909,
        descricao2: 'Resíduos de estações de tratamento de água (ETA) para consumo humano ou de água de uso industrial',
        codResiduo: '190906',
        descricao3: 'Soluções e lodos da regeneração de colunas de troca iônica',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1909,
        descricao2: 'Resíduos de estações de tratamento de água (ETA) para consumo humano ou de água de uso industrial',
        codResiduo: '190999',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1910,
        descricao2: 'Resíduos da trituração de resíduos contendo metais',
        codResiduo: '191001',
        descricao3: 'Resíduos de ferro ou aço',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1910,
        descricao2: 'Resíduos da trituração de resíduos contendo metais',
        codResiduo: '191002',
        descricao3: 'Resíduos não ferrosos',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1910,
        descricao2: 'Resíduos da trituração de resíduos contendo metais',
        codResiduo: '191003(*)',
        descricao3: 'Frações leves e poeiras contendo substancias perigosas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1910,
        descricao2: 'Resíduos da trituração de resíduos contendo metais',
        codResiduo: '191004',
        descricao3: 'Frações leves e poeiras não abrangidas em 19 10 03 (* )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1910,
        descricao2: 'Resíduos da trituração de resíduos contendo metais',
        codResiduo: '191005(*)',
        descricao3: 'Outras frações contendo substancias perigosas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1910,
        descricao2: 'Resíduos da trituração de resíduos contendo metais',
        codResiduo: '191006',
        descricao3: 'Outras frações não abrangidas em 19 10 05 (* )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1911,
        descricao2: 'Resíduos de regeneração de óleos',
        codResiduo: '191101(*)',
        descricao3: 'Argilas de filtração usadas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1911,
        descricao2: 'Resíduos de regeneração de óleos',
        codResiduo: '191102(*)',
        descricao3: 'Borras ácidas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1911,
        descricao2: 'Resíduos de regeneração de óleos',
        codResiduo: '191103(*)',
        descricao3: 'Resíduos líquidos aquosos',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1911,
        descricao2: 'Resíduos de regeneração de óleos',
        codResiduo: '191104(*)',
        descricao3: 'Resíduos de limpeza de combustíveis com bases',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1911,
        descricao2: 'Resíduos de regeneração de óleos',
        codResiduo: '191105(*)',
        descricao3: 'Lodos do tratamento local de efluentes contendo substancias perigosas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1911,
        descricao2: 'Resíduos de regeneração de óleos',
        codResiduo: '191106',
        descricao3: 'Lodos de tratamento local de efluentes não abrangidos em 19 11 05 (* )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1911,
        descricao2: 'Resíduos de regeneração de óleos',
        codResiduo: '191107(*)',
        descricao3: 'Resíduos de limpeza de gases de combustão',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1911,
        descricao2: 'Resíduos de regeneração de óleos',
        codResiduo: '191199',
        descricao3: 'Outros resíduos não anteriormente especificados',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1912,
        descricao2: 'Resíduos do tratamento mecânico de resíduos (por exemplo, triagem, trituração, compactação, peletização) não anteriormente especificados',
        codResiduo: '191201',
        descricao3: 'Papel e cartão',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1912,
        descricao2: 'Resíduos do tratamento mecânico de resíduos (por exemplo, triagem, trituração, compactação, peletização) não anteriormente especificados',
        codResiduo: '191202',
        descricao3: 'Metais ferrosos',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1912,
        descricao2: 'Resíduos do tratamento mecânico de resíduos (por exemplo, triagem, trituração, compactação, peletização) não anteriormente especificados',
        codResiduo: '191203',
        descricao3: 'Metais não ferrosos',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1912,
        descricao2: 'Resíduos do tratamento mecânico de resíduos (por exemplo, triagem, trituração, compactação, peletização) não anteriormente especificados',
        codResiduo: '191204',
        descricao3: 'Plásticos',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1912,
        descricao2: 'Resíduos do tratamento mecânico de resíduos (por exemplo, triagem, trituração, compactação, peletização) não anteriormente especificados',
        codResiduo: '191205',
        descricao3: 'Vidro',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1912,
        descricao2: 'Resíduos do tratamento mecânico de resíduos (por exemplo, triagem, trituração, compactação, peletização) não anteriormente especificados',
        codResiduo: '191206(*)',
        descricao3: 'Madeira contendo substancias perigosas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1912,
        descricao2: 'Resíduos do tratamento mecânico de resíduos (por exemplo, triagem, trituração, compactação, peletização) não anteriormente especificados',
        codResiduo: '191207',
        descricao3: 'Madeira não abrangidas em 19 12 06 (* )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1912,
        descricao2: 'Resíduos do tratamento mecânico de resíduos (por exemplo, triagem, trituração, compactação, peletização) não anteriormente especificados',
        codResiduo: '191208',
        descricao3: 'Têxteis',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1912,
        descricao2: 'Resíduos do tratamento mecânico de resíduos (por exemplo, triagem, trituração, compactação, peletização) não anteriormente especificados',
        codResiduo: '191209',
        descricao3: 'Substancias minerais (por exemplo, areia, rochas )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1912,
        descricao2: 'Resíduos do tratamento mecânico de resíduos (por exemplo, triagem, trituração, compactação, peletização) não anteriormente especificados',
        codResiduo: '191210',
        descricao3: 'Resíduos combustíveis (combustíveis derivados de resíduos )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1912,
        descricao2: 'Resíduos do tratamento mecânico de resíduos (por exemplo, triagem, trituração, compactação, peletização) não anteriormente especificados',
        codResiduo: '191211',
        descricao3: 'Borrachas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1912,
        descricao2: 'Resíduos do tratamento mecânico de resíduos (por exemplo, triagem, trituração, compactação, peletização) não anteriormente especificados',
        codResiduo: '191212(*)',
        descricao3: 'Outros resíduos (incluindo misturas de materiais) do tratamento mecânico de resíduos contendo substancias perigosas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1912,
        descricao2: 'Resíduos do tratamento mecânico de resíduos (por exemplo, triagem, trituração, compactação, peletização) não anteriormente especificados',
        codResiduo: '191213',
        descricao3: 'Outros resíduos (incluindo misturas de materiais) do tratamento mecânico de resíduos não abrangidos em 19 12 12 (* )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1913,
        descricao2: 'Resíduos da descontaminação de solos e águas freáticas',
        codResiduo: '191301(*)',
        descricao3: 'Resíduos da descontaminação de solos contendo substancias perigosas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1913,
        descricao2: 'Resíduos da descontaminação de solos e águas freáticas',
        codResiduo: '191302',
        descricao3: 'Resíduos da descontaminação de solos não abrangidos em 19 13 01 (* )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1913,
        descricao2: 'Resíduos da descontaminação de solos e águas freáticas',
        codResiduo: '191303(*)',
        descricao3: 'Lodos da descontaminação de solos contendo substancias perigosas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1913,
        descricao2: 'Resíduos da descontaminação de solos e águas freáticas',
        codResiduo: '191304',
        descricao3: 'Lodos da descontaminação de solos não abrangidos em 19 13 03 (* )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1913,
        descricao2: 'Resíduos da descontaminação de solos e águas freáticas',
        codResiduo: '191305(*)',
        descricao3: 'Lodos da descontaminação de águas freáticas contendo substancias perigosas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1913,
        descricao2: 'Resíduos da descontaminação de solos e águas freáticas',
        codResiduo: '191306',
        descricao3: 'Lodos da descontaminação de águas freáticas não abrangidas em 19 13 05 (* )',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1913,
        descricao2: 'Resíduos da descontaminação de solos e águas freáticas',
        codResiduo: '191307(*)',
        descricao3: 'Resíduos líquidos aquosos e concentrados aquosos da descontaminação de águas freáticas contendo substancias perigosas',
      },
      {
        capitulo: 19,
        descricao: 'Resíduos de instalações de gestão de resíduos, de estações de tratamento de águas residuais e da preparação de água para consumo humano e água para consumo industrial',
        subcapitulo: 1913,
        descricao2: 'Resíduos da descontaminação de solos e águas freáticas',
        codResiduo: '191308',
        descricao3: 'Resíduos líquidos aquosos e concentrados aquosos da descontaminação de águas freáticas não abrangidas em 19 13 07 (* )',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200101',
        descricao3: 'Papel e cartão',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200102',
        descricao3: 'Vidro',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200108',
        descricao3: 'Resíduos biodegradáveis de cozinha e cantinas',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200110',
        descricao3: 'Roupas',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200111',
        descricao3: 'Têxteis',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200113(*)',
        descricao3: 'Solventes',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200114(*)',
        descricao3: 'Ácidos',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200115(*)',
        descricao3: 'Resíduos alcalinos',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200117(*)',
        descricao3: 'Produtos químicos para fotografia',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200119(*)',
        descricao3: 'Pesticidas',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200121(*)',
        descricao3: 'Lâmpadas fluorescentes, de vapor de sódio e mercúrio e de luz mista',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200123(*)',
        descricao3: 'Produtos eletroeletrônicos fora de uso contendo clorofluorcarbonetos',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200125',
        descricao3: 'Óleos e gorduras vegetais alimentares',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200126(*)',
        descricao3: 'Óleos e gorduras não abrangidas em 20 01 25',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200127(*)',
        descricao3: 'Tintas, produtos adesivos, colas e resinas contendo substancias perigosas',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200128',
        descricao3: 'Tintas, produtos adesivos, colas e resinas não abrangidas em 20 01 27 (* )',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200129(*)',
        descricao3: 'Detergentes contendo substancias perigosas',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200130',
        descricao3: 'Detergentes não abrangidos em 20 01 29 (* )',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200131(*)',
        descricao3: 'Medicamentos citotóxicos e citostáticos (B conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200132',
        descricao3: 'Medicamentos não abrangidos em 20 01 31 (*) - (B conforme ANVISA RDC 306 04 )',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200133(*)',
        descricao3: 'Pilhas e acumuladores abrangidos em 16 06 01 (*) ou 16 06 03 (*) e pilhas e acumuladores não separados contendo essas pilhas ou acumuladores',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200134',
        descricao3: 'Pilhas e acumuladores não abrangidos em 20 01 33 (* )',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200135(*)',
        descricao3: 'Produtos eletroeletrônicos e seus componentes fora de uso não abrangido em 20 01 21 (*) ou 20 01 23 (*) contendo componentes perigosos (6 )',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200136',
        descricao3: 'Produtos eletroeletrônicos e seus componentes fora de uso não abrangido em 20 01 21 (*), 20 01 23 (*) ou 20 01 35 (* )',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200137(*)',
        descricao3: 'Madeira contendo substancias perigosas',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200138',
        descricao3: 'Madeira não abrangida em 20 01 37 (* )',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200139',
        descricao3: 'Plásticos',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200140',
        descricao3: 'Metais',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200141',
        descricao3: 'Resíduos da limpeza de chaminés',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2001,
        descricao2: 'Resíduos provenientes da coleta seletiva de resíduos sólidos urbanos (exceto 15 01)',
        codResiduo: '200199',
        descricao3: 'Outras frações não anteriormente especificadas',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2002,
        descricao2: 'Resíduos de limpeza urbana',
        codResiduo: '200201',
        descricao3: 'Resíduos de varrição, limpeza de logradouros e vias públicas e outros serviços de limpeza urbana biodegradáveis',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2002,
        descricao2: 'Resíduos de limpeza urbana',
        codResiduo: '200202',
        descricao3: 'Terras e pedras',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2002,
        descricao2: 'Resíduos de limpeza urbana',
        codResiduo: '200203',
        descricao3: 'Outros resíduos de varrição, limpeza de logradouros e vias públicas e outros serviços de limpeza urbana não biodegradáveis',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2003,
        descricao2: 'Outros resíduos dos serviços públicos de saneamento básico e equiparados',
        codResiduo: '200301',
        descricao3: 'Outros resíduos urbanos e equiparados, incluindo misturas de resíduos',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2003,
        descricao2: 'Outros resíduos dos serviços públicos de saneamento básico e equiparados',
        codResiduo: '200302',
        descricao3: 'Resíduos de mercados públicos e feiras',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2003,
        descricao2: 'Outros resíduos dos serviços públicos de saneamento básico e equiparados',
        codResiduo: '200303',
        descricao3: 'Resíduos da limpeza de ruas e de galerias de drenagem pluvial',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2003,
        descricao2: 'Outros resíduos dos serviços públicos de saneamento básico e equiparados',
        codResiduo: '200304',
        descricao3: 'Lodos de fossas sépticas',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2003,
        descricao2: 'Outros resíduos dos serviços públicos de saneamento básico e equiparados',
        codResiduo: '200306',
        descricao3: 'Resíduos de limpeza de esgotos, bueiros e bocas de lobo',
      },
      {
        capitulo: 20,
        descricao: 'Resíduos sólidos urbanos e equiparados (resíduos domésticos, do comércio, indústria e serviços), incluindo as frações provenientes da coleta seletiva',
        subcapitulo: 2003,
        descricao2: 'Outros resíduos dos serviços públicos de saneamento básico e equiparados',
        codResiduo: '200399',
        descricao3: 'Resíduos urbanos e equiparados não anteriormente especificados',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1805,
        descricao2: 'Resíduos do serviço de saúde',
        codResiduo: 'Grupo A',
        descricao3: 'Resíduos de Serviços de Saúde classificados como Grupos A1, A2, A3, A4 ou A5, conforme ANVISA RDC 306 2004 - Contempla os resíduos códigos 180101(*), 180102(*), 180103(*), 180104(*), 180105(*), 180106(*), 180107(*), 180108(*), 180109(*), 180110(*), 180111(*), 180112(*), 180113(*), 180114(*) e 180115(*) conforme IBAMA 13 2014',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1805,
        descricao2: 'Resíduos do serviço de saúde',
        codResiduo: 'Grupo B',
        descricao3: 'Resíduos de Serviços de Saúde classificados como Grupo B, conforme ANVISA RDC 306 2005 - Contempla os resíduos códigos 180201(*), 180202(*), 180203(*), 180204(*), 180205(*), 200131(*) e 200132 conforme IBAMA 13 2014',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1805,
        descricao2: 'Resíduos do serviço de saúde',
        codResiduo: 'Grupo C',
        descricao3: 'Materiais resultantes de laboratórios de pesquisa e ensino na área de saúde, laboratórios de análises clínicas e serviços de medicina nuclear e radioterapia que contenha radionuclídeos em quantidade superior aos limites de eliminação (CNEN-6.05) - Contempla o resíduo código 180301(*) conforme IBAMA 13 2014',
      },
      {
        capitulo: 18,
        descricao: 'Resíduos do serviço de saúde',
        subcapitulo: 1805,
        descricao2: 'Resíduos do serviço de saúde',
        codResiduo: 'Grupo E',
        descricao3: 'Materiais perfurocortantes ou escarificantes, tais como: lâminas de barbear, agulhas, escalpes, ampolas de vidro, brocas, limas endodônticas, pontas diamantadas, lâminas de bisturi, lancetas, tubos capilares; micropipetas; lâminas e lamínulas; espátulas; e todos os utensílios de vidro quebrados no laboratório (pipetas, tubos de coleta sanguínea e placas de Petri) e outras similares - Contempla o resíduo código 180401(*) conforme IBAMA 13 2014',
      },
    ],
  },
  getters: {
    getClassificationsOptions: state => state.classificationsOptions,
    getPhysicalStateOptions: state => state.physicalStateOptions,
    getUnitOptions: state => state.unitOptions,
    getTechnologyOptions: state => state.technologyOptions,
    getConditionningOptions: state => {
      state.conditionningOptions.sort((a, b) => a.text.localeCompare(b.text))
        .unshift({
          text: 'Nenuma das opções',
          value: null,
        })

      return state.conditionningOptions
    },
    getWasteData: state => state.wasteData,
    getWasteInventory: state => state.wasteInventory,
    getWasteOperations: state => state.wasteOperations,
    getWaste: state => state.waste,
    getWasteOptions: state => state.wasteOptions,
    getWasteDashboardPerMonth: state => state.wasteDashboardPerMonth,
    getWasteInventoryOptions: state => state.wasteInventoryOptions,
    getIsOperationsTabActive: state => state.isOperationsTabActive,
    getWasteCode: state => state.wasteCode,
  },
  mutations: {
    SET_WASTE_DATA(state, payload) {
      state.wasteData = payload
    },
    SET_WASTE(state, payload) {
      state.waste = payload
    },
    SET_WASTE_INVENTORY(state, payload) {
      state.wasteInventory = payload
    },
    SET_WASTE_OPERATIONS(state, payload) {
      state.wasteOperations = payload
    },
    SET_WASTE_DASHBOARD_PER_MONTH(state, payload) {
      state.wasteDashboardPerMonth = payload
    },
    SET_IS_OPERATIONS_TAB_ACTIVE(state, payload) {
      state.isOperationsTabActive = payload
    },
  },
  actions: {
    fetchWaste({ state, commit }) {
      waste.getWaste().then(res => {
        commit('SET_WASTE', res.data.filter(arr => arr.isActive))
        state.wasteOptions = [{ text: 'Nenhuma das opções', value: null }]
        state.wasteOptions.push(...state.waste.map(arr => ({
          text: arr.project.project_name,
          value: arr.id,
        })).sort((a, b) => a.text.localeCompare(b.text)))
      })
    },
    fetchWasteInventory({ state, commit }, id) {
      waste.getWasteInventory().then(res => {
        commit('SET_WASTE_INVENTORY', res.data.filter(arr => arr.isActive && arr.waste_id === Number(id)))
        state.wasteInventoryOptions = [{ text: 'Nenhuma das opções', value: null }]
        state.wasteInventoryOptions.push(...state.wasteInventory.map(arr => ({
          text: arr.name,
          value: arr.id,
        })).sort((a, b) => a.text.localeCompare(b.text)))
      })
    },
    fetchWasteOperation({ commit }, id) {
      waste.getWasteOperations().then(res => {
        commit('SET_WASTE_OPERATIONS', res.data.filter(arr => arr.isActive && arr.waste_id === Number(id)))
      })
    },
    fetchWasteDashboardPerMonth({ commit }, obj) {
      waste.getWasteDashboardPerMonth(obj).then(res => commit('SET_WASTE_DASHBOARD_PER_MONTH', res.data))
    },
    fetchAndActivateOperationsTab({ commit }, status) {
      console.log(status)
      commit('SET_IS_OPERATIONS_TAB_ACTIVE', status)
    },
  },
}
