<template>
  <svg
    viewBox="0 0 9 6"
    fill="none"
    width="1px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.375 3L3.375 5L7.375 1"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

</template>
<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    },
  },
}
</script>
