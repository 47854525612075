export default [
  {
    path: '/nao-autorizado',
    name: '401',
    component: () => import(/* webpackChunkName: "company" */ '@/views/erros/401.vue'),
    meta: {
      action: 'read',
      resource: 'auth',
    },
  },

]
