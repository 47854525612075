/* eslint-disable func-names */
import Vue from 'vue'
import jsonp from 'jsonp'
import router from '@/router/index'
import store from '@/store'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

Vue.prototype.showModal = function (modalName) {
  this.$modal.show(modalName)
}

Vue.prototype.hideModal = function (modalName) {
  this.$modal.hide(modalName)
}

Vue.prototype.detailItemTable = function (items) {
  store.commit('app/SET_ITEM_TABLE_DETAIL', items[0])
  const routePath = window.location.href.split('/')
  const routeNode = routePath[routePath.length - 1]
  router.push(`${routeNode}/${items[0].id}/detalhes`)
}

Vue.prototype.verifyRouteAddNewItem = function () {
  if (this.$route.path.split('/').includes('adicionar')) {
    store.commit('app/SET_ITEM_TABLE_EDIT', true)
    return true
  }
  store.commit('app/SET_ITEM_TABLE_EDIT', false)
  return false
}

Vue.prototype.getAdressByCep = function (cep) {
  const cepFormated = cep.replace(/\D/gim, '')
  const xmlHttp = new XMLHttpRequest()
  xmlHttp.open('GET', `https://viacep.com.br/ws/${cepFormated}/json/`, false)
  xmlHttp.send(null)
  return JSON.parse(xmlHttp.responseText)
}

Vue.prototype.toast = function (config) {
  if (config.variant === 'success') {
    this.$toast.success({
      component: ToastificationContent,
      props: {
        colorIcon: '#18AC81',
        icon: 'TickCircle',
        title: config.title,
        text: config.text,
        variant: config.variant,
      },
    })
  } else if (config.variant === 'erro') {
    this.$toast.error({
      component: ToastificationContent,
      props: {
        colorIcon: '#FD3258',
        icon: 'CloseCircle',
        title: config.title,
        text: config.text,
        variant: config.variant,
      },
    })
  } else if (config.variant === 'warning') {
    this.$toast.warning({
      component: ToastificationContent,
      props: {
        colorIcon: '#FECD2F',
        icon: 'InfoCircle',
        title: config.title,
        text: config.text,
        variant: config.variant,
      },
    })
  } else if (config.variant === 'primary' || config.variant === null) {
    this.$toast({
      component: ToastificationContent,
      props: {
        colorIcon: '#18AC81',
        icon: 'Flash1',
        title: config.title,
        text: config.text,
        variant: config.variant,
      },
    })
  }
}

Vue.prototype.verifyTokenValid = function () {
  return new Date(useJwt.decodeToken().exp * 1000) > new Date()
}

Vue.prototype.getCompanyReceitaByCnpj = function (cnpj) {
  return new Promise((resolve, reject) => {
    const cnpjFormated = cnpj.replace(/\D/gim, '')
    jsonp(`https://www.receitaws.com.br/v1/cnpj/${cnpjFormated}`, null, (err, data) => {
      if (err) {
        reject(err.message)
      } else {
        resolve(data)
      }
      return null
    })
  })
}

Vue.prototype.regressiveCount = function (date) {
  if (date) {
    const diference = new Date(date) - new Date()
    const days = (diference / (60 * 60 * 24 * 1000))
    return Math.round(days) + 1 || 0
  } return 0
}
