import contacts from '@/services/contacts'

export default {
  namespaced: true,
  state: {
    contacts: [],
    contactData: null,
    optionsMarcador: ['Celular', 'Casa', 'Fixo', 'WhatsApp'],
  },
  getters: {
    getContacts: state => state.contacts,
    geContactData: state => state.contactData,
    getOptionsMarcador: state => state.optionsMarcador,
  },
  mutations: {
    SET_CONTACTS_DATA(state, payload) {
      state.contactData = payload
    },
    SET_CONTACTS(state, payload) {
      state.contacts = payload
    },
    SET_RELATION(state, payload) {
      state.contactRelation = payload
    },
  },
  actions: {
    fetchContacts({ commit }) {
      contacts.getContacts().then(res => {
        commit('SET_CONTACTS', res.data.filter(arr => arr.isActive))
      })
    },
    fetchContactByIdAndRelation({ commit }, data) {
      contacts.getContactsByRelationAndId(data).then(res => {
        commit('SET_CONTACTS_DATA', res.data.filter(arr => arr.isActive && arr.telephone.filter(arrTel => arrTel.principal_icon)))
      })
    },
  },
}
