<script>
/* eslint-disable */
</script>
<template>
  <div>
    <AlertAccountPayment />
    <b-navbar
      toggleable="sm"
      type="light"
      variant="light"
      class="header"
    >
      <router-link :to="{ name: 'home' }">
        <b-navbar-brand>
          <VSIcons
            name="LogoVerdeSys"
            color-primary="#1BAD82"
            color-secondary="#076E5F"
            width="140"
            height="50"
          />
        </b-navbar-brand>
      </router-link>

      <b-button
        class="icon-button-header"
        @click="toggle(!collapsed)"
      ><VSIcons
        name="Menu"
        color="#1BAD82"
      /></b-button>

      <b-collapse
        id="nav-collapse"
        is-nav
      >
        <b-nav-form class="search-header">
          <b-input-group class="search-header search-input-group input-group-merge round">
            <b-input-group-prepend
              is-text
            >
              <VSIcons
                name="Search"
                color="#7D8E9E"
                width="18"
                height="18"
              />
            </b-input-group-prepend>
            <b-form-input
              id="search-header"
              v-model="searchFilter"
              placeholder="Buscar..."
              type="search"
              @input="showResults()"
              @blur="closeResults()"
              @click="showResults()"
            />
          </b-input-group>
          <transition
            name="fade"
            mode="out-in"
          >
            <div
              id="result-search-id"
              ref="results"
              class="result-search"
              :class="showResultClass ? 'show-result' : ''"
            >
              <span class="result-title">
                Módulos
              </span>
              <div
                v-for="item in itensResults"
                :key="item.title"
              >
                <div
                  v-if="item.title.toLowerCase().indexOf(searchFilter.toLowerCase()) != -1"
                  class="w-100 cursor-pointer"
                  @click="goTo(item.route)"
                >
                  <li
                    class="result-item"
                  >
                    <VSIcons
                      :name="item.icon"
                      color="#7D8E9E"
                      class="mr-2"
                      height="18"
                    />
                    {{ item.title }}
                  </li>
                </div>
              </div>
            </div>
          </transition>
        </b-nav-form>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto d-flex align-items-center">
          <div>
            <a
              v-b-tooltip.hover
              title="Precisando de ajuda?"
              class="bg-transparent rounded-circle d-flex justify-content-center align-items-center font-weight-normal mr-2"
              style="color: #7D8E9E; font-size: 22px; height: 34px; width: 34px; border: 1px solid rgba(160, 174, 185, 0.34); text-decoration:none"
            >
              ?
            </a>
          </div>

          <b-dropdown
            no-caret
            toggle-class="btn-header rounded-circle"
            menu-class="menu-notification"
          >
            <template #button-content>
              <div
                v-if="notifications.some(arr => arr.status !== 'Read')"
                class="badge-notification-header"
              />
              <VSIcons
                name="Notification"
                color="#7D8E9E"
                width="22"
                height="26"
              />
            </template>
            <HeaderDropdownNotification />
          </b-dropdown>

          <div class="line" />

          <div class="user-information mr-2">
            <span class="text-user text-nowrap">
              Olá, {{ userData.username || userData.full_name.split(' ')[0] | title }}
            </span>
            <small class="text-information">
              {{ permission.name }}
            </small>
          </div>

          <div class="avatar-header">
            <AvatarUser :src="userAvatar" />
          </div>

          <b-nav-item-dropdown
            right
            no-caret
            menu-class="dropdown-user-header"
          >
            <template #button-content>
              <VSIcons
                name="ArrowDownHeader"
                color="#33383C"
              />
            </template>
            <div class="d-flex">
              <AvatarUser
                :src="userAvatar"
                :upload="true"
                size="36px"
                class="mr-2"
              />
              <div class="d-block">
                <span class="user-dropdown-header font-weight-bold text-dark">{{ userData.username | title }}</span>
                <span class="email-dropdown-header">
                  {{ userData.email }}
                </span>
              </div>
            </div>
            <b-dropdown-divider />
            <b-dropdown-item @click="$router.push({name: 'my-account'})">
              <div class="d-flex align-items-center text-secondary">
                <VSIcons
                  class="mr-2"
                  color="#7D8E9E"
                  name="UserOctagon"
                /> Minha conta
              </div>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="permission.name === 'Master'"
              @click="$router.push({name: 'management-verdesys'})"
            >
              <div class="d-flex align-items-center text-secondary">
                <VSIcons
                  class="mr-2"
                  color="#7D8E9E"
                  name="People"
                /> Gestão VerdeSys
              </div>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isActive === true || permission.name === 'Master'"
              @click="$router.push({name: 'user-management'})"
            >
              <div class="d-flex align-items-center text-secondary">
                <VSIcons
                  class="mr-2"
                  color="#7D8E9E"
                  name="Setting4"
                /> Gerenciar usuários
              </div>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isActive === true || permission.name === 'Master'"
              @click="showModal('Ticket')"
            >
              <div class="d-flex align-items-center text-secondary">
                <VSIcons
                  class="mr-2"
                  color="#7D8E9E"
                  name="QuestionMark"
                /> Tickets
              </div>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isActive === true || permission.name === 'Master'"
              @click="showModal('Feedback')"
            >
              <div class="d-flex align-items-center text-secondary">
                <VSIcons
                  class="mr-2"
                  color="#7D8E9E"
                  name="Star"
                /> Feedback
              </div>
            </b-dropdown-item>
            <b-dropdown-item @click="logout()">
              <div class="d-flex align-items-center text-secondary">
                <VSIcons
                  class="mr-2"
                  color="#7D8E9E"
                  name="Logout"
                /> Sair
              </div>
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <VSModal
      width="670"
      name="Feedback"
    />
    <VSModal
      name="Ticket"
      width="670"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  BNavbar,
  BCollapse,
  BNavbarNav,
  BDropdownItem,
  BNavItemDropdown,
  BDropdownDivider,
  BDropdown,
  BButton,
  BFormInput,
  BNavForm,
  BNavbarBrand,
  BInputGroupPrepend,
  BInputGroup,
  VBTooltip,
} from 'bootstrap-vue'
import AvatarUser from '../avatar-user/AvatarUser.vue'
import VSIcons from '@/assets/icons/VSIcons.vue'
import HeaderDropdownNotification from './HeaderDropdownNotification.vue'
import VSModal from '@/components/modals/VSModal.vue'
import useJwt from '@/auth/jwt/useJwt'
import navItens from '../navigation/nav-itens'
import { title } from '@/@core/utils/filter'
import AlertAccountPayment from '@/components/alert-account-payment/AlertAccountPayment.vue'
import api from '@/libs/axios'

export default {
  components: {
    BNavbar,
    BCollapse,
    BNavbarNav,
    BDropdownItem,
    BNavItemDropdown,
    BButton,
    BFormInput,
    BNavForm,
    BNavbarBrand,
    BInputGroupPrepend,
    BInputGroup,
    BDropdownDivider,
    BDropdown,
    AvatarUser,

    VSIcons,
    VSModal,

    HeaderDropdownNotification,
    AlertAccountPayment,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    title,
  },
  data() {
    return {
      searchFilter: '',
      showResultClass: false,
      itensResults: [],
      permissionUser: [],
      isActive: null,
    }
  },
  computed: {
    ...mapGetters({
      collapsed: 'app/getSidebarCollapsed',
      userData: 'auth/getUserData',
      permission: 'auth/getUserPermissions',
      getUserPermissionsAcl: 'auth/getUserPermissions',
      userAvatar: 'auth/getUserAvatar',
      notifications: 'announcements/getMyAnnouncements',
      getUserData: 'auth/getUserData',
    }),
    route() { return this.$route.path },
  },
  mounted() {
    this.fetchAvatar()
    const perm = Object.entries(JSON.parse(localStorage.getItem('user')).permission).filter(arr => arr[0].indexOf('read') > -1 && arr[1] === true).map(res => res[0].split('_')[0])
    this.itensResults = navItens.filter(arr => perm.indexOf(arr.resource) !== -1)

    const userIdAccount = JSON.parse(localStorage.getItem('id_account_verdesys'));
    api.get(`/accounts/${userIdAccount}`).then((response) => {
        this.isActive = response.data.isActive;
    });
  },
  methods: {
    ...mapActions({ fetchAvatar: 'auth/fetchAvatar' }),
    ...mapMutations({ toggle: 'app/SET_SIDEBAR_COLLAPSED' }),
    goTo(route) {
      this.$router.push(route).then(() => {
        this.showResultClass = false
      })
    },
    onClick() {
      this.$refs.dropdown.hide(true)
    },
    logout() {
      useJwt.logout()
      this.$router.push({ name: 'auth' })
    },
    showResults() {
      if (this.searchFilter.length > 0) {
        this.showResultClass = true
      } else {
        this.showResultClass = false
      }
    },
    closeResults() {
      setTimeout(() => {
        this.showResultClass = false
      }, 250)
      // this.searchFilter = ''
    },
  },
}
</script>
<style lang="scss" scoped>
.result-search {
  position: absolute;
  z-index: 100;
  top: 4rem;
  margin-top: .2rem;
  width: 35vw;
  @media (max-width: 1025px) {
    width: 70vw;
  }
  @media (max-width: 1366px) {
    width: 50vw;
  }
  @media (max-width: 1440px) {
    width: 47vw;
  }
  min-height: 7vh;
  max-height: 40vh;
  overflow-x: hidden;
  background-color: #fff;
  visibility: hidden;
  transition: ease .1s;
  box-shadow: 0px 4px 6px 1px rgba(0,0,0,0.2);
  // box-shadow: 0px 0px 15px 1900px rgba(0,0,0,0.45);
  border-radius: 10px;
  a {
    text-decoration: none;
  }
  .result-item {
    list-style: none;
    width: 100%;
    padding: .4rem 1rem;
    display: flex;
    align-items: center;
    color: #7D8E9E;
    font-size: 13px;
    &:hover {
      background-color: rgba(235, 235, 235, 0.4);
    }
  }
  .result-title {
    padding: .4rem 1rem;
    color: #7D8E9E;
    font-size: 14px;
    font-weight: 500;
  }
}
.show-result {
  transition: ease .1s;
  visibility: visible !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1.3s ease;
}
.fade-enter, .fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
