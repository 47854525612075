<template>
  <div class="">
    <h2 class="mb-4">
      Verifique sua importação de dados
    </h2>
    <p
      v-for="conflict in conflictList"
      :key="conflict.row"
    >
      {{ conflict.error }}
    </p>
    <div class="d-flex justify-content-center mt-2">
      <b-row>
        <b-col
          md="12"
        >
          <BButton
            variant="danger"
            class="button mt-2 button-custom"
            @click="hideModal('ImportError')"
          >
            Fechar
          </BButton>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BButton, BRow, BCol,
  },
  props: {
    conflictList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang='scss' scoped>
.modal-content {
  box-shadow: none !important;
}

h2 {
  font-size: 21px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.75px;
  color: #33383C;
}

p {
  font-weight: 500;
  color: #2a2e30;
  font-size: 13px;
  line-height: 100%;
  line-height: 100%;
  letter-spacing: 0.75px;
}

.button-custom {
  width: 150px;
}
</style>
