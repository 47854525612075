export default [
  {
    path: '/licenca',
    name: 'license',
    component: () => import(/* webpackChunkName: "licensing" */ '@/views/license/License.vue'),
    meta: {
      title: 'Licença e condicionantes',
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'licensesconditions',
    },
  },
  {
    path: '/licenca/:id/detalhes',
    name: 'license-details',
    component: () => import(/* webpackChunkName: "licensing-details" */ '@/views/license/license-details.vue'),
    meta: {
      title: 'Licença e condicionantes',
      menu: true,
      authRequired: true,
      subTitle: true,
      action: 'read',
      resource: 'licensesconditions',
    },
  },
]
