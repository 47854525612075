import api from '@/libs/axios'

export default {
  getLicensingConditions: () => api.get('/system/licensing-conditions/general'),
  getLicensingConditionsById: id => api.get(`/system/licensing-conditions/general/${id}`),
  editLicensingConditions: form => api.patch(`/system/licensing-conditions/general/${form.id}`, form),
  createLicensingConditions: form => api.post('/system/licensing-conditions/general', form),
  deleteLicensingConditions: array => api.delete('/system/licensing-conditions/general', array),

  getLicensingConditionsConstraints: () => api.get('/system/licensing-conditions/constraints'),
  getLicensingConditionsConstraintsRecurrence: id => api.get(`/system/licensing-conditions/constraints/recurrence/${id}`),
  createLicensingConditionsConstraints: form => api.post('/system/licensing-conditions/constraints', form),
  editLicensingConditionsConstraints: form => api.patch(`/system/licensing-conditions/constraints/${form.id}`, form),

  getLicensingConditionsReports: (date, query) => api.get(`/system/licensing-conditions/general/date/${date}${query}`),
  getLicensingConditionsManagementReports: query => api.get(`/system/licensing-conditions/general/report/get${query}`),

  getLicensingConditionsDashboardPerYear: year => api.get(`/system/licensing-conditions/general/year/${year}?version=v2`),
  getLicensingConditionsDashboardTable: () => api.get('/system/licensing-conditions/general/dashboard/search'),
  getLicensingConditionsDashboardPerMonth: obj => api.get(`/system/licensing-conditions/general/month/${obj.month}/year/${obj.year}`),

}
