export default [
  {
    path: '/empreendimento',
    name: 'enterprise',
    component: () => import(/* webpackChunkName: "company" */ '@/views/enterprise/Enterprise.vue'),
    meta: {
      title: 'Empreendimento',
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'project',
    },
  },
  {
    path: '/empreendimento/:id/detalhes',
    name: 'enterprise-details',
    component: () => import(/* webpackChunkName: "company-details" */ '@/views/enterprise/EnterpriseDetails.vue'),
    meta: {
      title: 'Empreendimento',
      subTitle: true,
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'project',
    },
  },
]
