<template>
  <div>
    <h2>Cadastrar nova permissão</h2>
    <label for="permission">Nome da permissão </label>
    <b-form-input
      id="permission"
      v-model="permissionName"
      type="text"
      class="mb-3"
    />

    <div class="d-flex justify-content-between mt-2">
      <b-button
        variant="outline-primary mr-3 w-50"
        @click="hideModal('NewPermission')"
      >
        Cancelear
      </b-button>
      <b-button
        variant="primary w-50"
        @click="createPermission"
      >
        Continuar
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BFormInput, BButton,
} from 'bootstrap-vue'
import { title } from '@core/utils/filter'
import permissions from '@/services/permissions'

export default {
  components: {
    BFormInput,
    BButton,
  },
  filters: {
    title,
  },
  props: {
    properties: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      permissionName: '',

    }
  },
  methods: {
    createPermission() {
      const permission = {
        name: this.permissionName,
      }
      permissions.createPermission(permission).then(() => {
        this.toast({
          title: 'Permissão criada.',
          text: 'Permissão criada com sucesso',
          variant: 'success',
        })
        this.hideModal('NewPermission')
        this.$router.go()
      }).catch(err => {
        if (err.response.status === 500) {
          this.toast({
            title: 'Erro no servidor',
            text: 'Entre em contato com o suporte',
            variant: 'erro',
          })
        }
      })
    },
  },
}
</script>

<style lang='scss' scoped>
  h2 {
      font-weight: 600;
      font-size: 21px;
      letter-spacing: 0.75px;
      text-align: center;
  }
  p {
    color: #7D8E9E;
    letter-spacing: 0.75px;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    line-height: 100%;
  }
  label, input {
    display: block;
    width: 100%;
  }
  input {
    background: #FFFFFF;
    border: 2px solid rgba(160, 174, 185, 0.21);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 14px 13px;
    margin-bottom: 34.5px;
  }
  label {
    font-family: Inter;
    font-weight: 600;
    font-size: 11px;
    letter-spacing: 0.75px;
    color: #33383C;
  }
</style>
