<template>
  <div>
    <h4 class="modal-title">
      {{ `Deseja ${properties.isActive ? 'ativar' : 'desativar'} esse usuário ?` }}
    </h4>
    <h6 class="modal-subtitle">
      {{ `Deseja realmente ${properties.isActive ? 'ativar' : 'desativar'} o usúario ${properties.full_name} ?` }}
    </h6>
    <div class="d-flex justify-content-between mt-5">
      <b-button
        variant="outline-success pr-4 pl-4 mr-5"
        @click="cancelAlternante()"
      >
        Cancelar
      </b-button>
      <b-button
        class="btn-grad pr-4 pl-4 m-0"
        @click="alternateState()"
      >
        Continuar
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  BButton,
} from 'bootstrap-vue'
import users from '@/services/users'

export default {
  components: {
    BButton,
  },
  props: {
    properties: {
      type: Object,
      default: null,
    },
  },
  methods: {
    alternateState() {
      users.editUser(this.properties).then(() => {
        this.hideModal('AlternanteUserState')
      })
    },
    cancelAlternante() {
      this.$router.go()
    },
  },
}
</script>
