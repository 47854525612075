import api from '@/libs/axios'

export default {
  getContacts: () => api.get('/system/contact/'),
  getContactsByRelationAndId: form => api.get(`/system/contact/relation/${form.relation}/${form.id}`),
  getContactsByRelation: relation => api.get(`/system/contact/relation/${relation}`),
  getContactById: id => api.get(`/system/contact/${id}`),
  editContact: form => api.patch(`/system/contact/${form.id}`, form),
  createContact: form => api.post('/system/contact', form),
  deleteContacts: array => api.delete('/system/contact', array),
}
