<template>
  <div>
    <h2 v-if="properties.action === 'read'">
      Detalhes notificações e autos
    </h2>
    <h2 v-if="properties.action === 'edit'">
      Editar notificações e autos
    </h2>
    <h2 v-if="properties.action === 'create'">
      Adicionar notificações e autos
    </h2>
    <validation-observer ref="form">
      <b-row>
        <b-col
          md="2"
          class="d-flex align-items-center"
        >
          <b-form-group>
            <b-form-checkbox
              v-model="item.isCompleted"
              name="check-button"
              :disabled="disbleInputs"
              switch
              size="lg"
            >Concluído
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <validation-provider
            v-slot="{ errors }"
            name="número"
            rules="required"
          >
            <b-form-group label="Número">
              <b-input-group>
                <b-form-input
                  id="numero"
                  v-model="item.record_number"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="4">
          <validation-provider
            v-slot="{ errors }"
            name="órgão"
            rules="required"
          >
            <b-form-group label="Órgão">
              <b-input-group class="input-group-merge">
                <b-form-select
                  id="orgao"
                  v-model="item.agency"
                  :disabled="disbleInputs"
                  :options="licensingAgencyOptions"
                />
                <b-input-group-append is-text>
                  <VSIcons
                    name="SearchIcon"
                    color="#A0AEB9"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="4">
          <validation-provider
            v-slot="{ errors }"
            name="departamento"
            rules="max:100"
          >
            <b-form-group label="Departamento">
              <b-input-group>
                <b-form-input
                  id="departament"
                  v-model="item.department"
                  :disabled="disbleInputs"
                  type="text"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="técnico"
            rules="max:100"
          >
            <b-form-group label="Técnico">
              <b-input-group>
                <b-form-input
                  id="tecnico"
                  v-model="item.technician"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="emissão"
            rules="required"
          >
            <b-form-group label="Data de Emissão">
              <b-input-group>
                <b-form-input
                  id="dataEmissao"
                  v-model="item.issue_date"
                  type="date"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <b-form-group label="Data de Recebimento">
            <b-input-group>
              <b-form-input
                id="dataRecebimento"
                v-model="item.receipt_date"
                :max="item.conclusion_date"
                type="date"
                :disabled="disbleInputs"
                @change="setday()"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="data de conclusão"
            rules="required"
          >
            <b-form-group label="Data de Conclusão">
              <b-input-group>
                <b-form-input
                  id="data-conclusao"
                  v-model="item.conclusion_date"
                  type="date"
                  :min="item.issue_date"
                  :disabled="disbleInputs"
                  @change="setday()"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col v-show="false">
          <b-form-group label="Prazo de Conclusão">
            <b-input-group>
              <b-form-input
                id="deadline"
                v-model.number="item.deadline"
                disabled
                type="number"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="prazo"
            rules="positive"
          >
            <b-form-group label="Prazo">
              <b-input-group>
                <b-form-input
                  id="prazo"
                  :value="deadline"
                  disabled
                  type="number"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <b-form-group label="Regressiva">
            <b-input-group>
              <b-form-input
                id="regressiva"
                :value="countdown"
                disabled
                type="number"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Observações">
            <b-input-group>
              <b-form-textarea
                id="observacoes"
                v-model="item.note"
                type="text"
                :disabled="disbleInputs"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <div v-if="properties.action === 'create'">
        <b-row>
          <b-col>
            <validation-provider
              v-slot="{ errors }"
              name="nome do arquivo"
              rules="max:100"
            >
              <b-form-group label="Nome do Arquivo">
                <b-input-group>
                  <b-form-input
                    id="nomeArquivo"
                    v-model="item.file_name"
                    type="text"
                    :disabled="disbleInputs"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="8">
            <b-form-group label="Descrição do Arquivo">
              <b-input-group>
                <b-form-input
                  id="descricaoArquivo"
                  v-model="item.file_description"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Anexar arquivo "
            >
              <b-form-file
                v-model="files"
                placeholder="Selecione um arquivo ou solte aqui..."
                drop-placeholder="Solte o arquivo aqui..."
                accept=".jpg, .png, .docx, .jpeg, .pdf, .xsls"
              />
              <b-badge
                v-if="files"
                variant="primary"
                class="mt-3"
              >
                {{ files ? files.name : null }}
                <b-button
                  variant=""
                  class="bg-transparent border-0 size-10"
                  @click="cleanFiles()"
                >
                  <span>
                    x
                  </span>
                </b-button>
              </b-badge>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </validation-observer>
    <div class="d-flex justify-content-between">
      <BButton
        variant="outline-primary"
        @click="hideModal('AddNotifications')"
      >
        Cancelar
      </BButton>
      <BButton
        v-if="!disbleInputs"
        variant="primary"
        @click="saveChanges()"
      >
        Salvar
      </BButton>
      <BButton
        v-else
        variant="primary"
        @click="() => {
          properties.action = 'edit'
          disbleInputs = false
        }"
      >
        Editar
      </BButton>
    </div>
  </div>

</template>

<script>
import {
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormGroup,
  BFormSelect,
  BFormTextarea,
  BFormFile,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import VSIcons from '@/assets/icons/VSIcons.vue'
import records from '@/services/records'
import upload from '@/services/upload'

export default {
  components: {
    BFormInput,
    BInputGroupAppend,
    VSIcons,
    BInputGroup,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    BFormFile,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
  },
  props: {
    properties: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      disbleInputs: false,
      files: null,
      item: {
        deadline: 0,
      },
    }
  },
  computed: {
    ...mapGetters({
      sectorOptions: 'sector/getSectorsOptions',
      deadlineOptions: 'app/getDeadlineOptions',
      licensingAgencyOptions: 'publicAgencies/getLicensingAgencyOptions',
    }),
    deadlineCount: {
      get() {
        const diference = new Date(this.item.conclusion_date) - new Date(this.item.receipt_date ?? this.item.issue_date)
        const days = (diference / (60 * 60 * 24 * 1000))
        return days || 0
      },
    },
    deadline() {
      const diference = new Date(this.item.conclusion_date) - new Date(this.item.receipt_date ?? this.item.issue_date)
      const days = (diference / (60 * 60 * 24 * 1000))

      return Math.round(days) < 0 ? 0 : Math.round(days)
    },
    countdown() {
      const diference = new Date(this.item.conclusion_date) - new Date(this.item.receipt_date ?? this.item.issue_date)
      const days = (diference / (60 * 60 * 24 * 1000))

      return Math.round(days) < 0 ? 0 : Math.round(days)
    },
  },
  created() {
    if (this.properties.action === 'read' || this.properties.action === 'edit') {
      if (this.properties.action === 'read' || this.properties.action === 'edit') {
        this.item = this.properties.item
        if (this.item.file_name) this.item.file_name = this.properties.item.attachment[0].file_name
        if (this.item.file_description) this.item.file_description = this.properties.item.attachment[0].file_name
      }
    }
    if (this.properties.action === 'read') {
      this.disbleInputs = true
    } else {
      this.fetchSector()
    }
  },
  methods: {
    ...mapActions({
      fetchRecords: 'records/fetchRecords',
      fetchSector: 'sector/fetchSectors',
      fetchUploads: 'upload/fetchUploads',
      fetchLicensingDocuments: 'licensing/fetchLicensingDocuments',
      fetchLicensingConditionsConstraints: 'licensingConditions/fetchLicensingConditionsConstraints',
    }),
    saveChanges() {
      this.$refs.form.validate().then(success => {
        if (success) {
          if (this.properties.module) {
            this.item.licensingCondition_id = Number(this.$route.params.id)
          } else {
            this.item.licensing_id = Number(this.$route.params.id)
          }
          this.item.deadline = this.deadline
          if (this.properties.action === 'create') {
            records.createRecord(this.item).then(res => {
              if (this.files) {
                this.createUpload(res.data.id)
              }
              this.createdMessage()
              this.fetchRecords()
            })
          } else if (this.properties.action === 'edit') {
            delete this.item.licensingAgency
            records.editRecord(this.item).then(() => {
              this.updateMessage()
              this.fetchRecords()
            })
          }
          this.hideModal('AddNotifications')
          this.fetchRecords()

          setTimeout(() => {
            if (this.properties.module) {
              this.fetchLicensingConditionsConstraints(this.$route.params.id)
            } else {
              this.fetchLicensingDocuments(this.$route.params.id)
            }
          }, 1000 * 3)
        }
      })
    },
    createUpload(id) {
      const formatData = new FormData()
      formatData.append('attachment_file', this.files)
      formatData.append('file_name', this.item.file_name)
      formatData.append('file_description', this.item.file_description)
      formatData.append('notifRecord_id', id)
      if (this.properties.module) {
        formatData.append('licensingCondition_id', this.$route.params.id)
      } else formatData.append('licensing_id', this.$route.params.id)

      const formUpload = formatData
      upload.createUploadFile(formUpload).then(() => {
        this.fetchUploads()
      })
    },
    createdMessage() {
      this.toast({
        title: 'Criado !',
        text: 'Cadastro realizado com sucesso.',
        variant: 'success',
      })
    },
    updateMessage() {
      this.toast({
        title: 'Editado !',
        text: 'Cadastro atualizado com sucesso.',
        variant: 'success',
      })
    },
    setday() {
      this.item.deadline = this.deadlineCount + 1
    },
  },
}
</script>

<style lang='scss' scoped>
h2 {
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.75px;
  color: #33383C;

}

.grid-1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-2 {
  display: grid;
  grid-template-columns: 250px 200px 200px 188px;
  justify-content: space-between;
}

.grid-3 {
  display: grid;
  grid-template-columns: 280px calc(100% - 280px);
  justify-content: space-between;
  column-gap: 1rem;
}

textarea {
  min-height: 82px;
}

button {
  width: 190px;
  height: 45px;
}

.size-10 {
  padding: 0;
  margin: 0;
  width: 8px !important;
  height: 19px !important;
}
</style>
