export default [
  {
    path: '/gerenciamento-de-usuarios',
    name: 'user-management',
    component: () => import(/* webpackChunkName: "my-account" */ '@/views/user-management/UserManagement.vue'),
    meta: {
      title: 'Gerenciamento de usúarios',
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'dashboard',
    },
  },
  {
    path: '/gerenciamento-de-usuarios/:id/usuarios',
    name: 'user-informations',
    component: () => import(/* webpackChunkName: "my-account" */ '@/views/user-management/UserInformations.vue'),
    meta: {
      title: 'Gerenciamento de usúarios',
      subTitle: true,
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'dashboard',
    },
  },
  {
    path: '/gerenciamento-de-usuarios/permissoes',
    name: 'permissoes',
    component: () => import(/* webpackChunkName: "my-account" */ '@/views/user-management/UserPermission.vue'),
    meta: {
      title: 'Gerenciamento de usúarios',
      subTitle: true,
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'dashboard',
    },
  },
]
