<template>
  <div
    v-if="plan.isTrial == 0 && plan.plan_price < 900"
    class="item"
  >
    <div class="container">
      <div
        v-if="plan.plan_name.includes('2.0')"
        class="text-center"
      >
        <VSIcons
          name="Star"
          color="#CCCC00"
          width="30"
          height="30"
        />
        <p>
          <strong>Plano preferido</strong>
        </p>
      </div>
      <h2> {{ plan.plan_name }} </h2>
      <div
        v-if="plan.plan_name.includes('2.0')"
        class="text-center"
      >
        <p>
          de
          <strike class="price-promotion">
            <strong>475,00</strong>
          </strike>
          por
        </p>
      </div>
      <p class="price">
        R$ {{ plan.plan_price }} / mês
      </p>
      <b-button
        v-if="currentPlan === plan.id"
        class="mx-auto d-block"
        variant="outline-primary"
        disabled
      >
        Plano Atual
      </b-button>
      <b-button
        v-else
        class="mx-auto d-block"
        :class="plan.plan_name === 'Premium' ? 'btn-grad' : ''"
        :variant=" plan.plan_name === 'Premium' && plan.id === 3 ? 'btn-grad' : 'outline-primary' "
        @click="updatePlan({id: accountId, plan_id: plan.id})"
      >
        {{
          plan.id == 3 ? 'Recomendado' : 'Alterar Plano'
        }}
      </b-button>
      <div class="divider" />
      <div class="plan-items">
        <li> <VSIcons
          name="CorrectIconFill"
          color="#7D8E9E"
        />{{ plan.user_limit }} Usuários</li>
        <li>
          <VSIcons
            name="CorrectIconFill"
            color="#7D8E9E"
          />
          {{ `${plan.project_limit === 0 ? 'Ilimitado' : plan.project_limit} Empreendimentos` }}
        </li>
      </div>
      <a class="see-all d-flex align-items-center">
        Ver todos os recursos <VSIcons
          class="ml-3"
          name="ArrowRightGreen"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import VSIcons from '@/assets/icons/VSIcons.vue'
import accounts from '@/services/accounts'

export default {
  components: {
    BButton,
    VSIcons,
  },
  props: {
    plan: {
      type: Object,
      required: true,
    },
    currentPlan: {
      type: [Number, String],
      required: true,
    },
    accountId: {
      type: [Number, String],
      required: true,
    },
  },
  methods: {
    ...mapActions({ fetchMyPlan: 'accounts/fetchMyPlan', fetchAccountsById: 'accounts/fetchAccountsById' }),
    updatePlan(form) {
      accounts.editAccount(form).then(res => {
        this.toast({
          title: 'Plano Atualizado!',
          text: 'Plano atualizado com sucesso.',
          variant: 'primary',
        })
        this.hideModal('ChoosePlan')
        this.fetchMyPlan(res.data.id)
        this.fetchAccountsById(this.$route.params.id || localStorage.getItem('id_account_verdesys'))
      })
    },
  },
}
</script>

<style lang='scss' scoped>
  .item {
  border: 1px solid rgba(160, 174, 185, 0.21);
  border-radius: 13px;
  width: 300px;
  padding: 21px 0px;
  margin-right: 21px;
  h2, p {
    text-align: center;
  }
  h2 {
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.75px;
  color: #33383C;
  }

  }
  .divider {
    border: 1px solid rgba(160, 174, 185, 0.21);
    margin: 21px 0;
}
  li {
  font-size: 13px;
  line-height: 100%;
  color: #7D8E9E;
  list-style: none;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin: 7.5px 0;
}
  .see-all {
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  color: #18AC81;
  padding-left: 16px;
  margin-top: 23px;
}
  .price {
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.75px;
  background: linear-gradient(90deg, #076E5F 0%, #18AC81 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
  :disabled {
  opacity: 0.34;
  border: 2px solid #A0AEB9;
  border-radius: 5px;
  color: #7D8E9E;
;
}

.price-promotion {
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.75px;
}

</style>
