import accounts from '@/services/accounts'
import users from '@/services/users'

export default {
  namespaced: true,
  state: {
    componentPage: 'LoginContent',
    emailRecoveryPassword: '',
    isUserLoggedIn: () => {
      const userInfo = localStorage.getItem('user')
      return userInfo ? userInfo && !(Object.entries(userInfo).length === 0 && userInfo.constructor === Object) : false
    },
    userData: JSON.parse(localStorage.getItem('user')),
    permissions: {},
    myAccount: {},
    userAvatar: '',
  },
  getters: {
    getComponentPage: state => state.componentPage,
    getEmailRecoveryPassword: state => state.emailRecoveryPassword,
    getUserData: state => state.userData,
    getUserPermissions: state => state.permissions,
    getUserPermissionsAcl: state => Object.entries(state.permissions).filter(arr => arr[1] === true).map(arr => ({ subject: arr[0].substr(0, arr[0].indexOf('_')), action: [arr[0].substr((arr[0].indexOf('_') + 1))] })),
    getMyAccount: state => state.myAccount,
    getUserAvatar: state => state.userAvatar,
  },
  mutations: {
    SET_COMPONENT_PAGE(state, payload) {
      state.componentPage = payload
    },
    SET_EMAIL_RECOVERY_PASSWORD(state, payload) {
      state.emailRecoveryPassword = payload
    },
    SET_USER_DATA(state, payload) {
      state.userData = payload
    },
    SET_PERMISSIONS(state, payload) {
      state.permissions = payload
    },
    SET_MY_ACCOUNT(state, payload) {
      state.myAccount = payload
    },
    SET_MY_AVATAR(state, payload) {
      state.userAvatar = payload
    },
  },
  actions: {
    setEmailRecoveryPassword({ commit }, payload) {
      commit('SET_EMAIL_RECOVERY_PASSWORD', payload)
    },
    fetchMyAccount({ commit }) {
      accounts.getAccountById(localStorage.getItem('id_account_verdesys')).then(res => {
        commit('SET_MY_ACCOUNT', res.data)
      })
    },
    fetchAvatar({ commit }) {
      users.getMyAvatar().then(res => {
        commit('SET_MY_AVATAR', res.data)
      })
    },
  },
}
