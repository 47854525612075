export default [
  {
    path: '/',
    redirect: { name: 'home' },
  },
  {
    path: '/dashboard',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/Home.vue'),
    meta: {
      title: 'Dashboard',
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'dashboard',
    },
  },
]
