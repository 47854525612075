export default [
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "company" */ '@/views/erros/404.vue'),
    meta: {
      action: 'read',
      resource: 'auth',
    },
  },

]
