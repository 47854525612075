import api from '@/libs/axios'

export default {
  getUsers: () => api.get('/system/users'),
  getUsersMaster: () => api.get('users-list'),
  getUserById: id => api.get(`/system/users/${id}`),
  createUser: user => api.post('system/users', user),
  editUser: user => api.patch(`/system/users/${user.id}`, user),
  getUserLogin: id => api.get(`/system/auth-logs/${id}`),
  getMyAvatar: () => api.get('system/users/my/thumbnail'),
  setMyAvatar: form => api.post('/system/users/thumbnail', form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
}
