import { VMoney } from 'v-money'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export const vmoney = {

  directives: { money: VMoney },

  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false, /* doesn't work with directive */
      },
    }
  },
}

export const togglePasswordVisibility = {
  data() {
    return {
      passwordFieldType: 'password',
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
  },
}

export const fetchTableAfterDelete = {
  computed: {
    ...mapGetters({ getItensToDelete: 'app/getItensDelete' }),
  },

  methods: {
    ...mapActions({
      fetchLicensingDocuments: 'licensing/fetchLicensingDocuments',
      fetchLogbooks: 'logbook/fetchLogbooks',
      fetchLicensingConditionsConstraints: 'licensingConditions/fetchLicensingConditionsConstraints',
      fetchRecords: 'records/fetchRecords',
      fetchInspects: 'inspects/fetchInspects',
      fetchContacts: 'contacts/fetchContacts',
      fetchCompanies: 'company/fetchCompanies',
      fetchEnterprises: 'enterprise/fetchEnterprises',
      fetchLicensingAgencies: 'publicAgencies/fetchLicensingAgencies',
      fetchLincenseTypes: 'licenseType/fetchLincenseTypes',
      fetchProviders: 'provider/fetchProviders',
      fetchLicensing: 'licensing/fetchLicensing',
      fetchLicensingConditions: 'licensingConditions/fetchLicensingConditions',
      fetchWaste: 'waste/fetchWaste',
      fetchAccountable: 'accountable/fetchAccountable',
      fetchWasteOperation: 'waste/fetchWasteOperation',
      fetchWasteInventory: 'waste/fetchWasteInventory',
      fetchDocumentsCondintioningByLicenseTypeId: 'licenseType/fetchDocumentsCondintioningByLicenseTypeId',
      fetchUploads: 'upload/fetchUploads',
      fetchTickets: 'tickets/fetchTickets',
      fetchFeedbacks: 'feedback/fetchFeedbacks',
      fetchUsers: 'users/fetchUsers',
    }),
    fetchTable() {
      if (this.getItensToDelete.fetchTable === 'LicensingDocuments') {
        this.fetchLicensingDocuments(this.$route.params.id)
      } else if (this.getItensToDelete.fetchTable === 'Logbook') {
        this.fetchLogbooks()
      } else if (this.getItensToDelete.fetchTable === 'LicensingConditionsConstraints') {
        this.fetchLicensingConditionsConstraints(this.$route.params.id)
      } else if (this.getItensToDelete.fetchTable === 'Records') {
        this.fetchRecords()
      } else if (this.getItensToDelete.fetchTable === 'Company') {
        this.fetchCompanies()
      } else if (this.getItensToDelete.fetchTable === 'Enterprise') {
        this.fetchEnterprises()
      } else if (this.getItensToDelete.fetchTable === 'LicensingAgency') {
        this.fetchLicensingAgencies()
      } else if (this.getItensToDelete.fetchTable === 'LicenseType') {
        this.fetchLincenseTypes()
      } else if (this.getItensToDelete.fetchTable === 'Provider') {
        this.fetchProviders()
      } else if (this.getItensToDelete.fetchTable === 'Licensing') {
        this.fetchLicensing()
      } else if (this.getItensToDelete.fetchTable === 'LicenseCondiotions') {
        this.fetchLicensingConditions()
      } else if (this.getItensToDelete.fetchTable === 'Waste') {
        this.fetchWaste()
      } else if (this.getItensToDelete.fetchTable === 'Accountable') {
        this.fetchAccountable()
      } else if (this.getItensToDelete.fetchTable === 'WasteOperations') {
        this.fetchWasteOperation(this.$route.params.id)
      } else if (this.getItensToDelete.fetchTable === 'WasteInventory') {
        this.fetchWasteInventory(this.$route.params.id)
      } else if (this.getItensToDelete.fetchTable === 'Survey') {
        this.fetchInspects()
      } else if (this.getItensToDelete.fetchTable === 'Tickets') {
        this.fetchTickets()
      } else if (this.getItensToDelete.fetchTable === 'Feedback') {
        this.fetchFeedbacks()
      } else if (this.getItensToDelete.fetchTable === 'Upload') {
        this.fetchUploads()
      } else if (this.getItensToDelete.fetchTable === 'DocumentsConditioning') {
        this.fetchDocumentsCondintioningByLicenseTypeId(this.$route.params.id)
      } else if (this.getItensToDelete.fetchTable === 'Users') {
        this.fetchUsers()
      } else if (this.getItensToDelete.fetchTable === 'Contacts') {
        this.fetchContacts()
      } else { this.$router.go() }
    },
  },

}

export const tableConfig = {
  data() {
    return {
      allSelected: false,
      indeterminate: false,
      filter: null,
      selected: [],
      filterOn: ['cpf', 'full_name', 'cnpj', 'corporate_name'],
      filteredItens: [],
      perPage: 20,
      currentPage: 1,
      rowsTable: 0,
    }
  },
  computed: {
    rows: {
      get() {
        return this.rowsTable > 0 ? this.rowsTable : this.items.length
      },
      set(newValue) {
        this.rowsTable = newValue
      },
    },
  },
  created() {
    this.filterFields(this.fields)
    this.defineRowsLength(this.items.length)
  },
  mounted() {
    this.$root.$on('mark-off', () => {
      this.selected = []
      this.allSelected = false
    })
  },
  watch: {
    selected(newValue) {
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.filteredItens.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    },
  },
  methods: {
    ...mapMutations({
      setItensToDelete: 'app/SET_ITENS_DELETE',
    }),
    deleteItem(row) {
      this.itemToDelete.item = row
      this.showModal('DeleteOneItem')
    },
    onFiltered(filteredItems) {
      this.filteredItens = filteredItems
      this.rows = filteredItems.length
      this.currentPage = 1
    },
    defineRowsLength(value) {
      this.rows = value
    },
    filterFields(fields) {
      this.filterOn.push(...fields.map(arr => arr.key))
    },
    toggleAll(api, fetchTable = '') {
      if (this.filteredItens.length === 0) {
        this.filteredItens = this.items
        this.allSelected = true
      }
      if (fetchTable === 'LicensingConditionsConstraintsRecurrence') {
        this.filteredItens = this.recurrence
        this.allSelected = true
      }
      if (this.filteredItens === this.selected) {
        this.selected = []
        this.allSelected = false
      } else {
        this.selected = this.filteredItens
        this.allSelected = true
      }
      this.setItensToDelete({ itens: this.selected, api, fetchTable })
    },
  },
}

export const maskPhone = {
  methods: {
    maskTelephone(telephone) {
      let mask = '(##) ####-#####'
      if (telephone) {
        if (telephone.length > 14) {
          mask = '(##) #####-####'
        } else {
          mask = '(##) ####-#####'
        }
      }
      return mask
    },

  },
  // computed: {

  // },
}

export const maskTelephone = telephone => {
  let mask = ''
  if (telephone) {
    mask = '(##) ####-#####'
    if (telephone.length > 14) {
      mask = '(##) #####-####'
    } else {
      mask = '(##) ####-#####'
    }
  } return mask
}

export const _ = null
