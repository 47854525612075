export default [
  {
    path: '/tipos-licenca',
    name: 'license-type',
    component: () => import(/* webpackChunkName: "license-types" */ '@/views/license-type/LicenseType.vue'),
    meta: {
      title: 'Tipos de Licenças',
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'licensetype',
    },
  },
  {
    path: '/tipos-licenca/:id/detalhes',
    name: 'license-type-details',
    component: () => import(/* webpackChunkName: "license-types-details" */ '@/views/license-type/LicenseTypeDetails.vue'),
    meta: {
      title: 'Tipos de Licenças',
      subTitle: true,
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'licensetype',
    },
  },
]
