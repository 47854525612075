var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.properties.action === 'create')?_c('h2',[_vm._v(" Adicionar pareceres e vistorias ")]):_vm._e(),(_vm.properties.action === 'read')?_c('h2',[_vm._v(" Detalhes pareceres e vistorias ")]):_vm._e(),(_vm.properties.action === 'edit')?_c('h2',[_vm._v(" Editar pareceres e vistorias ")]):_vm._e(),_c('validation-observer',{ref:"form"},[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"número","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Número"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"numero","type":"text","disabled":_vm.disbleInputs},model:{value:(_vm.item.number),callback:function ($$v) {_vm.$set(_vm.item, "number", $$v)},expression:"item.number"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"título","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Título"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"titulo","type":"text","disabled":_vm.disbleInputs},model:{value:(_vm.item.title),callback:function ($$v) {_vm.$set(_vm.item, "title", $$v)},expression:"item.title"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Data de Emissão"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"dataEmissao","type":"date","disabled":_vm.disbleInputs},model:{value:(_vm.item.issue_date),callback:function ($$v) {_vm.$set(_vm.item, "issue_date", $$v)},expression:"item.issue_date"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"órgão","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Órgão"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-select',{attrs:{"id":"orgao","disabled":_vm.disbleInputs,"options":_vm.licensingAgencyOptions},model:{value:(_vm.item.licensingAgency_id),callback:function ($$v) {_vm.$set(_vm.item, "licensingAgency_id", $$v)},expression:"item.licensingAgency_id"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('VSIcons',{attrs:{"name":"SearchIcon","color":"#A0AEB9"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"departamento","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Departamento"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"department","disabled":_vm.disbleInputs,"type":"text"},model:{value:(_vm.item.department),callback:function ($$v) {_vm.$set(_vm.item, "department", $$v)},expression:"item.department"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"técnico","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Técnico"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"tecnico","type":"text","disabled":_vm.disbleInputs},model:{value:(_vm.item.technician),callback:function ($$v) {_vm.$set(_vm.item, "technician", $$v)},expression:"item.technician"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"objeto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Objeto"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"objeto","disabled":_vm.disbleInputs,"type":"text"},model:{value:(_vm.item.object),callback:function ($$v) {_vm.$set(_vm.item, "object", $$v)},expression:"item.object"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Observações"}},[_c('b-input-group',[_c('b-form-textarea',{attrs:{"id":"observacoes","type":"text","disabled":_vm.disbleInputs},model:{value:(_vm.item.note),callback:function ($$v) {_vm.$set(_vm.item, "note", $$v)},expression:"item.note"}})],1)],1)],1)],1),(_vm.properties.action === 'create')?_c('div',[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"nome do arquivo","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome do Arquivo"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"nomeArquivo","type":"text","disabled":_vm.disbleInputs},model:{value:(_vm.item.file_name),callback:function ($$v) {_vm.$set(_vm.item, "file_name", $$v)},expression:"item.file_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1646030121)})],1),_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{attrs:{"label":"Descrição do Arquivo"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"descricaoArquivo","type":"text","disabled":_vm.disbleInputs},model:{value:(_vm.item.file_description),callback:function ($$v) {_vm.$set(_vm.item, "file_description", $$v)},expression:"item.file_description"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Anexar arquivo "}},[_c('b-form-file',{attrs:{"placeholder":"Selecione um arquivo ou solte aqui...","drop-placeholder":"Solte o arquivo aqui...","accept":".jpg, .png, .docx, .jpeg, .pdf, .xsls"},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),(_vm.files)?_c('b-badge',{staticClass:"mt-3",attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.files ? _vm.files.name : null)+" "),_c('b-button',{staticClass:"bg-transparent border-0 size-10",attrs:{"variant":""},on:{"click":function($event){return _vm.cleanFiles()}}},[_c('span',[_vm._v(" x ")])])],1):_vm._e()],1)],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"d-flex justify-content-between"},[_c('BButton',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.hideModal('AddSurveys')}}},[_vm._v(" Cancelar ")]),(!_vm.disbleInputs)?_c('BButton',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.saveChanges()}}},[_vm._v(" Salvar ")]):_c('BButton',{attrs:{"variant":"primary"},on:{"click":function () {
        _vm.properties.action = 'edit'
        _vm.disbleInputs = false
      }}},[_vm._v(" Editar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }