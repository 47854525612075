import Vue from 'vue'
import Vuex from 'vuex'

// modules
import app from './app'
import auth from './auth'
import company from './company'
import enterprise from './enterprise'
import publicAgencies from './public-agencies'
import licenseType from './license-type'
import waste from './waste'
import sector from './sector'
import feedback from './feedback'
import tickets from './tickets'
import licensing from './licensing'
import licensingConditions from './licensing-conditions'
import provider from './provider'
import contacts from './contacts'
import logbook from './logbook'
import records from './records'
import inspects from './inspects'
import accountable from './accountable'
import annotations from './annotations'
import upload from './upload'
import users from './users'
import accounts from './accounts'
import announcements from './announcements'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    company,
    enterprise,
    publicAgencies,
    licenseType,
    waste,
    sector,
    feedback,
    tickets,
    licensing,
    licensingConditions,
    provider,
    contacts,
    logbook,
    records,
    inspects,
    accountable,
    annotations,
    upload,
    users,
    accounts,
    announcements,
  },
})
