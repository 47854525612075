export default [
  {
    path: '/residuos',
    name: 'waste',
    component: () => import(/* webpackChunkName: "waste" */ '@/views/waste/Waste.vue'),
    meta: {
      title: 'Resíduos',
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'waste',
    },
  },
  {
    path: '/residuos/:id/detalhes',
    name: 'waste-details',
    component: () => import(/* webpackChunkName: "waste-details" */ '@/views/waste/WasteDetails.vue'),
    meta: {
      title: 'Resíduos',
      subTitle: true,
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'waste',
    },
  },
]
