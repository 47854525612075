export default [
  {
    path: '/orgaos-publicos',
    name: 'public-agencies',
    component: () => import(/* webpackChunkName: "public-agencies" */ '@/views/public-agencies/PublicAgencies.vue'),
    meta: {
      title: 'Órgãos Licenciadores',
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'licensingagency',
    },
  },
  {
    path: '/orgaos-publicos/:id/detalhes',
    name: 'public-agencies-details',
    component: () => import(/* webpackChunkName: "public-agencies-details" */ '@/views/public-agencies/PublicAgenciesDetails.vue'),
    meta: {
      title: 'Órgãos Licenciadores',
      subTitle: true,
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'licensingagency',
    },
  },
]
