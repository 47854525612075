/* eslint-disable */
import { createToastInterface } from 'vue-toastification'
import jwtDefaultConfig from './jwtDefaultConfig'
import store from '@/store/index'
// eslint-disable-next-line import/no-cycle
import router from '@/router/index'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import api from '@/libs/axios.js'

export default class JwtService {
  axiosIns = null

  jwtConfig = { ...jwtDefaultConfig }

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        if (config.url !== '/announcements/my-announcements') {
          store.commit('app/SET_API_IN_CALL', true)
        }
        const accessToken = this.getToken()
        const database = this.getDatabase()
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          // eslint-disable-next-line no-param-reassign
          config.headers.dbname = `${database}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    this.axiosIns.interceptors.response.use(
      response => {
        setTimeout(() => {
          store.commit('app/SET_API_IN_CALL', false)
        }, 500)
        return response
      },
      error => {
        setTimeout(() => {
          store.commit('app/SET_API_IN_CALL', false)
        }, 500)
        const { response } = error
        const toastOptions = {
          timeout: 4000,
          icon: false,
          newestOnTop: true,
          closeButton: false,
          toastClassName: 'bg-white',
        }
        const toast = createToastInterface(toastOptions)
        if (response && response.status === 400) {
          toast.error({
            component: ToastificationContent,
            props: {
              colorIcon: '#FD3258',
              icon: 'CloseCircle',
              title: 'Erro',
              text: response.data.message || 'Verifique se todos os dados estão corretos e tente novamente.',
              variant: 'warning',
            },
          })
        }
        if (response.data.statusCode === 402 && response.status === 402) {
          toast.error({
            component: ToastificationContent,
            props: {
              colorIcon: '#FD3258',
              icon: 'CloseCircle',
              title: 'Limite Atingido',
              text: response.data.message,
              variant: 'warning',
            },
          })
        }
        if (response && response.status === 401 && router.history.current.fullPath !== '/auth') {
          this.logout()
          toast.error({
            component: ToastificationContent,
            props: {
              colorIcon: '#FD3258',
              icon: 'CloseCircle',
              title: 'Erro',
              text: 'Sua sessão expirou, faça login novamente para continuar usando o VerdeSys.',
              variant: 'warning',
            },
          })
          router.push({ name: 'auth' })
        }
        if (response && response.status === 404) {
          // tratativa de erro 404
        }
        if (response && response.status === 409) {
          toast.error({
            component: ToastificationContent,
            props: {
              colorIcon: '#FD3258',
              icon: 'CloseCircle',
              title: 'Erro',
              text: response.data.message,
              variant: 'warning',
            },
          })
        }
        if (response && response.status === 500) {
          toast.error({
            component: ToastificationContent,
            props: {
              colorIcon: '#FD3258',
              icon: 'CloseCircle',
              title: 'Erro 500',
              text: 'Erro no sistema. Entre em contato com o suporte.',
              variant: 'warning',
            },
          })
        }
        return Promise.reject(error)
      },
    )
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getTokenRecovery() {
    return localStorage.getItem(this.jwtConfig.storageRecoveryTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setDatabase(value) {
    localStorage.setItem(this.jwtConfig.storageDatabaseKeyname, value)
  }

  setIdMyAccount(value) {
    localStorage.setItem(this.jwtConfig.setIdMyAccountKeyName, value)
    this.setUserInfo( value )
  }
  setUserInfo( value ) {
    const role = JSON.parse(localStorage.getItem('user'))
    const id = JSON.parse(localStorage.getItem('id_account_verdesys'))


    if(role.role != "Master" && id != 25) {
      console.log('entrou')
      api.get('/accounts/client-subscription/' + value)
      .then(res => {
        console.log(res.data) 
        localStorage.setItem('user_infos', JSON.stringify(res.data))
      })
      .catch(err => {
        console.log(err)
      })
    }
  }
  

  getIdMyAccount() {
    return localStorage.getItem(this.jwtConfig.setIdMyAccountKeyName)
  }

  getDatabase() {
    return localStorage.getItem(this.jwtConfig.storageDatabaseKeyname)
  }

  getUserData() {
    return this.axiosIns.get(this.jwtConfig.userDataEndpoint)

    

  }

  setUserDataLocalStorage(value) {
    localStorage.setItem(this.jwtConfig.storageUserDataKeyName, JSON.stringify(value))
  }

  setUserData(value) {
    localStorage.setItem(this.jwtConfig.storageUserDataKeyName, JSON.stringify(value))
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  redefinePassword(value) {
    return this.axiosIns.post(this.jwtConfig.recoverPasswordEndpoint, { email: value })
  }

  sendRecoveryCode(...args) {
    return this.axiosIns.post(this.jwtConfig.sendRecoveryCodeEndpoint, ...args)
  }

  setRecoveryToken(value) {
    localStorage.setItem(this.jwtConfig.storageRecoveryTokenKeyName, value)
  }

  setNewPasswordRecovery(user) {
    return this.axiosIns.patch(`/system/users/${user.id}`, user, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(this.jwtConfig.storageRecoveryTokenKeyName)}`,
        dbname: `${localStorage.getItem(this.jwtConfig.storageDatabaseKeyname)}`,
      },
    })
  }

  logout() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageUserDataKeyName)
    localStorage.removeItem(this.jwtConfig.storageDatabaseKeyname)
    localStorage.removeItem(this.jwtConfig.storageRecoveryTokenKeyName)
    localStorage.removeItem(this.jwtConfig.setIdMyAccountKeyName)
    // localStorage.removeItem('mode-license')
    // localStorage.removeItem('mode-licensing')
  }

  decodeToken() {
    const base64Url = this.getToken().split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = JSON.parse(decodeURIComponent(atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join('')))
    return jsonPayload
  }
}
