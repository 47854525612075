import Vue from 'vue'
import Toast from 'vue-toastification'
import Close from '@/assets/icons/icons-components/close.vue'

import '@/assets/scss/libs/toastification.scss'

const options = {
  toastClassName: [
    'bg-white',
    'shadow',
    'w-25',
  ],
  closeButton: Close,
  timeout: 4000,
  icon: false,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(
      t => t.type === toast.type,
    ).length !== 0) {
      // Returning false discards the toast
      return false
    }
    // You can modify the toast if you want
    return toast
  },
}

Vue.use(Toast, options)
