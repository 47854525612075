<template>
  <div class="">
    <h2>Renovação do Plano Trial</h2>
    <div class="d-flex justify-content-between mt-4">
      <b-row>
        <b-col md="6">
          <b-form-group label="Plano de renovação">
            <b-input-group>
              <b-form-select
                id="plano"
                v-model="planChange.plan_identifier"
                :options="trialPlansToSelect"
                @change="onChange()"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Data de vencimento renovada para"
          >
            <div class="signatureValid">
              <p id="vencimento">
                {{ planChange.signature_valid_until }}
              </p>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div class="d-flex justify-content-between mt-4">
      <b-row>
        <b-col md="6">
          <BButton
            variant="outline-primary"
            class="button"
            @click="hideModal('RenewPlanTrial')"
          >
            Cancelar
          </BButton>
        </b-col>
        <b-col md="6">
          <BButton
            variant="primary"
            class="button"
            @click="sendRenewPlan"
          >
            Enviar
          </BButton>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BFormGroup, BInputGroup, BButton, BFormSelect, BRow, BCol,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import accounts from '@/services/accounts'
import api from '@/libs/axios'

export default {
  components: {
    BFormGroup,
    BInputGroup,
    BButton,
    BFormSelect,
    BRow,
    BCol,
  },
  data() {
    return {
      trialPlansToSelect: [{ value: '', text: '' }],
      //pega o id de dentro do user_infos no localstorage em json
      subs_iugu_id:  '',
      planChange: {
        plan_id: 0,
        plan_identifier: '',
        isActive: true,
        signature_valid_until: '',
        status: 'Adimplente',
      },
    }
  },
  computed: { ...mapGetters({ accountsData: 'accounts/getAccountsData' }) },
  created() {
    this.getTrialPlans()
    this.fetchAccount()
  },
  methods: {
    
    fetchAccount() {
      accounts.getAccountById(this.$route.params.id).then()
    },
    async getTrialPlans() {
      const { data } = await api.get('/plan/trial-plans')
      data.forEach(plans => (

        this.trialPlansToSelect.push({ value: plans.plan_identifier, text: plans.plan_name })

      ))
    },
    onChange() {
      switch (this.planChange.plan_identifier) {
        case 'teste_trial_7dias': {
          const dateNowSeven = new Date().getTime()
          const updatedDateSeven = new Date(dateNowSeven + 7 * 24 * 60 * 60 * 1000)
          this.planChange.signature_valid_until = moment(updatedDateSeven).format('YYYY-MM-DD')
          this.planChange.plan_id = 4
          break
        }
        case 'teste_trial_15dias': {
          const dateNowFifteen = new Date().getTime()
          const updatedDateFifteen = new Date(dateNowFifteen + 15 * 24 * 60 * 60 * 1000)
          this.planChange.signature_valid_until = moment(updatedDateFifteen).format('YYYY-MM-DD')
          this.planChange.plan_id = 5
          break
        }
        case 'teste_trial_30dias': {
          const dateNowThirty = new Date().getTime()
          const updatedDateThirty = new Date(dateNowThirty + 30 * 24 * 60 * 60 * 1000)
          this.planChange.signature_valid_until = moment(updatedDateThirty).format('YYYY-MM-DD')
          this.planChange.plan_id = 6
          break
        }
        case 'teste_trial_60dias': {
          const dateNowSixty = new Date().getTime()
          const updatedDateSixty = new Date(dateNowSixty + 60 * 24 * 60 * 60 * 1000)
          this.planChange.signature_valid_until = moment(updatedDateSixty).format('YYYY-MM-DD')
          this.planChange.plan_id = 7
          break
        }
        default:

          break
      }
    },
    sendRenewPlan() {

      accounts.editRenewalPlan(this.planChange, this.subs_iugu_id, this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.toast({
            title: 'Atualizado com sucesso',
            text: 'Plano trial foi atualizado com sucesso!',
            variant: 'success',
          })

          if (!this.$route.name === 'management-verdesys') {
            this.$router.push('/gestao-verdesys')
          }
          this.hideModal('RenewPlanTrial')
        }
      })
    },

    fetchAccounts() {
      api.get('/accounts/' + this.$route.params.id).then(res => {
        console.log('aquiiiiiiiiiii',res)
        this.subs_iugu_id = res.data.iugu_subscription_id
      })
    },

  },
  mounted() {
    this.fetchAccounts()
    
    console.log("ID DA IUGU",this.subs_iugu_id)
  },
}
</script>

<style lang='scss' scoped>
.modal-content {
  box-shadow: none !important;
}

h2 {
  font-size: 21px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.75px;
  color: #33383C;
}

p {
  font-weight: 500;
  color: #7D8E9E;
  font-size: 13px;
  line-height: 100%;
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.75px;
}

button {
  width: 190px;
}

.signatureValid {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 100%;
  border: 2px solid #E0E0E0;
  border-radius: 4px;
  background: #FFFFFF;
}

#vencimento {
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  letter-spacing: 0.75px;
  color: #7D8E9E;
  margin-bottom: 0!important;
}

</style>
