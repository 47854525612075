<script>
/* eslint-disable */
</script>
<template>
  <div>
    <div
      v-if="$can('read', navItem.resource)"
      class="navigation-item"
    >
      <router-link
        id="navigation-link"
        class="nav-item d-flex align-items-center text-decoration-none"
        :to="ckeckPermissionPlanActive()"
      >
        <VSIcons
          :name="navItem.icon"
          :color="colorIcon"
          class="nav-item-icon"
        />
        <span
          class="ml-2 text-nowrap"
          :class="`${collapsed ? 'nav-item-title' : 'nav-item-title-opened'}`"
        >{{ navItem.title }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VSIcons from '@/assets/icons/VSIcons.vue'
import api from '@/libs/axios'

export default {
  components: {
    VSIcons,
  },
  props: {
    navItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      colorIcon: '#7D8E9E',
      activeLink: false,
      isActive: null,
    }
  },
  computed: {
    ...mapGetters({ collapsed: 'app/getSidebarCollapsed', getUserData: 'auth/getUserData' }),
    // colorIcon() {
    //   return this.activeLink ? '#fff' : '#7D8E9E'
    // },
  },
  mounted() {
    const doc = document.getElementById('navigation-link').classList.contains('router-link-active')
    this.activeLink = doc

    const userIdAccount = JSON.parse(localStorage.getItem('id_account_verdesys'));
    api.get(`/accounts/${userIdAccount}`).then((response) => {
        this.isActive = response.data.isActive;
    });
  },
  methods: {
    ckeckPermissionPlanActive() {
      return this.isActive ? this.navItem.route : 'nao-autorizado'
    },
  },
}
</script>
