import api from '@/libs/axios'

export default {
  getLicensing: () => api.get('/system/licensing/general'),
  getLicensingById: id => api.get(`/system/licensing/general/${id}`),
  editLicensing: form => api.patch(`/system/licensing/general/${form.id}`, form),
  createLicensing: form => api.post('/system/licensing/general', form),
  deleteLicensing: array => api.delete('/system/licensing/general', array),

  getLicensingDocuments: () => api.get('/system/licensing/docs'),
  createLicensingDocuments: form => api.post('/system/licensing/docs', form),
  editLicensingDocuments: form => api.patch(`/system/licensing/docs/${form.id}`, form),

  getLicensingReports: (date, query) => api.get(`/system/licensing/general/date/${date}${query}`),
  getLicensingManagementReports: query => api.get(`/system/licensing/general/report/get${query}`),

  getLicensingDashboardPerYear: year => api.get(`/system/licensing/general/year/${year}?version=v2`),
  getLicensingDashboardPerMonth: obj => api.get(`/system/licensing/general/month/${obj.month}/year/${obj.year}`),
  getLicensingDashboardTable: () => api.get('/system/licensing/general/dashboard/search'),
}
