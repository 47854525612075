/* eslint-disable */
import api from '@/libs/axios'

export default {
  // dados da conta
  getAccounts: () => api.get('/accounts'),
  getAccountById: id => api.get(`/accounts/${id}`),
  editAccount: form => api.patch(`/accounts/${form.id}`, form),

  // dados de pagamento
  getAccountsPayment: () => api.get('/payment'),
  getAccountsPaymentByAccountId: id => api.get(`/payment/${id}/my-payments`),
  getPaymentsDashboardMaster: () => api.get('/payment/dashboard/all'),

  // dados de planos
  getAccountsPlanByAccountId: id => api.get(`/accounts/${id}/my-plan`),
  postCancelPlan: () => api.post('/accounts/cancel-account'),
  postCancelPlanMaster: id => api.put(`/accounts/cancel/${id}`),
  editRenewalPlan: (form, subs_iugu_id, id) => api.patch(`/accounts/trial-plan-renewaln/${subs_iugu_id}/ ${id}`, form),

  getAccountInfo: id => api.get(`/accounts/${id}/account-info`),
  getAccountInfoDashboard: () => api.get('/accounts-info/dashboard'),

  getAccountDashboardMaster: () => api.get('/accounts/dashboard/all'),

}
