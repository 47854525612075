<template>
  <div>
    <h2 v-if="properties.action === 'create'">
      Adicionar diário de bordo
    </h2>
    <h2 v-if="properties.action === 'read'">
      Detalhes diário de bordo
    </h2>
    <h2 v-if="properties.action === 'edit'">
      Editar diário de bordo
    </h2>
    <validation-observer ref="form">
      <b-row>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="data de início"
            rules="required"
          >
            <b-form-group
              label="Data"
              class="mr-3"
            >
              <b-input-group>
                <b-form-input
                  id="data"
                  v-model="item.date"
                  type="date"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

        </b-col>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="título"
            rules="required|max:100"
          >
            <b-form-group label="Título">
              <b-input-group>
                <b-form-input
                  v-model="item.title"
                  class="w-100"
                  :disabled="disbleInputs"
                  type="text"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="tipo de contato"
            rules="required"
          >
            <b-form-group label="Tipo de contato">
              <b-input-group>
                <b-form-select
                  id="marcardor"
                  v-model="item.contact_type"
                  :options="contactType"
                  type="text"
                  class="mr-3"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <validation-provider
        v-slot="{ errors }"
        name="descrição"
        rules="required|max:250"
      >
        <b-form-group label="Descrição">
          <b-input-group>
            <b-form-textarea
              v-model="item.description"
              :disabled="disbleInputs"
              type="text"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
      <div class="grid-2">
        <!-- <b-form-group label="Deseja receber um alerta?">
          <b-input-group>
            <b-form-select
              v-model="item.alert"
              class="w-100"
              :disabled="disbleInputs"
              :options="optionsBoolean"
            />
          </b-input-group>
        </b-form-group> -->
      </div>
      <div v-if="properties.action === 'create'">
        <div class="grid-2">
          <b-form-group
            label="Nome do arquivo"
            class="mr-3"
          >
            <b-input-group>
              <b-form-input
                v-model="item.file_name"
                :disabled="disbleInputs"
                type="text"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group label="Descrição do arquivo">
            <b-input-group>
              <b-form-input
                v-model="item.file_description"
                class="w-100"
                :disabled="disbleInputs"
                type="text"
              />
            </b-input-group>
          </b-form-group>
        </div>
        <b-form-group
          label="Anexar arquivo "
        >
          <b-form-file
            v-model="files"
            placeholder="Selecione um arquivo ou solte aqui..."
            drop-placeholder="Solte o arquivo aqui..."
            accept=".jpg, .png, .docx, .jpeg, .pdf, .xsls"
          />
          <b-badge
            v-if="files"
            variant="primary"
            class="mt-3"
          >
            {{ files ? files.name : null }}
            <b-button
              variant=""
              class="bg-transparent border-0 size-10"
              @click="cleanFiles()"
            >
              <span>
                x
              </span>
            </b-button>
          </b-badge>
        </b-form-group>
      </div>
    </validation-observer>
    <div class="d-flex justify-content-between">
      <BButton
        variant="outline-primary"
        @click="hideModal('AddLogbook')"
      >
        Cancelar
      </BButton>
      <BButton
        v-if="!disbleInputs"
        variant="primary"
        @click="saveChanges()"
      >
        Salvar
      </BButton>
      <BButton
        v-else
        variant="primary"
        @click="() => {
          properties.action = 'edit'
          disbleInputs = false
        }"
      >
        Editar
      </BButton>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup, BInputGroup, BFormInput, BFormTextarea, BFormSelect, BFormFile, BBadge, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import logbook from '@/services/logbook'
import upload from '@/services/upload'

export default {
  components: {
    BFormGroup, BInputGroup, BFormInput, BFormTextarea, BFormSelect, BFormFile, BBadge, BButton, BRow, BCol,
  },
  props: {
    properties: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contactType: ['Ligação', 'E-mail', 'Reunião', 'Visita', 'Outros'],
      selectedContactType: 'Ligação',
      files: null,
      disbleInputs: false,
      item: {},
    }
  },
  computed: { ...mapGetters({ optionsBoolean: 'app/getBooleanOptions' }) },
  created() {
    if (this.properties.action === 'read' || this.properties.action === 'edit') {
      this.item = this.properties.item
      if (this.item.file_name) this.item.file_name = this.properties.item.attachment[0].file_name
      if (this.item.file_description) this.item.file_description = this.properties.item.attachment[0].file_name
    }
    if (this.properties.action === 'read') { this.disbleInputs = true }
  },
  methods: {
    ...mapActions({ fetchLogbooks: 'logbook/fetchLogbooks', fetchUploads: 'upload/fetchUploads' }),
    saveChanges() {
      this.$refs.form.validate().then(success => {
        if (success) {
          if (this.properties.module) {
            this.item.licensingCondition_id = Number(this.$route.params.id)
          } else {
            this.item.licensing_id = Number(this.$route.params.id)
          }
          if (this.properties.action === 'create') {
            logbook.createLogbook(this.item).then(res => {
              if (this.files) {
                this.createUpload(res.data.id)
              }
              this.createdMessage()
              this.fetchLogbooks()
            })
          } else if (this.properties.action === 'edit') {
            delete this.item.attachment
            logbook.editLogbook(this.item).then(() => {
              this.updateMessage()
              this.fetchLogbooks()
            })
          }
          this.hideModal('AddLogbook')
          this.fetchLogbooks()
        }
      })
    },
    createUpload(id) {
      const formatData = new FormData()
      formatData.append('attachment_file', this.files)
      formatData.append('file_name', this.item.file_name)
      formatData.append('file_description', this.item.file_description)
      formatData.append('logbook_id', id)
      if (this.properties.module) {
        formatData.append('licensingCondition_id', this.$route.params.id)
      } else formatData.append('licensing_id', this.$route.params.id)

      const formUpload = formatData
      upload.createUploadFile(formUpload).then(() => {
        this.fetchUploads()
      })
    },
    createdMessage() {
      this.toast({
        title: 'Criado !',
        text: 'Cadastro realizado com sucesso.',
        variant: 'success',
      })
    },
    updateMessage() {
      this.toast({
        title: 'Editado !',
        text: 'Cadastro atualizado com sucesso.',
        variant: 'success',
      })
    },
    cleanFiles() {
      this.files = null
    },
  },
}
</script>

<style lang='scss' scoped>
h2 {
font-weight: 600;
font-size: 21px;
line-height: 25px;
text-align: center;
letter-spacing: 0.75px;
color: #33383C;
margin-bottom: 21px;
}
.grid-2 {
  display: grid;
  grid-template-columns: 267px calc(100% - 267px);
}
label {
  margin-bottom: 5px;
  display: block;
}
textarea {
  min-height: 82px;
}
.size-10 {
  padding: 0;
  margin: 0;
  width: 8px !important;
  height: 19px !important;
}
</style>
