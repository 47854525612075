export default [
  {
    title: 'Dashboard',
    route: { name: 'home' },
    icon: 'HomeTrendUp',
    resource: 'dashboard',
    action: 'read',
  },
  {
    title: 'Empresa',
    route: { name: 'company' },
    icon: 'Buildings',
    resource: 'company',
    action: 'read',
  },
  {
    title: 'Empreendimento',
    resource: 'project',
    route: { name: 'enterprise' },
    action: 'read',
    icon: 'Briefcase',
  },
  {
    title: 'Órgão licenciador',
    route: { name: 'public-agencies' },
    icon: 'ShieldTick',
    resource: 'licensingagency',
    action: 'read',
  },
  {
    title: 'Tipos de licença',
    resource: 'licensetype',
    action: 'read',
    route: { name: 'license-type' },
    icon: 'TaskSquare',
  },
  {
    title: 'Fornecedor',
    resource: 'supplier',
    action: 'read',
    route: { name: 'provider' },
    icon: 'ThreeDCube',
  },
  {
    title: 'Licenciamento',
    resource: 'licensing',
    action: 'read',
    route: { name: 'licensing' },
    icon: 'ReceiptEdit',
  },
  {
    title: 'Licença e Cond',
    resource: 'licensesconditions',
    action: 'read',
    route: { name: 'license' },
    icon: 'Judge',
  },
  {
    title: 'Resíduos',
    resource: 'waste',
    action: 'read',
    route: { name: 'waste' },
    icon: 'Recycle',
  },
  {
    title: 'Relatórios',
    resource: 'reports',
    action: 'read',
    route: { name: 'reports' },
    icon: 'DocumentText',
  },
]
