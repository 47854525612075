<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.9048 3.05182C15.9401 1.08486 13.3273 0.00115356 10.5438 0C4.80798 0 0.139886 4.66796 0.137579 10.4053C0.13681 12.2393 0.615924 14.0297 1.5266 15.6077L0.050293 21L5.56677 19.5529C7.08678 20.3821 8.79803 20.819 10.5395 20.8195H10.5439C16.279 20.8195 20.9476 16.1512 20.9498 10.4136C20.951 7.63301 19.8696 5.01865 17.9048 3.05182ZM10.5438 19.0621H10.5402C8.98824 19.0615 7.46617 18.6444 6.13804 17.8565L5.82234 17.669L2.54879 18.5278L3.42255 15.3361L3.21683 15.0089C2.35101 13.6318 1.89382 12.0401 1.89459 10.4059C1.89638 5.63721 5.77646 1.75752 10.5472 1.75752C12.8574 1.75829 15.0291 2.65909 16.662 4.29395C18.2949 5.92881 19.1937 8.10187 19.1929 10.413C19.1909 15.1821 15.3111 19.0621 10.5438 19.0621ZM15.288 12.5844C15.028 12.4541 13.7496 11.8253 13.5112 11.7384C13.2731 11.6516 13.0995 11.6084 12.9264 11.8686C12.753 12.1288 12.2547 12.7146 12.103 12.888C11.9512 13.0616 11.7997 13.0833 11.5397 12.9531C11.2796 12.823 10.4419 12.5483 9.44864 11.6625C8.67575 10.9731 8.15395 10.1216 8.0022 9.86144C7.8507 9.60099 8.00092 9.47384 8.11627 9.3308C8.39774 8.98127 8.6796 8.61482 8.76624 8.4414C8.85301 8.26785 8.80956 8.11597 8.74445 7.98587C8.6796 7.85577 8.15959 6.57596 7.94298 6.05519C7.73175 5.54839 7.51757 5.61683 7.35787 5.60889C7.20637 5.60132 7.03295 5.59979 6.85953 5.59979C6.68624 5.59979 6.40451 5.66477 6.16611 5.92522C5.92783 6.18554 5.2562 6.81449 5.2562 8.09431C5.2562 9.37412 6.1879 10.6105 6.31786 10.784C6.44783 10.9576 8.15139 13.5839 10.7596 14.71C11.38 14.9781 11.8642 15.138 12.2419 15.2578C12.8649 15.4557 13.4315 15.4278 13.8796 15.3609C14.3792 15.2861 15.4178 14.7318 15.6347 14.1245C15.8513 13.5171 15.8513 12.9966 15.7862 12.888C15.7213 12.7796 15.5479 12.7146 15.288 12.5844Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
}
</script>
