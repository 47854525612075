export default [
  {
    path: '/minha-conta',
    name: 'my-account',
    component: () => import(/* webpackChunkName: "my-account" */ '@/views/my-account/MyAccount.vue'),
    meta: {
      title: 'Minha Conta',
      subTitle: true,
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'dashboard',
      canAccessUserPlanDisabled: true,
    },
  },
]
