<template>
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M14.1663 2.08325H6.83381C3.64881 2.08325 1.75006 3.982 1.75006 7.167V14.4908C1.75006 17.6845 3.64881 19.5833 6.83381 19.5833H14.1576C17.3426 19.5833 19.2413 17.6845 19.2413 14.4995V7.167C19.2501 3.982 17.3513 2.08325 14.1663 2.08325Z"
      fill="#A0AEB9"
      fill-opacity="0.6"
    />
    <path
      d="M8 11.0002H13.5"
      stroke="#A0AEB9"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>
