import licensingAgency from '@/services/licensingAgency'

export default {
  namespaced: true,
  state: {
    licensingAgencyData: null,
    licensingAgencies: [],
    licensingAgenciesOptions: [{ text: 'Nenhuma das opções', value: null }],
  },
  getters: {
    getLicensingAgencyData: state => state.licensingAgencyData,
    getLicensingAgencies: state => state.licensingAgencies,
    getLicensingAgencyOptions: state => state.licensingAgenciesOptions,
  },
  mutations: {
    SET_LICENSING_AGENCY_DATA(state, payload) {
      state.licensingAgencyData = payload
    },
    SET_LICENSING_AGENCIES(state, payload) {
      state.licensingAgencies = payload
    },
  },
  actions: {
    fetchLicensingAgencies({ state, commit }) {
      licensingAgency.getLicensingAgencies().then(res => {
        commit('SET_LICENSING_AGENCIES', res.data.filter(arr => arr.isActive))
        state.licensingAgenciesOptions = [{ text: 'Nenhuma das opções', value: null }]
        state.licensingAgenciesOptions.push(...state.licensingAgencies.map(arr => ({ text: arr.acronyms, value: arr.id })).sort((a, b) => a.text.localeCompare(b.text)))
      })
    },
    fetchLicensingAgencyById({ commit }, id) {
      licensingAgency.getLicensingAgencyById(id).then(res => commit('SET_LICENSING_AGENCY_DATA', res.data))
    },
  },
}
