import companies from '@/services/companies'

export default {
  namespaced: true,
  state: {
    companyData: null,
    companies: [],
    companiesOptions: [{ text: 'Nenhuma das opções', value: null }],
  },
  getters: {
    getCompanyData: state => state.companyData,
    getCompanies: state => state.companies,
    getCompaniesOptions: state => state.companiesOptions,
  },
  mutations: {
    SET_COMPANY_DATA(state, payload) {
      state.companyData = payload
    },
    SET_COMPANIES(state, payload) {
      state.companies = payload
    },
  },
  actions: {
    fetchCompanies({ state, commit }) {
      companies.getCompanies().then(res => {
        commit('SET_COMPANIES', res.data.filter(arr => arr.isActive))
        state.companiesOptions = [{ text: 'Nenhuma das opções', value: null }]
        state.companiesOptions.push(...state.companies.map(arr => ({ text: arr.nome_fantasia || arr.full_name, value: arr.id })).sort((a, b) => a.text.localeCompare(b.text)))
      })
    },
    fetchCompanyById({ commit }, id) {
      companies.getCompanyById(id).then(res => commit('SET_COMPANY_DATA', res.data))
    },
  },
}
