import api from '@/libs/axios'

export default {
  getWaste: () => api.get('/system/waste/general'),
  getWasteById: id => api.get(`/system/waste/general/${id}`),
  editWaste: form => api.patch(`/system/waste/general/${form.id}`, form),
  createWaste: form => api.post('/system/waste/general', form),
  deleteWaste: array => api.delete('/system/waste/general', array),

  getWasteInventory: () => api.get('/system/waste/inventory'),
  getWasteInventoryById: id => api.get(`/system/waste/inventory/${id}`),
  editWasteInventory: form => api.patch(`/system/waste/inventory/${form.id}`, form),
  createWasteInventory: form => api.post('/system/waste/inventory', form),
  deleteWasteInventory: array => api.delete('/system/waste/inventory', array),

  getWasteOperations: () => api.get('/system/waste/operation'),
  getWasteOperationsById: id => api.get(`/system/waste/operation/${id}`),
  createWasteOperation: form => api.post('/system/waste/operation', form),
  editWasteOperation: form => api.patch(`/system/waste/operation/${form.id}`, form),
  deleteWasteOperations: array => api.delete('/system/waste/operation', array),

  getWasteReports: (date, query) => api.get(`/system/waste/general/date/${date}${query}`),
  getWasteDashboardPerMonth: obj => api.get(`/system/waste/operation/month/${obj.month}/year/${obj.year}`),
  getWasteReportsInventory: query => api.get(`/system/waste/inventory/report/get${query}`),
}
