export default {
  namespaced: true,
  state: {
    apiInCall: false,
    sidebarCollapsed: true,
    windowSize: window.innerWidth,
    windowHeight: window.innerHeight,
    itensDelete: { itens: [], api: '' },
    subTitle: '',
    itemTableDetail: {},
    itemTableEdit: false,
    ufOptions: [
      { value: null, text: 'Nenhuma das opções' },
      { text: 'Acre', value: 'AC' },
      { text: 'Alagoas', value: 'AL' },
      { text: 'Amapá', value: 'AP' },
      { text: 'Amazonas', value: 'AM' },
      { text: 'Bahia', value: 'BA' },
      { text: 'Ceará', value: 'CE' },
      { text: 'Espírito Santo', value: 'ES' },
      { text: 'Goiás', value: 'GO' },
      { text: 'Maranhão', value: 'MA' },
      { text: 'Mato Grosso', value: 'MT' },
      { text: 'Mato Grosso do Sul', value: 'MS' },
      { text: 'Minas Gerais', value: 'MG' },
      { text: 'Pará', value: 'PA' },
      { text: 'Paraíba', value: 'PB' },
      { text: 'Paraná', value: 'PR' },
      { text: 'Pernambuco', value: 'PE' },
      { text: 'Rio de Janeiro', value: 'RJ' },
      { text: 'Rio Grande do Norte', value: 'RN' },
      { text: 'Rio Grande do Sul', value: 'RS' },
      { text: 'Rondônia', value: 'RO' },
      { text: 'Roraima', value: 'RR' },
      { text: 'Santa Catarina', value: 'SC' },
      { text: 'São Paulo', value: 'SP' },
      { text: 'São Sergipe', value: 'SE' },
      { text: 'Tocantins', value: 'TO' },
      { text: 'Distrito Federal', value: 'DF' },
    ],
    deadlineOptions: [
      { value: null, text: 'Nenhuma das opções' }, { value: 160, text: '160 Dias' }, { value: 90, text: '90 Dias' }, { value: 30, text: '30 Dias' }, { value: 15, text: '15 Dias' },
    ],
    stepOptions: [
      { value: null, text: 'Nenhuma das opções' }, 'Instrução', 'Protocolo', 'Em análise', 'Concluído',
    ],
    processType: [
      { value: null, text: 'Nenhuma das opções' }, 'Obtenção', 'Renovação', 'Prorrogação', 'Averbação', 'Outros',
    ],
    statusOptions: [
      'Não iniciado', 'Em execução', 'Concluído', 'Cancelado',
    ],
    statusWasteOptions: [
      { value: null, text: 'Nenhuma das opções' }, 'Em execução', 'Concluído', 'Cancelado', 'Suspenso',
    ],
    optionsAgenciesState: [
      { value: 'INEA-RJ', text: 'INEA - RJ' },
    ],
    situationOptions: [
      'Vigente', 'Vencida', 'Renovação dentro do prazo', 'Renovação fora do prazo', 'Prorrogação dentro do prazo', 'Prorrogação fora do prazo',
    ],
    booleanOptions: [
      { value: null, text: 'Nenhuma das opções' }, { value: true, text: 'Sim' }, { value: false, text: 'Não' },
    ],
    recurrenceOptions: [
      { value: null, text: 'Nenhuma das opções' },
      { value: 'Mensal', text: 'Mensal' },
      { value: 'Bimestral', text: 'Bimestral' },
      { value: 'Trimestral', text: 'Trimestral' },
      { value: 'Semestral', text: 'Semestral' },
      { value: 'Anual', text: 'Anual' },
      { value: 'A cada 2 Anos', text: 'A cada 2 Anos' },
      { value: 'A cada 3 Anos', text: 'A cada 3 Anos' },
      { value: 'A cada 4 Anos', text: 'A cada 4 Anos' },
    ],
  },
  getters: {
    getApiInCall: state => state.apiInCall,
    getSidebarCollapsed: state => state.sidebarCollapsed,
    getWindowSize: state => state.windowSize,
    getWindowHeight: state => state.windowHeight,
    getItensDelete: state => state.itensDelete,
    getSubTitle: state => state.subTitle,
    getItemTableDetail: state => state.itemTableDetail,
    getItemTableEdit: state => state.itemTableEdit,
    getUfOptions: state => state.ufOptions,
    getDeadlineOptions: state => state.deadlineOptions,
    getStepOptions: state => state.stepOptions,
    getProcessTypeOptions: state => state.processType,
    getStatusOptions: state => state.statusOptions,
    getStatusWasteOptions: state => state.statusWasteOptions,
    getOptionsAgenciesState: state => state.optionsAgenciesState,
    getSituationOptions: state => state.situationOptions,
    getBooleanOptions: state => state.booleanOptions,
    recurrenceOptions: state => state.recurrenceOptions,
  },
  mutations: {
    SET_API_IN_CALL(state, payload) {
      state.apiInCall = payload
    },
    SET_SIDEBAR_COLLAPSED(state, payload) {
      state.sidebarCollapsed = payload
    },
    SET_SIZE_WINDOW(state, payload) {
      state.windowSize = payload
    },
    SET_HEIGHT_WINDOW(state, payload) {
      state.windowHeight = payload
    },
    SET_ITENS_DELETE(state, payload) {
      state.itensDelete = payload
    },
    SET_SUBTITLE(state, payload) {
      state.subTitle = payload
    },
    SET_ITEM_TABLE_DETAIL(state, payload) {
      state.itemTableDetail = payload
    },
    SET_ITEM_TABLE_EDIT(state, payload) {
      state.itemTableEdit = payload
    },
  },
  actions: {
    toggleSidebar({ commit }, payload) {
      commit('SET_SIDEBAR_COLLAPSED', payload)
    },
    setSizeWindow({ commit }) {
      window.addEventListener('resize', () => {
        commit('SET_SIZE_WINDOW', window.innerWidth)
      })
    },
    setHeightWindow({ commit }) {
      window.addEventListener('resize', () => {
        commit('SET_HEIGHT_WINDOW', window.innerHeight)
      })
    },
  },
}
