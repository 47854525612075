<template>
  <div>
    <h2 v-if="properties.action === 'create'">
      Adicionar pareceres e vistorias
    </h2>
    <h2 v-if="properties.action === 'read'">
      Detalhes pareceres e vistorias
    </h2>
    <h2 v-if="properties.action === 'edit'">
      Editar pareceres e vistorias
    </h2>
    <validation-observer ref="form">
      <b-row>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="número"
            rules="required"
          >
            <b-form-group label="Número">
              <b-input-group>
                <b-form-input
                  id="numero"
                  v-model="item.number"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="título"
            rules="required|max:100"
          >
            <b-form-group label="Título">
              <b-input-group>
                <b-form-input
                  id="titulo"
                  v-model="item.title"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <b-form-group label="Data de Emissão">
            <b-input-group>
              <b-form-input
                id="dataEmissao"
                v-model="item.issue_date"
                type="date"
                :disabled="disbleInputs"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="órgão"
            rules="required"
          >
            <b-form-group label="Órgão">
              <b-input-group class="input-group-merge">
                <b-form-select
                  id="orgao"
                  v-model="item.licensingAgency_id"
                  :disabled="disbleInputs"
                  :options="licensingAgencyOptions"
                />
                <b-input-group-append is-text>
                  <VSIcons
                    name="SearchIcon"
                    color="#A0AEB9"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="departamento"
            rules="max:50"
          >
            <b-form-group label="Departamento">
              <b-input-group>
                <b-form-input
                  id="department"
                  v-model="item.department"
                  :disabled="disbleInputs"
                  type="text"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="técnico"
            rules="max:100"
          >
            <b-form-group label="Técnico">
              <b-input-group>
                <b-form-input
                  id="tecnico"
                  v-model="item.technician"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="objeto"
            rules="required"
          >
            <b-form-group label="Objeto">
              <b-input-group>
                <b-form-input
                  id="objeto"
                  v-model="item.object"
                  :disabled="disbleInputs"
                  type="text"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Observações">
            <b-input-group>
              <b-form-textarea
                id="observacoes"
                v-model="item.note"
                type="text"
                :disabled="disbleInputs"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <div v-if="properties.action === 'create'">
        <b-row>
          <b-col>
            <validation-provider
              v-slot="{ errors }"
              name="nome do arquivo"
              rules="max:100"
            >
              <b-form-group label="Nome do Arquivo">
                <b-input-group>
                  <b-form-input
                    id="nomeArquivo"
                    v-model="item.file_name"
                    type="text"
                    :disabled="disbleInputs"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="8">
            <b-form-group label="Descrição do Arquivo">
              <b-input-group>
                <b-form-input
                  id="descricaoArquivo"
                  v-model="item.file_description"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Anexar arquivo "
            >
              <b-form-file
                v-model="files"
                placeholder="Selecione um arquivo ou solte aqui..."
                drop-placeholder="Solte o arquivo aqui..."
                accept=".jpg, .png, .docx, .jpeg, .pdf, .xsls"
              />
              <b-badge
                v-if="files"
                variant="primary"
                class="mt-3"
              >
                {{ files ? files.name : null }}
                <b-button
                  variant=""
                  class="bg-transparent border-0 size-10"
                  @click="cleanFiles()"
                >
                  <span>
                    x
                  </span>
                </b-button>
              </b-badge>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </validation-observer>
    <div class="d-flex justify-content-between">
      <BButton
        variant="outline-primary"
        @click="hideModal('AddSurveys')"
      >
        Cancelar
      </BButton>
      <BButton
        v-if="!disbleInputs"
        variant="primary"
        @click="saveChanges()"
      >
        Salvar
      </BButton>
      <BButton
        v-else
        variant="primary"
        @click="() => {
          properties.action = 'edit'
          disbleInputs = false
        }"
      >
        Editar
      </BButton>
    </div>
  </div>

</template>

<script>
import {
  BFormInput, BInputGroupAppend, BInputGroup, BFormGroup, BFormSelect, BFormTextarea, BFormFile, BBadge, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import VSIcons from '@/assets/icons/VSIcons.vue'
import inspects from '@/services/inspect'
import upload from '@/services/upload'

export default {
  components: {
    BFormInput, BInputGroupAppend, VSIcons, BInputGroup, BFormGroup, BFormSelect, BFormTextarea, BFormFile, BBadge, BButton, BRow, BCol,
  },
  props: {
    properties: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      disbleInputs: false,
      files: null,
      item: {},
    }
  },
  computed: {
    ...mapGetters({
      deadlineOptions: 'app/getDeadlineOptions',
      licensingAgencyOptions: 'publicAgencies/getLicensingAgencyOptions',
    }),
  },
  created() {
    if (this.properties.action === 'read' || this.properties.action === 'edit') {
      this.item = this.properties.item
      if (this.item.file_name) this.item.file_name = this.properties.item.attachment[0].file_name
      if (this.item.file_description) this.item.file_description = this.properties.item.attachment[0].file_name
    }
    if (this.properties.action === 'read') { this.disbleInputs = true }
  },
  methods: {
    ...mapActions({
      fetchinspects: 'inspects/fetchInspects',
      fetchUploads: 'upload/fetchUploads',
    }),
    saveChanges() {
      this.$refs.form.validate().then(success => {
        if (success) {
          if (this.properties.module) {
            this.item.licensingCondition_id = Number(this.$route.params.id)
          } else {
            this.item.licensing_id = Number(this.$route.params.id)
          }
          if (this.properties.action === 'create') {
            inspects.createInspect(this.item).then(res => {
              if (this.files) {
                this.createUpload(res.data.id)
              }
              this.createdMessage()
              this.fetchinspects()
            })
          } else if (this.properties.action === 'edit') {
            delete this.item.sector
            inspects.editInspect(this.item).then(() => {
              this.updateMessage()
              this.fetchinspects()
            })
          }
          this.hideModal('AddSurveys')
          this.fetchinspects()
        }
      })
    },
    createUpload(id) {
      const formatData = new FormData()
      formatData.append('attachment_file', this.files)
      formatData.append('file_name', this.item.file_name)
      formatData.append('file_description', this.item.file_description)
      formatData.append('opinionsInspec_id', id)
      if (this.properties.module) {
        formatData.append('licensingConditions_id', this.$route.params.id)
      } else formatData.append('licensing_id', this.$route.params.id)

      const formUpload = formatData
      upload.createUploadFile(formUpload).then(() => {
        this.fetchUploads()
      })
    },
    createdMessage() {
      this.toast({
        title: 'Criado !',
        text: 'Cadastro realizado com sucesso.',
        variant: 'success',
      })
    },
    updateMessage() {
      this.toast({
        title: 'Editado !',
        text: 'Cadastro atualizado com sucesso.',
        variant: 'success',
      })
    },
  },
}
</script>

<style lang='scss' scoped>
h2 {
font-weight: 600;
font-size: 21px;
line-height: 25px;
text-align: center;
letter-spacing: 0.75px;
color: #33383C;

}

textarea {
  min-height: 82px;
}

button {
width: 190px;
height: 45px;
}

.size-10 {
  padding: 0;
  margin: 0;
  width: 8px !important;
  height: 19px !important;
}

</style>
