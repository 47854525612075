export default [
  {
    path: '/licenciamento',
    name: 'licensing',
    component: () => import(/* webpackChunkName: "licensing" */ '@/views/licensing/Licensing.vue'),
    meta: {
      title: 'Licenciamento',
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'licensing',
    },
  },
  {
    path: '/licenciamento/:id/detalhes',
    name: 'licensing-details',
    component: () => import(/* webpackChunkName: "licensing-details" */ '@/views/licensing/LicensingDetails.vue'),
    meta: {
      title: 'Licenciamento',
      menu: true,
      authRequired: true,
      subTitle: true,
      action: 'read',
      resource: 'licensing',
    },
  },
]
