import api from '@/libs/axios'

export default {
  getAnnouncements: () => api.get('/announcements/'),
  getAnnouncementsById: id => api.get(`/announcements/${id}`),
  getMyAnnouncements: () => api.get('/announcements/my-announcements'),
  setReadAnnouncements: id => api.patch(`/announcements/open-read/${id}`),
  editAnnouncements: form => api.patch(`/announcements/update/${form.id}`, form),
  createAnnouncements: form => api.post('/announcements', form),
  deleteAnnouncements: array => api.delete('/announcements', array),
}
