<template>
  <div class="notifications">
    <div class="d-flex justify-content-between align-items-center notification-header">
      <h6 class="m-0">
        Notificações
      </h6>
      <button
        class="notification-action"
        @click="setAllNotificationRead()"
      >
        Marcar todos como lido
      </button>
    </div>
    <div class="notification-list">
      <div v-if="notifications.length === 0">
        Você ainda não recebeu notificações
      </div>
      <div v-else>
        <b-dropdown-item
          v-for="notification in notifications"
          :key="notification.id"
          class="dropdown-item-padding-0 dropdown-item-hover"
          @click="setNotificationRead(notification.id)"
        >
          <div class="d-flex align-items-top">
            <div class="notification-logo d-flex j-c-center mr-2">
              <VSIcons
                name="Logo"
                width="30"
                height="32"
              />
            </div>
            <div>
              <div
                v-b-popover.hover="{ variant: 'primary', content: notification.subject }"
                class="notification-title"
              >
                <span
                  class="font-15 font-weight-600 mr-2 text-truncate-30"
                >{{ notification.subject }}</span>
                <div
                  v-if="notification.status !== 'Read'"
                  class="badge-not"
                />
              </div>
              <div class="notification-message">
                <div
                  class="text-wrap text-secondary m-0"
                  v-html="notification.message"
                />
              </div>
              <div class="notification-data">
                <p class="text-wrap text-secondary font-12 font-color-date">
                  {{ notification.sendDate | formatDateTimeNoLocal }} - por {{ notification.sender }}
                </p>
              </div>
            </div>
          </div>
          <b-dropdown-divider />
        </b-dropdown-item>
      </div>
    </div>
    <div class="notification-footer">
      <button
        class="notification-action"
        @click="showModal('Notifications')"
      >
        Expandir notificações
      </button>
    </div>
    <VSModal
      name="Notifications"
      :width="600"
    />
  </div>
</template>

<script>
import {
  BDropdownItem, BDropdownDivider, VBPopover,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import VSIcons from '@/assets/icons/VSIcons.vue'
import { formatDateTimeNoLocal } from '@/@core/utils/filter'
import announcements from '@/services/announcements'
import VSModal from '@/components/modals/VSModal.vue'

export default {
  components: {
    BDropdownItem,
    BDropdownDivider,
    VSIcons,
    VSModal,
  },
  filters: {
    formatDateTimeNoLocal,
  },
  directives: {
    'b-popover': VBPopover,
  },
  computed: { ...mapGetters({ notifications: 'announcements/getMyAnnouncements' }) },
  created() {
    this.fetchMyAnnouncements()
    this.updateAnnoucements()
    if (this.notifications.some(not => not.status !== 'Read')) {
      this.toast({
        title: 'Você tem notificações não lidas',
        text: 'Confira as novidades em suas notificações.',
        variant: 'primary',
      })
    }
  },
  methods: {
    ...mapActions({ fetchMyAnnouncements: 'announcements/fetchMyAnnouncements' }),
    updateAnnoucements() {
      const myInterval = setInterval(() => {
        if (this.$store.state.auth.isUserLoggedIn()) {
          this.fetchMyAnnouncements().then(res => {
            const notificationsNoDelivered = res.filter(arr => arr.isDelivered === false)
            if (notificationsNoDelivered.length > 0) {
              this.toast({
                title: 'Nova notificação!',
                text: 'Confira as novidades em suas notificações.',
                variant: 'primary',
              })

              notificationsNoDelivered.forEach(element => {
                const notification = {
                  isDelivered: true,
                  id: element.id,
                }
                announcements.editAnnouncements(notification)
              })
            }
          })
        } else clearInterval(myInterval)
      }, 1000 * 60) // a cada um minuto
    },
    setNotificationRead(id) {
      announcements.setReadAnnouncements(id).then(() => this.fetchMyAnnouncements())
    },
    setAllNotificationRead() {
      announcements.setReadAnnouncements().then(() => this.fetchMyAnnouncements())
    },
  },
}
</script>

<style lang="scss" scoped>
.notification-header {
  height: 45px;
}
.notification-action {
  background-color: transparent;
  border: none;
  color: #7d8e9e;
  font-weight: 600;
  &:hover {
    color: #18ac81;
    text-decoration: underline;
  }
}
.notification-list {
  overflow: auto;
  max-height: 70vh;
  min-height: 5vh;
  padding: 0 5px;
}
.notification-logo {
  display: block;
  min-width: 50px;
  max-width: 50px;
  max-height: 50px;
  background: rgba(160, 174, 185, 0.13);
  border-radius: 100px;
}
.notification-title {
  display: flex;
  align-items: center;
  .badge-not {
    background-color: #FD3258;
    width: 9px;
    height: 9px;
    border-radius: 100%;
  }
}
.notification-footer {
  height: 35px;
  display: flex;
  align-items: center;
}
.font-color-date {
  color: rgba(160, 174, 185, 0.79) !important;
}
</style>
