<template>
  <div>
    <h1>Envie um ticket</h1>
    <p>Não importa se é um probleminha técnico ou um surto,
      estamos aqui para ajudar!</p>
    <BFormGroup label="Assunto">
      <BFormInput
        v-model="subject"
        class="mt-1"
      />
    </BFormGroup>
    <BFormGroup label="Descreva o que está acontecendo">
      <BFormTextarea
        v-model="description"
        class="textarea mt-1"
      />
    </BFormGroup>
    <div class="d-flex justify-content-between align-items-center">
      <BButton
        variant="outline-primary"
        class="button"
        @click="hideModal('Ticket')"
      >
        Cancelar
      </BButton>
      <BButton
        variant="primary"
        class="button"
        @click="sendTicket"
      >
        Enviar
      </BButton>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BFormTextarea, BButton,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import tickets from '@/services/tickets'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
  },
  data() {
    return {
      subject: '',
      description: '',
    }
  },
  methods: {
    ...mapActions({ fetchUserTickets: 'tickets/fetchUserTickets' }),
    sendTicket() {
      const newTicket = {
        subject: this.subject,
        description: this.description,
      }
      tickets.createTicket(newTicket).then(res => {
        if (res.status === 201) {
          this.toast({
            title: 'Enviado com sucesso',
            text: 'Seu ticket foi enviado com sucesso!',
            variant: 'success',
          })

          this.fetchUserTickets()

          if (!this.$route.name === 'management-verdesys') {
            this.$router.push('/gestao-verdesys')
          }
          this.hideModal('Ticket')
        }
      })
    },
  },
}
</script>

<style lang='scss' scoped>
h1 {
font-weight: 600;
font-size: 21px;
line-height: 100%;
text-align: center;
letter-spacing: 0.75px;
color: #33383C;
text-align: center;
}
p {
font-weight: 500;
font-size: 13px;
line-height: 100%;
text-align: center;
letter-spacing: 0.75px;
color: #7D8E9E;
margin-top: 13px;
}
.textarea {
  min-height: 266px;
  resize: none;
}
button {
  width: 190px;
}
</style>
