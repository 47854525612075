import annotations from '@/services/annotation'

export default {
  namespaced: true,
  state: {
    annotations: [],
    annotationData: null,
  },
  getters: {
    getAnnotations: state => state.annotations,
    geAnnotationData: state => state.annotationData,
  },
  mutations: {
    SET_ANNOTATIONS_DATA(state, payload) {
      state.annotationData = payload
    },
    SET_ANNOTATIONS(state, payload) {
      state.annotations = payload
    },
  },
  actions: {
    fetchAnnotationsConstraints({ commit }, id) {
      annotations.getAnnotationsConstraints(id).then(res => commit('SET_ANNOTATIONS', res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))))
    },
    fetchAnnotationsDocuments({ commit }, id) {
      annotations.getAnnotationsDocuments(id).then(res => commit('SET_ANNOTATIONS', res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))))
    },
  },
}
