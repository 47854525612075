<script>/* eslint-disable */</script>
<template>
  <div>
    

  
  <div v-if="showAviso" class="alert-payment" style="background-color: #ffa500a6;  align-items: center;
      display: flex;
      justify-content: center;" >
    <div class="alert-payment-content">
      <span
      ></span>
      <p>
         Sua assinatura irá vencer em {{ 
        formattedDate

        
      }} e seu acesso em {{ 
        expireDateFormated
      
      }}. verifique seu e-mail ou <router-link
        class="text-withe"
        to="/assinar-plano"
      > <b>clique aqui</b>   </router-link> para verificar o status do seu plano.</p>
      
      
     

    </div>

    

    
  </div>
  <div
    v-if="show"
    class="alert-payment"
  >
    <div />
    <div class="alert-payment" style="background-color: #ffa500a6;  align-items: center;
      display: flex;
      justify-content: center;">
      <span  v-if="statusPlan === 'pending' ">Você tem um boleto pendente. Verifique sua fatura.
      
      </span>
      <span v-if='statusPlan === "canceled" || statusPlan === "expired"'>Sua assinatura foi cancelada.</span>

    </div>

    <div class="alert-payment-content">
      <span v-if='statusPlan === "canceled" || statusPlan === "expired"'>Sua assinatura foi cancelada.</span>
     <!-- <span>{{ `Assinatura vencida em ${formatLocalDate(infoAccount.accountExpirationDate)}, seus acessos serão desativados em ${infoAccount.countDown} dias.` }}<router-link
        class="text-withe"
        to="/minha-conta"
      > Clique aqui para regularizar.</router-link></span> -->
    </div>
    <a
      href="#"
      class="alert-payment-close"
      @click="handleShowAlert()"
    >x</a>
  </div>
</div>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex'
import { formatLocalDate } from '@core/utils/filter'
import accounts from '@/services/accounts'
import useJwt from '@/auth/jwt/useJwt'

export default {
  data() {
    return {
      statusPlan: null,
      fatura: null,
      infoAccount: {},
      propertiesDeleteModal: {
        title: 'Deseja excluir esse cadastro ?',
        text: 'Esta ação não poderá ser revertida!',
      },
      show: false,
      showAviso: false,
      expireDate: null,
      formattedDate: null,
      expireDateFormated: null,
    }
  },
  mounted() {

      const localUserInfos = JSON.parse(localStorage.getItem('user_infos'))
      const expirationDateDef = new Date(localUserInfos.expires_at)
      expirationDateDef.setDate(expirationDateDef.getDate() + 5)
    const expireDate = expirationDateDef.toISOString().split('T')[0]
      const plan = localUserInfos.plan_identifier
      this.expireDate = expireDate
      this.expireDateFormated = this.expireDate.split('-').reverse().join('/')

      // se tiver faltando 5 dias para expirar em relação a data do this.expireDate então mostra o alerta
        if (this.expireDate) { 
         const date = new Date(this.expireDate)
         const dateNow = new Date()
          const diffTime = Math.abs(date - dateNow);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          if (diffDays <= 10 && plan == 'plano_1' || plan == 'plano_2' || plan == 'plano_3') {
            this.showAviso = true
          }

          //se tiver passado a data de expiração então mostra o alerta
          if (dateNow > date) {
            this.showAviso = false
          }
        }

        if (this.expireDate) {
          //inverte yyyy-mm-dd para dd-mm-yyyy
          const date = this.expireDate.split('-').reverse().join('/')
          const dateMenos5Dias = new Date(this.expireDate)
          dateMenos5Dias.setDate(dateMenos5Dias.getDate() - 5)
          const dateMenos5DiasFormat = dateMenos5Dias.toISOString().split('T')[0]
          //inverte yyyy-mm-dd para dd-mm-yyyy
          const dateMenos5DiasFormat2 = dateMenos5DiasFormat.split('-').reverse().join('/')

          this.formattedDate = dateMenos5DiasFormat2      
        }




        this.statusPlan = JSON.parse(localStorage.getItem("user_infos")).recent_invoices[0].status;
        console.log(this.statusPlan)
        this.fatura = JSON.parse(localStorage.getItem("user_infos")).recent_invoices[0].secure_url;
    },
  computed: {
    ...mapGetters({ getItensToDelete: 'app/getItensDelete', getMyAccount: 'auth/getMyAccount', getUserPermissions: 'auth/getUserPermissions' }),
  },
  created() {
    this.fetchInfo()
    
  },
  methods: {
    ...mapMutations({ setItensToDelete: 'app/SET_ITENS_DELETE' }),
    formatLocalDate,
    handleShowAlert() {
      this.show = false
    },
    fetchInfo() {
      accounts.getAccountInfo(useJwt.getIdMyAccount()).then(res => {
        if (JSON.parse(localStorage.getItem('user')).permission.name === 'Administrador') {
          this.infoAccount = res.data
          this.show = this.infoAccount.accountStatus !== 'Adimplente'
        }
      })
    },

    checkExpire() {
      
      
    }



  },
}
</script>
<style lang="scss" scoped>
.alert-payment {
  position: absolute;
  padding: 0 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 2rem;
  background-color: rgba(red, 0.7);
  color: #fff;
  &-content {
    text-align: center;
    .text-withe {
      color: #fff;
    }
  }
  &-close {
    text-decoration: none;
    color: #fff;
    &:hover {
      color: #000;
    }
  }
}
</style>
