import { Ability } from '@casl/ability'
import { initialAbility } from './config'

const permissions = JSON.parse(localStorage.getItem('user')) === null ? initialAbility : JSON.parse(localStorage.getItem('user')).permission

const listPermission = Object.entries(permissions)
  .filter(arr => arr[1] === true)
  .map(arr => ({
    subject: arr[0]
      .substr(0, arr[0]
        .indexOf('_')),
    action: [arr[0]
      .substr((arr[0]
        .indexOf('_') + 1))],
  }))

const existingAbility = listPermission

export default new Ability(existingAbility || initialAbility)
