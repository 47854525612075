import records from '@/services/records'

export default {
  namespaced: true,
  state: {
    recordsData: null,
    records: [],
  },
  getters: {
    getRecordsData: state => state.recordsData,
    getRecords: state => state.records,
    getRecordsLicensing: state => id => state.records.filter(arr => arr.lincensing_id === Number(id)),
    getRecordsLicenseConditions: state => id => state.records.filter(arr => arr.licensingCondition_id === Number(id)),
  },
  mutations: {
    SET_RECORD_DATA(state, payload) {
      state.recordsData = payload
    },
    SET_RECORDS(state, payload) {
      state.records = payload
    },
  },
  actions: {
    fetchRecords({ commit }) {
      records.getRecords().then(res => commit('SET_RECORDS', res.data.filter(arr => arr.isActive)))
    },
    fetchRecordsById({ commit }, id) {
      records.getRecordsById(id).then(res => commit('SET_RECORD_DATA', res.data))
    },
  },
}
