<template>
  <div class="notification">
    <b-img-lazy
      class="notification__icon"
      src="@/assets/images/verdesys-notifications-logo.png"
    />
    <div>
      <h2 class="notification__title">
        {{ title }}
      </h2>
      <div
        class="notification__text"
        v-html="text"
      />
      <div>
        <span class="notification__day">{{ formatDateTimeNoLocal(day) }}</span>
        <span>-</span>
        <span class="notification__author">{{ author }}</span>
        <span
          v-if="read"
          class="notification__circle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BImgLazy } from 'bootstrap-vue'
import { formatDateTimeNoLocal } from '@core/utils/filter'

export default {
  components: {
    BImgLazy,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    day: {
      type: String,
      required: true,
    },
    author: {
      type: String,
      required: true,
    },
    read: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    formatDateTimeNoLocal,
  },
}
</script>

<style lang='scss' scoped>
.notification {
  display: flex;
  align-items: center;
  padding: 21px 0px;
  position: relative;

  &:after {
    content: '';
    display: block;
    white-space: wrap;
    width: 200%;
    position: absolute;
    border-bottom: rgba(160, 174, 185, 0.13) 1px solid;
    bottom: 0;
    left: -50%;

  }
    &__icon {
    align-self: flex-start;
    margin-right: 10px;
  }
  &__title {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.75px;
  color: #33383C;
  }
 &__text {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.75px;
  color: #7D8E9E;
  }
 &__time, &__day, &__author {
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  letter-spacing: 0.75px;
  color: rgba(160, 174, 185, 0.79);
 }
 &__circle {
  background: #FD3258;
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 100%;
  margin-left: 5px;
 }

}

</style>
