<template>
  <transition name="fade-top">
    <div
      v-if=" getItensToDelete.itens.length > 0"
      class="delete"
    >
      <div class="delete-selected-text text-nowrap">
        <span class="font-weight-bold text-dark">
          {{ getItensToDelete.itens.length }}
        </span>
        Selecionado
      </div>
      <div class="delete-actions">
        <b-button
          variant="outline-primary"
          @click="markOff()"
        >
          Desmarcar
        </b-button>
        <b-button
          variant="outline-danger"
          @click="showModal('DeleteItem')"
        >
          <VSIcons
            name="TrashSquare"
            color="#FD3258"
            class="mr-1"
          />
          Excluir
        </b-button>
      </div>
      <VSModal
        :properties="propertiesDeleteModal"
        name="DeleteItem"
      />
    </div>
  </transition>
</template>
<script>
import { BButton } from 'bootstrap-vue'
import { mapMutations, mapGetters } from 'vuex'
import VSIcons from '@/assets/icons/VSIcons.vue'
import VSModal from '@/components/modals/VSModal.vue'

export default {
  components: {
    BButton,
    VSIcons,
    VSModal,
  },
  data() {
    return {
      propertiesDeleteModal: {
        title: 'Deseja excluir esse cadastro ?',
        text: 'Esta ação não poderá ser revertida!',
      },
    }
  },
  computed: {
    ...mapGetters({ getItensToDelete: 'app/getItensDelete' }),
  },
  methods: {
    ...mapMutations({ setItensToDelete: 'app/SET_ITENS_DELETE' }),
    markOff() {
      this.setItensToDelete({ itens: [], api: '' })
      this.$root.$emit('mark-off')
    },
  },
}
</script>
<style lang="scss">
.delete {
  transition: .3s ease;
  align-items: center;
  display: flex;

  &-selected-text {
    color: #7D8E9E;
    font-size: 13px;
    font-weight: 600px;
    margin-right: 1rem;
  }

  &-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  position: absolute;
  bottom: 0;
  z-index: 10;

  height: 5rem;
  width: 100%;

  background-color: #FFF;
  padding: 0 1rem;

  button {
    display: flex;
    align-items: center;
    padding: 0 2rem;
    &:hover {
      svg {
        path {
          stroke: #FFF;
        }
      }
    }
  }
}
</style>
