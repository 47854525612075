<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1818 1.45455H0.727273C0.329697 1.45455 0 1.12485 0 0.727273C0 0.329697 0.329697 0 0.727273 0H18.1818C18.5794 0 18.9091 0.329697 18.9091 0.727273C18.9091 1.12485 18.5794 1.45455 18.1818 1.45455Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M18.1818 6.30303H0.727273C0.329697 6.30303 0 5.97333 0 5.57576C0 5.17818 0.329697 4.84848 0.727273 4.84848H18.1818C18.5794 4.84848 18.9091 5.17818 18.9091 5.57576C18.9091 5.97333 18.5794 6.30303 18.1818 6.30303Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M18.1818 11.1515H0.727273C0.329697 11.1515 0 10.8218 0 10.4242C0 10.0267 0.329697 9.69697 0.727273 9.69697H18.1818C18.5794 9.69697 18.9091 10.0267 18.9091 10.4242C18.9091 10.8218 18.5794 11.1515 18.1818 11.1515Z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M18.1818 16H0.727273C0.329697 16 0 15.6703 0 15.2727C0 14.8752 0.329697 14.5455 0.727273 14.5455H18.1818C18.5794 14.5455 18.9091 14.8752 18.9091 15.2727C18.9091 15.6703 18.5794 16 18.1818 16Z"
      fill="url(#paint3_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="7.95558e-08"
        y1="8.17778"
        x2="18.9091"
        y2="8.17778"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#076E5F" />
        <stop
          offset="1"
          stop-color="#18AC81"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="7.95558e-08"
        y1="8.17778"
        x2="18.9091"
        y2="8.17778"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#076E5F" />
        <stop
          offset="1"
          stop-color="#18AC81"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="7.95558e-08"
        y1="8.17778"
        x2="18.9091"
        y2="8.17778"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#076E5F" />
        <stop
          offset="1"
          stop-color="#18AC81"
        />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="7.95558e-08"
        y1="8.17778"
        x2="18.9091"
        y2="8.17778"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#076E5F" />
        <stop
          offset="1"
          stop-color="#18AC81"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
}
</script>
