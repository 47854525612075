<template>
  <div>
    <h4 class="modal-title">
      Deseja excluir esse cadastro ?
    </h4>
    <h6 class="modal-subtitle">
      Está ação é irreversível, deseja continuar ?
    </h6>
    <div class="d-flex justify-content-between mt-5">
      <b-button
        variant="outline-success pr-4 pl-4 mr-5"
        @click="hideModal('DeleteOneItem')"
      >
        Cancelar
      </b-button>
      <b-button
        class="btn-grad pr-4 pl-4 m-0"
        @click="deleteItem()"
      >
        Continuar
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  BButton,
} from 'bootstrap-vue'
import { fetchTableAfterDelete } from '@core/mixins/ui/forms'
import { mapMutations } from 'vuex'
import axios from '@/libs/axios'

export default {
  components: {
    BButton,
  },
  mixins: [fetchTableAfterDelete],
  props: {
    properties: {
      type: Object,
      default: null,
    },
  },
  created() {
    this.setItensToDelete(this.properties)
  },
  methods: {
    ...mapMutations({
      setItensToDelete: 'app/SET_ITENS_DELETE',
    }),
    deleteItem() {
      axios.delete(`${this.properties.api}/${this.properties.item.id}`).then(() => {
        this.hideModal('DeleteOneItem')
        this.toast({
          title: 'Excluído',
          text: 'Cadastro excluído com sucesso',
          variant: 'success',
        })
        this.fetchTable()
      })
    },
  },
}
</script>
