<template>
  <div>
    <svg
      width="20"
      height="10"
      viewBox="0 0 20 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 4.99387C19.5 4.79387 19.42 4.6039 19.28 4.4639L15.56 0.743867C15.27 0.453867 14.79 0.453867 14.5 0.743867C14.21 1.03387 14.21 1.51386 14.5 1.80386L16.95 4.25388H0.75C0.34 4.25388 0 4.59388 0 5.00388C0 5.41388 0.34 5.75388 0.75 5.75388H16.94L14.5 8.19388C14.21 8.48387 14.21 8.96387 14.5 9.25387C14.65 9.40387 14.84 9.47387 15.03 9.47387C15.22 9.47387 15.41 9.40387 15.56 9.25387L19.27 5.54391C19.34 5.47391 19.4 5.3839 19.44 5.2839C19.44 5.2739 19.44 5.26388 19.45 5.25388C19.48 5.16388 19.5 5.08387 19.5 4.99387Z"
        fill="#18AC81"
      />
    </svg>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
