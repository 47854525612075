import inspects from '@/services/inspect'

export default {
  namespaced: true,
  state: {
    inspectsData: null,
    inspects: [],
  },
  getters: {
    getInspectsData: state => state.inspectsData,
    getInspects: state => state.inspects,
    getInspectsLicensing: state => id => state.inspects.filter(arr => arr.lincensing_id === Number(id)),
    getInspectsLicenseConditions: state => id => state.inspects.filter(arr => arr.licensingCondition_id === Number(id)),
  },
  mutations: {
    SET_INSPECT_DATA(state, payload) {
      state.inspectsData = payload
    },
    SET_INSPECTS(state, payload) {
      state.inspects = payload
    },
  },
  actions: {
    fetchInspects({ commit }) {
      inspects.getInspects().then(res => commit('SET_INSPECTS', res.data.filter(arr => arr.isActive)))
    },
    fetchInspectsById({ commit }, id) {
      inspects.getInspectsById(id).then(res => commit('SET_INSPECT_DATA', res.data))
    },
  },
}
