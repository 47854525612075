import api from '@/libs/axios'

export default {
  getAccountables: () => api.get('/system/accountable'),
  getAccountableById: id => api.get(`/system/accountable/${id}`),
  editAccountable: form => api.patch(`/system/accountable/${form.id}`, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  createAccountable: form => api.post('/system/accountable', form),
  deleteAccountables: array => api.delete('/system/accountable', array),
}
