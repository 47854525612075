export default [
  {
    path: '/gestao-verdesys',
    name: 'management-verdesys',
    component: () => import(/* webpackChunkName: "management-verdesys" */ '@/views/management-verdesys/ManagementVerdeSys.vue'),
    meta: {
      title: 'Gestão VerdeSys',
      menu: true,
      authRequired: true,
      action: 'read',
      masterManagement: true,
      resource: 'dashboard',
    },
  },
  {
    path: '/gestao-verdesys/contas/:id/detalhes',
    name: 'management-verdesys-accounts-details',
    component: () => import(/* webpackChunkName: "management-verdesys-details" */ '@/views/management-verdesys/ManagementVerdeSysAccountsDetails.vue'),
    meta: {
      title: 'Gestão de contas',
      subTitle: true,
      menu: true,
      authRequired: true,
      action: 'read',
      masterManagement: true,
      resource: 'dashboard',
    },
  },
]
