<template>
  <div
    class="sidebar"
    :style="`width: ${collapsed ? '5.6' : '15'}rem;`"
    :class="`${windowSize < 575 ? !collapsed ? 'sidebar-mobile-opened' : 'd-none' : ''}`"
    @mouseenter="toggleSidebar(false)"
    @mouseleave="toggleSidebar(true)"
  >
    <NavigationItem
      v-for="item in navItens"
      :key="item.title"
      :nav-item="item"
    />
  </div>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex'
import NavigationItem from './NavigationItem.vue'
import navItens from './nav-itens'

export default {
  components: {
    NavigationItem,
  },
  data() {
    return {
      navItens,
    }
  },
  computed: {
    ...mapGetters({ collapsed: 'app/getSidebarCollapsed', windowSize: 'app/getWindowSize' }),
  },
  methods: {
    ...mapMutations({ toggleSidebar: 'app/SET_SIDEBAR_COLLAPSED' }),
  },
}
</script>
