<template>
  <div>
    <h4 class="modal-title">
      {{ properties.action === 'edit' ? 'Editar' : properties.action === 'create' ? 'Adicionar' : 'Detalhar' }} inventário
    </h4>
    <validation-observer ref="form">
      <b-row>
        <b-col
          class="pr-0"
          md="2"
        >
          <validation-provider
            v-slot="{ errors }"
            name="cód interno"
            rules="required"
          >
            <b-form-group label="Cód Interno">
              <b-input-group>
                <b-form-input
                  id="cod-interno"
                  v-model="item.internal_code"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          class="pr-0"
          md="3"
        >
          <validation-provider
            v-slot="{ errors }"
            name="nome"
            rules="required"
          >
            <b-form-group label="Nome">
              <b-input-group>
                <b-form-input
                  id="nome"
                  v-model="item.name"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="2"
        >
          <validation-provider
            v-slot="{ errors }"
            name="classificação"
            rules="required"
          >
            <b-form-group label="Classificação">
              <b-input-group>
                <b-form-select
                  id="classificacao"
                  v-model="item.classification"
                  :options="classificationsOptions"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          class="pl-0"
          md="2"
        >
          <validation-provider
            v-slot="{ errors }"
            name="estado físico"
            rules="required"
          >
            <b-form-group label="Estado físico">
              <b-input-group>
                <b-form-select
                  id="esado-fisico"
                  v-model="item.physical_state"
                  :options="physicalStateOptions"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          class="pl-0"
          md="3"
        >
          <validation-provider
            v-slot="{ errors }"
            name="estado físico"
            rules="required"
          >
            <b-form-group label="Armazenamento">
              <b-input-group>
                <b-form-input
                  id="armazenamento"
                  v-model="item.storage"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="pr-0"
          md="3"
        >
          <validation-provider
            v-slot="{ errors }"
            name="estado físico"
            rules="required"
          >
            <b-form-group label="Acondicionamento">
              <b-input-group>
                <b-form-select
                  id="acondicionamento"
                  v-model="item.conditioning"
                  :options="conditionningOptions"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          class="pr-0"
          md="4"
        >
          <validation-provider
            v-slot="{ errors }"
            name="código resíduo"
            rules="required"
          >
            <b-form-group label="Cód Resíduo">
              <b-input-group>
                <b-form-input
                  id="cod-residuo"
                  v-model="item.residue_code"
                  type="text"
                  disabled
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          class="pr-0"
          md="4"
        >
          <b-form-group label="Descrição Resíduo">
            <b-input-group>
              <b-form-input
                id="desc-residuo"
                v-model="description_waste"
                type="text"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="1">
          <b-button
            variant="primary"
            class="mt-3"
            @click="openModal()"
          >
            <VSIcons
              name="Search"
              color="#FFF"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Descrição">
            <b-input-group>
              <b-form-textarea
                v-model="item.description"
                :disabled="disbleInputs"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="pr-0"
          md="3"
        >
          <b-form-group label="Nome do arquivo">
            <b-input-group>
              <b-form-input
                id="nome-arquivo"
                v-model="item.file_name"
                type="text"
                :disabled="disbleInputs"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="9">
          <b-form-group label="Descrição do arquivo">
            <b-input-group>
              <b-form-input
                id="descricao-arquivo"
                v-model="item.file_description"
                type="text"
                :disabled="disbleInputs"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!disbleInputs">
        <b-col>
          <b-form-group label="Anexar arquivo ">
            <b-form-file
              v-model="files"
              placeholder="Selecione um arquivo ou solte aqui..."
              drop-placeholder="Solte o arquivo aqui..."
              accept=".jpg, .png, .docx, .jpeg, .pdf, .xsls"
            />
            <b-badge
              v-if="files"
              variant="primary"
              class="mt-3"
            >
              {{ files ? files.name : null }}
              <b-button
                variant=""
                class="remove-file-upload"
                @click="files = null"
              >
                <span>
                  x
                </span>
              </b-button>
            </b-badge>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <div class="d-flex justify-content-between mt-2">
      <b-button
        variant="outline-success pr-4 pl-4 mr-5"
        @click="hideModal('Inventory')"
      >
        Cancelar
      </b-button>
      <BButton
        v-if="!disbleInputs"
        variant="primary"
        @click="saveChanges()"
      >
        Salvar
      </BButton>
      <BButton
        v-else
        variant="primary"
        @click="() => {
          properties.action = 'edit'
          disbleInputs = false
        }"
      >
        Editar
      </BButton>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup, BInputGroup, BFormInput, BRow, BCol, BFormTextarea, BFormFile, BBadge, BButton, BFormSelect,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import waste from '@/services/waste'
import upload from '@/services/upload'
import VSIcons from '@/assets/icons/VSIcons.vue'

export default {
  components: {
    BFormGroup,
    BInputGroup,
    BFormInput,
    BRow,
    BCol,
    BFormTextarea,
    BFormFile,
    BBadge,
    BButton,
    BFormSelect,
    VSIcons,
  },
  props: {
    properties: {
      type: Object,
      default: null,
    },
    openModalSearchCode: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      disbleInputs: false,
      files: null,
      description_waste: '',
      item: {
        residue_code: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      classificationsOptions: 'waste/getClassificationsOptions',
      physicalStateOptions: 'waste/getPhysicalStateOptions',
      conditionningOptions: 'waste/getConditionningOptions',
      wasteData: 'waste/getWasteCode',
    }),
  },
  created() {
    if (this.properties.action === 'read' || this.properties.action === 'edit') {
      this.item = this.properties.item
      this.fillWasteDataEdit(this.item.residue_code)
    }
    if (this.properties.action === 'read') { this.disbleInputs = true }
  },
  methods: {
    ...mapActions({
      fetchWasteInventory: 'waste/fetchWasteInventory',
      fetchUploads: 'upload/fetchUploads',
    }),
    saveChanges() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.item.waste_id = Number(this.$route.params.id)
          if (this.properties.action === 'create') {
            waste.createWasteInventory(this.item).then(res => {
              this.createdMessage()
              this.createUpload(res.data.id)
              this.fetchWasteInventory(this.$route.params.id)
            })
          } else if (this.properties.action === 'edit') {
            delete this.item.waste
            delete this.item.operation
            waste.editWasteInventory(this.item).then(() => {
              this.updateMessage()
              this.fetchWasteInventory(this.$route.params.id)
            })
          }
          this.hideModal('Inventory')
          this.fetchWasteInventory(this.$route.params.id)
        }
      })
    },
    createdMessage() {
      this.toast({
        title: 'Criado !',
        text: 'Cadastro realizado com sucesso.',
        variant: 'success',
      })
    },
    updateMessage() {
      this.toast({
        title: 'Editado !',
        text: 'Cadastro atualizado com sucesso.',
        variant: 'success',
      })
    },
    createUpload(id) {
      if (this.files) {
        const formatData = new FormData()
        formatData.append('attachment_file', this.files)
        formatData.append('file_name', this.item.file_name)
        formatData.append('file_description', this.item.file_description)
        formatData.append('inventory_id', id)
        formatData.append('waste_id', this.$route.params.id)
        const formUpload = formatData
        upload.createUploadFile(formUpload).then(() => {
          this.fetchUploads()
        })
      }
    },
    openModal() {
      this.openModalSearchCode()
    },
    receiveWasteData(data) {
      this.item.residue_code = data.codResiduo
      this.description_waste = data.descricao3
    },
    fillWasteDataEdit(code) {
      const Waste = this.wasteData.filter(arr => arr.codResiduo.startsWith(code))[0]
      this.description_waste = Waste.descricao3
    },
  },
}
</script>

<style>

</style>
