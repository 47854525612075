import api from '@/libs/axios'

export default {
  getAnnotations: () => api.get('/system/annotations/'),
  getAnnotationsConstraints: id => api.get(`/system/annotations/contrains/${id}`),
  getAnnotationsDocuments: id => api.get(`/system/annotations/docs/${id}`),
  editAnnotation: form => api.patch(`/system/annotations/${form.id}`, form),
  createAnnotation: form => api.post('/system/annotations', form),
  deleteAnnotations: array => api.delete('/system/annotations', array),
}
