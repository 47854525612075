import enterprises from '@/services/enterprises'

export default {
  namespaced: true,
  state: {
    enterprises: [],
    enterpriseData: null,
    enterpriseOptions: [{ text: 'Nenhuma das opções', value: null }],
  },
  getters: {
    getEnterprises: state => state.enterprises,
    getEnterpriseData: state => state.enterpriseData,
    getEnterpriseOptions: state => state.enterpriseOptions,
    getEnterpriseOptionsByCompanyId: state => id => state.enterprises.filter(arr => arr.isActive === true && arr.company.id === id).map(arr => ({ text: arr.name, value: arr.id })),
  },
  mutations: {
    SET_ENTERPRISE_DATA(state, payload) {
      state.enterpriseData = payload
    },
    SET_ENTERPRISES(state, payload) {
      state.enterprises = payload
    },
    SET_ENTERPRISES_OPTIONS(state, payload) {
      state.enterpriseOptions = payload
    },
  },
  actions: {
    fetchEnterprises({ state, commit }) {
      enterprises.getEnterprises().then(res => {
        commit('SET_ENTERPRISES', res.data.filter(arr => arr.isActive))
        state.enterpriseOptions = [{ text: 'Nenhuma das opções', value: null }]
        state.enterpriseOptions.push(...state.enterprises.map(arr => ({ text: arr.fantasy_name || arr.full_name, value: arr.id })).sort((a, b) => a.text.localeCompare(b.text)))
      })
    },
    fetchEnterpriseById({ commit }, id) {
      enterprises.getEnterpriseById(id).then(res => commit('SET_ENTERPRISE_DATA', res.data))
    },
  },
}
