<template>
  <div
    id="page-title"
    class="d-flex align-items-center w-50"
  >
    <b-button
      v-if="!dashboardHideButton"
      class="back-button"
      variant="primary"
      @click="goBack()"
    >
      <VSIcons name="Back" />
    </b-button>
    <div v-if="$route.meta.title">
      <span class="page-name d-flex justify-content-start text-nowrap">
        {{ $route.meta.title }}
      </span>
    </div>
    <VSIcons
      v-if="$route.meta.subTitle"
      name="ArrowRightTitle"
      height="12"
      width="12"
    />
    <div v-if="$route.meta.subTitle">
      <span class="page-subtitle d-flex justify-content-start text-nowrap">
        {{ subTitle }}
      </span>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import VSIcons from '@/assets/icons/VSIcons.vue'

export default {
  components: {
    BButton,
    VSIcons,
  },
  computed: {
    ...mapGetters({ subTitle: 'app/getSubTitle' }),
    dashboardHideButton() {
      return this.$route.name === 'home'
    },
  },
  methods: {
    goBack() {
      return window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
  },
}
</script>

<style lang="scss" scoped>
#page-title {
  margin: 4px 0 14px 0;
  @media (max-width: 767px) {
    display: block;
  };
}
.back-button {
  width: 34px;
  height: 34px;
  border-radius: 8px;
  margin: 0 .8rem 0 0;
  padding: 0;
}
.page-name {
  font-size: 21px;
  font-weight: 600;
}
.page-subtitle {
  font-size: 21px;
  font-weight: 600;
  color:#7D8E9E;
  @media (max-width: 767px) {
    visibility: hidden;
  };
}
</style>
