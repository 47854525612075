import logbook from '@/services/logbook'

export default {
  namespaced: true,
  state: {
    logbookData: null,
    logbooks: [],
  },
  getters: {
    getLogbookData: state => state.logbookData,
    getLogbooks: state => state.logbooks,
    getLogbooksLicensing: state => id => state.logbooks.filter(arr => arr.lincensing_id === Number(id)),
    getLogbooksLicenseConditions: state => id => state.logbooks.filter(arr => arr.licensingCondition_id === Number(id)),
  },
  mutations: {
    SET_LOGBOOK_DATA(state, payload) {
      state.logbookData = payload
    },
    SET_LOGBOOKS(state, payload) {
      state.logbooks = payload
    },
  },
  actions: {
    fetchLogbooks({ commit }) {
      logbook.getLogbooks().then(res => commit('SET_LOGBOOKS', res.data.filter(arr => arr.isActive)))
    },
    fetchLogbookById({ commit }, id) {
      logbook.getLogbookById(id).then(res => commit('SET_LOGBOOK_DATA', res.data))
    },
  },
}
