<template>
  <div>
    <h4 class="modal-title">
      {{ properties.action === 'edit' ? 'Editar' : properties.action === 'create' ? 'Adicionar' : 'Detalhar' }} operações
    </h4>
    <validation-observer ref="form">
      <h6>Dados do Resíduo</h6>
      <b-row>
        <b-col md="3">
          <validation-provider
            v-slot="{ errors }"
            name="resíduo"
            rules="required"
          >
            <b-form-group label="Resíduo">
              <b-input-group>
                <b-form-select
                  id="inventario"
                  v-model="item.inventory_id"
                  :disabled="disbleInputs"
                  :options="wasteInventoryOptions"
                  @input="fillInventoryData(item.inventory_id)"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="2">
          <b-form-group label="Código Interno">
            <b-input-group>
              <b-form-input
                id="codigo-interno"
                v-model="internal_code"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <validation-provider
            v-slot="{ errors }"
            name="código resíduo"
            rules="max:50"
          >
            <b-form-group label="Cód Resíduo">
              <b-input-group>
                <b-form-input
                  id="codigo-residuo"
                  v-model="item.waste_code"
                  type="text"
                  disabled
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          class="pr-0"
          md="3"
        >
          <b-form-group label="Descrição Resíduo">
            <b-input-group>
              <b-form-input
                id="desc-residuo"
                v-model="description_waste"
                type="text"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="1">
          <b-button
            variant="primary"
            class="mt-3"
            @click="openModal()"
            :disabled="disbleInputs"
          >
            <VSIcons
              name="Search"
              color="#FFF"
            />
          </b-button>
        </b-col>
        <b-col md="3">
          <validation-provider
            v-slot="{ errors }"
            name="número MTR"
            rules="required|max:50"
          >
            <b-form-group label="Número MTR">
              <b-input-group>
                <b-form-input
                  id="mtr"
                  v-model="item.mtr_number"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <validation-provider
            v-slot="{ errors }"
            name="situacao"
            rules="required|max:50"
          >
            <b-form-group label="Situação">
              <b-input-group>
                <b-form-input
                  id="situacao"
                  v-model="item.status "
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <b-form-group label="CDF">
            <b-input-group>
              <b-form-input
                id="cdf"
                v-model="item.cdf "
                :disabled="disbleInputs"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <validation-provider
            v-slot="{ errors }"
            name="tecnologia"
            rules="required"
          >
            <b-form-group label="Tecnologia">
              <b-input-group>
                <b-form-select
                  id="tecnologia"
                  v-model="item.technology"
                  :options="technologyOptions"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <validation-provider
            v-slot="{ errors }"
            name="classificação"
            rules="required"
          >
            <b-form-group label="Classificação">
              <b-input-group>
                <b-form-select
                  id="classificacao"
                  v-model="item.classification"
                  :options="classificationsOptions"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <b-form-group label="Estado físico">
            <b-input-group>
              <b-form-select
                id="estado-fisico"
                v-model="item.physical_state"
                :options="physicalStateOptions"
                :disabled="disbleInputs"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Data de emissão">
            <b-input-group>
              <b-form-input
                id="dataEmissao"
                v-model="item.issue_date"
                type="date"
                :disabled="disbleInputs"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <h6>Dados do Transporte e Destinação Final</h6>
      <b-row>
        <b-col md="3">
          <validation-provider
            v-slot="{ errors }"
            name="transportador"
            rules="max:100"
          >
            <b-form-group label="Transportador">
              <b-input-group>
                <b-form-select
                  id="transportador"
                  v-model="item.carrier_id"
                  :disabled="disbleInputs"
                  :options="providersOptions"
                  @change="fetchProviderData(item.carrier_id, 'carrier')"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <b-form-group label="CNPJ ou CPF Transportador">
            <b-input-group>
              <b-form-input
                id="cnpj-transportador"
                v-model="item.carrier_cnpj"
                type="text"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <validation-provider
            v-slot="{ errors }"
            name="CNPJ destinador"
            rules="max:100"
          >
            <b-form-group label="Motorista">
              <b-input-group>
                <b-form-input
                  id="motorista"
                  v-model="item.driver"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <validation-provider
            v-slot="{ errors }"
            name="placa"
            rules="min:6"
          >
            <b-form-group label="Placa">
              <b-input-group>
                <b-form-input
                  id="placa"
                  v-model="item.license_plate"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <validation-provider
            v-slot="{ errors }"
            name="destinador"
            rules="max:100"
          >
            <b-form-group label="Destinador">
              <b-input-group>
                <b-form-select
                  id="destinador"
                  v-model="item.consignee_id"
                  :disabled="disbleInputs"
                  :options="providersOptions"
                  @change="fetchProviderData(item.consignee_id, 'consignee')"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <b-form-group label="CNPJ ou CPF Destinador">
            <b-input-group>
              <b-form-input
                id="cnpj-destinador"
                v-model="item.consignee_cnpj"
                type="text"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Descrição Interna do Destinador">
            <b-input-group>
              <b-form-input
                id="descricao-interna-destinador"
                v-model="item.destinatary_description"
                :disabled="disbleInputs"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Data do transporte">
            <b-input-group>
              <b-form-input
                id="data-transporte"
                v-model="item.transport_date"
                type="date"
                :disabled="disbleInputs"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <validation-provider
            v-slot="{ errors }"
            name="data de recebimento"
            rules="required"
          >
            <b-form-group label="Data de Recebimento">
              <b-input-group>
                <b-form-input
                  id="dataRecebimento"
                  v-model="item.receipt_data"
                  type="date"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <b-form-group label="Resp. Recebimento">
            <b-input-group>
              <b-form-input
                id="responsavel-recebimento"
                v-model="item.reponsible_for_receive"
                :disabled="disbleInputs"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Observações">
            <b-input-group>
              <b-form-input
                id="observacoes"
                v-model="item.observations"
                :disabled="disbleInputs"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group label="Peso bruto">
            <b-input-group>
              <b-form-input
                id="peso-bruto"
                v-model.number="item.gross_weight"
                type="number"
                :disabled="disbleInputs"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Tara Caminhão">
            <b-input-group>
              <b-form-input
                id="tara-caminhao"
                v-model.number="item.truck_tare"
                type="number"
                :disabled="disbleInputs"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <validation-provider
            v-slot="{ errors }"
            name="peso líquido/quantidade"
            rules="required|max:50"
          >
            <b-form-group label="Peso Líquido/Quantidade">
              <b-input-group>
                <b-form-input
                  id="quantidade"
                  v-model.number="item.quantity"
                  type="number"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <validation-provider
            v-slot="{ errors }"
            name="unidade"
            rules="required"
          >
            <b-form-group label="Unidade">
              <b-input-group>
                <b-form-select
                  id="unidade"
                  v-model="item.unit"
                  :options="unitOptions"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group label="NF de Saída">
            <b-input-group>
              <b-form-input
                id="nf-saida"
                v-model.number="item.exit_Invoice"
                type="text"
                :disabled="disbleInputs"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Acondicionamento">
            <b-input-group>
              <b-form-select
                id="acondicionamento"
                v-model="item.packaging"
                :options="conditionningOptions"
                :disabled="disbleInputs"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group label="Nome do arquivo">
            <b-input-group>
              <b-form-input
                id="nome-arquivo"
                v-model="item.file_name"
                type="text"
                :disabled="disbleInputs"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="9">
          <b-form-group label="Descrição do arquivo">
            <b-input-group>
              <b-form-input
                id="descricao-arquivo"
                v-model="item.file_description"
                type="text"
                :disabled="disbleInputs"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!disbleInputs">
        <b-col>
          <b-form-group label="Anexar arquivo ">
            <b-form-file
              v-model="files"
              placeholder="Selecione um arquivo ou solte aqui..."
              drop-placeholder="Solte o arquivo aqui..."
              accept=".jpg, .png, .docx, .jpeg, .pdf, .xsls"
            />
            <b-badge
              v-if="files"
              variant="primary"
              class="mt-3"
            >
              {{ files ? files.name : null }}
              <b-button
                variant=""
                class="remove-file-upload"
                @click="files = null"
              >
                <span>
                  x
                </span>
              </b-button>
            </b-badge>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <div class="d-flex justify-content-between mt-2">
      <b-button
        variant="outline-success pr-4 pl-4 mr-5"
        @click="hideModal('Operations')"
      >
        Cancelar
      </b-button>
      <BButton
        v-if="!disbleInputs"
        variant="primary"
        @click="saveChanges()"
      >
        Salvar
      </BButton>
      <BButton
        v-else
        variant="primary"
        @click="() => {
          properties.action = 'edit'
          disbleInputs = false
        }"
      >
        Editar
      </BButton>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup, BInputGroup, BFormInput, BRow, BCol, BFormFile, BBadge, BButton, BFormSelect,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import waste from '@/services/waste'
import upload from '@/services/upload'
import VSIcons from '@/assets/icons/VSIcons.vue'

export default {
  components: {
    BFormGroup,
    BInputGroup,
    BFormInput,
    BRow,
    BCol,
    BFormFile,
    BBadge,
    BButton,
    BFormSelect,
    VSIcons,
  },
  props: {
    properties: {
      type: Object,
      default: null,
    },
    openModalSearchCode: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      disbleInputs: false,
      files: null,
      item: {
        consignee_cnpj: '', carrier_cnpj: '', waste_code: '', classification: '', physical_state: '',
      },
      internal_code: '',
      description_waste: '',
      unitOptions: [
        { text: 'Nenuma das opções', value: null },
        'Metro cúbico', 'Litro', 'Quilograma', 'Tonelada', 'Unidade',
      ],
      technologyOptions: [
        { text: 'Nenuma das opções', value: null },
        'Aterro',
        'Auto clave',
        'Blendagem para coprocessamento',
        'Compostagem',
        'Coprocessamento',
        'Descontaminação de Lâmpadas',
        'Estação de Transferência de RSU',
        'Gaseificação',
        'Incineração',
        'Microondas', 'Reciclagem',
        'Recuperação Energética',
        'Rerrefino',
        'Tratamento de Efluentes',
        'Tratamento Térmico',
        'Uso Agrícola',
        'Outros',
      ],
    }
  },
  computed: {
    ...mapGetters({
      classificationsOptions: 'waste/getClassificationsOptions',
      physicalStateOptions: 'waste/getPhysicalStateOptions',
      conditionningOptions: 'waste/getConditionningOptions',
      providersOptions: 'provider/getProviderOptions',
      wasteInventory: 'waste/getWasteInventory',
      wasteData: 'waste/getWasteCode',
      wasteInventoryOptions: 'waste/getWasteInventoryOptions',
    }),
  },
  created() {
    this.fetchProviders()
    this.fetchWasteInventory(this.$route.params.id)
    if (this.properties.action === 'read' || this.properties.action === 'edit') {
      this.item = this.properties.item
      this.fillInventoryDataEdit(this.item.inventory_id)
      this.fillWasteDataEdit(this.item.waste_code)
    }
    if (this.properties.action === 'read') { this.disbleInputs = true }
  },
  methods: {
    ...mapActions({
      fetchWasteOperation: 'waste/fetchWasteOperation',
      fetchUploads: 'upload/fetchUploads',
      fetchProviders: 'provider/fetchProviders',
      fetchWasteInventory: 'waste/fetchWasteInventory',
    }),

    fetchProviderData(id, type) {
      if (type === 'consignee') {
        const item = this.providersOptions.filter(arr => arr.value === id)[0]
        this.item.consignee_cnpj = this.maskCpfCnpj(item.cnpjCpf)
      } else {
        const item = this.providersOptions.filter(arr => arr.value === id)[0]
        this.item.carrier_cnpj = this.maskCpfCnpj(item.cnpjCpf)
      }
    },

    maskCpfCnpj(value) {
      if (value) {
        const onlyNumbers = value.replace(/[^0-9]/g, '')
        if (onlyNumbers.length <= 11) {
          return onlyNumbers.replace(/^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/, '$1.$2.$3-$4')
        }
        return onlyNumbers.replace(/^([\d]{2})([\d]{3})([\d]{3})([\d]{4})([\d]{2})$/, '$1.$2.$3/$4-$5')
      }
      return ''
    },

    fillInventoryData(id) {
      this.item.waste_code = ''
      this.item.classification = ''
      this.item.physical_state = ''
      this.item.internal_code = ''
      const inventoryWaste = this.wasteInventory.filter(arr => arr.id === id)[0]
      this.item.waste_code = inventoryWaste.residue_code
      this.item.classification = inventoryWaste.classification
      this.item.physical_state = inventoryWaste.physical_state
      this.internal_code = inventoryWaste.internal_code
      const Waste = this.wasteData.filter(arr => arr.codResiduo.startsWith(this.item.waste_code))[0]
      this.description_waste = Waste.descricao3
    },

    fillInventoryDataEdit(id) {
      const inventoryWaste = this.wasteInventory.filter(arr => arr.id === id)[0]
      this.internal_code = inventoryWaste.internal_code
    },

    fillWasteDataEdit(code) {
      const Waste = this.wasteData.filter(arr => arr.codResiduo.startsWith(code))[0]
      this.description_waste = Waste.descricao3
    },

    saveChanges() {
      this.$refs.form.validate().then(success => {
        delete this.item.inventory
        delete this.item.waste
        delete this.item.carrier
        delete this.item.consignee

        if (success) {
          this.item.waste_id = Number(this.$route.params.id)
          if (this.properties.action === 'create') {
            waste.createWasteOperation(this.item).then(res => {
              this.createdMessage()
              this.createUpload(res.data.id)
              this.fetchWasteInventory(this.$route.params.id)
              this.fetchWasteOperation(this.$route.params.id)
            })
          } else if (this.properties.action === 'edit') {
            waste.editWasteOperation(this.item).then(() => {
              this.updateMessage()
              this.fetchWasteInventory(this.$route.params.id)
              this.fetchWasteOperation(this.$route.params.id)
            })
          }
          this.hideModal('Operations')
        }
      })
    },
    createdMessage() {
      this.toast({
        title: 'Criado !',
        text: 'Cadastro realizado com sucesso.',
        variant: 'success',
      })
    },
    updateMessage() {
      this.toast({
        title: 'Editado !',
        text: 'Cadastro atualizado com sucesso.',
        variant: 'success',
      })
    },
    createUpload(id) {
      if (this.files) {
        const formatData = new FormData()
        formatData.append('attachment_file', this.files)
        formatData.append('file_name', this.item.file_name)
        formatData.append('file_description', this.item.file_description)
        formatData.append('operation_id', id)
        formatData.append('waste_id', this.$route.params.id)
        const formUpload = formatData
        upload.createUploadFile(formUpload).then(() => {
          this.fetchUploads()
        })
      }
    },
    openModal() {
      this.openModalSearchCode()
    },
    receiveWasteData(data) {
      this.item.waste_code = data.codResiduo
      this.description_waste = data.descricao3
    },
  },
}
</script>

<style>

</style>
