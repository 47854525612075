export default [
  {
    path: '/fornecedor',
    name: 'provider',
    component: () => import(/* webpackChunkName: "provider" */ '@/views/provider/Provider.vue'),
    meta: {
      title: 'Fornecedor',
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'supplier',
    },
  },
  {
    path: '/fornecedor/:id/detalhes',
    name: 'provider-details',
    component: () => import(/* webpackChunkName: "provider-details" */ '@/views/provider/ProviderDetails.vue'),
    meta: {
      title: 'Fornecedor',
      subTitle: true,
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'supplier',
    },
  },
]
