<template>
  <div id="app">
    <ContentWithSidebar v-if="$route.meta.menu" />
    <router-view v-else />
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import moment from 'moment'
import ContentWithSidebar from './components/layout/ContentWithSidebar.vue'
import users from '@/services/users'
import useJwt from '@/auth/jwt/useJwt'
import api from '@/libs/axios.js'
import accounts from './store/accounts'


export default {
  components: {
    ContentWithSidebar,
  },
  computed: {
    ...mapGetters({ permission: 'auth/getUserPermissionsAcl', user: 'auth/getUserData' }),
  },
  created() {

    moment.locale('pt-BR')
    if (this.user) {
      if (!this.verifyTokenValid()) {
        useJwt.logout()
        this.$router.push({ name: 'auth' })
      } else {
        users.getUserById(this.user.id).then(res => {
          this.setUserPermissions(res.data.permission)
          this.$ability.update(this.permission)
        }).catch(err => {
          if (err.response.status === 401) {
            useJwt.logout()
            this.$router.push({ name: 'auth' })
          }
        })
      }
    }
  },
  mounted() {
    this.sizeWidth(window.innerWidth)
    this.sizeHeigth(window.innerHeight)
    // eslint-disable-next-line
    const user_infos =localStorage.getItem('id_account_verdesys')
    const role =JSON.parse(localStorage.getItem('user'))
    const id = JSON.parse(localStorage.getItem('id_account_verdesys'))

  if(role.role != "Master" && id != 25){
    console.log('ID AQUIIIIIIIII') 
    try {
      api.get('/accounts/client-subscription/' + user_infos)
    .then(res => {
      
      console.log('iugu atualizado')
      localStorage.setItem('user_infos', JSON.stringify(res.data))
    })

    } catch (error) {
      console.log(error)
    }
    
   
  }

  },
  methods: {
    ...mapActions({ sizeWidth: 'app/setSizeWindow', sizeHeigth: 'app/setHeightWindow' }),
    ...mapMutations({ setUserPermissions: 'auth/SET_PERMISSIONS' }),
    ...mapState({ getAccountsData: 'accounts/getAccountsData' }),
  },
}
</script>
