import upload from '@/services/upload'

export default {
  namespaced: true,
  state: {
    upload: [],
  },
  getters: {
    getUpload: state => state.upload,
  },
  mutations: {
    SET_UPLOAD(state, payload) {
      state.upload = payload
    },
  },
  actions: {
    fetchUploads({ commit }) {
      upload.getUploads().then(res => { commit('SET_UPLOAD', res.data.filter(arr => arr.isActive)) })
    },
  },
}
