<template>
  <div>
    <h4 class="modal-title">
      Gestão de feedback
    </h4>
    <b-row class="border-bottom">
      <b-col class="pr-0">
        <b-form-group label="Data">
          <b-input-group>
            <b-form-input
              disabled
              :value="getDate(properties.createdAt)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        md="1"
        class="pr-0"
      >

        <b-form-group label="Reação">
          <div class="emoji-feedback-input">

            <VSIcons :name="properties.reaction" />
          </div>
        </b-form-group>
      </b-col>
      <b-col class="pr-0">
        <b-form-group label="Nome">
          <b-input-group>
            <b-form-input
              disabled
              :value="properties.user.name"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="E-mail">
          <b-input-group>
            <b-form-input
              disabled
              :value="properties.user.email"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col />
    </b-row>
    <h5 class="mt-3">
      {{ properties.subject }}
    </h5>
    <p class="feedback-message">
      {{ properties.description }}
    </p>

    <div>
      <h6>Anexos</h6>
      <div class="feedbacks-anexos">
        <div
          v-for="anexo in properties.anexos"
          :key="anexo.src"
          class="wrapper-image"
          @click="downloadPictcure(anexo.src)"
        >
          <b-img
            class="feedback-anexo"
            :src="anexo.src"
          />
          <VSIcons
            name="Import2"
            color="white"
            class="icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BInputGroup, BFormInput, BImg,
} from 'bootstrap-vue'
import VSIcons from '@/assets/icons/VSIcons.vue'

export default {
  components: {
    BFormGroup, BInputGroup, BFormInput, BRow, BCol, VSIcons, BImg,
  },
  props: {
    properties: {
      type: Object,
      required: true,
    },
  },
  methods: {
    downloadPictcure() {
    },
    getDate(date) {
      const correctDate = new Date(date)
      return correctDate.toLocaleString('pt-br').split(' ')[0]
    },
  },
}
</script>

<style lang="scss" scoped>
.feedback-message {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #7D8E9E;
  max-height: 10rem;
  overflow-x: auto;
}
.emoji-feedback-input {
  height: 45px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E4E9EB;

  border: 2px solid #D5DDE1;
  border-radius: 5px;
}
.feedbacks-anexos {
  display: flex;
  max-height: 70px;
  overflow-y: hidden;
}
.feedback-anexo {
  border-radius: 13px;
  max-width: 70px;
  min-height: 70px;
  border: 3px solid rgba(160, 174, 185, 0.21);
  &:hover {
    border-color: rgba(27, 31, 34, 0.21);
  }
}

.wrapper-image {
  position: relative;
  margin-right: 8px;
  cursor: pointer;
  .icon {
    transition: .3s;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #A0AEB9;

    height: 25px;
    width: 25px;
    border-radius: 100px;
    padding: 3px;
  }
  &:hover {
    .icon {
     opacity: 1 !important;
    }
  }
}
</style>
