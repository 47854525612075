export default {
  loginEndpoint: '/auth/login',
  recoverPasswordEndpoint: '/auth/recovery',
  sendRecoveryCodeEndpoint: '/auth/recovery/check',
  tokenType: 'Bearer',
  storageTokenKeyName: 'accessToken',
  storageRecoveryTokenKeyName: 'recoveryToken',
  storageUserDataKeyName: 'user',
  storageDatabaseKeyname: 'db_name',
  setIdMyAccountKeyName: 'id_account_verdesys',
}
