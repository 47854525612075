<template>
  <div>
    <h4 class="modal-title">
      Selecione uma nova imagem
    </h4>
    <h6 class="modal-subtitle">
      A imagem deve ser arquivo .jpg/.jpeg/.png
    </h6>
    <div class="mt-3 mb-4 d-flex justify-content-center align-items-center">
      <b-avatar
        size="100px"
        :src="url"
      />
    </div>
    <b-form-file
      v-model="files"
      accept="image/*"
      placeholder="Selecione um arquivo ou solte aqui..."
      drop-placeholder="Solte o arquivo aqui..."
      @change="onFileChange"
    />
    <div class="d-flex justify-content-between mt-5">
      <b-button
        variant="outline-success pr-4 pl-4 mr-5"
        @click="hideModal('AddNewAvatarUser')"
      >
        Cancelar
      </b-button>
      <b-button
        class="btn-grad pr-4 pl-4 m-0"
        @click="setAvatar()"
      >
        Continuar
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  BButton,
  BFormFile,
  BAvatar,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import users from '@/services/users'

export default {
  components: {
    BButton,
    BFormFile,
    BAvatar,
  },
  data() {
    return {
      files: null,
      url: null,
    }
  },
  computed: {
    ...mapGetters({ avatarUser: 'auth/getUserAvatar' }),
    userImage() {
      return localStorage.getItem('user-img')
    },
  },
  created() {
    this.url = this.avatarUser
  },
  methods: {
    ...mapActions({ fetchAvatar: 'auth/fetchAvatar' }),
    onFileChange(e) {
      const file = e.target.files[0]
      this.url = URL.createObjectURL(file)
    },
    setAvatar() {
      const formatData = new FormData()
      formatData.append('image', this.files)
      users.setMyAvatar(formatData).then(() => {
        this.hideModal('AddNewAvatarUser')
        this.fetchAvatar()
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.preview {
  height: 100px;
  width: 100px;
  border-radius: 100%;
}
</style>
