<template>
  <div>
    <h2>Criar nova senha</h2>
    <b-form-group label="Senha">
      <label class="d-block"> Senha Atual </label>
      <b-input-group class="input-group-merge disabled">
        <b-form-input
          id="password"
          placeholder="Senha"
          type="password"
        />
        <b-input-group-append is-text>
          <VSIcons
            name="eye"
            color="#A0AEB9"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <label class="d-block"> Nova senha </label>
    <b-form-group
      label="Senha"
      class="d-block"
    >
      <b-input-group class="input-group-merge disabled">
        <b-form-input
          id="password"
          placeholder="Senha"
          type="password"
        />
        <b-input-group-append is-text>
          <VSIcons
            name="eye"
            color="#A0AEB9"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <label class="d-block"> Confirmar nova senha </label>
    <b-form-group
      label="Senha"
      class="d-block"
    >
      <b-input-group class="input-group-merge disabled">
        <b-form-input
          id="password"
          placeholder="Senha"
          type="password"
        />
        <b-input-group-append is-text>
          <VSIcons
            name="eye"
            color="#A0AEB9"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <div class="d-flex justify-content-between">
      <BButton
        variant="outline-primary"
        @click="hideModal('NewPassword')"
      >
        Cancelar
      </BButton>
      <BButton variant="primary">
        Continuar
      </BButton>
    </div>
  </div>
</template>

<script>
import {
  BInputGroup, BFormInput, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import VSIcons from '@/assets/icons/VSIcons.vue'

export default {
  components: {
    BInputGroup, BFormInput, BInputGroupAppend, VSIcons, BButton,
  },
}
</script>

<style lang='scss' scoped>
label {
margin: 21px 0 5px 0;
font-weight: 600;
font-size: 11px;
line-height: 13px;
letter-spacing: 0.75px;
color: #33383C;
}
.input-group.disabled .input-group-text {
  background-color: transparent;
}
button {
  width: 190px;
  margin-top: 34.5px;
}
</style>
