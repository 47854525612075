import sectors from '@/services/sector'

export default {
  namespaced: true,
  state: {
    sectors: [],
    sectorData: null,
    sectorsOptions: [{ text: 'Nenhuma das opções', value: null }],
  },
  getters: {
    getSectors: state => state.sectors,
    getSectorData: state => state.sectorData,
    getSectorsOptions: state => state.sectorsOptions,
  },
  mutations: {
    SET_SECTOR_DATA(state, payload) {
      state.sectorData = payload
    },
    SET_SECTORS(state, payload) {
      state.sectors = payload
    },
  },
  actions: {
    fetchSectors({ state, commit }) {
      sectors.getSectors().then(res => {
        commit('SET_SECTORS', res.data)
        state.sectorsOptions = [{ text: 'Nenhuma das opções', value: null }]
        state.sectorsOptions.push(...state.sectors.map(arr => ({ text: arr.name, value: arr.id })).sort((a, b) => a.text.localeCompare(b.text)))
      })
    },
    fetchSectorById({ commit }, id) {
      sectors.getSectorById(id).then(res => commit('SET_SECTOR_DATA', res.data))
    },
  },
}
