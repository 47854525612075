export default [
  {
    path: '/relatorios',
    name: 'reports',
    component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/Reports.vue'),
    meta: {
      title: 'Relatórios',
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'reports',
    },
  },
]
