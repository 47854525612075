/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { canNavigate } from '@/libs/acl/routeProtection'
import { checkUserPlanActive, checkUserAccessDate } from '@/libs/acl/checkUserPlanRoutes'
import { checkUserMaster } from '@/libs/acl/checkUserMaster'

// routes
import auth from './auth'
import home from './home'
import company from './company'
import enterprise from './enterprise'
import publicAgencies from './public-agencies'
import licenseType from './license-type'
import provider from './provider'
import licensing from './licensing'
import myAccount from './my-account'
import help from './help'
import fileImport from './file-import'
import userManagement from './user-management'
import license from './lincense'
import waste from './waste'
import managementVerdesys from './management-verdesys'
import NotFound from './404'
import notAuth from './401'
import reports from './reports'
import useJwt from '@/auth/jwt/useJwt'


Vue.use(VueRouter)

const routes = [
  {
    path: '/relatio',
    name: 'relatorio',
    component: () => import(/* webpackChunkName: "waste" */ '@/components/reports/ReportDaily.vue'),
  },
  {
    path: '/assinar-plano',
    name: 'change plan',
    component: () => import(/* webpackChunkName: "my-account" */ '@/views/change-plan/ChangePlan.vue'),
    meta: {
      title: 'assinar-plano',
      subTitle: true,
      menu: true,
      authRequired: true,
      action: 'read',
      resource: 'dashboard',
      canAccessUserPlanDisabled: true,
    },


  },
  ...notAuth,
  ...NotFound,
  ...auth,
  ...home,
  ...company,
  ...enterprise,
  ...publicAgencies,
  ...licenseType,
  ...provider,
  ...licensing,
  ...myAccount,
  ...help,
  ...fileImport,
  ...license,
  ...waste,
  ...reports,
  ...userManagement,
  ...managementVerdesys,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes,
})

router.beforeEach((to, from, next) => {
  store.commit('app/SET_SIDEBAR_COLLAPSED', true)
  const id = localStorage.getItem('id_account_verdesys')
  if (to.meta.authRequired) {
    if (!store.state.auth.isUserLoggedIn()) {
      next({ name: 'auth' })
    } else if (!canNavigate(to)) {
      next({ name: '401' })
    }
    if (checkUserPlanActive() === false && !to.meta.canAccessUserPlanDisabled) {
      next({ name: '401' })
    }
    if (id !== '25' && id !== '16'  ) {
    if (checkUserAccessDate() === false && !to.meta.canAccessUserPlanDisabled) {
      next({ name: '401' })
      console.log('CheckuserPlan deu certo', checkUserPlanActive())
      console.log('checkuseracess deu certo', checkUserAccessDate())
    }
  }
    if (checkUserMaster() === 'Administrador' && to.meta.masterManagement) {
      next({ name: '401' })
    }
    if (store.state.auth.isUserLoggedIn()) {
      if (!(new Date(useJwt.decodeToken().exp * 1000) > new Date())) {
        useJwt.logout()
        next({ name: 'auth' })
      }
    }
    if (store.state.app.itensDelete.itens) {
      if (store.state.app.itensDelete.itens.length > 0) {
        store.commit('app/SET_ITENS_DELETE', { itens: [], api: '' })
        return next()
      }
    }
  } else if (to.name === 'auth' && store.state.auth.isUserLoggedIn()) {
    next({ name: 'home' })
  }
  return next()
})

export default router
