<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.66667 14.6666C12.3485 14.6666 15.3333 11.6818 15.3333 7.99998C15.3333 4.31808 12.3485 1.33331 8.66667 1.33331C4.98477 1.33331 2 4.31808 2 7.99998C2 11.6818 4.98477 14.6666 8.66667 14.6666Z"
      fill="white"
    />
    <path
      d="M11.1411 10.62C11.0544 10.62 10.9677 10.6 10.8877 10.5466L8.82107 9.31331C8.30773 9.00665 7.92773 8.33331 7.92773 7.73998V5.00665C7.92773 4.73332 8.1544 4.50665 8.42773 4.50665C8.70107 4.50665 8.92773 4.73332 8.92773 5.00665V7.73998C8.92773 7.97998 9.12773 8.33331 9.3344 8.45331L11.4011 9.68665C11.6411 9.82665 11.7144 10.1333 11.5744 10.3733C11.4744 10.5333 11.3077 10.62 11.1411 10.62Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
}
</script>
