<template>
  <div>
    <div class="j-c-center">
      <b-avatar
        :variant="properties.color"
        size="70px"
        :text="properties.name | avatarText"
      />
    </div>
    <div class="j-c-center mt-3">
      <b-badge
        class="cargo-badge"
        variant="secondary"
      >
        <span
          v-if="properties.department"
          class="cargo-contatc j-c-center text-nowrap"
        >
          {{ properties.department }}
        </span>
      </b-badge>
    </div>
    <div class="mt-3 text-center">
      <h5 class="mb-0">
        {{ properties.name }}
      </h5>
      <span
        v-if="properties.job_position"
        class="cargo-information"
      >
        <VSIcons
          class="mr-1"
          name="Medal"
        />
        {{ properties.job_position }}
      </span>
      <p class="contact-observ mt-2">
        {{ properties.note }}
      </p>
    </div>
    <div
      v-if="properties.email || properties.birth_date"
      class="mb-3"
    >
      <h6>Dados Pessoais</h6>
      <span
        v-if="properties.email"
        class="contact-info d-flex align-items-center"
      >
        <VSIcons
          class="icon-contact-modal"
          name="Sms"
          color="#7D8E9E"
          width="16"
          height="16"
        />
        {{ properties.email }}
      </span>
      <span
        v-if="properties.birth_date"
        class="contact-info d-flex align-items-center"
      >
        <VSIcons
          class="icon-contact-modal"
          name="Cake"
          color="#7D8E9E"
          width="16"
          height="16"
        />
        {{ properties.birth_date | formatDate }}
      </span>
    </div>
    <div class="telephon-list">
      <div
        v-for="phones in properties.telephone"
        :key="phones.telephone_number"
        class="mb-3 d-flex justify-content-between align-items-center"
      >
        <div>
          <h6 class="m-0 font-weight-bold">
            {{ phones.telephone_type }}
          </h6>
          <span class="phone-contact m-0">{{ phones.telephone_number }}</span>
        </div>
        <div>
          <b-button
            v-if="phones.whatsapp_icon"
            variant="primary"
            class="button-icon-modal"
            @click="openWhatsapp(phones.telephone_number)"
          >
            <VSIcons
              name="Whatsapp"
              color="#fff"
              width="16"
              height="16"
            />
          </b-button>
          <a :href="`tel:+55${phones.telephone_number}`">
            <b-button
              variant="success"
              class="button-icon-modal ml-3"
            >
              <VSIcons
                name="CallCalling"
                color="#fff"
                width="16"
                height="16"
              />
            </b-button>
          </a>
        </div>
      </div>
    </div>
    <div class="close-button">
      <b-button
        class="btn-grad w-50"
        variant="primary"
        @click="hideModal('ContactDetails')"
      >
        Sair
      </b-button>
    </div>
  </div>
</template>

<script>
import { BAvatar, BBadge, BButton } from 'bootstrap-vue'
import { formatDate, avatarText, onlyNumber } from '@core/utils/filter'
import VSIcons from '@/assets/icons/VSIcons.vue'

export default {
  components: {
    BAvatar,
    BBadge,
    BButton,
    VSIcons,
  },
  filters: {
    formatDate,
    avatarText,
    onlyNumber,
  },
  props: {
    properties: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openWhatsapp(number) {
      window.open(`https://wa.me/55${onlyNumber(number)}`, 'height=200,width=200')
    },
  },
}
</script>

<style lang="scss" scoped>
.cargo-badge {
  background-color: rgba(160, 174, 185, 0.21) !important;
  color: #7D8E9E;
}
.cargo-contatc {
  height: 28px;
  min-width: 90px;
  font-size: 13px;
  font-weight: 500;

  border-radius: 3px;
}
.cargo-information {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  color: #7D8E9E;
}

.contact-observ {
  @media (max-width: 1366px) {
    max-height: 160px;
  }
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  color: #808080;
}

.icon-contact-modal {
  height: 34px;
  width: 34px;
  background-color: rgba(160, 174, 185, 0.21);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 10px;
}

.contact-info {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #7D8E9E;
}

.phone-contact {
  font-weight: 500;
  font-size: 13px;
  color: #B3B3B3;
}

.button-icon-modal {
  height: 34px;
  width: 34px;
  // background-color: rgba($color: #18AC81, $alpha: .3);
  padding: 0;
  border: 0;
  border-radius: 9px;
}

.close-button {
  display: flex;
  justify-content: center;
  bottom: 0;
}

.telephon-list {
  @media (max-width: 1366px) {
    max-height: 100px;
  }
  max-height: 250px;
  overflow: auto;
  padding: 0 .3rem;
}
</style>
