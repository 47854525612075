import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/libs/axios'
import '@/libs/vee-validate'
import '@/libs/v-mask'
import '@/libs/v-money'
import '@/libs/v-modal'
import '@/libs/sweet-alert'
import '@/libs/toastification'
import '@core/utils/global-functions'
import '@/libs/acl'
import '@/libs/pdfMake'
import '@/libs/v-calendar'

Vue.config.productionTip = false

require('./assets/scss/style.scss')

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
