import feedback from '@/services/feedback'

export default {
  namespaced: true,
  state: {
    feedbacks: [],
    feedbackData: null,
  },
  getters: {
    getFeedbacks: state => state.feedbacks.filter(arr => arr.isActive),
    getFeedbackData: state => state.feedbackData,
  },
  mutations: {
    SET_FEEDBACK_DATA(state, payload) {
      state.feedbackData = payload
    },
    SET_FEEDBACKS(state, payload) {
      state.feedbacks = payload
    },
  },
  actions: {
    fetchFeedbackById({ commit }, id) {
      feedback.getFeedbackById(id).then(res => commit('SET_FEEDBACK_DATA', res.data))
    },
    fetchFeedbacks({ commit }) {
      feedback.getFeedbacks().then(res => commit('SET_FEEDBACKS', res.data))
    },
  },
}
