<template>
  <div>
    <h4
      v-if="properties.module"
      class="modal-title"
    >
      {{ properties.action === 'create' ? 'Adicionar condicionantes e atividades' : properties.action === 'edit' ? 'Editar condicionantes e atividades' : 'Detalhes de condicionantes e atividades' }}
    </h4>
    <h4
      v-else
      class="modal-title"
    >
      {{ properties.action === 'create' ? 'Adicionar documentos e atividades' : properties.action === 'edit' ? 'Editar documentos e atividades' : 'Detalhes de documentos e atividades' }}
    </h4>
    <validation-observer ref="form">
      <b-form class="mt-3">
        <b-row>
          <b-col>
            <validation-provider
              v-slot="{ errors }"
              name="status"
              rules="required"
            >
              <b-form-group label="Status">
                <b-input-group>
                  <b-form-select
                    id="titulo"
                    v-model="item.status"
                    :disabled="disbleInputs"
                    :options="statusOptions"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- <b-col
            v-if="item.status != 'Em execução' && item.status != 'Concluído' && item.status != 'Não iniciado'"
          >
            <b-form-group label="Observação Status">
              <b-input-group>
                <b-form-input
                  id="statusObservation"
                  v-model="item.status_observation"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
            </b-form-group>
          </b-col> -->
          <b-col>
            <validation-provider
              v-slot="{ errors }"
              name="título"
              rules="required|max:100"
            >
              <b-form-group label="Título">
                <b-input-group>
                  <b-form-input
                    id="titulo"
                    v-model="item.title"
                    type="text"
                    :disabled="disbleInputs"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              v-slot="{ errors }"
              name="responsável"
              rules="max:100"
            >
              <b-form-group label="Responsável">
                <b-input-group>
                  <b-form-input
                    id="responsavel"
                    v-model="item.accountable"
                    type="text"
                    :disabled="disbleInputs"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <validation-provider
              v-slot="{ errors }"
              name="data de inicio"
              :rules="item.recurrence ? 'required' : ''"
            >
              <b-form-group label="Data de Inicio">
                <b-input-group>
                  <b-form-input
                    id="prazo"
                    v-model="item.start_date"
                    :disabled="disbleInputs"
                    :max="item.conclusion_date"
                    type="date"
                    @change="setDays()"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider
              v-slot="{ errors }"
              name="prazo de conclusão"
              :rules="item.recurrence ? 'required' : ''"
            >
              <b-form-group label="Prazo de Conclusão">
                <b-input-group>
                  <b-form-input
                    id="prazo"
                    v-model.number="item.deadline"
                    :disabled="disbleInputs"
                    type="number"
                    min="0"
                    @input="setDays(item.deadline)"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <b-form-group label="Data de Conclusão">
              <b-input-group>
                <b-form-input
                  id="prazo"
                  v-model="item.conclusion_date"
                  :disabled="disbleInputs"
                  :min="item.start_date"
                  type="date"
                  @change="setDays()"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Contagem Regressiva">
              <b-input-group>
                <b-form-input
                  id="regressiva"
                  v-model="regressiveCountDays"
                  disabled
                  type="text"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col :md="item.protocol ? '3' : '4'">
            <b-form-group label="Notificação">
              <b-input-group>
                <b-form-select
                  id="notificao"
                  v-model="notificationSelected"
                  :disabled="disbleInputs"
                  :options="optionsNotifications"
                  @change="item.notification.push( {id: notificationSelected })"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col :md="item.protocol ? '3' : '4'">
            <b-form-group label="Fornecedor">
              <b-input-group>
                <b-form-select
                  id="fornecedor"
                  v-model="item.supplier_id"
                  :disabled="disbleInputs"
                  :options="providerOptions"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col :md="item.protocol ? '3' : '4'">
            <b-form-group label="Protocolo">
              <b-input-group>
                <b-form-select
                  id="protocolo"
                  v-model="item.protocol"
                  :disabled="disbleInputs"
                  :options="booleanOptions"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            v-if="item.protocol"
            md="3"
          >
            <b-form-group label="Data de Protocolo">
              <b-input-group>
                <b-form-input
                  id="prazo"
                  v-model="item.protocol_date"
                  :disabled="disbleInputs"
                  type="date"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors }"
              name="Descrição"
              rules="required|max:200"
            >
              <b-form-group label="Descrição">
                <b-input-group>
                  <b-form-textarea
                    id="descricacao"
                    v-model="item.description"
                    :disabled="disbleInputs"
                    type="text"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="properties.module"
            md="6"
          >
            <b-form-group label="Recorrência">
              <b-input-group>
                <b-form-select
                  id="recorrencia"
                  v-model="item.recurrence"
                  :disabled="disbleInputs"
                  :options="recurrenceOptions"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            v-else
            md="6"
          >
            <b-form-group label="Observação">
              <b-input-group>
                <b-form-textarea
                  id="observacao"
                  v-model="item.note"
                  :disabled="disbleInputs"
                  type="text"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <div v-if="properties.action === 'create'">
          <b-row>
            <b-col md="4">
              <validation-provider
                v-slot="{ errors }"
                name="nome do arquivo"
                :rules="files ? 'required' : ''"
              >
                <b-form-group label="Nome do arquivo">
                  <b-input-group>
                    <b-form-input
                      id="nomeArquivo"
                      v-model="filesInfo.file_name"
                      :disabled="disbleInputs"
                      type="text"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="8">
              <b-form-group label="Descrição do arquivo">
                <b-input-group>
                  <b-form-input
                    id="descricaoArquivo"
                    v-model="filesInfo.file_description"
                    :disabled="disbleInputs"
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Anexar arquivo "
              >
                <b-form-file
                  v-model="files"
                  placeholder="Selecione um arquivo ou solte aqui..."
                  drop-placeholder="Solte o arquivo aqui..."
                  accept=".jpg, .png, .docx, .jpeg, .pdf, .xsls"
                />
                <b-badge
                  v-if="files"
                  variant="primary"
                  class="mt-3"
                >
                  {{ files ? files.name : null }}
                  <b-button
                    variant=""
                    class="bg-transparent border-0 size-10"
                    @click="files = null"
                  >
                    <span>
                      x
                    </span>
                  </b-button>
                </b-badge>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-form>
    </validation-observer>
    <div class="d-flex justify-content-between mt-1">
      <b-button
        variant="outline-primary mr-3 pr-5 pl-5"
        @click="hideModal('AddNewDocumentsActivies')"
      >
        Cancelar
      </b-button>
      <b-button
        v-if="!disbleInputs"
        class="btn-grad m-0 pr-5 pl-5"
        @click="saveChanges()"
      >
        Salvar
      </b-button>
      <b-button
        v-else
        class="btn-grad m-0 pr-5 pl-5"
        @click="() => {
          properties.action = 'edit'
          disbleInputs = false
        }"
      >
        Editar
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  BForm, BRow, BCol, BFormGroup, BInputGroup, BFormInput, BFormSelect, BFormTextarea, BFormFile, BBadge, BButton,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import licensing from '@/services/licensing'
import licensingConditions from '@/services/license'
import upload from '@/services/upload'

export default {
  components: {
    BForm, BRow, BCol, BFormGroup, BInputGroup, BFormInput, BFormSelect, BFormTextarea, BFormFile, BBadge, BButton,
  },
  props: {
    properties: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      regressiveCountDays: 0,
      disbleInputs: false,
      item: { deadline: null, notification: [] },
      notificationSelected: null,
      filesInfo: {},
      files: null,
      messageCreated: {
        title: 'Criado!',
        text: 'Cadastro feito com sucesso.',
        variant: 'success',
      },
      messageEdited: {
        title: 'Editado!',
        text: 'Cadastro editado com sucesso.',
        variant: 'success',
      },
    }
  },
  computed: {
    ...mapGetters({
      statusOptions: 'app/getStatusOptions',
      booleanOptions: 'app/getBooleanOptions',
      providerOptions: 'provider/getProviderOptions',
      recordsOptions: 'records/getRecords',
      recurrenceOptions: 'app/recurrenceOptions',
    }),
    optionsNotifications() {
      if (this.properties.module) {
        return this.recordsOptions.filter(arr => arr.licensingCondition_id === Number(this.$route.params.id)).map(arr => ({ text: arr.record_number, value: arr.id }))
      }
      return this.recordsOptions.filter(arr => arr.licensing_id === Number(this.$route.params.id)).map(arr => ({ text: arr.record_number, value: arr.id }))
    },
  },
  created() {
    if (this.properties.action === 'read' || this.properties.action === 'edit') {
      this.setDays()
      this.item = this.properties.item
      if (this.properties.item.notification.length) {
        this.notificationSelected = this.properties.item.notification[0].id
      }
      if (this.item.file_name) this.item.file_name = this.properties.item.attachment[0].file_name
      if (this.item.file_description) this.item.file_description = this.properties.item.attachment[0].file_name
    }
    if (this.properties.action === 'read') {
      this.setDays()
      this.disbleInputs = true
    } else {
      this.setDays()
      this.fetchProvider()
      this.fetchRecords()
    }
  },
  methods: {
    ...mapActions({
      fetchProvider: 'provider/fetchProviders',
      fetchLicensingDocuments: 'licensing/fetchLicensingDocuments',
      fetchLicensingConditionsConstraints: 'licensingConditions/fetchLicensingConditionsConstraints',
      fetchUploads: 'upload/fetchUploads',
      fetchRecords: 'records/fetchRecords',
    }),
    saveChanges() {
      this.$refs.form.validate().then(success => {
        if (success) {
          delete this.item.annotations
          if (this.properties.action === 'create') {
            if (this.properties.module) {
              this.createConstraints()
            } else {
              this.createDocumentActivies()
            }
          } else if (this.properties.module) {
            this.editConstraints()
          } else {
            this.editDocumentsActivies()
          }
        }
      })
    },
    createConstraints() {
      this.item.licensingCondition_id = Number(this.$route.params.id)
      licensingConditions.createLicensingConditionsConstraints(this.item).then(res => {
        if (this.files) {
          this.createUpload(res.data.id)
        }
        this.fetchLicensingConditionsConstraints(this.$route.params.id)
        this.hideModal('AddNewDocumentsActivies')
        this.toast(this.messageCreated)
        if (res.data.statusCode !== 400) {
          this.toast(this.messageCreated)
        } else {
          this.toast({
            title: 'Erro',
            text: res.data.message,
            variant: 'erro',
          })
          this.toast(this.messageCreated)
        }
      })
    },
    editConstraints() {
      delete this.item.annotations
      delete this.item.attachment
      delete this.item.supplier
      this.item.licensingCondition_id = Number(this.$route.params.id)
      licensingConditions.editLicensingConditionsConstraints(this.item).then(() => {
        this.fetchLicensingConditionsConstraints(this.$route.params.id)
        this.hideModal('AddNewDocumentsActivies')
        this.toast(this.messageEdited)
      })
    },
    createDocumentActivies() {
      this.item.licensing_id = Number(this.$route.params.id)
      licensing.createLicensingDocuments(this.item).then(res => {
        if (this.files) {
          this.createUpload(res.data.id)
        }
        this.fetchLicensingDocuments(this.$route.params.id)
        this.hideModal('AddNewDocumentsActivies')
        this.toast(this.messageCreated)
      })
    },
    editDocumentsActivies() {
      delete this.item.annotations
      delete this.item.attachment
      delete this.item.supplier
      this.item.licensing_id = Number(this.$route.params.id)
      licensing.editLicensingDocuments(this.item).then(() => {
        this.fetchLicensingDocuments(this.$route.params.id)
        this.hideModal('AddNewDocumentsActivies')
        this.toast(this.messageEdited)
      })
    },
    createUpload(id) {
      const formatData = new FormData()
      formatData.append('attachment_file', this.files)
      formatData.append('file_name', this.filesInfo.file_name)
      formatData.append('file_description', this.filesInfo.file_description)
      formatData.append('docsActivity_id', id)
      if (this.properties.module) {
        formatData.append('licensingCondition_id', this.$route.params.id)
      } else formatData.append('licensing_id', this.$route.params.id)

      const formUpload = formatData
      upload.createUploadFile(formUpload).then(() => {
        this.fetchUploads()
      })
    },
    setDays(newValue) {
      if (newValue) {
        this.calcDays(newValue)
      }
      this.item.deadline = (new Date(this.item.conclusion_date) - new Date(this.item.start_date)) / (60 * 60 * 24 * 1000) || 0
      this.regressiveCountDays = this.regressiveCount(this.item.conclusion_date)
    },
    calcDays(newValue) {
      // eslint-disable-next-line
      Date.prototype.addDays = function (days) {
        const date = new Date(this.valueOf())
        date.setDate(date.getDate() + days + 1)
        return date
      }
      const today = new Date()
      if (!this.item.start_date) {
        this.item.start_date = moment(today).format('YYYY-MM-DD')
      }
      const startDate = new Date(this.item.start_date)

      this.item.conclusion_date = moment(startDate.addDays(newValue)).format('YYYY-MM-DD')
    },
  },
}
</script>
<style lang="scss" scoped>
.size-10 {
  padding: 0;
  margin: 0;
  width: 8px !important;
  height: 19px !important;
}
</style>
