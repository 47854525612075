<template>
  <div class="modal-content">
    <h2>Escolha um plano</h2>
    <div class="d-flex justify-content-around">
      <ChoosePlanItem
        v-for="plan in plans"
        :key="plan.id"
        :plan="plan"
        :current-plan="accountsData.plan_id"
        :account-id="accountsData.id"
      />
    </div>
    <b-button
      variant="primary"
      class="d-block mx-auto cancel-button"
      @click="hideModal('ChoosePlan')"
    >
      Cancelar
    </b-button>

  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ChoosePlanItem from './choose-plans-item/ChoosePlanItem.vue'
import plan from '@/services/plan'

export default {
  components: {
    ChoosePlanItem,
    BButton,
  },
  data() {
    return {
      plans: [],
    }
  },
  computed: { ...mapGetters({ accountsData: 'accounts/getAccountsData' }) },
  created() {
    this.fetchPlans()
  },
  methods: {
    fetchPlans() {
      plan.getPlans().then(res => {
        this.plans = res.data
      })
    },
  },
}
</script>

<style lang='scss' scoped>
.modal-content {
  box-shadow: none!important;
}
h2 {
font-size: 21px;
font-weight: 600;
text-align: center;
letter-spacing: 0.75px;
color: #33383C;
}
p {
font-weight: 500;
color: #7D8E9E;
font-size: 13px;
line-height: 100%;
text-align: center;
line-height: 100%;
letter-spacing: 0.75px;

}
.cancel-button {
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
width: 190.5px;
border-radius: 5px;
margin-top: 21px;
}
</style>
