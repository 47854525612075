<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.8 14.32V1.68C6.8 0.48 6.288 0 5.016 0H1.784C0.512 0 0 0.48 0 1.68V14.32C0 15.52 0.512 16 1.784 16H5.016C6.288 16 6.8 15.52 6.8 14.32Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M16 8.72V1.68C16 0.48 15.488 0 14.216 0H10.984C9.712 0 9.2 0.48 9.2 1.68V8.72C9.2 9.92 9.712 10.4 10.984 10.4H14.216C15.488 10.4 16 9.92 16 8.72Z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="6.73164e-08"
        y1="8.17778"
        x2="16"
        y2="8.17778"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#076E5F" />
        <stop
          offset="1"
          stop-color="#18AC81"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="6.73164e-08"
        y1="8.17778"
        x2="16"
        y2="8.17778"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#076E5F" />
        <stop
          offset="1"
          stop-color="#18AC81"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
}
</script>
