import api from '@/libs/axios'

export default {
  getUploads: () => api.get('/system/upload'),
  getUploadsById: id => api.get(`/system/upload/${id}`),
  getLinkDownload: key => api.get(`/system/upload/file/${key}`),
  createUploadFile: form => api.post('system/upload/file', form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  createUploadImage: form => api.post('system/upload/image', form),
  editUpload: (form, id) => api.patch(`/system/upload/${id}`, form),
  deleteUpload: id => api.delete(`/system/upload/${id}`),
  deleteUploads: form => api.delete('/system/upload', form),
}
