<template>
  <div id="toast-content">
    <div class="d-flex align-items-center">
      <VSIcons
        :name="icon"
        :color="colorIcon"
        class="d-flex mr-1"
        width="20"
        height="20"
      />
      <h6
        v-if="title"
        class="mb-0 font-weight-bolder toastification-title"
        v-text="title"
      />
    </div>
    <div class="d-flex align-items-center">
      <div class="d-flex flex-grow-1">
        <div>
          <span
            v-if="text"
            class="d-inline-block text-body"
            v-text="text"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VSIcons from '@/assets/icons/VSIcons.vue'

export default {
  components: {
    VSIcons,
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: 'Flash1',
    },
    colorIcon: {
      type: String,
      default: '#18AC81',
    },
  },
}
</script>
<style lang="scss" scoped>
#toast-content {
  background-color: #fff;
}
.text-body {
  font-weight: normal;
  font-size: 13px;
  color: #7D8E9E !important;
}
.toastification-title {
  font-weight: 600;
  font-size: 16px;
  color: #33383C;
}
</style>
