<template>
  <div id="verde-sys-icons">
    <component
      :is="name"
      :color="color"
      :color-primary="colorPrimary"
      :color-secondary="colorSecondary"
      :width="width"
      :height="height"
      :opacity="opacity"
    />
  </div>
</template>

<script>
import VerdeSysGreen from './icons-components/verde-sys-green-logo.vue'
import HomeTrendUp from './icons-components/home-trend-up.vue'
import Buildings from './icons-components/buildings.vue'
import Briefcase from './icons-components/briefcase.vue'
import Shield from './icons-components/shield.vue'
import ShieldTick from './icons-components/shield-tick.vue'
import TaskSquare from './icons-components/task-square.vue'
import ThreeDCube from './icons-components/3dcube.vue'
import ReceiptEdit from './icons-components/receipt-edit.vue'
import Judge from './icons-components/judge.vue'
import BagTick from './icons-components/bag-tick.vue'
import DocumentText from './icons-components/document-text.vue'
import Menu from './icons-components/menu.vue'
import LogoVerdeSys from './icons-components/logo-verde-sys.vue'
import Back from './icons-components/back.vue'
import SmsSearch from './icons-components/sms-search.vue'
import Eye from './icons-components/eye.vue'
import EyeSlash from './icons-components/eye-slash.vue'
import Search from './icons-components/search-normal-1.vue'
import Notification from './icons-components/notification.vue'
import User from './icons-components/user.vue'
import ArrowDownHeader from './icons-components/arrow-down-header.vue'
import AddSquare from './icons-components/add-square.vue'
import ArrowLeft from './icons-components/arrow-left.vue'
import ArrowRight from './icons-components/arrow-right.vue'
import Call from './icons-components/call.vue'
import Actions from './icons-components/actions.vue'
import Edit from './icons-components/edit.vue'
import TrashSquare from './icons-components/square-trash.vue'
import Recycle from './icons-components/recycle.vue'
import BriefcaseFillGradient from './icons-components/briefcase-fill-gradient.vue'
import ArrowRightTitle from './icons-components/arrow-right-title.vue'
import TickSquare from './icons-components/tick-square.vue'
import Plus from './icons-components/plus.vue'
import UserBold from './icons-components/user-bold.vue'
import MedalStar from './icons-components/medal-star.vue'
import Cake from './icons-components/cake.vue'
import Sms from './icons-components/sms.vue'
import CallCalling from './icons-components/call-calling.vue'
import Whatsapp from './icons-components/whatsapp.vue'
import TickFileFillGradient from './icons-components/tick-file-fill-gradient.vue'
import FileFillGradient from './icons-components/file-fill-gradient.vue'
import TaskSquareFillGradient from './icons-components/task-square-fill-gradient.vue'
import List from './icons-components/list.vue'
import Clock from './icons-components/clock-1.vue'
import Kanban from './icons-components/kanban.vue'
import Setting4 from './icons-components/setting-4.vue'
import Star from './icons-components/star.vue'
import Logout from './icons-components/logout.vue'
import UserOctagon from './icons-components/user-octagon.vue'
import Warning2 from './icons-components/warning-2.vue'
import Monitor from './icons-components/monitor.vue'
import Mobile from './icons-components/mobile.vue'
import InfoCircle from './icons-components/info-circle.vue'
import Cloud from './icons-components/cloud.vue'
import ActionsHorizontal from './icons-components/actions-horizontal.vue'
import Logo from './icons-components/logo.vue'
import TrendUp from './icons-components/trend-up.vue'
import TrendUpFill from './icons-components/trend-up-fill.vue'
import NoteFillGradient from './icons-components/note-1.vue'
import PaperClip from './icons-components/paperclip.vue'
import TickCircle from './icons-components/tick-circle.vue'
import CloseCircle from './icons-components/close-circle.vue'
import Flash from './icons-components/flash.vue'
import Flash1 from './icons-components/flash-1.vue'
import Calendar from './icons-components/calendar.vue'
import SearchIcon from './icons-components/search-icon.vue'
import Hyphen from './icons-components/hyphen.vue'
import CorrectSign from './icons-components/correct-sign.vue'
import CorrectIconFill from './icons-components/correct-icon-fill.vue'
import ArrowRightGreen from './icons-components/arrow-right-green.vue'
import TrendUpFillGrad from './icons-components/trend-up-fill-grad.vue'
import TrendDownFillGrad from './icons-components/trend-down-fill-grad.vue'
import EmojiHeart from './icons-components/emoji-heart.vue'
import EmojiHappy from './icons-components/emoji-happy.vue'
import EmojiSad from './icons-components/emoji-sad.vue'
import People from './icons-components/people.vue'
import SlantedArrow from './icons-components/slanted-arrow.vue'
import Check from './icons-components/check.vue'
import Import2 from './icons-components/import-2.vue'
import QuestionMark from './icons-components/question-mark.vue'
import Close from './icons-components/close.vue'
import TrendNoFill from './icons-components/trend-no-fill.vue'
import CloseSquare from './icons-components/close-square.vue'
import Medal from './icons-components/medal.vue'
import DocumentDownload from './icons-components/document-download.vue'
import Export2 from './icons-components/export-2.vue'
import TickFile from './icons-components/tick-file.vue'
import EditImageAvatar from './icons-components/edit-image-avatar.vue'
import SmsNotification from './icons-components/sms-notification.vue'

export default {
  components: {
    SlantedArrow,
    VerdeSysGreen,
    Close,
    QuestionMark,
    ArrowRightGreen,
    CorrectIconFill,
    Hyphen,
    CorrectSign,
    SearchIcon,
    HomeTrendUp,
    Calendar,
    Buildings,
    Briefcase,
    Shield,
    ShieldTick,
    TaskSquare,
    ThreeDCube,
    ReceiptEdit,
    Judge,
    BagTick,
    DocumentText,
    Menu,
    Back,
    LogoVerdeSys,
    SmsSearch,
    Eye,
    EyeSlash,
    Search,
    Notification,
    User,
    ArrowDownHeader,
    AddSquare,
    ArrowLeft,
    ArrowRight,
    Call,
    Actions,
    Edit,
    TrashSquare,
    Recycle,
    BriefcaseFillGradient,
    ArrowRightTitle,
    TickSquare,
    Plus,
    UserBold,
    MedalStar,
    Cake,
    Sms,
    CallCalling,
    Whatsapp,
    TickFileFillGradient,
    FileFillGradient,
    TaskSquareFillGradient,
    List,
    Clock,
    Kanban,
    Setting4,
    Star,
    Logout,
    UserOctagon,
    Warning2,
    Monitor,
    Mobile,
    InfoCircle,
    Cloud,
    ActionsHorizontal,
    Logo,
    TrendUp,
    TrendUpFill,
    NoteFillGradient,
    PaperClip,
    TickCircle,
    CloseCircle,
    Flash,
    Flash1,
    TrendUpFillGrad,
    TrendDownFillGrad,
    EmojiHeart,
    EmojiHappy,
    EmojiSad,
    People,
    Check,
    Import2,
    TrendNoFill,
    CloseSquare,
    Medal,
    DocumentDownload,
    Export2,
    TickFile,
    EditImageAvatar,
    SmsNotification,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '24',
    },
    height: {
      type: String,
      default: '24',
    },
    color: {
      type: String,
      default: '#FFF',
    },
    colorPrimary: {
      type: String,
      default: '#FFF',
    },
    colorSecondary: {
      type: String,
      default: '#FFF',
    },
    opacity: {
      type: String,
      default: '1',
    },
    fill: {
      type: String,
      default: '#FFF',
    },
  },
}
</script>
