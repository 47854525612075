import licenseType from '@/services/licenseType'

export default {
  namespaced: true,
  state: {
    licenseTypeData: null,
    licenseTypes: [],
    documentsCondintioning: [],
    licenseTypesOptions: [{ text: 'Nenhuma das opções', value: null }],
  },
  getters: {
    getLicenseTypeData: state => state.licenseTypeData,
    getLicenseTypes: state => state.licenseTypes,
    getDocumentsCondintioning: state => state.documentsCondintioning,
    getDocuments: state => state.documentsCondintioning.filter(arr => arr.document),
    getConditioning: state => state.documentsCondintioning.filter(arr => arr.conditioning),
    getLicenseTypesOptions: state => state.licenseTypesOptions,
    getLicenseTypesOptionsByPublicAgency: state => id => state.licenseTypes.filter(arr => arr.isActive === true && arr.licensing_agency.id === id).map(arr => ({ text: arr.subject, value: arr.id })),
  },
  mutations: {
    SET_LICENSE_TYPE_DATA(state, payload) {
      state.licenseTypeData = payload
    },
    SET_LICENSE_TYPES(state, payload) {
      state.licenseTypes = payload
    },
    SET_DOCUMENTS_CONDINTIONING(state, payload) {
      state.documentsCondintioning = payload
    },
  },
  actions: {
    fetchLincenseTypes({ state, commit }) {
      licenseType.getLicenseTypes().then(res => {
        commit('SET_LICENSE_TYPES', res.data.filter(arr => arr.isActive))
        state.licenseTypesOptions = [{ text: 'Nenhuma das opções', value: null }]
        state.licenseTypesOptions.push(...state.licenseTypes.map(arr => ({ text: arr.subject, value: arr.id })).sort((a, b) => a.text.localeCompare(b.text)))
      })
    },
    fetchLincenseTypeById({ commit }, id) {
      licenseType.getLicenseTypeById(id).then(res => commit('SET_LICENSE_TYPE_DATA', res.data.filter(arr => arr.isActive)))
    },
    fetchDocumentsCondintioning({ commit }) {
      licenseType.getDocumentsCondintioning().then(res => commit('SET_DOCUMENTS_CONDINTIONING', res.data.filter(arr => arr.isActive)))
    },
    fetchDocumentsCondintioningByLicenseTypeId({ commit }, id) {
      licenseType.getDocumentsCondintioningByLicenseTypeId(id).then(res => commit('SET_DOCUMENTS_CONDINTIONING', res.data.filter(arr => arr.isActive)))
    },
  },
}
