<template>
  <div>
    <h4 class="modal-title">
      Editar um contato
    </h4>
    <h6 class="modal-subtitle">
      Preencha todos os campos para atualizar
      novo contato.
    </h6>
    <div class="mt-4">
      <validation-observer ref="form">
        <b-row>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors }"
              name="nome"
              rules="alpha_spaces|max:50"
            >
              <b-form-group label="Nome">
                <b-input-group>
                  <b-form-input
                    id="nome"
                    v-model="contactData.name"
                    type="text"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors }"
              name="e-mail"
              rules="email|max:50"
            >
              <b-form-group label="E-mail">
                <b-input-group>
                  <b-form-input
                    id="email"
                    v-model="contactData.email"
                    type="email"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group label="Aniversário">
              <b-input-group>
                <b-form-input
                  id="aniversario"
                  v-model="contactData.birth_date"
                  type="date"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors }"
              name="cargo"
              rules="max:30"
            >
              <b-form-group label="Cargo">
                <b-input-group>
                  <b-form-input
                    id="cargo"
                    v-model="contactData.job_position"
                    type="text"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <validation-provider
              v-slot="{ errors }"
              name="departamento"
              rules="max:30"
            >
              <b-form-group label="Departamento">
                <b-input-group>
                  <b-form-input
                    id="departamento"
                    v-model="contactData.department"
                    type="text"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
      <validation-observer ref="telephone">
        <b-row>
          <b-col md="6">
            <validation-provider
              name="telefone"
              rules="required|phone"
            >
              <b-form-group label="Telefone">
                <b-input-group>
                  <b-form-input
                    id="telefone"
                    v-model="telephoneAdd.telephone_number"
                    v-mask="maskTelephone(telephoneAdd.telephone_number)"
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="6"
            class="d-flex c-center"
          >
            <b-form-group
              label="Marcador"
              class="mr-4 w-75"
            >
              <validation-provider
                v-slot="{ errors }"
                name="marcador"
                :rules="telephoneAdd.telephone_number.length > 0 ? 'required' : ''"
              >
                <b-input-group>
                  <b-form-select
                    id="marcardor"
                    v-model="telephoneAdd.telephone_type"
                    :options="optionsMarcador"
                    type="text"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              variant="primary"
              class="add-contador rounded-pill"
              @click="addNewMark(telephoneAdd)"
            >
              <VSIcons name="Plus" />
            </b-button>
          </b-col>
        </b-row>
        <b-row
          v-for="phone in telephone"
          :key="phone.telephone_number"
        >
          <b-col md="6">
            <b-form-group label="Telefone">
              <b-input-group>
                <b-form-input
                  id="telefone"
                  v-model="phone.telephone_number"
                  v-mask="maskTelephone(phone.telephone_number)"
                  type="text"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="d-flex c-center"
          >
            <b-form-group
              label="Marcador"
              class="mr-4 w-75"
            >
              <b-input-group>
                <b-form-select
                  id="marcardor"
                  v-model="phone.telephone_type"
                  :options="optionsMarcador"
                  type="text"
                  disabled
                />
              </b-input-group>
            </b-form-group>
            <b-button
              variant="transparent"
              class="add-contador rounded-pill"
              @click="removeMark(phone)"
            >
              <VSIcons
                color="#A0AEB9"
                name="Close"
                class="d-flex"
              />
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group label="Observações">
              <b-form-textarea
                id="textarea"
                v-model="contactData.note"
                rows="4"
                max-rows="5"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <div class="d-flex justify-content-between mt-3">
        <b-button
          block
          variant="outline-primary mr-3 w-30"
          @click="hideModal('EditContact')"
        >
          Cancelar
        </b-button>
        <b-button
          block
          class="btn-grad m-0 w-30"
          @click="saveChanges(contactData)"
        >
          Continuar
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BInputGroup, BRow, BCol, BButton, BFormSelect, BFormTextarea,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import VSIcons from '@/assets/icons/VSIcons.vue'
import contacts from '@/services/contacts'
import { maskPhone } from '@/@core/mixins/ui/forms'

export default {
  components: {
    BFormGroup, BFormInput, BInputGroup, BRow, BCol, BButton, BFormSelect, VSIcons, BFormTextarea,
  },
  mixins: [
    maskPhone,
  ],
  props: {
    properties: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optionsMarcador: ['Celular', 'Casa', 'Fixo', 'WhatsApp'],
      telephoneAdd: {
        telephone_type: '', telephone_number: '', whatsapp_icon: '', principal_icon: '',
      },
      telephone: [],
      contactData: {
        company_id: null,
        agency_id: null,
        project_id: null,
        supplier_id: null,
      },
    }
  },
  created() {
    this.telephone = this.properties.item.telephone
    this.contactData = this.properties.item
  },
  methods: {
    ...mapActions({ fetchContacts: 'contacts/fetchContacts' }),

    removeMark(obj) {
      const index = this.telephone.indexOf(obj)

      if (index > -1) {
        this.telephone.splice(index, 1)
      }
    },
    addNewMark(obj) {
      this.$refs.telephone.validate().then(success => {
        if (success) {
          this.telephone.push({
            telephone_number: obj.telephone_number,
            telephone_type: obj.telephone_type,
            principal_icon: obj.principal_icon,
            whatsapp_icon: obj.telephone_type === 'WhatsApp',
          })
          this.telephoneAdd.telephone_number = ''
          this.telephoneAdd.telephone_type = ''
          this.telephoneAdd.whatsapp_icon = ''
          this.telephoneAdd.principal_icon = ''
          this.$refs.telephone.reset()
        }
      })
    },

    saveChanges(form) {
      this.$refs.form.validate().then(success => {
        if (success) {
          // eslint-disable-next-line no-param-reassign
          delete form.company
          // eslint-disable-next-line no-param-reassign
          delete form.project
          // eslint-disable-next-line no-param-reassign
          delete form.supplier
          this.contactData.telephone = this.telephone
          contacts.editContact(this.contactData).then(() => {
            this.toast({
              title: 'Atualizado!',
              text: 'Contato atualizado com sucesso.',
              variant: 'success',
            })
          })
          this.hideModal('EditContact')
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.add-contador {
  height: 45px;
}
</style>
