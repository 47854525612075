var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.properties.action === 'create')?_c('h2',[_vm._v(" Adicionar responsáveis ")]):_vm._e(),(_vm.properties.action === 'read')?_c('h2',[_vm._v(" Detalhes responsáveis ")]):_vm._e(),(_vm.properties.action === 'edit')?_c('h2',[_vm._v(" Editar responsáveis ")]):_vm._e(),_c('validation-observer',{ref:"form"},[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"nome","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"nome","type":"text","disabled":_vm.disbleInputs},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"cpf","rules":"required|cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CPF"}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"id":"cpf","type":"text","disabled":_vm.disbleInputs},model:{value:(_vm.item.cpf),callback:function ($$v) {_vm.$set(_vm.item, "cpf", $$v)},expression:"item.cpf"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"telefone","rules":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Telefone"}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.maskTelephone(_vm.item.telephone)),expression:"maskTelephone(item.telephone)"}],attrs:{"id":"telefone","type":"text","disabled":_vm.disbleInputs},model:{value:(_vm.item.telephone),callback:function ($$v) {_vm.$set(_vm.item, "telephone", $$v)},expression:"item.telephone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"e-mail","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"E-mail"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"email","type":"text","disabled":_vm.disbleInputs},model:{value:(_vm.item.email),callback:function ($$v) {_vm.$set(_vm.item, "email", $$v)},expression:"item.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"profissão","rules":"max:70"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Profissão"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"profissao","type":"text","disabled":_vm.disbleInputs},model:{value:(_vm.item.occupation),callback:function ($$v) {_vm.$set(_vm.item, "occupation", $$v)},expression:"item.occupation"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"reg. profissional","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Reg. Profissional"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"regProfissional","type":"text","disabled":_vm.disbleInputs},model:{value:(_vm.item.professional_registration),callback:function ($$v) {_vm.$set(_vm.item, "professional_registration", $$v)},expression:"item.professional_registration"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"tipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo"}},[_c('b-input-group',[_c('b-form-select',{attrs:{"id":"tipo","options":_vm.typeOptions,"disabled":_vm.disbleInputs},model:{value:(_vm.item.type),callback:function ($$v) {_vm.$set(_vm.item, "type", $$v)},expression:"item.type"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),(_vm.properties.action === 'create')?_c('div',[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"nome arquivo","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome do Arquivo"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"nomeArquivo","type":"text","disabled":_vm.disbleInputs},model:{value:(_vm.item.file_name),callback:function ($$v) {_vm.$set(_vm.item, "file_name", $$v)},expression:"item.file_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1646030121)})],1),_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{attrs:{"label":"Descrição do Arquivo"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"descricaoArquivo","type":"text","disabled":_vm.disbleInputs},model:{value:(_vm.item.file_description),callback:function ($$v) {_vm.$set(_vm.item, "file_description", $$v)},expression:"item.file_description"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Anexar arquivo "}},[_c('b-form-file',{attrs:{"placeholder":"Selecione um arquivo ou solte aqui...","drop-placeholder":"Solte o arquivo aqui...","accept":".jpg, .png, .docx, .jpeg, .pdf, .xsls"},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),(_vm.files)?_c('b-badge',{staticClass:"mt-3",attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.files ? _vm.files.name : null)+" "),_c('b-button',{staticClass:"bg-transparent border-0 size-10",attrs:{"variant":""},on:{"click":function($event){return _vm.cleanFiles()}}},[_c('span',[_vm._v(" x ")])])],1):_vm._e()],1)],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"d-flex justify-content-between"},[_c('BButton',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.hideModal('AddResponsables')}}},[_vm._v(" Cancelar ")]),_c('BButton',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.saveChanges()}}},[_vm._v(" Continuar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }