<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.4883 16.8146L19.0362 12.5712C18.8599 12.2663 18.4699 12.162 18.1651 12.3382C17.8602 12.5144 17.7559 12.9045 17.9321 13.2094L20.3843 17.4528C20.5835 17.7976 20.4508 18.102 20.3846 18.2169C20.3182 18.3319 20.1207 18.5995 19.7216 18.5995H13.2643L14.0185 18.1645C14.3236 17.9885 14.4282 17.5986 14.2523 17.2935C14.0763 16.9885 13.6864 16.8838 13.3814 17.0598L10.5641 18.6849C10.1448 18.9266 10.134 19.5415 10.5641 19.7896L13.3814 21.4146C13.6866 21.5906 14.0764 21.4857 14.2523 21.1808C14.4282 20.8758 14.3235 20.4858 14.0185 20.3099L13.2643 19.8748H19.7216C20.4596 19.8748 21.1203 19.4933 21.4891 18.8543C21.8575 18.2156 21.8572 17.4531 21.4883 16.8146Z"
      class="fill-color"
      fill="#7D8E9E"
      stroke="transparent"
    />
    <path
      d="M6.94898 18.5995H2.04361C1.64457 18.5995 1.44702 18.3319 1.38062 18.2169C1.31435 18.102 1.18168 17.7976 1.38088 17.4528L4.61033 11.8644V12.7369C4.61033 13.0891 4.89579 13.3746 5.2479 13.3746C5.60001 13.3746 5.88547 13.0891 5.88547 12.7369V9.48682C5.88547 8.99161 5.34907 8.6923 4.92933 8.93444L2.11201 10.5595C1.80696 10.7355 1.7023 11.1254 1.87825 11.4305C2.05415 11.7355 2.44401 11.8402 2.74911 11.6642L3.5052 11.2281L0.27689 16.8146C-0.0920556 17.4531 -0.0923107 18.2156 0.27621 18.8543C0.644943 19.4933 1.30568 19.8748 2.04365 19.8748H6.94898C7.3011 19.8748 7.58655 19.5894 7.58655 19.2372C7.58651 18.885 7.3011 18.5995 6.94898 18.5995Z"
      class="fill-color"
      fill="#7D8E9E"
      stroke="transparent"
    />
    <path
      d="M16.5172 5.59909C16.1651 5.59909 15.8796 5.88459 15.8796 6.23674V7.10922L12.6494 1.51939C12.2805 0.881103 11.62 0.5 10.8827 0.5C10.1453 0.5 9.48476 0.881103 9.1159 1.51939L6.66281 5.76429C6.4866 6.06917 6.59092 6.45924 6.89576 6.63545C7.20069 6.81174 7.59063 6.70733 7.76684 6.40245L10.2199 2.15756C10.4193 1.81254 10.7498 1.7753 10.8827 1.7753C11.0155 1.7753 11.346 1.81254 11.5454 2.15756L14.7746 7.74551L14.0185 7.30945C13.7136 7.13345 13.3236 7.23811 13.1476 7.54321C12.9717 7.84826 13.0764 8.23821 13.3814 8.41416L16.1987 10.0392C16.1989 10.0393 16.199 10.0394 16.1992 10.0394C16.6267 10.2853 17.1548 9.97317 17.1548 9.48686V6.23674C17.1548 5.88459 16.8694 5.59909 16.5172 5.59909Z"
      class="fill-color"
      fill="#7D8E9E"
      stroke="transparent"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
}
</script>
