<template>
  <div>
    <h4 class="modal-title">
      Deseja realmente cancelar essa conta ?
    </h4>
    <h6 class="modal-subtitle">
      Está ação é irreversível, deseja continuar ?
    </h6>
    <div class="d-flex justify-content-between mt-5">
      <b-button
        class="pr-4 pl-4 mr-5"
        variant="primary"
        @click="hideModal('CancelPlan')"
      >
        Não, voltar
      </b-button>
      <b-button
        variant="outline-danger"
        class="pr-4 pl-4 m-0"
        @click="cancelPlan()"
      >
        Sim, continuar
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  BButton,
} from 'bootstrap-vue'
import accounts from '@/services/accounts'

export default {
  components: {
    BButton,
  },
  methods: {
    cancelPlan() {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user.role === 'Master') {
        accounts.postCancelPlanMaster(this.$route.params.id).then(() => {
          this.hideModal('CancelPlan')
          this.toast({
            title: 'Cancelamento Realizado',
            text: 'Cancelamento realizada',
            variant: 'warning',
          })
        })
      } else {
        accounts.postCancelPlan().then(() => {
          this.hideModal('CancelPlan')
          this.toast({
            title: 'Solicitação realizada',
            text: 'Solicitação de cancelamento realizada, logo você receberá um e-mail com a confirmação.',
            variant: 'warning',
          })
        })
      }
    },
  },
}
</script>
