import accountable from '@/services/accountable'

export default {
  namespaced: true,
  state: {
    accountableData: null,
    accountable: [],
    accountableOptions: [{ text: 'Nenhuma das opções', value: null }],
  },
  getters: {
    getAccountableData: state => state.accountableData,
    getAccountable: state => state.accountable,
    getAccountableOptions: state => state.accountableOptions,
    getAccountableLicensing: state => id => state.accountable.filter(arr => arr.lincensing_id === Number(id)),
    getAccountableLicenseConditions: state => id => state.accountable.filter(arr => arr.licensingCondition_id === Number(id)),
  },
  mutations: {
    SET_ACCOUNTABLE_DATA(state, payload) {
      state.accountableData = payload
    },
    SET_ACCOUNTABLE(state, payload) {
      state.accountable = payload
    },
  },
  actions: {
    fetchAccountable({ state, commit }) {
      accountable.getAccountables().then(res => {
        commit('SET_ACCOUNTABLE', res.data.filter(arr => arr.isActive))
        state.accountableOptions.push(...state.accountable.map(arr => ({ text: arr.name, value: arr.id })))
      })
    },
    fetchAccountableById({ commit }, id) {
      accountable.getAccountableById(id).then(res => commit('SET_ACCOUNTABLE_DATA', res.data))
    },
  },
}
