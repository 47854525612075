import accounts from '@/services/accounts'

export default {
  namespaced: true,
  state: {
    accountsData: null,
    accounts: [],
    payment: [],
    myPayment: [],
    myPlan: null,
  },
  getters: {
    getAccountsData: state => state.accountsData,
    getAccounts: state => state.accounts,
    getPayment: state => state.payment,
    getMyPayment: state => state.myPayment,
    getMyPlan: state => state.myPlan,
  },
  mutations: {
    SET_ACCOUNTS_DATA(state, payload) {
      state.accountsData = payload
    },
    SET_ACCOUNTS(state, payload) {
      state.accounts = payload
    },
    SET_PAYMENT(state, payload) {
      state.payment = payload
    },
    SET_MY_PAYMENT(state, payload) {
      state.myPayment = payload
    },
    SET_MY_PLAN(state, payload) {
      state.myPlan = payload
    },
  },
  actions: {
    fetchAccounts({ commit }) {
      accounts.getAccounts().then(res => commit('SET_ACCOUNTS', res.data))
    },
    fetchAccountsById({ commit }, id) {
      accounts.getAccountById(id).then(res => commit('SET_ACCOUNTS_DATA', res.data))
    },
    fetchPayment({ commit }) {
      accounts.getAccountsPayment().then(res => commit('SET_PAYMENT', res.data.filter(arr => arr.isActive)))
    },
    fetchMyPayment({ commit }, id) {
      accounts.getAccountsPaymentByAccountId(id).then(res => commit('SET_MY_PAYMENT', res.data.filter(arr => arr.isActive)))
    },
    fetchMyPlan({ commit }, id) {
      accounts.getAccountsPlanByAccountId(id).then(res => commit('SET_MY_PLAN', res.data))
    },
  },
}
