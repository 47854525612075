<template>
  <div>
    <div class="d-flex justify-content-between">
      <b-form-group
        label="Status"
        class="w-100"
      >
        <BFormInput placeholder="Em execução" />
      </b-form-group>
      <b-form-group
        label="Título"
        class="w-100 mx-3"
      >
        <BFormInput placeholder="Simples nacional" />
      </b-form-group>
      <b-form-group
        label="Responsável"
        class="w-100"
      >
        <BFormInput placeholder="Murylo rique" />
      </b-form-group>
    </div>
    <div class="grid">
      <b-form-group label="Prazo">
        <b-form-select
          :options="deadlineOptions"
        />
      </b-form-group>
      <b-form-group label="Notificação">
        <b-form-select
          :options="notificationOptions"
        />
      </b-form-group>
      <b-form-group label="Fornecedor">
        <b-input-group class="input-group-merge">
          <b-form-input
            type="text"
            placeholder="Casa do Óleo"
          />
          <b-input-group-append is-text>
            <VSIcons
              name="SearchIcon"
              color="#A0AEB9"
            />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group label="Protocolo">
        <b-form-select
          :options="protocolOptions"
        />
      </b-form-group>
    </div>
    <div class="d-flex">
      <b-form-textarea
        placeholder="Descrição"
        class="mr-3"
      />
      <b-form-textarea placeholder="Recorrência" />
    </div>
    <div class="grid-2 mt-3">
      <b-form-group label="Nome do arquivo">
        <b-form-input
          type="text"
        />
      </b-form-group>
      <b-form-group label="Descrição do arquivo">
        <b-form-input
          type="text"
        />
      </b-form-group>
    </div>
    <b-form-group label="Anexar arquivo ">
      <b-form-file
        v-model="files"
        placeholder="Selecione um arquivo ou solte aqui..."
        drop-placeholder="Solte o arquivo aqui..."
      />
      <b-badge
        v-if="files.name"
        variant="primary"
        class="mt-3"
      >
        {{ files.name }}
        <span>X</span>
      </b-badge>
    </b-form-group>
    <div class="d-flex justify-content-between">
      <BButton
        variant="outline-primary"
        @click="hideModal('AddConditions')"
      >
        Cancelar
      </BButton>
      <BButton variant="primary">
        Continuar
      </BButton>
    </div>
  </div>
</template>

<script>
import {
  BFormInput, BFormGroup, BFormSelect, BInputGroupAppend, BInputGroup, BFormTextarea, BBadge, BButton, BFormFile,
} from 'bootstrap-vue'
import VSIcons from '@/assets/icons/VSIcons.vue'

export default {
  components: {
    BFormInput, BFormGroup, BFormSelect, BInputGroupAppend, VSIcons, BInputGroup, BFormTextarea, BBadge, BButton, BFormFile,
  },
  data() {
    return {
      deadline: '',
      deadlineOptions: ['60 dias'],
      notificationOptions: ['Sim', 'Não'],
      protocolOptions: ['Sim', 'Não'],
      files: [],
    }
  },
}
</script>

<style lang='scss' scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
}
.grid-2 {
display: grid;
grid-template-columns: 280px calc(100% - 280px);
column-gap: 1rem;
}

textarea {
  min-height: 82px;
}
</style>
