<template>
  <div
    class="wrapper"
  >
    <div class="container-fluid position-relative">
      <div
        class=""
        @click="closeModal()"
      >
        <VSIcon
          class="close-modal"
          name="close"
          color="#A0AEB9"
        > x </VSIcon>
      </div>
      <b-row>
        <b-col md="7">
          <h2>Informações do Ticket</h2>
          <b-row>
            <b-col>
              <b-form-group label="Data da abertura">
                <b-input-group>
                  <b-form-input
                    :value="createdDate"
                    disabled
                    type="date"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col class="pl-0">
              <b-form-group label="Protocolo">
                <b-input-group>
                  <b-form-input
                    :value="protocolToken"
                    disabled
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col class="pl-0">
              <b-form-group label="Última atualização">
                <b-input-group>
                  <b-form-input
                    :value="updatedDate"
                    disabled
                    type="date"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col class="pl-0">
              <b-form-group label="Status">
                <b-input-group>
                  <b-form-select
                    v-model="statusSelected"
                    :disabled="userData.permission.name !== 'Master'"
                    :options="statusOptions"
                    @change="updateStatus()"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <hr class="mt-1">
          <h3 class="title">
            {{ ticketData.subject }}
          </h3>
          <p class="message">
            {{ ticketData.description }}
          </p>
        </b-col>
        <div
          class="col-md-5 right-side"
        >
          <span class="title d-block mb-4 pl-4"> Resposta do ticket </span>
          <div
            ref="messagesbox"
            class="messages"
          >
            <div
              v-for="(item, index) in messages"
              :key="index"
            >

              <TicketMessage
                :message="item.message"
                :username="getUserName(item.sender.full_name)"
                :date="getMessageDate(item.createdAt)"
                user-image="verdesys-notifications-logo.png"
              />
            </div>
          </div>

          <BFormGroup
            label="Responder"
            class="pl-4"
          >
            <BFormTextarea
              v-model="answerValue"
              :disabled="ticketData.status !== 'Aberto'"
              class="textarea pl-4"
              placeholder="Escrever resposta..."
            />
          </BFormGroup>
          <BButton
            :disabled="ticketData.status !== 'Aberto'"
            class="send-button"
            variant="primary"
            @click="sendTicketMessage"
          >
            Enviar
          </BButton>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BFormTextarea, BFormGroup, BButton, BFormSelect, BFormInput, BInputGroup, BRow, BCol,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import VSIcon from '@/assets/icons/VSIcons.vue'
import TicketMessage from '@/components/modals/modal-content/ticket-content/TicketMessage.vue'
import tickets from '@/services/tickets'

export default {
  components: {
    VSIcon,
    TicketMessage,
    BFormTextarea,
    BFormGroup,
    BButton,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BRow,
    BCol,
  },
  props: {
    properties: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      messages: '',
      answerValue: '',
      statusOptions: ['Aberto', 'Fechado', 'Resolvido'],
      statusSelected: '',
      ticketData: {},
    }
  },

  computed: {
    ...mapGetters({ userData: 'auth/getUserData' }),
    protocolToken() {
      return `#${this.properties.protocol}`
    },
    createdDate() {
      return this.properties.createdAt.split('T')[0]
    },
    updatedDate() {
      return this.properties.last_update.split('T')[0]
    },
  },
  created() {
    this.getTicketMessages()
    this.fetchTicketData(this.properties.protocol)
  },
  updated() {
    this.scrollToBottom()
  },

  methods: {
    ...mapActions({ fetchTickets: 'tickets/fetchTickets' }),
    scrollToBottom() {
      const div = this.$refs.messagesbox
      div.scrollTop = div.scrollHeight
    },
    getUserName(name) {
      return name || 'Sem nome'
    },
    getTicketMessages() {
      return new Promise(resolve => {
        tickets.getTicketMessages(this.properties.protocol).then(res => {
          this.messages = res.data
          resolve(res.data)
        })
      })
    },
    correctDate(date) {
      const correctDate = new Date(date)
      return correctDate.toLocaleString('pt-br').split(' ')[0]
    },
    getMessageDate(date) {
      const correctDate = new Date(date)
      return correctDate.toLocaleString('pt-br')
    },

    fetchTicketData(id) {
      tickets.getTicketByid(id).then(res => {
        this.ticketData = res.data
        this.statusSelected = res.data.status
      })
    },

    updateStatus() {
      const ticket = { protocol: this.properties.protocol, status: this.statusSelected }
      tickets.editTicket(ticket).then(() => {
        this.fetchTicketData(this.properties.protocol)
        this.fetchTickets()
        this.toast({
          title: 'Atualizado!',
          text: 'Ticket atualizado com sucesso.',
          variant: 'success',
        })
      })
    },

    sendTicketMessage() {
      const newMessage = {
        message: this.answerValue,
        protocol_id: this.properties.protocol,
      }
      if (this.answerValue) {
        tickets.setNewMessage(newMessage).then(() => {
          this.toast({
            title: 'Mensagem enviado com sucesso',
            text: 'Sua mensagem foi enviado com sucesso',
            variant: 'success',
          })
          this.getTicketMessages().then(() => {
            this.scrollToBottom()
          })
        })
        this.answerValue = ''
      } else {
        this.toast({
          title: 'Preencha a mensagem',
          text: 'Preencha a mensagem para enviar.',
          variant: 'warning',
        })
      }
    },
    closeModal() {
      this.hideModal('TicketChat')
      this.fetchTickets()
    },
  },
}
</script>

<style lang='scss' scoped>

.messages {
  max-height: 250px;
  overflow: scroll;
}
h2, .title {
font-weight: 600;
font-size: 15px;
line-height: 100%;
color: #33383C;
opacity: 0.87;
}
.textarea {
background: #FFFFFF;
border: 2px solid #F6F8F9;
box-sizing: border-box;
border-radius: 5px;
min-height: 82px;
margin-top: 5px;
&::placeholder {
font-weight: 500;
font-size: 13px;
line-height: 16px;
letter-spacing: 0.75px;
color: #7D8E9E;
opacity: 0.38;

}
}
.inputs-wrapper {
  .label {
    white-space: nowrap;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #7D8E9E;
  }
  .date {
  background: rgba(125, 142, 158, 0.13);
  border-radius: 5px;
  text-align: center;
  padding: 16px 13px;
  color: #33383C;

  }
  .status {
    background: rgba(26, 183, 127, 0.21);
    border-radius: 5px;
    color: #18AC81;
  }
  .title {
    font-weight: 600;
    font-size: 21px;
    line-height: 100%;
    color: #33383C;
  }
}
  .message {
  font-size: 15px;
  line-height: 22px;
  color: #7D8E9E;
  }
  .right-side {
    border-left: solid 1px rgba(160, 174, 185, 0.21);
  }
  .send-button {
    display: block;
    margin-left: auto;
    width: 190px;
  }
  .close-modal {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(5px) translateY(-20px);
    color:  #7D8E9E;
    z-index: 20;
    cursor: pointer;
  }

.select-status {
  // height: 45px;
  display: flex;
}
</style>
