<template>
  <div>
    <h4 class="modal-title">
      Buscar Código do Resíduo
    </h4>
    <b-row>
      <b-col>
        <b-input-group class="input-group-merge round search-input-filter w-50">
          <b-form-input
            id="filter-input"
            v-model="filter"
            placeholder="Buscar..."
            type="search"
          />
        </b-input-group>
      </b-col>
    </b-row>
    <b-table
      id="search-code-table"
      ref="SearchCodeTable"
      class="details-table mt-2"
      :items="items"
      :fields="fields"
      hover
      responsive
      details-td-class="align-middle"
      :busy="apiInCall"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      empty-filtered-text="Não há resultados para sua pesquisa."
      empty-text="Não há registros para serem exibidos."
      show-empty
      @filtered="onFiltered"
    >
      <template #table-busy>
        <b-spinner variant="primary" />
      </template>
      <template
        #cell(select)="row"
      >
        <b-form-checkbox
          v-model="selectedItem"
          :checked="row.item === selectedItem"
          :value="row.item"
        />
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="search-code-table"
    >
      <template #prev-text>
        <VSIcons name="ArrowLeft" />
      </template>
      <template #next-text>
        <VSIcons name="ArrowRight" />
      </template>
    </b-pagination>
    <div class="d-flex justify-content-between">
      <b-button
        variant="outline-success pr-4 pl-4 mr-5"
        @click="hideModal('SearchCode')"
      >
        Cancelar
      </b-button>
      <BButton
        variant="primary"
        @click="sendData()"
      >
        Salvar
      </BButton>
    </div>
  </div>
</template>

<script>
import {
  BInputGroup, BRow, BCol, BButton, BFormInput, BTable, BSpinner, BPagination, BFormCheckbox,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { tableConfig } from '@core/mixins/ui/forms'
import VSIcons from '@/assets/icons/VSIcons.vue'

export default {
  components: {
    BInputGroup,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BSpinner,
    BPagination,
    VSIcons,
    BFormCheckbox,
  },
  mixins: [
    tableConfig,
  ],
  props: {
    sendWasteData: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      disbleInputs: false,
      selectedItem: null,
      item: {},
      fields: [
        {
          label: '',
          key: 'select',
          tdClass: 'align-middle',
        },
        {
          label: 'Capítulo',
          key: 'capitulo',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Descrição',
          key: 'descricao',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Subcapítulo',
          key: 'subcapitulo',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Cod. Resíduo',
          key: 'codResiduo',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Descrição 2',
          key: 'descricao2',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Descrição 3',
          key: 'descricao3',
          sortable: true,
          tdClass: 'align-middle',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'waste/getWasteCode',
    }),
  },
  created() {
    if (this.properties.action === 'read' || this.properties.action === 'edit') { this.item = this.properties.item }
    if (this.properties.action === 'read') { this.disbleInputs = true }
  },
  methods: {
    sendData() {
      if (this.selectedItem) {
        this.sendWasteData(this.selectedItem)
      } else {
        this.toast({
          title: 'Erro',
          text: 'Selecione um código para salvar',
          variant: 'erro',
        })
      }
    },
  },
}
</script>

<style>

</style>
