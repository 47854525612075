<template>
  <modal
    ref="listing"
    :name="name"
    :width="width"
    :height="height"
    :draggable="draggable"
    :scrollable="true"
    classes="v-modal-style"
    @before-open="handleBeforeOpen"
  >
    <component
      :is="name"
      ref="componentModal"
      :properties="properties"
      :conflict-list="conflictList"
      :open-modal-search-code="openModalSearchCode"
      :send-waste-data="sendWasteData"
    />
  </modal>
</template>
<script>
import AddNewContact from './modal-content/AddNewContact.vue'
import AddNewDocumentsActivies from './modal-content/AddNewDocumentsActivies.vue'
import ContactDetails from './modal-content/ContactDetails.vue'
import DeleteItem from './modal-content/DeleteItem.vue'
import DocumentsConditioningRelations from './modal-content/DocumentsConditioningRelations.vue'
import UploadFile from './modal-content/UploadFile.vue'
import Inventory from './modal-content/Inventory.vue'
import SearchCode from './modal-content/SearchCode.vue'
import Operations from './modal-content/Operations.vue'
import NewPermission from './modal-content/NewPermission.vue'
import ChoosePlan from './modal-content/ChoosePlan.vue'
import DisconnectedSession from './modal-content/DisconnectedSession.vue'
import NewPassword from './modal-content/NewPassword.vue'
import DisconnectAllSessions from './modal-content/DisconnectAllSessions.vue'
import AddLogbook from './modal-content/AddLogbook.vue'
import AddNotifications from './modal-content/AddNotifications.vue'
import AddSurveys from './modal-content/AddSurveys.vue'
import AddResponsables from './modal-content/AddResponsables.vue'
import AddConditions from './modal-content/AddConditions.vue'
import Notifications from './modal-content/Notifications.vue'
import Feedback from './modal-content/Feedback.vue'
import DownloadReport from './modal-content/DownloadReport.vue'
import DetailFeedback from './modal-content/DetailFeedback.vue'
import Ticket from './modal-content/Ticket.vue'
import TicketChat from './modal-content/TicketChat.vue'
import AlternanteUserState from './modal-content/AlternateUserState.vue'
import EditContact from './modal-content/EditContact.vue'
import DeleteOneItem from './modal-content/DeleteOneItem.vue'
import AddNewAvatarUser from './modal-content/AddNewAvatarUser.vue'
import CreateNotification from './modal-content/CreateNotification.vue'
import CancelPlan from './modal-content/CancelPlan.vue'
import RenewPlanTrial from './modal-content/RenewPlanTrial.vue'
import Import from './modal-content/Import.vue'
import ImportError from './modal-content/ImportError.vue'

export default {
  components: {
    TicketChat,
    Ticket,
    Notifications,
    AddConditions,
    AddResponsables,
    AddSurveys,
    AddNotifications,
    AddLogbook,
    DisconnectAllSessions,
    NewPassword,
    DisconnectedSession,
    ChoosePlan,
    NewPermission,
    AddNewContact,
    AddNewDocumentsActivies,
    ContactDetails,
    DeleteItem,
    DocumentsConditioningRelations,
    UploadFile,
    Inventory,
    SearchCode,
    Operations,
    Feedback,
    DownloadReport,
    DetailFeedback,
    AlternanteUserState,
    EditContact,
    DeleteOneItem,
    AddNewAvatarUser,
    CreateNotification,
    CancelPlan,
    RenewPlanTrial,
    Import,
    ImportError,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    height: {
      type: [String, Number],
      default: 'auto',
    },
    width: {
      type: [String, Number],
      default: 470,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    properties: {
      type: [Object, Boolean, String, Number],
      default: null,
    },
    conflictList: {
      type: [Array],
      default: null,
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    openModalSearchCode: {
      type: Function,
      default: null,
    },
    sendWasteData: {
      type: Function,
      default: null,
    },
  },
  methods: {
    handleBeforeOpen() {
      this.$refs.listing.modal.renderedHeight = ''
    },
    receiveWasteData(data) {
      this.$refs.componentModal.receiveWasteData(data)
    },
  },
}
</script>
