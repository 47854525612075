<template>
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6 3.9H15.4M10.6 6.3H13M16.2 9.1C16.2 13.3 12.8 16.7 8.6 16.7C4.4 16.7 1 13.3 1 9.1C1 4.9 4.4 1.5 8.6 1.5M17 17.5L15.4 15.9"
      stroke="#A0AEB9"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

</template>

<script>
export default {

}
</script>

<style>

</style>
