import license from '@/services/license'

export default {
  namespaced: true,
  state: {
    licensingConditionsData: null,
    licensingConditions: [],
    licensingConditionsConstraints: [],
    licensingConditionsConstraintsRecurrence: [],
    licenseModeView: '',
    apiLicensingConditionsIncall: false,
    isCondAndActTabActive: false,
  },
  getters: {
    getLicensingConditionsData: state => state.licensingConditionsData,
    getLicensingConditions: state => state.licensingConditions,
    getLicensingConditionsConstraints: state => state.licensingConditionsConstraints,
    getLicensingConditionsConstraintsRecurrence: state => state.licensingConditionsConstraintsRecurrence,
    getLicenseModeView: state => state.licenseModeView,
    getLicensingConditionsApiInCall: state => state.apiLicensingConditionsIncall,
    getIsCondAndActTabActive: state => state.isCondAndActTabActive,
  },
  mutations: {
    SET_LICENSING_CONDITIONS_DATA(state, payload) {
      state.licensingConditionsData = payload
    },
    SET_LICENSING_CONDITIONS(state, payload) {
      state.licensingConditions = payload
    },
    SET_LICENSING_CONDITIONS_CONSTRAINTS(state, payload) {
      state.licensingConditionsConstraints = payload
    },
    SET_LICENSING_CONDITIONS_CONSTRAINTS_RECURRENCE(state, payload) {
      state.licensingConditionsConstraintsRecurrence = payload
    },
    SET_LICENSE_MODE_VIEW(state, payload) {
      state.licenseModeView = payload
    },
    SET_API_LICENSING_CONDITIONS_IN_CALL(state, payload) {
      state.apiLicensingConditionsIncall = payload
    },
    SET_IS_COND_AND_ACT_TAB_ACTIVE(state, payload) {
      state.isCondAndActTabActive = payload
    },
  },
  actions: {
    fetchLicensingConditions({ commit, state }) {
      license.getLicensingConditions().then(res => {
        commit('SET_LICENSING_CONDITIONS', res.data.filter(arr => arr.isActive))
        state.apiLicensingConditionsIncall = false
      })
    },
    fetchLicensingConditionsById({ commit }, id) {
      license.getLicensingConditionsById(id).then(res => commit('SET_LICENSING_CONDITIONS_DATA', res.data))
    },
    fetchLicensingConditionsConstraints({ commit }, id) {
      license.getLicensingConditionsConstraints().then(res => commit('SET_LICENSING_CONDITIONS_CONSTRAINTS', res.data.filter(arr => arr.isActive && Number(id) === arr.licensingCondition_id && !arr.first_constraint_id)))
    },
    async fetchLicensingConditionsConstraintsRecurrence({ commit, state }, id) {
      try {
        const response = await license.getLicensingConditionsConstraintsRecurrence(id)
        console.log('Valor do getter antes da mutação:', state.licensingConditionsConstraintsRecurrence)
        commit('SET_LICENSING_CONDITIONS_CONSTRAINTS_RECURRENCE', response.data.filter(arr => arr.isActive))
        console.log('Valor do getter após a mutação:', state.licensingConditionsConstraintsRecurrence)
        return response.data.filter(arr => arr.isActive)
      } catch (error) {
        console.error('Erro ao buscar as condições de licenciamento:', error)
        throw error
      }
    },
    fetchAndActivateCondAndActTab({ commit }, status) {
      commit('SET_IS_COND_AND_ACT_TAB_ACTIVE', status)
    },
  },
}
