<template>
  <div>
    <div>
      <h4 class="modal-title">
        {{ `${properties.action === 'create' ? 'Criar' : properties.action === 'edit' ? 'Editar' : 'Detalhes'} notificação` }}
      </h4>
      <h6 class="modal-subtitle">
        {{ `${properties.action === 'create' ? 'Crie' : properties.action === 'edit' ? 'Edite' : 'Veja'} uma notificação notificação.` }}
      </h6>
    </div>
    <validation-observer ref="form">
      <b-row>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="nome"
            rules="required|max:100"
          >
            <b-form-group label="Titulo da notificação">
              <b-input-group>
                <b-form-input
                  id="titulo"
                  v-model="item.subject"
                  type="text"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="data de envio"
            rules="required"
          >
            <b-form-group label="Data de envio">
              <b-input-group>
                <b-form-input
                  id="data-envio"
                  v-model="item.sendDate"
                  type="datetime-local"
                  :disabled="disbleInputs"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <b-form-group label="Tipo">
            <b-input-group>
              <b-form-select
                id="tipo"
                v-model="item.type"
                :options="senderOptions"
                :disabled="disbleInputs"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            v-slot="{ ariaDescribedby }"
            label="Enviar para:"
          >
            <b-form-checkbox-group
              id="checkbox-group-1"
              v-model="optionsSendSelected"
              :options="optionsSend"
              :aria-describedby="ariaDescribedby"
              name="flavour-1"
              :disabled="disbleInputs"
              @change="handleOptionsSend()"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Selecionar usuário"
          >
            <b-input-group>
              <b-form-select
                id="tipo"
                v-model="item.userListIdArray"
                class="select-user"
                multiple
                :options="userOptions"
                :disabled="disbleInputs || optionsSendSelected !== 'select'"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Mensagem da notificação">
            <b-input-group>
              <b-form-textarea
                id="forma-envio"
                v-model="item.message"
                :disabled="disbleInputs"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <div class="d-flex justify-content-between">
      <BButton
        variant="outline-primary"
        @click="hideModal('CreateNotification')"
      >
        Cancelar
      </BButton>
      <BButton
        v-if="!disbleInputs"
        variant="primary"
        @click="saveChanges()"
      >
        Salvar
      </BButton>
      <BButton
        v-else
        variant="primary"
        @click="() => {
          properties.action = 'edit'
          disbleInputs = false
        }"
      >
        Editar
      </BButton>
    </div>
  </div>

</template>

<script>
import {
  BFormInput, BInputGroup, BFormGroup, BButton, BRow, BCol, BFormTextarea, BFormSelect, BFormCheckboxGroup,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import announcements from '@/services/announcements'

export default {
  components: {
    BFormInput, BInputGroup, BFormGroup, BButton, BRow, BCol, BFormTextarea, BFormSelect, BFormCheckboxGroup,
  },
  props: {
    properties: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      disbleInputs: false,
      files: null,
      item: {
        userListIdArray: [],
      },
      optionsSendSelected: 'select',
      senderOptions: [
        'Sistema',
        'E-mail',
        'Email e Sistema',
      ],
    }
  },
  computed: {
    ...mapGetters({ userOptions: 'users/getUsersMasterOptions', users: 'users/getUsersMaster' }),
    optionsSend() {
      return [
        { text: 'Selecionar usuários', value: 'select' },
        { text: 'Todos administradores', value: 'admin' },
        { text: 'Todos usuários', value: 'all' },
      ]
    },
  },
  created() {
    this.fetchUsersMaster()
    if (this.properties.action === 'read' || this.properties.action === 'edit') {
      this.item = this.properties.item
    }
    if (this.properties.action === 'read') { this.disbleInputs = true }
  },
  methods: {
    ...mapActions({ fetchAnnouncements: 'announcements/fetchAnnouncements', fetchUsersMaster: 'users/fetchUsersMaster' }),
    handleOptionsSend() {
      if (this.optionsSendSelected === 'all') {
        this.item.userListIdArray = this.userOptions.map(arr => arr.value)
      } else if (this.optionsSendSelected === 'admin') {
        this.item.userListIdArray = this.users.filter(arr => arr.role === 'Administrador').map(arr => arr.id)
      }
    },
    saveChanges() {
      announcements.createAnnouncements(this.item).then(() => {
        this.toast({
          title: 'Criada!',
          text: 'Notificação criada com sucesso',
          variant: 'success',
        })
        this.hideModal('CreateNotification')
        this.fetchAnnouncements()
      })
    },
  },
}
</script>

<style lang='scss' scoped>
.select-user {
  height: 20vh !important;
}
</style>
